import {Inject, Injectable} from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {SouladSPdInfoModel} from "@app/doss/models/soulad-s-pd-info.model";

@Injectable({
  providedIn: 'root'
})
export class SouladSPdService {

  constructor(private restangular: Restangular) {
  }

  update(akceKey: string, ukonId: number, souladSPd: SouladSPdInfoModel): Promise<SouladSPdInfoModel> {
    return this.restangular.one(`ukon/${ukonId}/soulad-s-pd/update`)
      .customPUT(souladSPd)
      .toPromise();
  }
}
