<div>
  <ng-content></ng-content>

  <div class="cb text-left" style="padding-top: 20px">
    <gmt-button *ngIf="!isFirstStep()" [clickAction]="onBackStep">
      {{ backButtonName }}
    </gmt-button>
    <gmt-button *ngIf="!isFinalStep(); else finalStepButton" [gmtDisabled]="!isValid()" [clickAction]="onNextStep">
      {{ nextButtonName }}
    </gmt-button>
  </div>
</div>

<ng-template #finalStepButton>
  <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSubmit">{{ submitButtonName }}</gmt-button>
</ng-template>
