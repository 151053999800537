<div *ngIf="list.itemCount" class="bordered">
  <table>
    <thead class="sticky">
    <th class="cell">
      <sort-by (sort)="onSort('nazev', $event)" [selected]="'nazev' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('dotcenyOrgan') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('adresniMisto', $event)" [selected]="'adresniMisto' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('adresa') }}
      </sort-by>
    </th>
    </thead>

    <tbody *ngFor="let dotcenyOrgan of list.list"
           [uiSref]="'doss.akce.data.organy.detail'" class="row-group" [uiParams]="{id: dotcenyOrgan.id}">
    <tr class="clickable">
      <td class="cell">
        {{ dotcenyOrgan?.nazev }}
      </td>
      <td class="cell">
        {{ dotcenyOrgan?.adresniMisto | address }}
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div *ngIf="!list.itemCount && list.list !== null" class="empty-info bordered">
  Nenalezena žádná data
</div>
