<div class="list">
  <div class="actions">
    <a uiSref="doss.akce.data.organy.create" class="button" *ngIf="canCreate()"><i class="fa fa-wrench"></i>Založit nový DOSS</a>
  </div>

  <h1>Dotčené orgány</h1>
  <ng-container *ngIf="list">
    <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
      <div class="column-100">
        <input class="fulltext" autocomplete="off" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
      </div>
    </filter>

    <div [loading]="list.loading || !settingsLoaded">
      <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
        <div class="flex1 basis0"></div>
        <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
        <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
          <span>Záznamů na stránce</span>
          <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
        </div>
      </div>

      <table-dotcene-organy [list]="list" (sort)="pageableList.onSort($event.column, $event.ascOrDesc)"></table-dotcene-organy>

      <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
      <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
    </div>
  </ng-container>
</div>
<help [helpId]="helpIds.DOSS_ORGANY_LIST" class="cm-help-page"></help>
