import {Component, Input} from '@angular/core';
import {
  CellAbstractComponent,
  CellTooltipMappingType
} from "@app/common/components/cell-abstract/cell-abstract.component";
import {
  TooltipKategorieDossComponent
} from "@app/doss/components/tooltip-kategorie-doss/tooltip-kategorie-doss.component";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";

@Component({
  selector: 'cell-kategorie-doss',
  templateUrl: './cell-kategorie-doss.component.html',
  styleUrls: ['./cell-kategorie-doss.component.scss']
})
export class CellKategorieDossComponent extends CellAbstractComponent {
  @Input() list = true;
  @Input() kategorieDoss: KategorieDossModel[];

  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'kategorie-doss': TooltipKategorieDossComponent,
  };
}
