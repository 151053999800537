﻿import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {KontaktniOsobaModel} from "@app/doss/models/kontaktni-osoba.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Pipe({
  name: 'kontaktniOsoba',
  pure: false,
})
@Injectable({
  providedIn: 'root'
})
export class KontaktniOsobaPipe implements PipeTransform {

  transform(kontaktniOsoba: KontaktniOsobaModel, withContact = false) {
    if (!kontaktniOsoba) {
      return '';
    }

    const userNameInfo: string[] = [];

    if (!!kontaktniOsoba.titulPredJmenem) {
      userNameInfo.push(kontaktniOsoba.titulPredJmenem);
    }

    if (!!kontaktniOsoba.krestniJmeno) {
      userNameInfo.push(kontaktniOsoba.krestniJmeno);
    }

    if (!!kontaktniOsoba.prijmeni) {
      userNameInfo.push(kontaktniOsoba.prijmeni);
    }

    if (!!kontaktniOsoba.titulZaJmenem) {
      userNameInfo.push(kontaktniOsoba.titulZaJmenem);
    }

    let userInfo: string = userNameInfo.join(' ');

    if (!!kontaktniOsoba.resenaAgenda && withContact) {
      userInfo += ', '  + kontaktniOsoba.resenaAgenda;
    }

    const contact = [];

    if (withContact) {
      if (!!kontaktniOsoba.telefon) {
        contact.push(kontaktniOsoba.telefon);
      }

      if (!!kontaktniOsoba.email) {
        contact.push(kontaktniOsoba.email);
      }

      if (ValidationUtils.isNonEmptyArray(contact)) {
        userInfo += `,\n${contact.join(' ')}`;
      }
    }

    return userInfo;
  }
}

