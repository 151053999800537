<div *ngIf="tooltipComponent && tooltipData && ValidationUtils.isNonEmptyArray(propojeniList)"
     [activeContent]="true"
     [data]="tooltipData"
     [tooltip]="tooltipComponent"
     class="tooltip-hover-area fill-content"
>
  <div class="fill-content flexbox-container flex-centered p-10">
    <div class="icon-count flexbox-container">
      <i class="fa fa-exchange" aria-hidden="true"></i>
    </div>
  </div>
</div>
