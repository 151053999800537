import {Component, Inject, OnInit} from '@angular/core';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';
import { OblibenyUkonModel } from '@app/doss/models/oblibeny-ukon.model';
import { RESTANGULAR_DOSS } from '@app/common/services/restangular-doss.service';
import { ValidationUtils } from '@app/doss/utils/validation.utils';

@Component({
    selector: 'home-oblibene',
  templateUrl: './home-oblibene.component.html',
  styleUrls: ['./home-oblibene.component.scss']
})
export class HomeOblibeneUkonyComponent implements OnInit {
  ValidationUtils = ValidationUtils;

  oblibeneUkonyList: OblibenyUkonModel[];
  loading: boolean;
  uniqueAkceSchemas: string[] = [];
  akceNames: Map<string, string> = new Map();
  ukonyForAkce: Map<string, OblibenyUkonModel[]> = new Map();

  constructor(
    @Inject(RESTANGULAR_DOSS) public restangularDoss: any,
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(APP_BRAND) public APP_BRAND: any
  ) {}

  async ngOnInit() {
    await this.loadOblibeneUkony();
    this.processAkceSchemasAndDetails();

  }

  private loadOblibeneUkony():  Promise<void> {
   return this.restangularDoss
      .one('/home/liked')
      .getList().toPromise()
      .then(data => this.oblibeneUkonyList = data);
  }

  private processAkceSchemasAndDetails(): void {
    if (!ValidationUtils.isNonEmptyArray(this.oblibeneUkonyList)) {
      return;
    }

    this.uniqueAkceSchemas = Array.from(new Set(this.oblibeneUkonyList.map(item => item.akceSchema)));

    this.uniqueAkceSchemas.forEach(schema => {
      this.akceNames.set(schema, this.getAkceName(schema));
      this.ukonyForAkce.set(schema, this.getUkonyForAkce(schema));
    });
  }

  getAkceName(akceSchema: string): string {
    if (!ValidationUtils.isNonEmptyArray(this.oblibeneUkonyList)) {
      return '';
    }
    const found = this.oblibeneUkonyList.find(item => item.akceSchema === akceSchema);
    return found ? found.nazevAkce : '';
  }

  getUkonyForAkce(akceSchema: string) {
    if (!ValidationUtils.isNonEmptyArray(this.oblibeneUkonyList)) {
      return [];
    }
    return this.oblibeneUkonyList.filter(item => item.akceSchema === akceSchema);
  }

}
