import {Component, Inject, OnInit} from '@angular/core';
import {PageableList} from "@app/models/pageable.list";
import * as _ from "lodash";
import {PageableListService} from "@app/common/services/pageable.list.service";
import {SettingsService} from "@app/ps/services/settings.service";
import {StateService} from "@uirouter/angular";
import {RESTANGULAR_CONFIGURATION} from "@app/common/services/restangular-configuration.service";
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import {RESTANGULAR_SETTINGS} from "@app/common/services/restangular-settings.service";
import {ExportListService} from "@app/ps/services/export-list.service";
import {SelectItem} from "@app/common/components/select/select.component";
import {StavAkceModel} from "@app/doss/models/stav-akce.model";
import {AuthService} from "@app/common/services/auth.service";
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './akce-list.component.html',
  styleUrls: ['./akce-list.component.scss']
})
export class AkceListComponent implements OnInit {
  list: any;
  helpIds = HelpService.HELP_IDS;
  pageableList: PageableList;
  deferredUpdateStatistics: any;
  settingsLoaded = false;
  exportEnable = true;
  manazerTypeOptions: SelectItem[] = [];
  stavyAkce: StavAkceModel[] = [];

  globalFilter = {
    organizacniJednotkaId: {values: []},
    manazerAkce: {values: []},
    stavAkce: {values: []},
  };

  constructor(
    @Inject(RESTANGULAR_DOSS) public restangularDoss: any,
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangularService: any,
    private pageableListService: PageableListService,
    private settingsService: SettingsService,
    private stateService: StateService,
    private exportListService: ExportListService,
    private authService: AuthService,
    public usernamePipe: UserNamePipe
  ) {
    this.registerUpdateStatisticsEntityFilterKey = this.registerUpdateStatisticsEntityFilterKey.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  async ngOnInit() {
    const promise = new Promise((resolve, reject) => {
      this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
      if (!this.settingsLoaded) {
        this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
      }

      this.deferredUpdateStatistics = {
        resolve: resolve,
        reject: reject,
      };
    });
    this.deferredUpdateStatistics.promise = promise;
    await this.loadOptions();
    await this.loadStavyAkce();
    this.initializeList();
  }

  canCreate() {
    return this.authService.hasPermissionOnProject('admin', 'doss_default');
  }

  canExport() {
    return this.authService.hasPermissionForDossAction('web')
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  registerUpdateStatisticsEntityFilterKey(registerCallback) {
    this.deferredUpdateStatistics.resolve(registerCallback.updateEntityFilterKey);
  }


  private async initializeList() {
    await this.settingsService.getSettingsLoader();
    const filter = this.getDefaultFilter();
    this.pageableList = this.pageableListService.get('akce/list', filter, 'akceFilter', this.restangularDoss);

    this.list = this.pageableList.list;

    _.assign(this.globalFilter, _.mapValues(this.globalFilter, (value, filterKey) => this.list.filter.filters[filterKey] || value));

    await this.initStatisticsEntityFilter();
    // this.resolveParams();
    this.pageableList.load()
  }

  async initStatisticsEntityFilter() {
    if (this.stateService.params.sef) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.getDefaultFilter().filters;
      await this.deferredUpdateStatistics.promise.then((updateEntityFilterKey) => {
        updateEntityFilterKey(this.stateService.params.sef);
      });
    }
  }

  private getDefaultFilter() {
    return {
      filters: {
        searchText: {values: [{id: ''}]},
        organizacniJednotkaId: { values: [], negation: false },
        manazerAkce: { values: [], negation: false },
        stavAkce: { values: [], negation: false }
      }
    };
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    return this.exportListService
      .exportListDoss(this.restangularDoss, 'akce', 'akce', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  private loadOptions(): Promise<any> {
    return this.restangularSettings
      .all("users/list")
      .customPOST(
        {
          filter: {
            sortOrder: [
              {sortBy: "surname"}, {sortBy: "name"}],
              filters: {
                applications: {
                  values: ['DOSS']
                }
              }
          }})
      .toPromise()
      .then(list => {
        this.manazerTypeOptions = list
    });
  }

  private loadStavyAkce(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/stavyAkce')
      .getList().toPromise()
      .then(data => this.stavyAkce = data);
  }
}
