import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from "@app/common/services/auth.service";
import * as _ from "lodash";
import {SettingsService} from "@app/ps/services/settings.service";
import {PageableList} from "@app/models/pageable.list";
import {PageableListService} from "@app/common/services/pageable.list.service";
import { Restangular } from 'ngx-restangular';
import {StateService} from "@uirouter/angular";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './dotcene-organy-list.component.html',
  styleUrls: ['./dotcene-organy-list.component.scss']
})
export class DotceneOrganyListComponent implements OnInit {
  akceSchema: string;
  helpIds = HelpService.HELP_IDS;

  list: any;
  pageableList: PageableList;
  deferredUpdateStatistics: any;
  settingsLoaded = false;

  globalFilter = {};

  constructor(public restangular: Restangular,
              private authService: AuthService,
              private settingsService: SettingsService,
              private pageableListService: PageableListService,
              private stateService: StateService) {
  }

  ngOnInit() {
    this.akceSchema = this.authService.getActualProject().schema;

    const promise = new Promise((resolve, reject) => {
      this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
      if (!this.settingsLoaded) {
        this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
      }

      this.deferredUpdateStatistics = {
        resolve: resolve,
        reject: reject,
      };
    });
    this.deferredUpdateStatistics.promise = promise;

    this.initializeList();
  }

  canCreate(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  private async initializeList() {
    await this.settingsService.getSettingsLoader();
    const filter = this.getDefaultFilter();
    this.pageableList = this.pageableListService.get(`dotcenyOrgan/list`, filter, 'dotceneOrganyFilter', this.restangular);

    this.list = this.pageableList.list;

    _.assign(this.globalFilter, _.mapValues(this.globalFilter, (value, filterKey) => this.list.filter.filters[filterKey] || value));

    await this.initStatisticsEntityFilter();
    await this.pageableList.load();
  }

  private async initStatisticsEntityFilter() {
    if (this.stateService.params.sef) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.getDefaultFilter().filters;
      await this.deferredUpdateStatistics.promise.then((updateEntityFilterKey) => {
        updateEntityFilterKey(this.stateService.params.sef);
      });
    }
  }

  private getDefaultFilter() {
    return {filters: {searchText: {values: [{id: ''}]}}};
  }
}
