<div class="column-100">
  <dotceny-organ-form [redirectToPageAfterDossSave]="redirectToPageAfterDossSave"
                      [redirectToPageParamsAfterDossSave]="redirectToPageParamsAfterDossSave"
                      [isUpdate]="isUpdate"
                      [ukon]="ukon">
  </dotceny-organ-form>

  <gmt-input *ngIf="!isUpdate; else kategorieDossInfo" label="Kategorie" required="true">
    <gmt-select
      [multiselect]="true"
      [data]="ukon"
      [itemPipe]="kategorieDossPipe"
      [optionItems]="kategorieDoss"
      field="kategorieDoss"
      required="true"
    ></gmt-select>
  </gmt-input>

  <gmt-input *ngIf="!isUpdate" label="Typ úkonu" required="true">
    <gmt-select
      [data]="ukon"
      [itemPipe]="typUkonuPipe"
      [optionItems]="typyUkonu"
      field="typUkonu"
      required="true"
    ></gmt-select>
  </gmt-input>

  <gmt-input label="Úkon na základě ustanovení zákona (předpisu)" required="true">
    <input [(ngModel)]="ukon.naZakladeUstanoveni" type="text"/>
  </gmt-input>

  <gmt-input label="Další popis (zdůvodnění potřeby)">
    <textarea [(ngModel)]="ukon.dalsiPopis" class="input flex-rest" maxlength="255"></textarea>
  </gmt-input>
</div>

<ng-template #kategorieDossInfo>
  <gmt-input label="Kategorie">
    <div class="info">{{ ukon.kategorieDoss | kategorieDossList }}</div>
  </gmt-input>
</ng-template>
