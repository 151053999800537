<div *ngIf="list.itemCount" class="bordered">
  <table>
    <thead class="sticky">
    <th class="cell" style="border-left: 6px solid #f2f2f2">
      <sort-by (sort)="onSort('evidencniCislo', $event)" [selected]="'evidencniCislo' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('evidencniCislo') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('nazevAkce', $event)" [selected]="'nazevAkce' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('nazevAkce') }}
      </sort-by>
    </th>
    <th class="cell">
      {{ getColumnName('stupen') }}
    </th>
    <th class="cell">
      {{ getColumnName('stav') }}
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('manazerAkce', $event)" [selected]="'manazerAkce' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('manazerAkce') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('organizacniJednotkaCode', $event)"
               [selected]="'organizacniJednotkaCode' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('organizacniJednotkaCode') }}
      </sort-by>
    </th>
    <th class="cell">
      {{ getColumnName('zpracovatel') }}
    </th>
    <th class="cell">
      {{ getColumnName('projektant') }}
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('datumSpusteni', $event)" [selected]="'datumSpusteni' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('datumSpusteni') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('predpokladaneDatumUkonceni', $event)" [selected]="'datumUkonceni' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('datumUkonceni') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('datumPosledniZmeny', $event)"
               [selected]="'datumPosledniZmeny' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('datumPosledniZmeny') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('autorPosledniZmeny', $event)"
               [selected]="'autorPosledniZmeny' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('autorPosledniZmeny') }}
      </sort-by>
    </th>
    </thead>
    <tbody *ngFor="let akce of list.list">
    <td [ngStyle]="{'border-left': getRowColor(akce.stav)}" class="cell">
      {{ akce.evidencniCislo }}
    </td>
    <td class="cell">
      <a [uiParams]="{ akceKey: akce.schema }" uiSref="doss.akce.data.overview">{{ akce.nazevAkce }}</a>
    </td>
    <td class="cell">
      {{ akce.stupen }}
    </td>
    <td class="cell">
      {{ akce.stav | stavAkce }}
    </td>
    <td class="cell">
      {{ akce.manazerAkce | username }}
    </td>
    <td class="cell">
      {{ akce.organizacniJednotkaCode }}
    </td>
    <td class="cell">
      {{ akce.zpracovatel | username }}
    </td>
    <td class="cell">
      {{ akce.projektant }}
    </td>
    <td class="cell">
      {{ akce.datumSpusteni | date }}
    </td>
    <td class="cell">
      {{ akce.predpokladaneDatumUkonceni | date }}
    </td>
    <td class="cell">
      {{ akce.datumPosledniZmeny | date }}
    </td>
    <td class="cell">
      {{ akce.autorPosledniZmeny | username }}
    </td>
    </tbody>
  </table>
</div>
<div *ngIf="!list.itemCount && list.list !== null" class="empty-info bordered">
  Nenalezena žádná data
</div>
