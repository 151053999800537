import {Inject, Injectable} from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {UkolCreateModel} from "@app/doss/models/ukol-create.model";
import {UkolModel} from "@app/doss/models/ukol.model";

@Injectable({
  providedIn: 'root'
})
export class UkolService {

  constructor(private restangular: Restangular) {
  }

  create(akceKey: string, ukonId: number, ukol: UkolCreateModel): Promise<UkolModel> {
    return this.restangular.all(`ukon/${ukonId}/ukol/create`)
      .post(ukol)
      .toPromise();
  }

  update(akceKey: string, ukonId: number, ukol: UkolModel, ukolId: number): Promise<UkolModel> {
    return this.restangular.one(`ukon/${ukonId}/ukol/${ukolId}`, ukonId, ukolId)
      .customPUT(ukol)
      .toPromise();
  }

  getById(akceKey: string, ukonId: number, ukolId: number): Promise<UkolModel> {
    return this.restangular.one(`ukon/${ukonId}/ukol/${ukolId}`, ukonId, ukolId)
      .get()
      .toPromise();
  }

  delete(akceKey: string, ukonId: number, ukolId: number): Promise<UkolModel> {
    return this.restangular.one(`ukon/${ukonId}/ukol/${ukolId}`)
      .remove()
      .toPromise();
  }
}
