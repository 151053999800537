import {Component, Inject, OnInit} from '@angular/core';
import {PageableList} from "@app/models/pageable.list";
import {SelectItem} from "@app/common/components/select/select.component";
import { Restangular } from 'ngx-restangular';
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import {PageableListService} from "@app/common/services/pageable.list.service";
import {StateService} from "@uirouter/angular";
import {ExportListService} from "@app/ps/services/export-list.service";
import * as _ from "lodash";
import {AuthService} from "@app/common/services/auth.service";
import {TypUkonuPipe} from "@app/doss/pipes/typ-ukonu.pipe";
import {KategorieDossPipe} from "@app/doss/pipes/kategorie-doss.pipe";
import {AkceModel} from "@app/doss/models/akce.model";
import {ClassName} from "@app/common/enums/class-name.enum";
import {DialogService} from "@app/common/services/dialog.service";
import {
  UkonNelzeZalozitComponent
} from "@app/doss/components/ukon-nelze-zalozit/ukon-nelze-zalozit.component";
import {VerzePdPipe} from "@app/doss/pipes/verze-pd.pipe";
import {StavSouladuSPdModel} from "@app/doss/models/stav-souladu-s-pd.model";
import {DotcenyOrganPipe} from "@app/doss/pipes/dotceny-organ.pipe";
import {StavFazeUkonuFilterPipe} from "@app/doss/pipes/stav-faze-ukonu-filter.pipe";
import {ListService} from "@app/common/services/list.service";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './ukon-list.component.html',
  styleUrls: ['./ukon-list.component.scss']
})
export class UkonListComponent implements OnInit {
  helpIds = HelpService.HELP_IDS;
  akce: AkceModel;

  list: any;
  pageableList: PageableList;
  exportEnable = true;
  typyUkonu: SelectItem[] = [];
  stavyFaze: SelectItem[] = [];
  kategorieDoss: SelectItem[] = [];
  platnost: SelectItem[] = [];
  verzePd: SelectItem[] = [];
  souladSPd: StavSouladuSPdModel[] = [
    StavSouladuSPdModel.NENI_V_SOULAD_S_PD,
    StavSouladuSPdModel.PREZKUM,
    StavSouladuSPdModel.JE_V_SOULAD_S_PD
  ];

  globalFilter = {
    dotcenyOrgan: {values: []},
    typUkonu: {values: []},
    stavyFaze: {values: []},
    kategorieDoss: {values: []},
    platnost: {values: []},
    verzePd: {values: []},
    souladSPd: {values: []},
    //oblibene: {values: []}
  };

  constructor(public restangular: Restangular,
              @Inject(RESTANGULAR_DOSS) public restangularDoss: any,
              private pageableListService: PageableListService,
              private stateService: StateService,
              private exportListService: ExportListService,
              private dialogService: DialogService,
              private authService: AuthService,
              public listService: ListService,
              public dotcenyOrganPipe: DotcenyOrganPipe,
              public typUkonuPipe: TypUkonuPipe,
              public kategorieDossPipe: KategorieDossPipe,
              public verzePdPipe: VerzePdPipe,
              public stavFazeUkonuFilterPipe: StavFazeUkonuFilterPipe) {
    this.onExport = this.onExport.bind(this);
  }

  async ngOnInit() {
    this.akce = this.authService.getActualProject();
    await this.loadTypUkonu();
    await this.loadKategorieDoss();
    await this.loadStavyFaze();
    await this.loadPlatnost();
    await this.loadVerzePd();

    this.initializeList(this.stateService.params.souladSPd, this.stateService.params.stavyFilter);
  }

  canCreate(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  canExport() {
    return this.authService.hasPermissionForDossAction('web')
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  updateEntityFilterKey(value: string[]) {
      this.list.filter.filters.stavyFaze = {
        values: (value && value.length ? value.map(v => { return { id: v }; }) : [])
      };
    }

  async initStatisticsEntityFilter() {
    if (this.stateService.params.stavyFilter) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.createFilter().filters;
      delete this.list.filter.filters.validity;
      this.updateEntityFilterKey(this.stateService.params.stavyFilter);
    } else {
      if (this.list.filter.filters.statisticsEntityFilterKey &&
        this.list.filter.filters.statisticsEntityFilterKey.values &&
        this.list.filter.filters.statisticsEntityFilterKey.values.length
      ) {
        delete this.list.filter.filters.validity;
      }
    }
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    return this.exportListService
      .exportListDoss(this.restangular, 'ukon', 'ukony', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  checkCases() {
    const exists = this.list.filter.filters.cancelStatus;
    return exists && (!exists.values || exists.values.length === 0);
  }

  checkOblibene() {
    const exists = this.list.filter.filters.oblibene;
    return exists && (!exists.values || exists.values.length === 0);
  }

  onHideDisabledCasesCheckboxClicked() {
    this.list.filter.filters.cancelStatus = {values: this.checkCases() ? [{id: 'notCancelled'}] : []};
  }

  onOblibeneCheckboxClicked() {
    this.list.filter.filters.oblibene = {values: this.checkOblibene() ? [{id: 'zobrazVsechny'}] : []};
  }

  handleUkonCreate() {
    if (this.akce.verzePd) {
      this.stateService.go('doss.akce.data.ukony.create');
    } else {
      const dialog = this.dialogService.open(UkonNelzeZalozitComponent, {
        data: {},
        className: ClassName.ADJUSTED_DIALOG,
      });

      const sub = dialog.afterClosed.subscribe(async (result: boolean) => sub.unsubscribe());
    }
  }

  private async initializeList(souladSPdDefaultValues?: any[], stavyFilter?: any[]) {
    const filter = this.createFilter(stavyFilter, souladSPdDefaultValues);

    this.pageableList = this.pageableListService.get('ukon/list', filter, 'ukonFilter', this.restangular);
    this.list = this.pageableList.list;

    _.assign(this.globalFilter, _.mapValues(this.globalFilter, (value, filterKey) => this.list.filter.filters[filterKey] || value));

    await this.initStatisticsEntityFilter();
    await this.pageableList.load();
  }

  private createFilter(stavyFilter?: any[], souladSPdDefaultValues?: any[]): any {
    return {
      filters: {
          searchText: {values: [{id: ''}]},
          dotcenyOrgan: {values: [], negation: false},
          typUkonu: {values: [], negation: false},
          stavyFaze: {values: stavyFilter || [], negation: false},
          kategorieDoss: {values: [], negation: false},
          platnost: {values: [], negation: false},
          verzePd: {values: [], negation: false},
          souladSPd: {values: souladSPdDefaultValues || [], negation: false},
          //oblibene: {values: []}
      }
    };
  }

  private loadTypUkonu(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/typUkonu')
      .getList().toPromise()
      .then(data => this.typyUkonu = data);
  }

  private loadKategorieDoss(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/kategorieDoss')
      .getList().toPromise()
      .then(data => this.kategorieDoss = data);
  }

  private loadStavyFaze(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/stavyFaze')
      .getList().toPromise()
      .then(data => this.stavyFaze = data);
  }

  //TODO: update endpoint pre platnost
  private loadPlatnost(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/kategorieDoss')
      .getList().toPromise()
      .then(data => this.platnost = data);
  }

  private loadVerzePd(): Promise<void> {
    return this.restangular
      .all('verze-pd/version-list')
      .getList().toPromise()
      .then(data => this.verzePd = data);
  }
}
