<h1>Kontakní osoba</h1>

<div class="cb">
  <fieldset>
    <div class="column-50">
      <gmt-input label="Titul před jménem"><input [(ngModel)]="kontaktniOsoba.titulPredJmenem" type="text"/></gmt-input>
      <gmt-input label="Křestní jméno" required="true"><input [(ngModel)]="kontaktniOsoba.krestniJmeno" type="text"/>
      </gmt-input>
      <gmt-input label="Příjmení" required="true"><input [(ngModel)]="kontaktniOsoba.prijmeni" type="text"/></gmt-input>
      <gmt-input label="Titul za jménem"><input [(ngModel)]="kontaktniOsoba.titulZaJmenem" type="text"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Telefon"><input [(ngModel)]="kontaktniOsoba.telefon" type="text"/></gmt-input>
      <gmt-input label="E-mail"><input [(ngModel)]="kontaktniOsoba.email" type="text"/></gmt-input>
      <gmt-input label="Řešená agenda"><input [(ngModel)]="kontaktniOsoba.resenaAgenda" type="text"/></gmt-input>
    </div>
  </fieldset>
</div>

<div class="text-center pt-20">
  <gmt-button [clickAction]="onCancel" class="button black">
    Zrušit změny
  </gmt-button>
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()" class="button">
    Uložit změny
  </gmt-button>
</div>
