import {Ng2StateDeclaration, TargetState, StateService} from '@uirouter/angular';
import {AkceInfoComponent} from "@app/doss/components/akce-info/akce-info.component";
import {AkceUpdateComponent} from "@app/doss/components/akce-update/akce-update.component";
import {AkceListComponent} from "@app/doss/components/akce-list/akce-list.component";
import {AkceCreateComponent} from "@app/doss/components/akce-create/akce-create.component";
import {AkceOverviewComponent} from "@app/doss/components/akce-overview/akce-overview.component";

import {Transition} from "@uirouter/core";
import {AuthService} from "@app/common/services/auth.service";
import {APPLICATIONS} from "@app/common/services/config.service";
import { ProjectStatusService } from '@app/common/services/project-status.service';

export function dossAkceOnEnter(transition: Transition) {
  const authService: AuthService = transition.injector().get(AuthService);
  const applications = transition.injector().get(APPLICATIONS);
  const projectStatusService: ProjectStatusService = transition.injector().get(ProjectStatusService);

  return authService.checkProjectStatus(transition, applications.doss.name, 'doss_', authService.loadDossProjects, 'error')
    .then(
      async () => {
        await authService.loadDOSSProject(transition.params().akceKey);
        projectStatusService.init();
      }, (newState: TargetState) => {
        return transition.router.stateService.go(newState.name(), newState.params(), newState.options());
      }
    );
}

export function dossAkceOnExit( transition: Transition) {
  const authService: AuthService = transition.injector().get(AuthService);
  const projectStatusService: ProjectStatusService = transition.injector().get(ProjectStatusService);

  authService.unloadDOSSProject();
  projectStatusService.destroy();
}

export const akceState: Ng2StateDeclaration = {
  name: 'doss.akce',
  url: '/akce',
  params: {
    akceKey: {
      squash: true,
    }
  },
  abstract: true
};

export const akceListState: Ng2StateDeclaration = {
  name: 'doss.akce.list',
  url: '/list?page',
  data: {title: 'Seznam akcí'},
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    }
  },
  views: {
    'content@doss': {
      component: AkceListComponent
    }
  }
};

export const akceCreateState: Ng2StateDeclaration = {
  name: 'doss.akce.create',
  url: '/new',
  data: {title: 'Založení nové akce'},
  views: {
    'content@doss': {
      component: AkceCreateComponent
    }
  }
};

export const akceDataState: Ng2StateDeclaration = {
  name: 'doss.akce.data',
  url: '/{akceKey:doss_[^/]*}',
  params: {
    akceKey: {
      squash: true,
    }
  },
  abstract: true,
  onEnter: dossAkceOnEnter,
  onExit: dossAkceOnExit,
  onRetain: dossAkceOnEnter
};

export const akceOverviewState: Ng2StateDeclaration = {
  name: 'doss.akce.data.overview',
  url: '/overview',
  data: {title: 'Přehled'},
  views: {
    'content@doss': {
      component: AkceOverviewComponent
    }
  }
};

export const akceDetailState: Ng2StateDeclaration = {
  name: 'doss.akce.data.detail',
  url: '/detail/:tab',
  data: {title: 'Karta akce'},
  params: {
    tab: {
      type: 'string',
      value: null,
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
  views: {
    'content@doss': {
      component: AkceInfoComponent
    }
  }
};

export const akceUpdateState: Ng2StateDeclaration = {
  name: 'doss.akce.data.update',
  url: '/update',
  data: {title: 'Úprava akce'},
  views: {
    'content@doss': {
      component: AkceUpdateComponent
    }
  }
};
