import {Component} from "@angular/core";
import {AuthService} from "@app/common/services/auth.service";

@Component({
    selector: 'home-information',
    templateUrl: './home-information.component.html',
    styleUrls: ['./home-information.component.scss']
})
export class HomeInformation {
  constructor(private authService: AuthService) {
  }

  canEditArticles() {
    return this.authService.hasPermission('admin');
  }
}
