import { SelectItem } from '@app/common/components/select/select.component';

export enum AkceStatusEnum {
  PRA = 'PRA',
  AA = 'AA',
  POA = 'POA',
  UA = 'UA',
}

export const akceStatusOptions: SelectItem[] = [
  {
    id: AkceStatusEnum.PRA,
    name: 'Připravovaná akce',
  },
  {
    id: AkceStatusEnum.AA,
    name: 'Aktivní akce',
  },
  {
    id: AkceStatusEnum.POA,
    name: 'Pozastavená akce',
  },
  {
    id: AkceStatusEnum.UA,
    name: 'Uzavřená akce"',
  },
];

export const LEGEND_ENTRIES = [
    { id: AkceStatusEnum.PRA, label: 'Připravovaná akce', color: '#ffa011' },
    { id: AkceStatusEnum.AA, label: 'Aktivní akce', color: '#19bb27'},
    { id: AkceStatusEnum.POA, label: 'Pozastavená akce', color: '#203864' },
    { id: AkceStatusEnum.UA, label: 'Uzavřená akce', color: '#a8a8a8' },
  ];
