import {Component, Input} from '@angular/core';
import {ListModel} from '@app/common/models/list.model';
import {PodrobneInformaceModel} from "@app/doss/models/podrobne-informace.model";


@Component({
  selector: 'podrobne-informace',
  templateUrl: './podrobne-informace.component.html',
  styleUrls: ['./podrobne-informace.component.scss']
})
export class PodrobneInformaceComponent {

  @Input() podrobneInformaceList: ListModel<PodrobneInformaceModel>;
}
