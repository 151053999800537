import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {VerzePdModel} from "@app/doss/models/verze-pd.model";

@Pipe({name: 'verzePd'})
@Injectable({
  providedIn: 'root'
})
export class VerzePdPipe implements PipeTransform {

  transform(verzePd: VerzePdModel): string {
    return !verzePd ? "" : verzePd.cisloVerze;
  }
}
