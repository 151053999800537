import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from "@angular/core";
import * as _ from 'lodash';


import {CallbackModel} from '@app/common/models/callback.model';
import {EVENTS, ROOT_CALLBACKS, APP_BRAND} from '@app/common/services/config.service';
import {Restangular} from 'ngx-restangular';
import {LocalStorageService} from 'angular-2-local-storage';
import {ListModel} from "@app/common/models/list.model";
import {TabbedBlockModel} from "@app/common/models/tabbed-block.model";
import {AuthService} from "@app/common/services/auth.service";
import {DialogService} from "@app/common/services/dialog.service";
import {ListService} from "@app/common/services/list.service";
import {PerfectScrollbarEvent} from "@app/common/components/perfectscrollbar/perfectscrollbar.component";
import {ConfirmationComponent} from "@app/common/components/confirmation/confirmation.component";
import {ClassName} from "@app/common/enums/class-name.enum";
import {UpozorneniDossModel} from "@app/doss/models/upozorneni-doss.model";
import {DotcenyOrganUtils} from "@app/doss/utils/dotceny-organ.utils";
import { User } from "@app/models/user";

@Component({
  selector: 'doss-upozorneni',
  templateUrl: './doss-upozorneni.component.html',
  styleUrls: ["./doss-upozorneni.component.scss"]
})
export class DossUpozorneniComponent implements OnInit, OnDestroy {

  public static readonly UPOZORNENI_FILTER: TabbedBlockModel[] = [
    {id: 'U', name: 'Doručená'},
    {id: 'A', name: 'Archiv', iconClass: 'fa fa-bookmark-o archive'},
  ];

  // bindings
  @Input() user: User;
  @Input() readonly = false;
  @Input() upozorneniList: ListModel<UpozorneniDossModel>;
  @Output() updated = new EventEmitter<{ type: String, data: ListModel<UpozorneniDossModel> }>();
  readonlyState = true;
  // model
  upozorneniFilter: any[] = [];
  upozorneniFilterSelectionVisible: TabbedBlockModel = DossUpozorneniComponent.UPOZORNENI_FILTER[0];
  selectedUpozorneni = [];
  checkedAllUpozorneni = false;
  protected DotcenyOrganUtils = DotcenyOrganUtils;
  // fields
  private storageKey: string;

  constructor(
    private restangular: Restangular,
    private localStorageService: LocalStorageService,
    @Inject(ROOT_CALLBACKS) private rootCallbackService: CallbackModel,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private authService: AuthService,
    private listService: ListService,
    private dialogService: DialogService
  ) {
    this.onCanScrollTop = this.onCanScrollTop.bind(this);
    this.onUpozorneniFilterChanged = this.onUpozorneniFilterChanged.bind(this);
    this.onChangeUpozorneniOrder = this.onChangeUpozorneniOrder.bind(this);
    this.onLoadNextUpozorneni = this.onLoadNextUpozorneni.bind(this);
    this.onCheckAllUpozorneni = this.onCheckAllUpozorneni.bind(this);
    this.onDeleteUpozorneni = this.onDeleteUpozorneni.bind(this);
    this.onArchiveUpozorneni = this.onArchiveUpozorneni.bind(this);
    this.onToggleUpozorneniSelection = this.onToggleUpozorneniSelection.bind(this);
  }

  ngOnInit() {
    if (!this.readonly) {
      this.upozorneniFilter = DossUpozorneniComponent.UPOZORNENI_FILTER;
    }

    if (!this.upozorneniList) {
      this.storageKey = this.authService.getActualProject().key + "." + 'homeNotificationsOrder';
      this.loadUpozorneni();
      this.onUpozorneniFilterChanged();
    } else {
      this.upozorneniList.promise.then(() => {
        this.readonlyState = this.readonly || !this.upozorneniList.list.some(upozorneni => !!upozorneni.user);
      });
    }
  }

  ngOnDestroy() {
    if (this.storageKey) {
      this.listService.cancelFetch(this.upozorneniList);
    }
  }

  canEdit() {
    return this.authService.hasPermissionForDossAction('manage_actions')
  }

  onCanScrollTop(scrollbarEvent: PerfectScrollbarEvent) {
    this.scrollTop = scrollbarEvent.scrollTop;
  };

  notifyChange() {
    this.readonlyState = this.readonly || !this.upozorneniList.list.some(upozorneni => !!upozorneni.user);
    this.updated.emit({
      type: this.upozorneniList.filter.filters.archive ? 'archived' : 'unarchived',
      data: this.upozorneniList,
    });
  }

  onUpozorneniFilterChanged(value: TabbedBlockModel = this.upozorneniFilterSelectionVisible) {
    this.scrollTop();
    this.upozorneniList.filter.filters.archive = (value.id === 'A');
    this.upozorneniList.filter.offset = 0;
    this.listService.fetchResult(this.upozorneniList).then(() => {
      this.upozorneniFilterSelectionVisible = value;
      this.uncheckAllUpozorneni();
      this.notifyChange();
    });
  };

  onChangeUpozorneniOrder() {
    const order = this.loadUpozorneniOrderSetting() === 'desc' ? 'asc' : 'desc';
    this.upozorneniList.filter.sortOrder.direction = order;
    if (this.storageKey) {
      this.localStorageService.set(this.storageKey, order);
    }
    this.upozorneniList.filter.offset = 0;
    this.listService.fetchResult(this.upozorneniList).then(() => {
      this.uncheckAllUpozorneni()
    });
  };

  onLoadNextUpozorneni() {
    this.upozorneniList.filter.offset += this.upozorneniList.filter.limit;
    this.listService.fetchResult(this.upozorneniList, true);
    this.checkedAllUpozorneni = false;
  };

  onCheckAllUpozorneni() {
    if (this.checkedAllUpozorneni) {
      this.selectedUpozorneni = [];
    } else {
      this.selectedUpozorneni = [].concat(this.upozorneniList.list);
    }
    this.checkedAllUpozorneni = !this.checkedAllUpozorneni;
  }

  onToggleUpozorneniSelection(upozorneni: UpozorneniDossModel, event) {
    const index = this.selectedUpozorneni.indexOf(upozorneni);
    if (index !== -1) {
      this.selectedUpozorneni.splice(index, 1);
      this.checkedAllUpozorneni = false;
    } else {
      this.selectedUpozorneni.push(upozorneni);
      if (this.selectedUpozorneni.length === this.upozorneniList.list.length) {
        this.checkedAllUpozorneni = true;
      }
    }
  }

  onDeleteUpozorneni(upozorneni: UpozorneniDossModel[]) {
    const ids = _.map(upozorneni, 'id');

    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete upozornění smazat?',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.upozorneniList.loading = true;
        this.restangular.all('notifications').customPUT(ids, 'hide').toPromise().then(() => {
          this.selectedUpozorneni = _.difference(this.selectedUpozorneni, upozorneni);
          this.upozorneniList.list = _.difference(this.upozorneniList.list, upozorneni);
          this.upozorneniList.itemCount -= upozorneni.length;
          this.upozorneniList.filter.offset -= upozorneni.length;

          const countWithWarning = upozorneni.reduce((total, item) => {
            return total + (item.withWarning && !item.archived ? 1 : 0);
          }, 0);

          if (countWithWarning > 0) {
            this.rootCallbackService.get(EVENTS.notificationWithWarningHidden)(countWithWarning);
          }

          if (this.upozorneniList.list.length === 0 && this.upozorneniList.itemCount > 0) {
            this.onLoadNextUpozorneni();
          }

          this.uncheckAllUpozorneni();
          this.notifyChange();
          this.upozorneniList.loading = false;
        });
      }
      sub.unsubscribe();
    });
  }

  onArchiveUpozorneni(upozorneni: UpozorneniDossModel[]) {
    const ids = _.map(upozorneni, 'id');

    this.upozorneniList.loading = true;
    this.restangular.all('notifications').customPUT(ids, 'archive').toPromise().then(() => {
      this.selectedUpozorneni = _.difference(this.selectedUpozorneni, upozorneni);
      this.upozorneniList.list = _.difference(this.upozorneniList.list, upozorneni);
      this.upozorneniList.itemCount -= upozorneni.length;
      this.upozorneniList.filter.offset -= upozorneni.length;
      this.upozorneniList.loading = false;

      const countWithWarning = upozorneni.reduce((total, item) => {
        return total + (item.withWarning && !item.archived ? 1 : 0);
      }, 0);
      if (countWithWarning > 0) {
        this.rootCallbackService.get(EVENTS.notificationWithWarningHidden)(countWithWarning);
      }

      if (this.upozorneniList.list.length === 0 && this.upozorneniList.itemCount > 0) {
        this.onLoadNextUpozorneni();
      }
      this.uncheckAllUpozorneni();
      this.notifyChange();
    });
  }

  isUpozorneniChecked(upozorneni: UpozorneniDossModel): boolean {
    return _.findIndex(this.selectedUpozorneni, upozorneni) !== -1;
  }

  canModify(upozorneni: UpozorneniDossModel) {
    return this.authService.isAuthorized(upozorneni.user);
  }

  canModifyAllUpozorneni() {
    for (const upozorneni of this.upozorneniList.list) {
      if (!this.canModify(upozorneni)) {
        return false;
      }
    }
    return true;
  }

  private scrollTop: () => void = () => {
  };

  private loadUpozorneni() {
    this.upozorneniList = this.listService.createList('notifications', {
      sortOrder: {sortBy: 'casOdeslani', direction: this.loadUpozorneniOrderSetting()},
      filters: {
        loadCollections: [],
        hidden: [false],
        currentUser: [false],
      }
    });
  }

  private uncheckAllUpozorneni() {
    this.checkedAllUpozorneni = false;
    this.selectedUpozorneni = [];
  }

  private loadUpozorneniOrderSetting(): string {
    const value = this.storageKey ? this.localStorageService.get(this.storageKey) : this.upozorneniList.filter.sortOrder.direction;
    return (value && value === 'desc') ? "desc" : "asc";
  };
}
