import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Pipe({name: 'email'})
@Injectable({
  providedIn: 'root'
})
export class EmailPipe implements PipeTransform {

  transform(emailArray: any[]): string {
    return ValidationUtils.isNonEmptyArray(emailArray) ? emailArray.map(email => email.email).join(", ") : "";
  }
}
