export class DocumentTypeModel {
  id: string;
  icon: string;
  title: string;
  extensions?: string[];
  disabled?: string;

  static readonly CASE_DOCUMENT_TYPES: DocumentTypeModel[] = [
    {
      id: 'CONTRACT',
      icon: 'fa-file',
      title: 'Návrh smlouvy'
    },
    {
      id: 'CONTRACT_APPROVED_BY_INVESTOR',
      icon: 'fa-hand-o-right',
      title: 'Návrh smlouvy schválený investorem'
    },
    {
      id: 'COVERING_LETTER',
      icon: 'fa-file-text',
      title: 'Průvodní dopis'
    },
    {
      id: 'INFORMATION_LETTER',
      icon: 'fa-file-text',
      title: 'Informační dopis'
    },
    {
      id: 'FINANCIAL_CONTROL',
      icon: 'fa-fonticons',
      title: 'Finanční kontrola FINKO'
    },
    {
      id: 'PRELIMINARY_PAYMENT_ORDER',
      icon: 'fa-list-ol',
      title: 'Proforma platebního příkazu'
    },
    {
      id: 'PAYMENT_ORDER',
      icon: 'fa-money',
      title: 'Platební příkaz'
    },
    {
      id: 'SIGNED_CONTRACT_SCAN',
      icon: 'fa-handshake-o',
      title: 'Sken všestranně podepsané smlouvy',
      extensions: ['pdf'],
    },
    {
      id: 'EXPROPRIATION_DECISION',
      icon: 'fa-handshake-o',
      title: 'Rozhodnutí o vyvlastnění',
      extensions: ['pdf'],
    },
    {
      id: 'CONTRACT_ADDENDUM',
      icon: 'fa-handshake-o',
      title: 'Dodatek smlouvy',
      extensions: ['pdf'],
    },
    {
      id: 'POWER_OF_ATTORNEY_TO_DIVIDE',
      icon: 'fa-file-text',
      title: 'Plná moc k dělení pozemku'
    },
    {
      id: 'OTHER',
      icon: 'fa-file-o',
      title: 'Nezařazené - ostatní'
    },
  ];

  static readonly SUBJECT_DOCUMENT_TYPES: DocumentTypeModel[] = [
    {
      id: 'POWER_OF_ATTORNEY_TO_DIVIDE',
      icon: 'fa-file-text',
      title: 'Plná moc k dělení pozemku'
    },
    {
      id: 'OTHER',
      icon: 'fa-file-o',
      title: 'Nezařazené - ostatní'
    }
  ];

  static readonly GP_DOCUMENT_TYPES: DocumentTypeModel[] = [
    {
      id: 'PARCEL_SPLIT_PRE_APPROVE_BY_BUILDING_AUTHORITY_SCAN',
      icon: 'fa-hand-o-right',
      title: 'Sken předběžného schválení dělení pozemků od SÚ'
    },
    {
      id: 'PARCEL_SPLIT_PRE_APPROVE_BY_OWNER_SCAN',
      icon: 'fa-handshake-o',
      title: 'Sken předběžného schválení dělení pozemků od vlastníka'
    },
    {
      id: 'GEOMETRIC_PLAN',
      icon: 'fa-object-ungroup',
      title: 'Geometrický plán'
    },
    {
      id: 'GEOMETRIC_PLAN_VFK',
      icon: 'fa-object-ungroup',
      title: 'VFK geometrického plánu'
    },
    {
      id: 'GEOMETRIC_PLAN_DOCUMENTATION',
      icon: 'fa-object-ungroup',
      title: 'Geodetická dokumentace GP'
    },
    {
      id: 'CONTRACT_MAP_ATTACHMENT',
      icon: 'fa-map',
      title: 'Mapová příloha smlouvy'
    },
    {
      id: 'OTHER',
      icon: 'fa-file-o',
      title: 'Nezařazené - ostatní'
    }
  ];

  static readonly OPINION_DOCUMENT_TYPES: DocumentTypeModel[] = [
    {
      id: 'OPINION_SCAN',
      icon: 'fa-book',
      title: 'Sken znaleckého posudku'
    },
    {
      id: 'OTHER',
      icon: 'fa-file-o',
      title: 'Nezařazené - ostatní'
    }
  ];

  static readonly SAMPLE_DOCUMENT_TYPES: DocumentTypeModel[] = [
    {
      id: 'SAMPLE',
      icon: 'fa-book',
      title: 'Vzor'
    },
    {
      id: 'REVISION_SAMPLE',
      icon: 'fa-file-o',
      title: 'Vzor s vyznačením úprav'
    },
    {
      id: 'TEMPLATE',
      icon: 'fa-file-text-o',
      title: 'Šablona'
    },
    {
      id: 'INACTIVE_TEMPLATE',
      icon: 'fa-file-text',
      title: 'Chybná šablona'
    },
    {
      id: 'INACTIVE_SAMPLE',
      icon: 'fa-book',
      title: 'Původní vzor'
    },
    {
      id: 'INACTIVE_REVISION_SAMPLE',
      icon: 'fa-file',
      title: 'Původní vzor s vyznačením úprav'
    },
  ];

  static readonly DOSS_DOCUMENT_TYPES: DocumentTypeModel[] = [
    {
      id: 'PODKLAD',
      icon: 'fa-file-o',
      title: 'Podklad'
    },
    {
      id: 'DOKUMENT_K_ROZHODNUTI',
      icon: 'fa-file-text',
      title: 'Dokument k rozhodnutí'
    }
  ];

  /**
   * Factory method
   */
  static createById(id: string): DocumentTypeModel {
    const types = DocumentTypeModel.CASE_DOCUMENT_TYPES
      .concat(DocumentTypeModel.GP_DOCUMENT_TYPES)
      .concat(DocumentTypeModel.OPINION_DOCUMENT_TYPES)
      .concat(DocumentTypeModel.SUBJECT_DOCUMENT_TYPES)
      .concat(DocumentTypeModel.SAMPLE_DOCUMENT_TYPES)
      .concat(DocumentTypeModel.DOSS_DOCUMENT_TYPES);

    return types.find(type => type.id === id)
      || types.find(type => type.id === 'OTHER');
  }

  static getByCategory(category: 'gp' | 'case' | 'o' | 'subject' | 'sample' | 'doss'): DocumentTypeModel[] {
    switch (category) {
      case 'gp':
        return DocumentTypeModel.GP_DOCUMENT_TYPES;
      case 'case':
        return DocumentTypeModel.CASE_DOCUMENT_TYPES;
      case 'o':
        return DocumentTypeModel.OPINION_DOCUMENT_TYPES;
      case 'subject':
        return DocumentTypeModel.SUBJECT_DOCUMENT_TYPES;
      case 'sample':
        return DocumentTypeModel.SAMPLE_DOCUMENT_TYPES;
      case 'doss':
        return DocumentTypeModel.DOSS_DOCUMENT_TYPES;
      default:
        return [];
    }
  }

  static getDefaultDOSSDocumentType(): DocumentTypeModel {
    return DocumentTypeModel.DOSS_DOCUMENT_TYPES.find(type => type.id === 'DOKUMENT_K_ROZHODNUTI');
  }
}
