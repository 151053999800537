<h1>Odbor dotčeného orgánu</h1>

<div class="cb">
  <fieldset>
    <div class="column-100">
      <gmt-input label="Název" required="true"><input [(ngModel)]="odbor.nazev" type="text"/></gmt-input>
    </div>
  </fieldset>
</div>

<div class="text-center pt-20">
  <gmt-button [clickAction]="onCancel" class="button black">
    Zrušit změny
  </gmt-button>
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()" class="button">
    Uložit změny
  </gmt-button>
</div>
