<div class="detail">
  <div>
    <a class="button mb-10" uiSref="^"><i class="fa fa-chevron-left mr-5"></i>Zpět na přehled</a>
  </div>

  <h1>Založení nového úkonu DOSS</h1>

  <wizard [submitButtonName]="'Založit úkon DOSS'" [submitAction]="createUkon" *ngIf="canCreate()">
    <wizard-tab [isValid]="ukonForm.isValid()">
      <ukon-form #ukonForm
                 redirectToPageAfterDossSave="doss.akce.data.ukony.create"
                 [ukon]="ukon">
      </ukon-form>
    </wizard-tab>
  </wizard>
</div>
<help [helpId]="helpIds.DOSS_UKON_CREATE" class="cm-help-page"></help>
