import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClassName} from "@app/common/enums/class-name.enum";
import {DialogService} from "@app/common/services/dialog.service";
import {TableServiceDoss} from "@app/doss/services/table-service-doss.service";
import {KontaktniOsobaComponent} from "@app/doss/components/kontaktni-osoba/kontaktni-osoba.component";
import {KontaktniOsobaModel} from "@app/doss/models/kontaktni-osoba.model";
import * as _ from "lodash";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {ConfirmationComponent} from "@app/common/components/confirmation/confirmation.component";

@Component({
  selector: 'table-kontaktni-osoby',
  templateUrl: './table-kontaktni-osoby.component.html',
  styleUrls: ['./table-kontaktni-osoby.component.scss']
})
export class TableKontaktniOsobyComponent {
  @Input()
  akceSchema: string;

  @Input()
  dotcenyOrganId: number;

  @Input()
  kontaktniOsoby: KontaktniOsobaModel[];

  @Input()
  columnNames: any;

  @Output()
  onChanged: EventEmitter<any> = new EventEmitter();

  protected readonly ValidationUtils = ValidationUtils;

  private readonly TABLE_ID = 'kontaktniOsoba';

  constructor(private dotcenyOrganService: DotcenyOrganService,
              private tableService: TableServiceDoss,
              private dialogService: DialogService) {
  }

  getColumnName(columnId: string) {
    if (this.columnNames && this.columnNames[columnId]) {
      return this.columnNames[columnId];
    }
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }

  onKontaktniOsobaUpdate(kontaktniOsoba: KontaktniOsobaModel): void {
    const dialog = this.dialogService.open(KontaktniOsobaComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.dotcenyOrganId,
        kontaktniOsoba: _.cloneDeep(kontaktniOsoba)
      },
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe(async (kontaktniOsoba: KontaktniOsobaModel) => {
      if (kontaktniOsoba) {
        this.dotcenyOrganService.updateKontakniOsoba(this.akceSchema, kontaktniOsoba).then(() => this.onChanged.emit());
      }
      sub.unsubscribe();
    });
  }

  showCancelButton(kontaktniOsoba : KontaktniOsobaModel): boolean {
    return kontaktniOsoba.id && !ValidationUtils.isNonEmptyArray(kontaktniOsoba.reseneUkony);
  }

  onKontaktniOsobaCancel(kontaktniOsoba : KontaktniOsobaModel): void {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete kontaktní osobu smazat? Její obnovení nebude možné!',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.dotcenyOrganService.cancelKontakniOsoba(this.akceSchema, kontaktniOsoba)
          .then(() => this.onChanged.emit());
      }
      sub.unsubscribe();
    });
  }
}
