import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {StateService, TargetState, Transition} from '@uirouter/angular';
import { TransitionService, UIRouterGlobals } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { MenuConfig } from '@app/models/menuConfig';
import { WordService } from '@app/common/services/word.service';
import { ProjectStatusService } from '@app/common/services/project-status.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { TitleCasePipe } from '@angular/common';
import { ApproveTermsService } from '@app/common/services/approve-terms.service';
import { ChangePasswordComponent } from '@app/common/components/change-password/change-password.component';
import { DialogService } from '@app/common/services/dialog.service';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { CallbackModel } from '@app/common/models/callback.model';
import { APP_BRAND, APP_CONFIG, APPLICATIONS, EVENTS, ROOT_CALLBACKS } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';
import { StateDeclaration } from '@uirouter/core/lib/state/interface';
import { StateObject } from '@uirouter/core/lib/state/stateObject';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';

@Component({
  templateUrl: './main.component.html',
})

export class MainComponent implements OnInit, OnDestroy {
  loading = false;
  collapsed = false;
  user = this.authService.getUser();
  servicedeskUrl = this.authService.getServiceDeskUrl();
  menuConfig: MenuConfig[] = [];
  projects;
  projectName;
  selProject;
  countWithWarningLoading = true;
  countWithWarning = 0;
  transitionDeregister: Function;
  processes;
  ENV = this.config.ENV;

  constructor(
    public projectStatusService: ProjectStatusService,
    private authService: AuthService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private wordService: WordService,
    private settingsService: SettingsService,
    private titleCasePipe: TitleCasePipe,
    private approveTermsService: ApproveTermsService,
    private dialogService: DialogService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject('$transition$') private transition: Transition,
    private restangular: Restangular,
    @Inject(APP_CONFIG) private config,
    @Inject(APP_BRAND) public APP_BRAND,
    @Inject(APPLICATIONS) private applications,
    private localStorageService: LocalStorageService,
    @Inject(ROOT_CALLBACKS) private rootCallbackService: CallbackModel,
    private brandTranslatePipe: BrandTranslatePipe,
  ) {
    this.checkPasswordChange = this.checkPasswordChange.bind(this);
    this.changeProject = this.changeProject.bind(this);
    this.onAuthProjectUpdated = this.onAuthProjectUpdated.bind(this);
    this.onNotificationWithWarningHidden = this.onNotificationWithWarningHidden.bind(this);
  }

  async ngOnInit() {
    this.onAuthProjectUpdated(this.authService.getActualProject());
    this.authService.setActiveApplication(this.applications.sy.name);
    this.authService.updateBaseUrl();
    this.authService.updateAuthorizationHeader();
    this.googleAnalyticsService.onUserCome();
    updateBody('symap');

    this.collapsed = this.localStorageService.get('menuOpened');
    this.approveTermsService.checkApprovedTerms(this.user.id, this.applications.sy.name).then(this.checkPasswordChange);

    const actualProject = this.authService.getActualProject();
    if (actualProject) {
      this.projectName = actualProject.name;
      this.selProject = actualProject;
    }

    const actualProjects = this.authService.getActualProjects();
    if (actualProjects) {
      this.projects = actualProjects.filter(p => p.key.match(/sy_/));
    }

    this.getMenuConfig().then(menu => {
      this.menuConfig = menu;
    });

    this.restangular.all('notifications').one('count-with-warning').customGET().toPromise().then((data) => {
      this.countWithWarning += data ? Number(data.data) : 0;
      this.countWithWarningLoading = false;
    });

    this.authService.registerCallback(EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
    this.rootCallbackService.add(EVENTS.notificationWithWarningHidden, this.onNotificationWithWarningHidden);

    this.projectStatusService.init();
    this.projectStatusService.registerListener((data) => {
      this.processes = data;
    });

    this.translateTitle(this.transition.targetState().$state());

    this.transitionDeregister = this.transitionService.onFinish({}, (transition) => {
      this.translateTitle(transition.to());
    });
  }

  ngOnDestroy() {
    if (this.transitionDeregister) {
      this.transitionDeregister();
    }
    this.projectStatusService.destroy();
    this.authService.unregisterCallback(EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
    this.rootCallbackService.remove(EVENTS.notificationWithWarningHidden, this.onNotificationWithWarningHidden);
  }

  onAuthProjectUpdated(project) {
    this.selProject = project;
    this.settingsService.clearSettings();
    this.wordService.clearVocabulary();
    if (project) {
      this.settingsService.loadSettings(project.id);
    }
  }

  onNotificationWithWarningHidden(countWithWarning) {
    this.countWithWarning -= countWithWarning;
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.localStorageService.set('menuOpened', this.collapsed);
  }

  logout() {
    this.authService.logout();
  }

  changeProject(project) {
    this.stateService.go('symap.project.home', { projectKey: project.key }, {inherit : false});
  }

  formatName(subject) {
    if (!subject) {
      return '';
    }

    let words = [];

    if (subject.name) {
      words.push(subject.name);
    }

    if (subject.surname) {
      words.push(subject.surname);
    }

    const tmp = [];
    if (words.length !== 0) {
      tmp.push(words.join(' '));
    }

    words = [];

    if (subject.titleBefore) {
      words.push(subject.titleBefore);
    }

    if (subject.degreeAfter) {
      words.push(subject.degreeAfter);
    }
    if (words.length !== 0) {
      tmp.push(words.join(' '));
    }

    return tmp.join(', ');
  }

  checkPasswordChange() {
    if (this.authService.isFirstLogin() && !this.authService.isSsoLoginEnabled()) {
      this.dialogService.open(ChangePasswordComponent, undefined);
    }
  }

  private getMenuConfig(): Promise<MenuConfig[]> {
    return this.wordService.getWord('CONSTRUCTION_OBJECT_SHORTCUT', true)
      .then(CONSTRUCTION_OBJECT_SHORTCUT => {
        const menu = this.getMenu(CONSTRUCTION_OBJECT_SHORTCUT);
        const project = this.authService.getActualProject();

        if (!project.geometricPlanModuleEnabled) {
          const gpItem = menu.find(i => i.title === 'GP');
          menu.splice(menu.indexOf(gpItem), 1);
        }

        if (!project.opinionModuleEnabled) {
          const oItem = menu.find(i => i.uiSref === 'symap.project.opinionRequests');
          menu.splice(menu.indexOf(oItem), 1);
        }

        if (!project.projectInfoEnabled) {
          const oItem = menu.find(i => i.uiSref === 'symap.project.info');
          menu.splice(menu.indexOf(oItem), 1);
        }

        if (!project.vfzeModuleEnabled) {
          const oItem = menu.find(i => i.title === 'Import');
          menu.splice(menu.indexOf(oItem), 1);
        }

        return menu;
      });
  }

  private getMenu(CONSTRUCTION_OBJECT_SHORTCUT) {
    return [
      new MenuConfig('Přehled', 'symap.project.home', 'fa-dashboard'),
      new MenuConfig(this.brandTranslatePipe.transform('ps.projectInfo'), 'symap.project.info', 'fa-info-circle'),
      new MenuConfig('Případy', 'symap.project.cases', 'fa-file', { page: 1 }),
      new MenuConfig('LV', 'symap.project.titles', 'fa-users', { page: 1 }),
      new MenuConfig('Vlastníci', 'symap.project.owners', 'fa-user', { page: 1 }),
      new MenuConfig('Zábory', 'symap.project.occupations', 'fa-map-pin'),
      new MenuConfig(CONSTRUCTION_OBJECT_SHORTCUT, 'symap.project.constructionObjects', 'fa-th', { page: 1 }),
      new MenuConfig('Parcely', 'symap.project.parcels', 'fa-toggle-up', { page: 1 }),
      new MenuConfig('GP', 'symap.project.geometricPlanRequests', 'fa-object-ungroup'),
      new MenuConfig((this.APP_BRAND.NAME === 'RSD' ? 'Posudky' : 'ZP'), 'symap.project.opinionRequests', 'fa-book'),
      new MenuConfig('Import', 'symap.project.vfze', 'fa-window-restore'),
      new MenuConfig('Mapa', 'symap.project.map', 'fa-map'),
      new MenuConfig('Exporty', 'symap.project.exports', 'fa-cloud-download'),
      new MenuConfig('Nastavení', 'symap.project.projectSettings', 'fa-wrench'),
    ];
  }

  private translateTitle(to: StateDeclaration | StateObject) {
    if (to.data && to.data.title && to.data.title.includes('$')) {
      this.wordService.replaceVariablesInText(to.data.title).then((text) => {
        document.title = this.titleCasePipe.transform(text);
      });
    }
  }
}
