import {Component, Inject, Input, OnInit} from '@angular/core';
import {ListService} from '@app/common/services/list.service';
import {AkceModel} from '@app/doss/models/akce.model';
import {RESTANGULAR_DOSS} from '@app/common/services/restangular-doss.service';
import {RESTANGULAR_CONFIGURATION} from "@app/common/services/restangular-configuration.service";
import {DialogService} from "@app/common/services/dialog.service";
import * as _ from "lodash";
import {StupenAkcePipe} from "@app/doss/pipes/stupen-akce.pipe";
import {ListModel} from "@app/common/models/list.model";
import {StupenAkceModel} from "@app/doss/models/stupen-akce.model";
import {OrganizationalUnitNamePipe} from "@app/common/pipes/organizational-unit-name.pipe";
import {ProjectSubjectNamePipe} from "@app/ps/pipes/project-subject-name.pipe";
import {ProjektantModel} from "@app/doss/models/projektant.model";
import {ProjektantComponent} from "@app/doss/components/projektant/projektant.component";
import {RESTANGULAR_SETTINGS} from "@app/common/services/restangular-settings.service";
import {UserNamePipe} from '@app/common/pipes/username.pipe';
import {AkceStatusEnum, akceStatusOptions} from "@app/doss/enums/akce-status.enum";
import { SelectItem } from '@app/common/components/select/select.component';

@Component({
  selector: 'first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.scss']
})
export class FirstStepComponent implements OnInit {
  public readonly STATE_LIST = akceStatusOptions;
  public state: SelectItem;

  @Input()
  akce: AkceModel;

  @Input()
  isUpdate: boolean = false;

  reloadOptionsOrganizationalUnit: any;
  organizationalUnitsNotCancelled: ListModel<any>;
  stupneAkce: StupenAkceModel[] = [];

  constructor(
    @Inject(RESTANGULAR_DOSS) public restangularDoss: any,
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangularService: any,
    public projectSubjectNamePipe: ProjectSubjectNamePipe,
    public organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    public stupenAkcePipe: StupenAkcePipe,
    public usernamePipe: UserNamePipe,
    private listService: ListService,
    private dialogService: DialogService
  ) {
    this.onUpdateOrganizacniJednotka = this.onUpdateOrganizacniJednotka.bind(this);
    this.reloadOptionsOrganizationalUnitRegister = this.reloadOptionsOrganizationalUnitRegister.bind(this);
    this.onUpdateOrganizace = this.onUpdateOrganizace.bind(this);
  }

  ngOnInit() {
    this.loadStupneAkce();
    this.organizationalUnitsNotCancelled = this.listService.createList('organizational-units', {filters: {cancelled: [false]}}, this.configurationRestangularService);
    this.state = akceStatusOptions.find(item => item.id === this.akce.stav);
  }

  isValid(): boolean {
    return !!this.akce && !!this.akce.nazevAkce && !!this.akce.organizace && !!this.akce.organizacniJednotka;
  }

  onUpdateOrganizace(organizace: any): void {
    if (!organizace) {
      this.akce.organizace = null;
      return;
    }
    this.akce.organizace = _.cloneDeep(organizace);
    this.akce.organizace.customerIdText = organizace.idText;
    this.akce.organizacniJednotka = null;
    this.reloadUnits();
  }

  onStateChanged(value: any) {
    this.akce.stav = value ? value.id : undefined;
  }

  onUpdateOrganizacniJednotka(event = undefined): void {
    if (event) {
      this.akce.organizacniJednotka = event.newValue;
    }
  }

  onEditProjektant(projektant: ProjektantModel): void {
    const dialog = this.dialogService.open(ProjektantComponent, {
      data: {
        projektant: projektant
      }
    });

    const sub = dialog.afterClosed.subscribe((res: ProjektantModel) => {
      if (res) {
        this.akce.projektant = res;
      }

      sub.unsubscribe();
    });
  }

  compareCustomers(item1: any, item2: any): boolean {
    return (item1.customerIdText || item1.idText) === (item2.customerIdText || item2.idText);
  }

  reloadOptionsOrganizationalUnitRegister(reloadOptionsOrganizationalUnit: any): void {
    this.reloadOptionsOrganizationalUnit = reloadOptionsOrganizationalUnit;
  }

  private async loadStupneAkce(): Promise<void> {
    this.stupneAkce = await this.restangularDoss.all('seznamy/stupneDokumentace').getList().toPromise();
  }

  private reloadUnits(): void {
    if (this.akce.organizace.customerIdText) {
      this.organizationalUnitsNotCancelled.filter.filters.idText = [this.akce.organizace.customerIdText];
      if (this.reloadOptionsOrganizationalUnit) {
        this.reloadOptionsOrganizationalUnit();
      }
    }
    this.onUpdateOrganizacniJednotka();
  }
}
