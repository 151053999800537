<div [loading]="ukolList.loading">
  <div *ngIf="ukolList.itemCount" class="notifications">
    <perfect-scrollbar [wheelPropagation]="true" (callbackRegister)="onCanScrollTop($event)" class="result">
      <ul *ngIf="ukolList.list.length">
        <li *ngFor="let ukol of getFilteredUkoly(true).list" class="bordered" style=" display: flex;">
          <div style="display: flex;">
            <div style="flex-grow: 1;display:flex;flex-direction: column;">
              <div class="flexbox-container flex-rest">
                <div class="date date-bar-today">DNES</div>
                <div *ngIf="ukol.nazev" class="message bordered-right flex-rest"><div [innerHTML]="ukol.nazev | safeHtml"></div></div>
                <div style="display: flex; align-items: right; justify-content: center; ">
                  <div style="white-space: nowrap;display: flex;" class="pr-5 pl-5">
                    <div style="margin: auto 0;"><i class="fa fa-user mr-5" style="color: lightgrey;"></i>
                      {{getName(ukol)}}
                    </div>
                  </div>
                  <div class="p-5">
                    <a (click)="onEditUkol(ukol)" title="Upravit" class="button"><span class="fa fa-pencil fa-lg"></span></a>
                    <a (click)="onDeleteukol(ukol)" title="Odstranit" class="button red ml-5"><span
                      class="fa fa-trash-o fa-lg"></span></a>
                  </div>
                </div>
              </div>
              <div class="prehled flex-rest bordered-top" *ngIf="isForPrehledAkce()">
                zdroj:
                <a uiSref="doss.akce.data.ukony.detail" [uiParams]="{ akceKey: akceSchema, id: ukol.ukon.id }">
                  {{ ukol.ukon.typUkonu.description }}
                </a>
                (založeno {{ (ukol.ukon.datumZalozeni | date ) }})
                <ng-container *ngIf="ukol.ukon.dotcenyOrgan?.nazev">{{ ukol.ukon.dotcenyOrgan.nazev }}, </ng-container>
                <ng-container *ngIf="ukol.ukon.odbor?.nazev">{{ ukol.ukon.odbor.nazev }}, </ng-container>
                <ng-container *ngIf="ukol.ukon.kategorieDoss">{{ ukol.ukon.kategorieDoss | kategorieDossList }}</ng-container>
              </div>
            </div>
          </div>
        </li>

        <h3 *ngIf="getFilteredUkoly(false).list.length" class="mt-10 mb-5">Následující</h3>

        <li *ngFor="let ukol of getFilteredUkoly(false).list" class="bordered" style=" display: flex;">
          <div style="display: flex;">
            <div style="flex-grow: 1;display:flex;flex-direction: column;">
              <div  class="flexbox-container flex-rest">
                <div class="date">{{ukol.termin | date}}</div>
                <div *ngIf="ukol.nazev" class="bordered-right message flex-rest"><div [innerHTML]="ukol.nazev | safeHtml"></div></div>
                <div style="display: flex; align-items: right; justify-content: center; ">
                  <div style="white-space: nowrap;display: flex;" class="pr-5 pl-5">
                    <div style="margin: auto 0;"><i class="fa fa-user mr-5" style="color: lightgrey;"></i>
                      {{getName(ukol)}}
                    </div>
                  </div>
                  <div class="p-5">
                    <a (click)="onEditUkol(ukol)" title="Upravit" class="button"><span class="fa fa-pencil fa-lg"></span></a>
                    <a (click)="onDeleteukol(ukol)" title="Odstranit" class="button red ml-5"><span
                      class="fa fa-trash-o fa-lg"></span></a>
                  </div>
                </div>
              </div>
              <div class="prehled bordered-top flex-rest" *ngIf="isForPrehledAkce()">
                zdroj:
                <a uiSref="doss.akce.data.ukony.detail" [uiParams]="{ akceKey: akceSchema, id: ukol.ukon.id }">
                  {{ ukol.ukon.typUkonu.description }}
                </a>
                (založeno {{ (ukol.ukon.datumZalozeni | date ) }})
                <ng-container *ngIf="ukol.ukon.dotcenyOrgan?.nazev">{{ ukol.ukon.dotcenyOrgan.nazev }}, </ng-container>
                <ng-container *ngIf="ukol.ukon.odbor?.nazev">{{ ukol.ukon.odbor.nazev }}, </ng-container>
                <ng-container *ngIf="ukol.ukon.kategorieDoss">{{ ukol.ukon.kategorieDoss | kategorieDossList }}</ng-container>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <span *ngIf="ukolList.list.length === 0">Žádné ukoly</span>
      <div class="load-next" *ngIf="ukolList.itemCount > ukolList.list.length">
        <input type="button" value="Načíst další" (click)="onLoadNextUkoly()" [disabled]="ukolList.loading"/>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="bordered" *ngIf="ukolList.list && ukolList.itemCount === 0">
    <div class="empty-info">
      <span>Žádné ukoly</span>
    </div>
  </div>
</div>
