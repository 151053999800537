<h1 class="centered-text">Nelze založit novou verzi PD.</h1>

<div class="padding-20">
  <div class="column-100" class="text-center">
    <span>Existují úkony vyžadujíci přezkum souladu s aktuálni verzi PD.</span>
    <br/>
    <span>Před založením nové verze PD je nutné tyto úkony přezkoumat, vyznačit u nich (ne)soulad s aktuální verzi PD.</span>
  </div>
</div>

<div class="actions">
  <gmt-button [clickAction]="onZobrazitUkonyKPrezkumu">ZOBRAZIT ÚKONY K PŘEZKUMU</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">ZPĚT</gmt-button>
</div>
