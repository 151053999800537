import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { OSM, TileWMS, WMTS, Vector } from 'ol/source';
import { Draw, Modify } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';

@Component({
  selector: 'map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss']
})
export class MapEditorComponent implements OnInit {

  @Output() changed = new EventEmitter<Feature[]>();

  map: Map;
  draw;
  modify;
  drawing = false;
  modifying = false;
  vectorLayer;

  constructor() {
    this.onDrawStart = this.onDrawStart.bind(this);
    this.onModifyStart = this.onModifyStart.bind(this);
    this.onActionStop = this.onActionStop.bind(this);
    this.onClear = this.onClear.bind(this);
    this.emitChanged = this.emitChanged.bind(this);
  }

  ngOnInit() {
    this.initMap();
  }

  async initMap() {
    this.map = new Map({
      target: 'map',
      layers: [],
      view: new View({
        zoom: 8,
        center: fromLonLat([14.456366300582888, 50.05321925103418]),
      })
    });

    // vector layer
    const source = new Vector();

    source.on('addfeature', this.emitChanged);
    source.on('changefeature', this.emitChanged);

    this.vectorLayer = new VectorLayer({
      source: source,
      visible: true,
    });
    this.vectorLayer.set('id', 'vector');
    this.vectorLayer.set('name', 'Geometrie akce');
    this.vectorLayer.set('icon', 'img/map/border-1-solid-2F6E9E.svg');
    this.vectorLayer.setZIndex(50);

    this.map.addLayer(this.vectorLayer);

    this.draw = new Draw({
      source: source,
      type: 'Polygon',
    });

    this.draw.on('drawend', (event) => {
      this.drawing = false;
      this.map.removeInteraction(this.draw);
    });

    this.modify = new Modify({
      source: source,
    });

    // kn map layer
    const knKmLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://services.cuzk.cz/wms/local-KM-wms.asp',
        params: { LAYERS: 'KN' },
      }),
      visible: false
    });

    knKmLayer.set('group', 'base');
    knKmLayer.set('id', 'knKm');
    knKmLayer.set('name', 'WMS ČÚZK KN mapa');
    knKmLayer.set('icon', 'img/map/border-1-solid-000000.svg');
    knKmLayer.setZIndex(40);
    this.map.addLayer(knKmLayer);

    // osm layer
    const osmLayer = new TileLayer({
      source: new OSM(),
      visible: true
    });

    osmLayer.set('group', 'base');
    osmLayer.set('id', 'osm');
    osmLayer.set('name', 'Open Street Map');
    osmLayer.set('icon', 'img/map/fill-299631.svg');
    osmLayer.setZIndex(30);
    this.map.addLayer(osmLayer);

    // ztm layer
    const ztmTileLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://ags.cuzk.cz/arcgis1/services/ZTM/MapServer/WMSServer',
        params: { LAYERS: '0' },
      }),
      visible: false
    });

    ztmTileLayer.set('id', 'ztm-tile');
    ztmTileLayer.set('name', 'Základní mapy');
    ztmTileLayer.set('icon', 'img/map/zm.png');
    ztmTileLayer.setZIndex(20);
    this.map.addLayer(ztmTileLayer);

    // orto layer
    const ortoLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/WMService.aspx',
        params: { LAYERS: 'GR_ORTFOTORGB' },
      }),
      visible: false
    });

    ortoLayer.set('id', 'orto');
    ortoLayer.set('name', 'Ortofoto ČÚZK');
    ortoLayer.set('icon', 'img/map/orthofoto.png');
    ortoLayer.setZIndex(10);
    this.map.addLayer(ortoLayer);
  }

  onDrawStart() {
    this.drawing = true;
    this.map.addInteraction(this.draw);
  }

  onModifyStart() {
    this.modifying = true;
    this.map.addInteraction(this.modify);
  }

  onActionStop() {
    this.drawing = false;
    this.modifying = false;
    this.map.removeInteraction(this.draw);
    this.map.removeInteraction(this.modify);
  }

  onClear() {
    this.vectorLayer.getSource().clear();
    this.emitChanged();
  }

  private emitChanged() {
    this.changed.emit(this.vectorLayer.getSource().getFeatures());
  }
}
