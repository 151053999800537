import {Component, EventEmitter, Input, Output} from '@angular/core';

import {TitleModel} from '@app/common/models/title.model';
import {ListModel} from '@app/common/models/list.model';
import {DotcenyOrganSeznamModel} from "@app/doss/models/dotceny-organ-seznam.model";
import {TableServiceDoss} from "@app/doss/services/table-service-doss.service";

@Component({
  selector: 'table-dotcene-organy',
  templateUrl: './table-dotcene-organy.component.html',
  styleUrls: ['./table-dotcene-organy.component.scss']
})
export class TableDotceneOrganyComponent {
  @Input() list: ListModel<DotcenyOrganSeznamModel>;
  @Input() isChecked: Function;
  @Input() inverseSelection: boolean;
  @Input() columnNames: any;
  @Input() customColumns: string[]; // avoid setting service
  @Input() customProjectKey: string;
  @Input() showNavIcons = false;
  @Output() checkAll: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<{}> = new EventEmitter();
  @Output() toggleCheckItem: EventEmitter<TitleModel> = new EventEmitter();
  public isArray = Array.isArray;
  private readonly TABLE_ID = 'dotceneOrgany';

  constructor(private tableService: TableServiceDoss) {
  }

  onSort(column: string, ascOrDesc: string) {
    this.sort.emit({
      column,
      ascOrDesc,
    });
  }

  getColumnName(columnId: string) {
    if (this.columnNames && this.columnNames[columnId]) {
      return this.columnNames[columnId];
    }
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }
}
