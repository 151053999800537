import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {StupenAkceModel} from "@app/doss/models/stupen-akce.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'stupenAkce'})
@Injectable({
  providedIn: 'root'
})
export class StupenAkcePipe implements PipeTransform {

  transform(stupenAkce: StupenAkceModel): string {
    return !stupenAkce ? "" : `${stupenAkce.id}: ${stupenAkce.value}`;
  }
}
