<div [loading]="noteList.loading">
  <div class="mb-10" *ngIf="isAdmin()">
    <div *ngIf="editable" class="tag" [ngClass]="{active: checkCancelled()}"
         title="Zobrazení uživateli zrušených poznámek "
         (click)="onCancelledCheckboxClicked()">
      <div>Zobrazit zrušené</div>
    </div>
  </div>
  <div *ngIf="editable" class="edit mb-20">
    <a class="button" (click)="onEditNote()" *ngIf="canEdit()">Přidat poznámku</a>
  </div>
  <div class="notes" *ngIf="noteList.itemCount">
    <perfect-scrollbar [wheelPropagation]="true" (callbackRegister)="onCanScrollTop($event)" [ngStyle]="{'max-height': maxHeight ? (maxHeight + 'px') : '700px'}">
      <ul *ngIf="noteList.list.length">
        <li *ngFor="let note of noteList.list" class="notes__item mb-10 mt-10 pt-10 pb-10 pr-10 pl-10" [ngClass]="{cancelled: note.cancelled}">
          <div style="display: flex; flex: 1 0 100%;">
            <div class="notes__meta mr-20">
              <ul>
                <li class="mb-10"><strong>{{note.timestamp | date: 'short'}}</strong></li>
                <li *ngIf="note.user">{{ note.user | username }}</li>
                <li *ngIf="editable">id: {{note.id}}</li>
              </ul>
            </div>
            <div *ngIf="note.text" class="article-preview-content ck-content" [innerHTML]="note.text | safeHtml"></div>
          </div>

          <div class="note-actions" *ngIf="canEdit()">
            <div><a class="button" (click)="onEditNote(note)">Upravit</a></div>
            <div class="pl-5"><a class="button" (click)="onDeleteNote(note)">Smazat</a></div>
          </div>
        </li>
      </ul>
      <span *ngIf="noteList.list.length === 0">Žádné poznámky</span>
      <div class="load-next" *ngIf="noteList.itemCount > noteList.list.length">
        <input type="button" value="Načíst další" (click)="onLoadNextNotes()" [disabled]="noteList.loading"/>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="bordered" *ngIf="noteList.list && noteList.itemCount === 0">
    <div class="empty-info">
      <span *ngIf="editable">Žádné poznámky</span>
      <span *ngIf="!editable">Žádna Historie</span>
    </div>
  </div>
</div>
