<h1 class="centered-text">Soulad s projektovou dokumentací</h1>

<fieldset [loading]="!loaded">
  <div *ngIf="loaded">
    <legend>Soulad úkonu DOSS s PD</legend>
    <div class="clearfix">
      <div class="column-100 mb-10">
        <label style="width: 100%">Verze PD při odeslání úkonu na dotčený orgán</label>
        <label>Číslo</label>
        <div class="info">{{ ukon?.souladSPdInfo?.predchoziVerzePd?.cisloVerze }}</div>

        <label>Popis</label>
        <div class="info">{{ ukon?.souladSPdInfo?.predchoziVerzePd?.popisVerze }}</div>
      </div>

      <div class="column-100 mb-10">
        <label>Aktuálni verze PD</label>

        <label>Číslo</label>
        <div class="info">{{ ukon?.souladSPdInfo?.aktualniVerzePd.cisloVerze }}</div>

        <label>Popis</label>
        <div class="info">{{ ukon?.souladSPdInfo?.aktualniVerzePd.popisVerze }}</div>
      </div>

      <div class="column-100 mb-10">
        <gmt-input *ngIf="canBeUpdated(); else zaverVyhodnoceniInfo" label="Závěr vyhodnocení" disabled="true">
          <gmt-switch disabled="true" (select)="handleSelectOption($event)" [options]="options" [selected]="currentOption"></gmt-switch>
        </gmt-input>

        <ng-template #zaverVyhodnoceniInfo>
          <label>Závěr vyhodnocení</label>
          <div class="info">{{ currentOption.value }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</fieldset>

<div class="actions">
  <gmt-button *ngIf="canBeUpdated(); else zavrit" [clickAction]="saveSouladSPd" [gmtDisabled]="!loaded">POTVRDIT
  </gmt-button>

  <ng-template #zavrit>
    <gmt-button [clickAction]="closeDialog" [gmtDisabled]="!loaded">ZAVŘÍT</gmt-button>
  </ng-template>
</div>
