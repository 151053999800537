<div class="detail">
  <div>
    <a class="button mb-10" uiSref="^"><i class="fa fa-chevron-left mr-5"></i>Zpět na složku úkonu DOSS</a>
  </div>
  <h1>Nastavení úkonu DOSS</h1>

  <div *ngIf="loaded">
    <wizard [submitButtonName]="'Uložit'" [submitAction]="updateUkon" *ngIf="canEdit()">
      <wizard-tab [isValid]="ukonForm.isValid()">
        <ukon-form #ukonForm
                   [isUpdate]="true"
                   redirectToPageAfterDossSave="doss.akce.data.ukony.detail.update"
                   [redirectToPageParamsAfterDossSave]="{id: id}"
                   [ukon]="ukon">
        </ukon-form>

          <div *ngFor="let podrobneInformace of podrobneInformaceList.list" class="column-100">
            <div>
              <label class="podrobne-informace-span">{{podrobneInformace.stav}}
                <div *ngIf="podrobneInformace.faze">({{podrobneInformace.faze}})</div>
              </label>
              <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.datumZmeny"/></div>
            </div>
            <div *ngIf="podrobneInformace.datumVydani">
              <label class="podrobne-informace-span">Datum vydání</label>
              <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.datumVydani"/></div>
            </div>
            <div *ngIf="podrobneInformace.datumKoncePlatnosti">
              <label class="podrobne-informace-span">Datum konce platnosti</label>
              <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.datumKoncePlatnosti"/></div>
            </div>
            <div *ngIf="podrobneInformace.lhutaProOdvolani">
              <label class="podrobne-informace-span">Lhůta pro odvolání</label>
              <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.lhutaProOdvolani"/></div>
            </div>
          </div>
      </wizard-tab>
    </wizard>
  </div>
</div>
<help [helpId]="helpIds.DOSS_UKON_UPDATE" class="cm-help-page"></help>
