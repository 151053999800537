<div class="mt-10">
    <table *ngIf="hasProjects()" class="bordered">
        <thead>
            <!-- aplikace -->
            <th class="cell">Aplikace</th>
            <!-- projekt -->
            <th class="cell">Projekt</th>
        </thead>
        <tbody>
            <tr
                *ngFor="let project of projects"
                class="row-group">
                <td class="cell">
                    <a (click)="removeProject(project.id)"><i class="fa fa-trash-o pr-15"></i></a>
                    {{ project.applicationName || project.application }}
                </td>
                <td class="cell">{{ project.projectName || project.projectKey }}</td>
            </tr>
        </tbody>
    </table>
    <div class="empty-info pb-20 pt-20 bordered centered-text" *ngIf="!hasProjects()">Ke smlouvě dosud nebyl přiřazen žádný projekt.</div>
</div>
