import { Component, Inject, OnInit } from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { ListModel } from '@app/common/models/list.model';
import { ExportModel } from '@app/common/models/export.model';
import { HelpService } from '@app/common/services/help.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ExportFormComponent } from '@app/common/components/export-form/export-form.component';
import { Restangular } from 'ngx-restangular';
import { ExportListService } from '@app/ps/services/export-list.service';

@Component({
  selector: 'export-list',
  templateUrl: './export-list.component.html',
  styleUrls: []
})
export class ExportListComponent implements OnInit {

  displayed: number;
  exports: ExportModel[];
  exportList: ListModel<ExportModel>;
  loading = true;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private listService: ListService,
    private constructionObjectNamePipe: ConstructionObjectNamePipe,
    private userNamePipe: UserNamePipe,
    private restangular: Restangular,
    private exportListService: ExportListService,
  ) {
  }

  ngOnInit() {
    this.exportList = this.listService.createList('exports/types-allowed', undefined);
    this.listService.fetchResult(this.exportList).then(() => {
      this.exports = this.exportList.list;

      for (const exp of this.exports) {
        // rozšíření validace a zavolání backend
        exp.isValid = this.isValid(exp);
        exp.backendCall = this.backendCall(exp);
        if (exp.userParams) {
          for (const param of exp.userParams) {
            // formát pro hodnoty a isVisible
            if (param.type === 'combo' && param.comboOptions) {
              param.comboOptions.itemPipe = this.getPipe(param.comboOptions.fieldName);
            }
            param.isVisible = this.isVisible(exp, param);
            param.selectedObject = {};
            if (!param.selected) {
              param.selected = [];
            }
            param.checklist = new ChecklistModel(param.selected);
          }
        }
      }

      this.loading = false;
    });
  }

  getPipe(pipeName: string) {
    switch (pipeName) {
      case 'constructionObjectName':
        return this.constructionObjectNamePipe;
      case 'userName':
        return this.userNamePipe;
    }
  }

  isVisible(exportCfg: ExportModel, param: any) {
    // special validation for statistics export
    if (exportCfg.key === 'STATISTICS' && (param.key === 'statisticsIds' || param.key === 'choices')) {
      return () => {
        const sectionsParam = exportCfg.userParams.find(p => p.key === 'sections');
        return sectionsParam.checklist.isChecked({id: 'statistics'});
      };
    }
  }

  isValid(exportCfg: ExportModel) {
    // special validation for statistics export
    if (exportCfg.key === 'STATISTICS') {
      return () => {
        const sectionsParam = exportCfg.userParams.find(p => p.key === 'sections');
        const choicesParam = exportCfg.userParams.find(p => p.key === 'choices');
        const statisticsIdsParam = exportCfg.userParams.find(p => p.key === 'statisticsIds');

        if (sectionsParam.checklist.isChecked({id: 'statistics'})) {
          return (choicesParam.checklist.checkedItems.length && statisticsIdsParam.selectedObject && statisticsIdsParam.selectedObject.selected);
        }

        return sectionsParam.checklist.checkedItems.length;
      };
    }
  }

  backendCall(exportCfg: ExportModel) {
    if (exportCfg.key === 'STATISTICS') {
      return () => {
        const setting = <any>ExportFormComponent.getSelectedSettings(exportCfg) || {};
        if (setting.exportSettings.sections[0].selection !== 'statistics') {
          const tableId = setting.exportSettings.sections[0].selection;
          return this.exportListService.exportDefaultList(tableId);
        }
      };
    }
  }
}
