import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {StupenAkceModel} from "@app/doss/models/stupen-akce.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'lokalizaceStavby'})
@Injectable({
  providedIn: 'root'
})
export class LokalizaceStavbyPipe implements PipeTransform {

  transform(lokalizaceStavby: any[]): string {
    return (lokalizaceStavby || []).map(lokalizaceStavby => lokalizaceStavby.name).join(", ");
  }
}
