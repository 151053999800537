import {Ng2StateDeclaration} from '@uirouter/angular';
import {UkonListComponent} from "@app/doss/components/ukon-list/ukon-list.component";
import {UkonCreateComponent} from "@app/doss/components/ukon-create/ukon-create.component";
import {UkonDetailComponent} from "@app/doss/components/ukon-detail/ukon-detail.component";
import {UkonUpdateComponent} from "@app/doss/components/ukon-update/ukon-update.component";
import {resolveId} from "@app/common/utils/state.utils";
import {
  ChangeUkonStatusComponent
} from "@app/doss/components/change-ukon-status/change-ukon-status.component";

export const ukonListState: Ng2StateDeclaration = {
  name: 'doss.akce.data.ukony',
  url: '/list?page',
  data: {title: 'Úkony DOSS'},
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
    souladSPd: undefined,
    stavyFilter: undefined
  },
  views: {
    'content@doss': {
      component: UkonListComponent
    }
  }
};

export const ukonCreateState: Ng2StateDeclaration = {
  name: 'doss.akce.data.ukony.create',
  url: '/new',
  data: {title: 'Založení nového úkonu DOSS'},
  params: {
    selectedDotcenyOrgan: undefined
  },
  views: {
    'content@doss': {
      component: UkonCreateComponent
    }
  }
};

export const ukonDetailState: Ng2StateDeclaration = {
  name: 'doss.akce.data.ukony.detail',
  url: "/:id/detail/{tab:poznamky|ukoly|podrobne-info|historie|upozorneni|dokumenty|propojeni}",
  params: {
    id: {
      type: 'int',
      value: undefined,
    },
    tab: {
      value: null,
      dynamic: true,
      inherit: false,
      squash: true
    }
  },
  data: {title: 'Složka úkonu DOSS'},
  views: {
    'content@doss': {
      component: UkonDetailComponent
    }
  },
  resolve: {
    id: ['$stateParams', resolveId]
  }
};

export const ukonUpdateState: Ng2StateDeclaration = {
  name: 'doss.akce.data.ukony.detail.update',
  url: '/update',
  data: {title: 'Úprava úkonu DOSS'},
  params: {
    selectedDotcenyOrgan: undefined
  },
  views: {
    'content@doss': {
      component: UkonUpdateComponent
    }
  },
  resolve: {
    id: ['$stateParams', resolveId]
  }
};

export const ukonUpdateStatusState: Ng2StateDeclaration = {
  name: 'doss.akce.data.ukony.detail.status',
  url: '/status?stav&faze',
  params: {
    stav: {
      type: 'string',
      inherit: false,
      value: undefined
    },
    faze: {
      type: 'string',
      inherit: false,
      value: undefined
    },
    selectedDotcenyOrgan: null
  },
  resolve: {
    id: ['$stateParams', resolveId]
  },
  views: {
    'content@doss': {
      component: ChangeUkonStatusComponent
    },
  },
};
