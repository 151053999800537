import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";

@Pipe({name: 'kategorieDossId'})
@Injectable({
  providedIn: 'root'
})
export class KategorieDossIdPipe implements PipeTransform {

  transform(kategorieDossList: KategorieDossModel[]): string {
    return ValidationUtils.isNonEmptyArray(kategorieDossList) ? kategorieDossList.map(kategorieDoss => kategorieDoss.idKategorie).join(", ") : "nezvoleno";
  }
}
