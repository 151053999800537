<div class="list">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam {{ "CONSTRUCTION_OBJECT_PLURAL_GENITIV" | wordtranslate }}</a>
  </div>
  <h1>Připravované {{ "CONSTRUCTION_OBJECT_PLURAL_NOMINATIV" | wordtranslate }}</h1>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">
            <span *ngIf="!hideColumn('createdDate')" class="cell">
              <sort-by
                [selected]="'createdDate' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('createdDate', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Založeno</sort-by>
            </span>
            <span *ngIf="!hideColumn('cislo')" class="cell">
              <sort-by
                [selected]="'cislo' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('cislo', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('cislo') }}</sort-by>
            </span>
            <span *ngIf="!hideColumn('usekEtapa')" class="cell">
              <sort-by
                [selected]="'usekEtapa' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('usekEtapa', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('usekEtapa') }}</sort-by>
            </span>
            <span *ngIf="!hideColumn('name')" class="cell">
              <sort-by
                [selected]="'name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('name') }}</sort-by>
            </span>
            <span class="cell">KU</span>
            <span class="cell">Akce</span>
          </div>
        </div>
        <div class="row-group">
          <div class="row" *ngFor="let constructionObject of list.list">
            <span *ngIf="!hideColumn('createdDate')" class="cell">{{ constructionObject.createdDate | date:'medium' }}</span>
            <span *ngIf="!hideColumn('cislo')" class="cell">{{ constructionObject.cislo }}</span>
            <span *ngIf="!hideColumn('usekEtapa')" class="cell">{{ constructionObject.usekEtapa }}</span>
            <span *ngIf="!hideColumn('name')" class="cell">{{ constructionObject.name }}</span>
            <span *ngIf="!hideColumn('ku')" class="cell">{{ constructionObject.kuList.join() }}</span>
            <span class="cell">
              <a gmtAuth="admin,manage_input_data" (click)="onDeleteImportedConstructionObject(constructionObject.id)">
                <i class="fa fa-trash-o c-blue"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!list.itemCount && list.list !== null && !list.loading" class="empty-info bordered pb-20 pt-20 centered-text">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>

  <help [helpId]="helpIds.PANEL_CONSTRUCTION_OBJECTS" class="cm-help-page"></help>

</div>
