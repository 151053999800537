import {Component, Inject, OnInit} from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { APPLICATIONS } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import { AkceHomePageModel } from '@app/doss/models/akce-home-page.model';
import { AkceService } from '@app/doss/services/akce.service';

@Component({
    selector: 'home-last-visited-akce',
  templateUrl: './home-last-visited-akce.component.html',
  styleUrls: ['./home-last-visited-akce.component.scss']
})
export class HomeLastVisitedAkceComponent implements OnInit {
  akceList: AkceHomePageModel[];
  akceLoading: boolean;

  constructor(
              public restangular: Restangular,
              @Inject(APPLICATIONS) public APPLICATIONS: any,
              private authService: AuthService,
              private akceService: AkceService) {}

  ngOnInit() {
    this.loadAkceListFromLocalStorage();
  }

  async loadAkceListFromLocalStorage(): Promise<void> {
    try {
      this.akceLoading = true;

      const lastAkceSchemas: string[] = this.authService.getLastVisitedProjects(this.APPLICATIONS.doss.name);

      this.akceList = await this.akceService.getAkceForHomePage(lastAkceSchemas);
    } catch (error) {
      console.error(error);
    } finally {
      this.akceLoading = false;
    }
  }
}
