<div class="list">
  <div class="actions">
    <a class="button" (click)="onCreateVerzePd()" *ngIf="canCreate()">Nová verze PD</a>
  </div>

  <h1>Verze projektové dokumentace</h1>

  <ng-container *ngIf="list">
    <div [loading]="list.loading || !settingsLoaded">
      <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
        <div class="flex1 basis0"></div>
        <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
        <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
          <span>Záznamů na stránce</span>
          <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
        </div>
      </div>

      <table-verze-pd [list]="list" [akceSchema]="akceSchema" (sort)="pageableList.onSort($event.column, $event.ascOrDesc)" (reload)="pageableList.load()"></table-verze-pd>

      <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
      <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
    </div>
  </ng-container>
</div>
