import {Component, OnInit} from '@angular/core';
import {AkceModel} from '@app/doss/models/akce.model';
import {StateService} from "@uirouter/angular";
import {AkceCreateModel} from "@app/doss/models/akce-create.model";
import {AkceService} from "@app/doss/services/akce.service";
import {AuthService} from "@app/common/services/auth.service";
import * as _ from "lodash";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './akce-update.component.html',
  styleUrls: ['./akce-update.component.scss']
})
export class AkceUpdateComponent implements OnInit {
  loaded: boolean = false;
  akce: AkceModel;
  helpIds = HelpService.HELP_IDS;

  constructor(private authService: AuthService,
              private akceService: AkceService,
              private stateService: StateService) {
    this.updateAkce = this.updateAkce.bind(this);
  }

  async ngOnInit() {
    await this.loadAkce();
    this.loaded = true;
  }

  async updateAkce(): Promise<void> {
    await this.akceService.update(this.mapToAkceCreateModel(this.akce))
      .then(() => this.stateService.go('doss.akce.data.detail', {akceKey: this.akce.schema}));
  }

  canEdit() {
    return this.authService.hasPermissionOnProject('admin', 'doss_default') || this.authService.hasPermissionOnProject('admin', this.akce.schema);
  }

  private loadAkce() {
    this.akce = _.cloneDeep(this.authService.getActualProject());
    // @ts-ignore
    this.akce.organizace.customerIdText = this.akce.organizace.idText;
  }

  private mapToAkceCreateModel(akce: AkceModel): AkceCreateModel {
    const {organizace, organizacniJednotka, stupen, manazerAkce, referent, zpracovatel, ...otherProperties} = akce;

    return {
      organizaceId: organizace.customerIdText,
      organizacniJednotkaCode: organizacniJednotka.code,
      stupen: stupen && stupen.id,
      manazerAkce: manazerAkce && manazerAkce.idText,
      referent: referent && referent.idText,
      zpracovatel: zpracovatel && zpracovatel.idText,
      ...otherProperties
    };
  }
}
