import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {DocumentTypeModel} from "@app/common/models/document-type.model";
import {DocumentTypePipeField} from "@app/common/pipes/document-type.pipe";
import {DokumentAdditionalDataModel} from "@app/doss/models/dokument-additional-data.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'DokumentType'})
@Injectable({
  providedIn: 'root'
})
export class DokumentTypePipe implements PipeTransform {

  transform(dokumentAdditionalData: DokumentAdditionalDataModel, field: DocumentTypePipeField): string {
    const documentType = DocumentTypeModel.createById(dokumentAdditionalData.attachmentType);

    switch (field) {
      case 'title':
        return documentType.title;
      case 'icon':
        return documentType.icon;
    }
  }
}
