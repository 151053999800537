import {Ng2StateDeclaration} from '@uirouter/angular';
import {ExportyComponent} from "@app/doss/exporty/components/exporty/exporty.component";

export const exportyState: Ng2StateDeclaration = {
  name: 'doss.akce.data.exporty',
  url: '/exporty',
  data: {title: 'Uživatelské exporty'},
  params: {},
  views: {
    'content@doss': {
      component: ExportyComponent
    }
  }
};
