<div class="detail">
  <div>
    <a class="button mb-10" [uiSref]="getZpetButtonRedirect()" [uiParams]="getZpetButtonRedirectParams()">
      <i class="fa fa-chevron-left mr-5"></i>
      Zpět
    </a>
  </div>

  <h2>Založení nového DOSS</h2>

  <div class="mt-20" *ngIf="canCreate()">
    <div class="flexbox-container">
      <div class="content-menu">
        <ng-container *ngFor="let tab of tabs">
          <div (click)="!isStepDisabled(tab) && selectTab(tab)"
               [ngClass]="{active : selectedTab.id === tab.id, cancelled: this.isStepDisabled(tab)}">
            {{ tab.name }}
          </div>
        </ng-container>
      </div>

      <div [loading]="loading">
        <fieldset *ngIf="selectedTab.id === 'czech-point'">
          <legend>Vyhledání orgánu v systému Czech POINT</legend>

          <div class="column-100">
            <label>Způsob hledání</label>
            <gmt-input>
              <gmt-switch (select)="handleSelectOption($event)" [options]="options"
                          [selected]="currentOption"></gmt-switch>
            </gmt-input>
          </div>

          <div *ngIf="currentOption.id === 'search-by-ico'" class="column-100">
            <gmt-input label="IČO">
              <input [(ngModel)]="icoToSearch" type="text"/>
            </gmt-input>
          </div>

          <div *ngIf="currentOption.id === 'search-by-name'" class="column-100">
            <gmt-input label="Název">
              <input [(ngModel)]="nameToSearch" type="text"/>
            </gmt-input>
          </div>

          <gmt-button [clickAction]="handleSearch" class="fr">Hledat</gmt-button>
        </fieldset>

        <fieldset *ngIf="selectedTab.id === 'vyber-doss'">
          <gmt-input label="Výběr z nalezených orgánů">
            <gmt-select
              (changed)="onUpdateSelectedDotcenyOrgan($event.newValue)"
              [data]="data"
              [itemPipe]="dotcenyOrganPipe"
              [optionItems]="dotceneOrgany"
              field="selectedDotcenyOrgan"
              required="true"
            ></gmt-select>
          </gmt-input>

          <gmt-button [clickAction]="goToLastStep" [gmtDisabled]="!slectedDotcenyOrgan()">Další</gmt-button>
        </fieldset>

        <div *ngIf="selectedTab.id === 'doplneni-dalsich-udaju'">
          <h3>Data Czech POINT</h3>

          <div class="column-100">
            <label>Adresa</label>
            <div class="info">{{ data.selectedDotcenyOrgan?.adresniMisto | address }}</div>

            <label>IČO</label>
            <div class="info">{{ data.selectedDotcenyOrgan?.ico }}</div>

            <label>Datová schránka</label>
            <div class="info">{{ data.selectedDotcenyOrgan?.datovaSchranka }}</div>

            <label>www</label>
            <div class="info">{{ data.selectedDotcenyOrgan?.www }}</div>

            <label>email</label>
            <div class="info">{{ data.selectedDotcenyOrgan?.email | email }}</div>
          </div>

          <div class="column-100">
            <fieldset>
              <legend>Kontaktní osoba</legend>
              <gmt-checkbox (click)="toggleCreateKontaktniOsoba()"
                            [gmtChecked]="data.createKontaktniOsoba"></gmt-checkbox>
              Kontaktní osoba

              <ng-container *ngIf="data.createKontaktniOsoba">
                <div *ngFor="let kontaktniOsoba of data.selectedDotcenyOrgan.kontaktniOsoby" class="padding-10">
                  <div>
                    {{ kontaktniOsoba | kontaktniOsoba: true }}
                    <a (click)="removeKontaktniOsoba(kontaktniOsoba)" *ngIf="!kontaktniOsoba.id"><i class="fa fa-trash-o"></i></a>
                  </div>
                </div>

                <div class="padding-10">
                  <gmt-button [clickAction]="onAddNewKontaktniOsoba"><i class="fa fa-plus"></i></gmt-button>
                </div>
              </ng-container>
            </fieldset>

            <odbor-pracoviste-create-form [akceSchema]="akceSchema"
                                          [data]="data"
                                          [dotcenyOrganId]="data.selectedDotcenyOrgan?.id">
            </odbor-pracoviste-create-form>
          </div>

          <gmt-button [clickAction]="saveDotcenyOrgan" [gmtDisabled]="!slectedDotcenyOrgan()">Založit</gmt-button>
        </div>
      </div>
    </div>
  </div>
</div>
<help [helpId]="helpIds.DOSS_ORGANY_CREATE" class="cm-help-page"></help>

