<div class="list">
    <div style="display:flex">
        <div class="line-height-l" style="min-width: 100px;">
            <gmt-back-button sref="^.^" text="Detail objednatele"></gmt-back-button>
        </div>
        <div class="actions">
            <gmt-button [clickAction]="addProject">
                <i class="fa fa-plus"></i>
                Přidat projekt
            </gmt-button>
            <gmt-button [clickAction]="editContract">
                <i class="fa fa-pencil"></i>
                Editovat smlouvu
            </gmt-button>
            <gmt-button [clickAction]="deleteContract" class="red">
                <i class="fa fa-trash-o"></i>
                Smazat smlouvu
            </gmt-button>
        </div>
    </div>

    <h1>Detail smlouvy</h1>
    <fieldset class="bordered">
        <div class="column-50">
            <!-- cislo smlouvy -->
            <label>Číslo smlouvy</label>
            <div class="input">{{contract.contractNumber}}</div>

            <!-- datum uzavreni smlouvy -->
            <label>Datum uzavření smlouvy</label>
            <div class="input">{{contract.signDate | date:'d. M. yyyy' }}</div>

            <!-- predmet smlouvy -->
            <label>Předmět smlouvy</label>
            <div class="input">{{contract.subject}}</div>

            <!-- poznamka -->
            <label *ngIf="contract.description?.length > 0">Poznámka</label>
            <div class="input">
                <p *ngIf="contract.description?.length > 0">{{contract.description}}</p>
            </div>

            <label>Dokument smlouvy</label>
            <div class="input">
                <a (click)="downloadFile()">{{filename}}</a>
            </div>
        </div>
    </fieldset>

    <h2>Projekty</h2>
    <project-list (projectRemoval)="removeProject($event)" [projects]="contract.projects" [customerId]="contract.customer.idText"></project-list>
</div>
