<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět</gmt-button>
  </div>

  <div *ngIf="loaded" style="display:flex">
    <h1>{{ config.name }}</h1>
  </div>

  <div *ngIf="loaded">
    <fieldset>
      <div *ngFor="let formField of config.formFields" class="column-100">
        <gmt-input *ngIf="formField.type !== 'doss'; else dotcenyOrgan"
                   [label]="formField.name"
                   [readonly]="!!formField.value"
                   [required]="formField.required">
          <ng-container *ngIf="formField.value">{{ formField.value }}</ng-container>
          <ng-container *ngIf="!formField.value">
            <ng-container [ngSwitch]="formField.type">
              <input *ngSwitchCase="'date'"
                    [(ngModel)]="stavUkonuUpdate.evidovaneHodnoty[formField.field]"
                    [maxToday]="getMaxToday(formField)"
                    pick-a-date
                    type="text"/>
              <input *ngSwitchCase="'string'"
                     [(ngModel)]="stavUkonuUpdate.evidovaneHodnoty[formField.field]"
                     type="text"/>
              <gmt-switch *ngSwitchCase="'switch'"
                          [options]="formField.options"
                          [selectedId]="stavUkonuUpdate.evidovaneHodnoty[formField.field]">
              </gmt-switch>
              <gmt-select *ngSwitchCase="'select'"
                          [data]="stavUkonuUpdate.evidovaneHodnoty"
                          [field]="formField.field"
                          [optionItems]="formField.options">
              </gmt-select>
              <textarea *ngSwitchCase="'input'"
                        [(ngModel)]="stavUkonuUpdate.evidovaneHodnoty[formField.field]"
                        class="input flex-rest" maxlength="255">
              </textarea>
              <ukon-status-documents *ngSwitchCase="'document'"
                                     [allowType]="true"
                                     [defaultData]="{projectInfo: true}"
                                     [defaultDocumentType]="defaultDocumentType"
                                     [dokumenty]="stavUkonuUpdate.dokumenty"
                                     [dokumentyAdditionalData]="stavUkonuUpdate.dokumentyAdditionalData"
                                     [required]="formField.required"
                                     class="blue">
              </ukon-status-documents>
            </ng-container>
          </ng-container>
        </gmt-input>

        <ng-template #dotcenyOrgan>
          <dotceny-organ-form [redirectToPageAfterDossSave]="'doss.akce.data.ukony.detail.status'"
                              [redirectToPageParamsAfterDossSave]="redirectToPageParamsAfterDossSave"
                              [isUpdate]="true"
                              [ukon]="ukon">
          </dotceny-organ-form>
        </ng-template>
      </div>
    </fieldset>

    <gmt-button [clickAction]="save" [gmtDisabled]="!isValid()" *ngIf="canEdit()">Uložit</gmt-button>
  </div>
</div>
