import {Ng2StateDeclaration, TargetState} from '@uirouter/angular';
import {MainComponent} from "@app/doss/components/main/main.component";
import {HomeComponent} from "@app/doss/components/home/home.component";

import {Transition} from "@uirouter/core";
import {AuthService} from "@app/common/services/auth.service";
import {APPLICATIONS} from "@app/common/services/config.service";

export function dossOnEnter(transition: Transition) {
  const authService: AuthService = transition.injector().get(AuthService);
  const applications = transition.injector().get(APPLICATIONS);

  return authService.checkProjectStatus(transition, applications.doss.name, 'doss_', authService.loadDossProjects, 'error')
    .then(
      () => {
        authService.unloadDOSSProject();
      }, (newState: TargetState) => {
        return transition.router.stateService.go(newState.name(), newState.params(), newState.options());
      }
    );
}

export const dossState: Ng2StateDeclaration = {
  name: 'doss',
  url: '/doss',
  abstract: true,
  component: MainComponent
};

export const dossHomeState: Ng2StateDeclaration = {
  name: 'doss.home',
  url: '/home',
  data: {title: 'Úvodní stránka'},
  views: {
    'content@doss': {
      component: HomeComponent
    }
  },
  onEnter: dossOnEnter
};

