import {Inject, Injectable} from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {DotcenyOrganModel} from "@app/doss/models/dotceny-organ.model";
import {PracovisteModel} from "@app/doss/models/pracoviste.model";
import {OdborModel} from "@app/doss/models/odbor.model";
import {KontaktniOsobaModel} from "@app/doss/models/kontaktni-osoba.model";
import {DotcenyOrganUpdateModel} from "@app/doss/models/dotceny-organ-update.model";

@Injectable({
  providedIn: 'root'
})
export class DotcenyOrganService {

  constructor(private restangular: Restangular) {
  }

  create(akceKey: string, dotcenyOrgan: DotcenyOrganModel): Promise<DotcenyOrganModel> {
    return this.restangular.all(`dotcenyOrgan/create`)
      .post(dotcenyOrgan)
      .toPromise();
  }

  update(akceKey: string, dotcenyOrganUpdate: DotcenyOrganUpdateModel): Promise<DotcenyOrganModel> {
    return this.restangular.all(`dotcenyOrgan/update/dotcenyOrgan/${dotcenyOrganUpdate.id}`)
      .customPUT(dotcenyOrganUpdate)
      .toPromise();
  }

  getById(akceKey: string, dotcenyOrganId: number): Promise<DotcenyOrganModel> {
    return this.restangular.one(`dotcenyOrgan`, dotcenyOrganId)
      .get()
      .toPromise();
  }

  cancelDotcenyOrgan(akceSchema: string, dotcenyOrgan: DotcenyOrganModel): Promise<DotcenyOrganModel> {
    return this.restangular.one(`dotcenyOrgan`, dotcenyOrgan.id)
      .remove()
      .toPromise();
  }

  checkIfKontaktniOsobaAlreadyExists(akceSchema: string, dotcenyOrganId: number, kontakniOsoba: KontaktniOsobaModel): Promise<any> {
    return this.restangular.all(`dotcenyOrgan/${dotcenyOrganId}/checkIfKontaktniOsobaAlreadyExists`)
      .post(kontakniOsoba)
      .toPromise();
  }

  checkIfOdborAlreadyExists(akceSchema: string, dotcenyOrganId: number, odbor: OdborModel): Promise<any> {
    return this.restangular.all(`dotcenyOrgan/${dotcenyOrganId}/checkIfOdborAlreadyExists`)
      .post(odbor)
      .toPromise();
  }

  checkIfPracovisteAlreadyExists(akceSchema: string, dotcenyOrganId: number, pracoviste: PracovisteModel): Promise<any> {
    return this.restangular.all(`dotcenyOrgan/${dotcenyOrganId}/checkIfPracovisteAlreadyExists`)
      .post(pracoviste)
      .toPromise();
  }

  updateKontakniOsoba(akceSchema: string, kontaktniOsoba: KontaktniOsobaModel): Promise<KontaktniOsobaModel> {
    return this.restangular.all(`kontaktniOsoba/update/kontaktniOsoba/${kontaktniOsoba.id}`)
      .customPUT(kontaktniOsoba)
      .toPromise();
  }

  cancelKontakniOsoba(akceSchema: string, kontaktniOsoba: KontaktniOsobaModel): Promise<void> {
    return this.restangular.one(`kontaktniOsoba`, kontaktniOsoba.id)
      .remove()
      .toPromise();
  }

  updateOdbor(akceSchema: string, odbor: OdborModel): Promise<OdborModel> {
    return this.restangular.all(`odbor/update/odbor/${odbor.id}`)
      .customPUT(odbor)
      .toPromise();
  }

  cancelOdbor(akceSchema: string, odbor: OdborModel): Promise<OdborModel> {
    return this.restangular.one(`odbor`, odbor.id)
      .remove()
      .toPromise();
  }

  updatePracoviste(akceSchema: string, pracoviste: PracovisteModel): Promise<void> {
    return this.restangular.all(`pracoviste/update/pracoviste/${pracoviste.id}`)
      .customPUT(pracoviste)
      .toPromise();
  }

  cancelPracoviste(akceSchema: string, pracoviste: PracovisteModel): Promise<PracovisteModel> {
    return this.restangular.one(`pracoviste`, pracoviste.id)
      .remove()
      .toPromise();
  }
}
