<div [loading]="!loaded" class="detail">
  <div class="mb-10 flexbox-container" style="display: flex;">
    <div class="flex-rest">
      <a uiSref="^" class="button"><i class="fa fa-chevron-left mr-5"></i>seznam úkonů</a>
    </div>
    <div *ngIf="ukon && !isZruseniUkonu(ukon?.stavUkonu?.stav) && (canCreate() || (!isStavUkonuPriprava() && canEdit()))">
      <action-buttons name="Možnosti">
        <a (click)="onUkolCreate()" *ngIf="canCreate()"class="bordered-bottom p-10">Nový úkol</a>
        <a (click)="onUpdateSouladSPd()" *ngIf="!isStavUkonuPriprava() && canEdit()" class="bordered-bottom p-10">Soulad s PD</a>
      </action-buttons>
    </div>
  </div>

  <div *ngIf="loaded">
    <div style="display:flex;">
      <div class="ukon-description">
        <h1>
          <oblibeny-ukon [ukon]="ukon" size="30px"></oblibeny-ukon>
          Složka úkonu DOSS
        </h1>
        <div *ngIf="ukon" class="mt-30">
          <span class="typ-ukonu">Typ: <b>{{ ukon.typUkonu.description }}</b></span>
        </div>
        <div class="mt-5"><span class="tag" [ngClass]="{'tag--danger': isPast(ukon.platnostDo), 'tag--active': !isPast(ukon.platnostDo)}" *ngIf="ukon.platnostDo">Konec platnosti {{ ukon.platnostDo | date }}</span></div>
      </div>

      <div *ngIf="ukon" class="col">
        <div><a class="dotceny-organ-description" uiSref="doss.akce.data.organy.detail" [uiParams]="{id: ukon.dotcenyOrgan.id}">{{ DotcenyOrganUtils.getDotcenyOrganDescription(ukon) }}</a></div>
        <div>{{ ukon.kategorieDoss | kategorieDossList }}</div>
        <div class="mt-30">
          <change-ukon-status-menu (back)="backStatus()"
                                   [actionButtons]="statusActions"
                                   [previousState]="previousState"
                                   [ukon]="ukon">
          </change-ukon-status-menu>
        </div>
      </div>
    </div>

    <div *ngIf="ukon">
      <div class="mt-30 mb-70 clearfix">
        <div *ngIf="canEdit()" class="edit">
          <a class="button" uiSref="doss.akce.data.ukony.detail.update">Upravit údaje</a>
        </div>

        <div class="column-33">
          <label>Adresa</label>
          <div
            class="info">{{ (ukon.pracoviste ? ukon.pracoviste.adresniMisto : ukon.dotcenyOrgan.adresniMisto)  | address }}
          </div>

          <label>Datová schránka</label>
          <div class="info">{{ ukon.dotcenyOrgan.datovaSchranka }}</div>
        </div>

        <div class="column-33">
          <label>Vyřizuje (kontakt)</label>
          <div class="info" style="white-space:pre-wrap">{{ ukon.kontaktniOsoba | kontaktniOsoba : true }}</div>

          <label>Datum založení</label>
          <div class="info">{{ ukon.datumZalozeni | date }}</div>

          <label>Poslední změna stavu</label>
          <div class="info">{{ ukon.datumPosledniZmeny | date }}</div>

          <label>Soulad s PD</label>
          <div class="info">
            <verze-pd [ukon]="ukon"></verze-pd>
          </div>
        </div>

        <div class="column-33">
          <label>Předpis</label>
          <div class="info">{{ ukon.naZakladeUstanoveni }}</div>

          <label>Popis úkonu</label>
          <div class="info">{{ ukon.dalsiPopis }}</div>
        </div>
      </div>
      <div class="mt-80">
        <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
          <div *ngIf="tab === 'poznamky'">
            <doss-notes-list
              (updated)="onUpdateNoteList()"
              [defaultData]="{projectInfo: true, systemType: false}"
              [noteList]="noteList"
              [ukonId]="ukon.id"
            ></doss-notes-list>
          </div>
          <div *ngIf="tab === 'ukoly'">
            <div class="actions" *ngIf="canCreate()">
              <a (click)="onUkolCreate()" class="button p-10">Nový úkol</a>
            </div>
            <ukol-list
              (updated)="onUpdateUkolList()"
              [defaultData]="{projectInfo: true, systemType: false}"
              [ukolList]="ukolList"
            ></ukol-list>
          </div>
          <div *ngIf="tab === 'propojeni'">
            <div class="actions" *ngIf="canLink()">
              <a (click)="handlePropojeniUkonuDialog()" class="button" *ngIf="canLink()"><i class="fa fa-wrench"></i>Založit nové propojení</a>
            </div>
            <table-propojeni-ukonu
              (onDelete)="handleDeletePropojeniUkonu($event)"
              (onUpdate)="handlePropojeniUkonuDialog($event)"
              [propojeniUkonuList]="propojeniUkonuList"
            ></table-propojeni-ukonu>
          </div>
          <div *ngIf="tab === 'podrobne-info'">
            <podrobne-informace
              [podrobneInformaceList]="podrobneInformaceList"
            ></podrobne-informace>
          </div>
          <div *ngIf="tab === 'historie'">
            <doss-notes-list
              [editable]="false"
              [noteList]="historyList"
              type="history"
            ></doss-notes-list>
          </div>
          <div *ngIf="tab === 'upozorneni'">
              <doss-upozorneni
                [readonly]="false"
                [upozorneniList]="upozorneniList"
                (updated)="onUpdateUpozorneni()"
              ></doss-upozorneni>
          </div>
          <div *ngIf="tab === 'dokumenty'">
            <gmt-documents
              (updated)="onUpdateDocumentList();"
              [allowType]="true"
              [defaultData]="{ ukonId: ukon.id }"
              [defaultDocumentType]="defaultDocumentType"
              [documentCategory]="'doss'"
              [documentList]="documentList"
            ></gmt-documents>
          </div>
        </gmt-navbar>
      </div>
    </div>
  </div>
</div>
<help [helpId]="helpIds.DOSS_UKON_DETAIL" class="cm-help-page"></help>
