import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {UkonModel} from "@app/doss/models/ukon.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {FazeUkonuPipe} from "@app/doss/pipes/faze-ukonu.pipe";
import {StateService} from "@uirouter/angular";
import {AuthService} from "@app/common/services/auth.service";
import {StavUkonuUtils} from "@app/doss/utils/stav-ukonu.utils";

@Component({
  selector: 'change-ukon-status-menu',
  templateUrl: './change-ukon-status-menu.component.html',
  styleUrls: ['./change-ukon-status-menu.component.scss']
})
export class ChangeUkonStatusMenuComponent {
  @Input()
  ukon: UkonModel;
  @Input()
  actionButtons: any[] = [];
  @Input()
  previousState: any;

  @Output() back = new EventEmitter();
  opened = false;

  public readonly ValidationUtils = ValidationUtils;
  public readonly StavUkonuUtils = StavUkonuUtils;

  constructor(private eRef: ElementRef,
              public stateService: StateService,
              public fazeUkonuPipe: FazeUkonuPipe,
              private authService: AuthService) {
  }

  canEdit(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  canChangeUkonStatus(): boolean {
    return ValidationUtils.isNonEmptyArray(this.actionButtons) &&
      !(this.actionButtons.length === 1 && this.actionButtons[0].key === 'zruseni-ukonu');
  }

  getStavUkonuNelzeZmenitMessage(): string {
    if (this.ukon.odeslanVeVerziPd && this.ukon.souladSPdInfo.stav === 'PREZKUM') {
      if (this.ukon.stavUkonu.stav.id === 'ZRUSENI_UKONU') {
        return 'Stav nelze změnit. Kontaktujte podporu aplikace.';
      }
      return 'Stav nelze změnit. Je nutné vyřešit přeskum souladu s PD.';
    }
    return 'Stav nelze změnit.';
  }

  toggle() {
    this.opened = !this.opened;
  }

  onBack() {
    this.back.emit();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }
}
