<h1 style="text-align: center;">{{!isEdit ? 'Vložení nové verze PD' : 'Editace verze PD'}}</h1>

<fieldset>
  <legend>{{!isEdit ? 'Nová verze projektové dokumentace' : 'Editace verze projektové dokumentace'}}</legend>

  <gmt-input label="Číslo verze" required="true">
    <input [(ngModel)]="verzePd.cisloVerze" type="text"/>
  </gmt-input>
  <gmt-input label="Popis verze" required="true">
    <input [(ngModel)]="verzePd.popisVerze" class="popis-verze" type="text"/>
  </gmt-input>

  <ng-container *ngIf="!isEdit; else editPlatnaOd">
    <gmt-input [readonly]="true" label="Platná od" required="true">
      <input [(ngModel)]="verzePd.platnaOd" [minFromDate]="minFromDate" pick-a-date type="text"/>
    </gmt-input>
  </ng-container>

  <ng-container *ngIf="!isEdit && !isFirstVerzePd(); else editKategorieDoss">
    <gmt-input [readonly]="true" label="Dotčené kategorie DOSS">
      <gmt-select
        [data]="selectedKategorieDoss"
        [itemPipe]="kategorieDossPipe"
        [multiselect]="true"
        [optionItems]="kategorieDoss"
        enableSelectAll="true"
        field="values"
        logGa
        selectTitle="Kategorie"
      ></gmt-select>
    </gmt-input>
  </ng-container>

  <ng-template #editPlatnaOd>
    <div class="column-100">
      <label>Platná od</label>
      <div class="info">{{ verzePd.platnaOd | date }}</div>
    </div>
  </ng-template>

  <ng-template #editKategorieDoss>
    <div class="column-100">
      <label>Dotčené kategorie DOSS</label>
      <div class="info">{{ verzePd.kategorieDoss | kategorieDossDetail }}</div>
    </div>
  </ng-template>
</fieldset>

<div class="text-center pt-20">
  <gmt-button [clickAction]="onSave" [gmtDisabled]="shouldDisableSave()">
    Uložit změny
  </gmt-button>
</div>
