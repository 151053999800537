import {Component, Inject, OnInit} from '@angular/core';

import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import {DialogRef} from '@app/common/services/dialog-ref';
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";
import { Restangular } from 'ngx-restangular';
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";
import {DotcenyOrganModel} from "@app/doss/models/dotceny-organ.model";
import {DotcenyOrganUpdateModel} from "@app/doss/models/dotceny-organ-update.model";

@Component({
  templateUrl: './odbor-pracoviste-create.component.html',
  styleUrls: []
})
export class OdborPracovisteCreateComponent implements OnInit {
  akceSchema: string;
  dotcenyOrganId: number;
  data: any;

  kategorieDoss: KategorieDossModel[] = [];

  constructor(public restangular: Restangular,
              private dotcenyOrganService: DotcenyOrganService,
              private dialogConfig: DialogConfig,
              private dialogRef: DialogRef) {
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async ngOnInit() {
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).akceSchema;
    this.dotcenyOrganId = (<DialogConfigData>this.dialogConfig.data).dotcenyOrganId;
    this.initData((<DialogConfigData>this.dialogConfig.data).dotcenyOrgan);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.dotcenyOrganService.update(this.akceSchema, this.mapToDotcenyOrganUpdateModel(this.data.selectedDotcenyOrgan))
      .then(result => {
        if (result) {
          this.dialogRef.close(result);
        }
      });
  }

  private mapToDotcenyOrganUpdateModel(dotcenyOrgan: DotcenyOrganModel): DotcenyOrganUpdateModel {
    return {
      id: dotcenyOrgan.id,
      kontaktniOsobyCreate: [],
      odboryCreate: dotcenyOrgan.odbory.filter(odbor => !odbor.id),
      pracovisteCreate: dotcenyOrgan.pracoviste.filter(pracoviste => !pracoviste.id)
    };
  }

  private initData(dotcenyOrgan: DotcenyOrganModel): void {
    this.data = {
      selectedDotcenyOrgan: dotcenyOrgan,
      createKontaktniOsoba: ValidationUtils.isNonEmptyArray(dotcenyOrgan.kontaktniOsoby),
      createOdbor: ValidationUtils.isNonEmptyArray(dotcenyOrgan.odbory),
      createPracoviste: ValidationUtils.isNonEmptyArray(dotcenyOrgan.pracoviste)
    };
  }
}
