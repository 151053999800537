<div>
  <div class="row">
    <div class="col-6">
      <h2 class="text-uppercase">Informace uživatelům</h2>
    </div>
    <div *ngIf="canEdit" class="col-6 text-right">
      <a (click)="onEdit()" class="button"><i class="fa fa-plus-square"></i> Přidat aktualitu</a>
    </div>
  </div>

  <div [loading]="list.loading">


    <div *ngIf="list.itemCount" class="table article-table">
      <div *ngFor="let article of list.list" class="article-column hover">
        <div class="article-preview bordered-left bordered-right  bordered-top bordered-bottom">
          <a *ngIf="canEdit" (click)="onEdit(article)" class="button edit"><i class="fa fa-pencil"></i></a>
          <a *ngIf="canEdit" (click)="onRemove(article)" class="button remove red"><i class="fa fa-trash-o"></i></a>
          <h1 (click)="article.collapsed = !!!article.collapsed">
            <a >
              <i class="fa fa-caret-{{ article.collapsed === undefined ? 'down' : (article.collapsed ? 'right' : 'down') }}"></i>
            </a>
            <ng-container *ngIf="article.publishDate">{{ article.publishDate | date:'mediumDate' }} - </ng-container>
            <ng-container *ngIf="!article.publishDate">V přípravě - </ng-container>
            {{ article.title }}
          </h1>
          <div *ngIf="article.content && (article.collapsed === undefined || !article.collapsed)" class="article-preview-content ck-content" [innerHTML]="article.content | safeHtml"></div>
        </div>
      </div>
    </div>
    <div *ngIf="!list.itemCount && list.list !== null" class="empty-info bordered">
      Nenalezena žádná data
    </div>
    <div class="table-top-container">
      <itemscounter
        *ngIf="list.itemCount && list.list !== null" [list]="list"
        (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
        class="flex1 basis0"
      ></itemscounter>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
  </div>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    [shouldUpdateUrl]="false"
    (pageChanged)="pageChanged($event)"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>
</div>
