import {Component, Inject, OnInit} from '@angular/core';
import {SwitchOption} from "@app/common/components/switch/switch.component";
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";
import {RESTANGULAR_SETTINGS} from "@app/common/services/restangular-settings.service";
import {DialogRef} from "@app/common/services/dialog-ref";
import {PropojeniUkonuModel} from "@app/doss/models/propojeni-ukonu.model";
import {UkonPropojeniPipe} from "@app/doss/pipes/propojeni-ukonu.pipe";
import {UkonService} from "@app/doss/services/ukon.service";
import {PropojeniUkonuCreateModel} from "@app/doss/models/propojeni-ukonu-create.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {PropojitelnyUkonModel} from "@app/doss/models/propojitelny-ukon.model";
import { AuthService } from '@app/common/services/auth.service';

@Component({
  templateUrl: './propojeni-ukonu-form.component.html',
  styleUrls: ['./propojeni-ukonu-form.component.scss']
})
export class PropojeniUkonuFormComponent implements OnInit {
  propojeniUkonuCreate = <PropojeniUkonuCreateModel>{};
  propojeniUkonu: PropojeniUkonuModel;
  propojitelneUkony: PropojitelnyUkonModel[];

  options: SwitchOption[] = [
    {
      id: 'PREDCHUDCE',
      value: 'Předchůce',
    },
    {
      id: 'SOUVISI',
      value: 'Související',
    },
    {
      id: 'NAVAZUJE',
      value: 'Navazující',
    }
  ];

  currentOption: SwitchOption;

  loaded = false;

  private akceSchema: string;
  private ukonId: number;

  constructor(@Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
              private ukonService: UkonService,
              private dialogConfig: DialogConfig,
              private dialog: DialogRef,
              public ukonPropojeniPipe: UkonPropojeniPipe,
              private authService: AuthService) {
    this.savePropojeni = this.savePropojeni.bind(this)
    this.handleSelectOption = this.handleSelectOption.bind(this);
  }

  async ngOnInit() {
    this.currentOption = this.options[0];
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).schema;
    this.ukonId = (<DialogConfigData>this.dialogConfig.data).ukonId;
    this.propojeniUkonu = (<DialogConfigData>this.dialogConfig.data).propojeniUkonu;
    await this.loadPropojitelneUkony();
    this.loaded = true;
  }

  handleSelectOption(option: SwitchOption) {
    this.currentOption = option;
  }

  isValid(): boolean {
    if (this.propojeniUkonu) {
      return !!this.propojeniUkonu.duvodPropojeni;
    }
    return ValidationUtils.isNonEmptyArray(this.propojeniUkonuCreate.targetUkony) && !!this.propojeniUkonuCreate.duvodPropojeni;
  }

  async savePropojeni(): Promise<void> {
    if (this.propojeniUkonu) {
      await this.ukonService.updatePropojeniUkonu(this.akceSchema, this.ukonId, this.propojeniUkonu)
        .then((data) => this.dialog.close(data));
    } else {
      const createPropojeniUkonu = this.mapToCreatePropojeniUkonu(this.propojeniUkonuCreate);
      await this.ukonService.createPropojeniUkonu(this.akceSchema, this.ukonId, createPropojeniUkonu)
        .then((data) => this.dialog.close(data));
    }
  }

  private mapToCreatePropojeniUkonu(propojeniUkonuCreate: PropojeniUkonuCreateModel): any {
    return {
      targetUkonIds: propojeniUkonuCreate.targetUkony.map(ukon => ukon.id),
      typPropojeni: this.currentOption.id,
      duvodPropojeni: propojeniUkonuCreate.duvodPropojeni
    }
  }

  private async loadPropojitelneUkony(): Promise<void> {
    return this.ukonService.getPropojitelneUkony(this.akceSchema, this.ukonId)
      .then(propojitelneUkony => {
        this.propojitelneUkony = propojitelneUkony;
      });
  }
}
