<h1 class="centered-text">Založení odboru / pracoviště</h1>

<odbor-pracoviste-create-form [akceSchema]="akceSchema"
                              [data]="data"
                              [dotcenyOrganId]="dotcenyOrganId">
</odbor-pracoviste-create-form>

<div class="text-center pt-20">
  <gmt-button [clickAction]="onCancel" class="button black">
    Zrušit změny
  </gmt-button>
  <gmt-button [clickAction]="onSave" class="button">
    Uložit změny
  </gmt-button>
</div>
