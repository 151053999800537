import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "@app/common/services/auth.service";
import {UkonModel} from "@app/doss/models/ukon.model";
import {UkonStatusConfigModel} from "@app/doss/models/ukon-status-config.model";
import {Title} from "@angular/platform-browser";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {UkonService} from "@app/doss/services/ukon.service";
import {StateService} from "@uirouter/angular";
import {DocumentTypeModel} from "@app/common/models/document-type.model";
import {StavUkonuUpdateModel} from "@app/doss/models/stav-ukonu-update.model";
import {UkonStatusService} from "@app/doss/services/ukon-status.service";
import {FileUtils} from "@app/common/utils/file.utils";

@Component({
  templateUrl: './change-ukon-status.component.html',
  styleUrls: ['./change-ukon-status.component.scss']
})
export class ChangeUkonStatusComponent implements OnInit {
  @Input()
  id: number;

  config: UkonStatusConfigModel;

  akceSchema: string;
  ukon: UkonModel;

  stavUkonuUpdate: StavUkonuUpdateModel = <StavUkonuUpdateModel>{
    evidovaneHodnoty: {},
    dokumenty: [],
    dokumentyAdditionalData: []
  };

  defaultDocumentType: DocumentTypeModel;
  redirectToPageParamsAfterDossSave: any;

  loaded: boolean = false;

  constructor(private titleService: Title,
              private authService: AuthService,
              private ukonService: UkonService,
              private stateService: StateService,
              private ukonStatusService: UkonStatusService) {
    this.save = this.save.bind(this);
  }

  async ngOnInit() {
    this.stavUkonuUpdate.stav = this.stateService.params.stav;
    this.stavUkonuUpdate.faze = this.stateService.params.faze;
    this.akceSchema = this.authService.getActualProject().schema;
    this.defaultDocumentType = DocumentTypeModel.getDefaultDOSSDocumentType();

    await this.loadUkon();

    if (this.stateService.params.selectedDotcenyOrgan) {
      this.ukon.dotcenyOrgan = this.stateService.params.selectedDotcenyOrgan;
      this.ukon.kontaktniOsoba = null;
      this.ukon.odbor = null;
      this.ukon.pracoviste = null;
    }

    this.config = this.ukonStatusService.getUkonStatusConfig(this.ukon.typUkonu, this.stavUkonuUpdate.stav, this.stavUkonuUpdate.faze)
    this.titleService.setTitle(this.config.name);
    this.initForm();

    this.redirectToPageParamsAfterDossSave = {
      id: this.ukon.id,
      stav: this.stavUkonuUpdate.stav,
      faze: this.stavUkonuUpdate.faze
    }

    this.loaded = true;
  }

  canEdit(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  isValid(): boolean {
    for (const formField of this.config.formFields) {
      if (formField.required) {
        if (formField.type === 'document') {
          if (!ValidationUtils.isNonEmptyArray(this.stavUkonuUpdate.dokumenty)) {
            return false;
          }
        } else {
          if (!this.stavUkonuUpdate.evidovaneHodnoty[formField.field]) {
            return false;
          }
        }
      }
    }
    return true;
  }

  async save(): Promise<void> {
    await this.ukonService.updateStavUkonu(this.akceSchema, this.id, this.mapToStavUkonuUpdateFormData(this.stavUkonuUpdate))
      .then(() => this.stateService.go('doss.akce.data.ukony.detail', {akceKey: this.akceSchema, id: this.id}));
  }

  private initForm(): void {
    for (const formField of this.config.formFields) {
      if (formField.default) {
        const defaultValue = formField.default === 'currentDate' ? new Date().toISOString().split('T')[0] : formField.default;
        this.stavUkonuUpdate.evidovaneHodnoty[formField.field] = this.stavUkonuUpdate.evidovaneHodnoty[formField.field] || defaultValue;
      }
    }
  }

  private loadUkon(): Promise<UkonModel> {
    return this.ukonService.getById(this.akceSchema, this.id).then(ukon => this.ukon = ukon);
  }

  getMaxToday(formField) {
    return !formField.allowFutureDate;
  }

  private mapToStavUkonuUpdateFormData(stavUkonuUpdate: StavUkonuUpdateModel): FormData {
    if (this.config.formFields.some(formField => formField.field === 'dotcenyOrgan')) {
      stavUkonuUpdate.ukonDossUpdate = {
        dotcenyOrganId: this.ukon.dotcenyOrgan.id,
        odbor: this.ukon.odbor,
        pracoviste: this.ukon.pracoviste,
        kontaktniOsoba: this.ukon.kontaktniOsoba
      };
    }

    const stavUkonuUpdateFormData = FileUtils.objectToFormData(this.prepareForFormData(stavUkonuUpdate, {}, 'dokumenty'));
    stavUkonuUpdate.dokumenty.forEach(dokument => stavUkonuUpdateFormData.append('dokumenty', dokument));

    return stavUkonuUpdateFormData;
  }

  private prepareForFormData(source: any, target: any, ...omitField: string[]): any {
    for (let [key, value] of Object.entries(source)) {
      if (value && !omitField.includes(key)) {
        target[key] = typeof value === 'string' ? value : JSON.stringify(value);
      }
    }
    return target;
  }
}
