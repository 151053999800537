import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "@app/common/services/auth.service";
import {DotcenyOrganModel} from "@app/doss/models/dotceny-organ.model";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {ClassName} from "@app/common/enums/class-name.enum";
import {DialogService} from "@app/common/services/dialog.service";
import {OdborModel} from "@app/doss/models/odbor.model";
import {
  OdborPracovisteCreateComponent
} from "@app/doss/components/odbor-pracoviste-create/odbor-pracoviste-create.component";
import * as _ from "lodash";
import {KontaktniOsobaComponent} from "@app/doss/components/kontaktni-osoba/kontaktni-osoba.component";
import {KontaktniOsobaModel} from "@app/doss/models/kontaktni-osoba.model";
import {DotcenyOrganUpdateModel} from "@app/doss/models/dotceny-organ-update.model";
import {ConfirmationComponent} from "@app/common/components/confirmation/confirmation.component";
import {StateService} from "@uirouter/angular";
import {TabModel} from "@app/common/models/tab.model";
import {OdborComponent} from "@app/doss/components/odbor/odbor.component";
import {PracovisteModel} from "@app/doss/models/pracoviste.model";
import {
  PracovisteComponent
} from "@app/doss/components/pracoviste/pracoviste.component";
import {PageableList} from "@app/models/pageable.list";
import {ListService} from "@app/common/services/list.service";
import {ListModel} from "@app/common/models/list.model";
import {UkonModel} from "@app/doss/models/ukon.model";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './dotceny-organ-detail.component.html',
  styleUrls: ['./dotceny-organ-detail.component.scss']
})
export class DotcenyOrganDetailComponent implements OnInit {
  @Input()
  id: number;
  helpIds = HelpService.HELP_IDS;

  akceSchema: string;
  dotcenyOrgan: DotcenyOrganModel;

  tabs: TabModel[];
  tab: string;

  loaded: boolean = false;

  protected readonly ValidationUtils = ValidationUtils;
  ukonyList: ListModel<UkonModel>;

  constructor(private dotcenyOrganService: DotcenyOrganService,
              private authService: AuthService,
              private dialogService: DialogService,
              private stateService: StateService,
              private listService: ListService,
  ) {
      this.uiOnParamsChanged(this.stateService.params);
      this.isActiveTab = this.isActiveTab.bind(this);
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab || 'ukony';
  }

  async ngOnInit() {
    this.tabs = this.getTabs();
    this.akceSchema = this.authService.getActualProject().schema;
    await this.loadDotcenyOrgan();
    this.initializeUkonList();
    this.loaded = true;
  }

  private async initializeUkonList() {
    const filter = {
      filters: {
         dotcenyOrgan: {values: [this.id], negation: false},
      },
      limit: null,
    };

    this.ukonyList = this.listService.createList('ukon/list', filter);

    await this.listService.fetchResult(this.ukonyList).then(() => {
      const noteTab = this.tabs.find((tab) => tab.id === 'ukony');
      noteTab.name = `Úkony (${this.ukonyList.itemCount})`;
    });
  }

  canEdit(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  private getTabs(): TabModel[] {
     return [
       {
         name: 'Úkony',
         id: 'ukony',
         href: 'doss.akce.data.organy.detail',
         urlParams: {tab: 'ukony'},
       },
       {
         name: 'Kontakty',
         id: 'kontakty',
         href: 'doss.akce.data.organy.detail',
         urlParams: {tab: 'kontakty'},
       },
       {
         name: 'Odbory',
         id: 'odbory',
         href: 'doss.akce.data.organy.detail',
         urlParams: {tab: 'odbory'},
       },
       {
         name: 'Úřadovny',
         id: 'uradovny',
         href: 'doss.akce.data.organy.detail',
         urlParams: {tab: 'uradovny'},
       },
     ];
   }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  public onOdborCreate() {
    const dialog = this.dialogService.open(OdborComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.id
      },
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe((res: OdborModel) => {
      if (res) {
        this.dotcenyOrganService.update(this.akceSchema,
          {
             id: this.id,
             odboryCreate: [res],
           })
          .then(result => {
            if (result) {
              return this.loadDotcenyOrgan();
            }
          });

      }

      sub.unsubscribe();
    });
  }

  onKontaktniOsobaCreate(): void {
    const dialog = this.dialogService.open(KontaktniOsobaComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.dotcenyOrgan.id
      },
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe(async (kontaktniOsoba: KontaktniOsobaModel) => {
      if (kontaktniOsoba) {
        await this.updateDotcenyOrgan(kontaktniOsoba);
      }
      sub.unsubscribe();
    });
  }

  onDotcenyOrganCancel(): void {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete orgán smazat?',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.dotcenyOrganService.cancelDotcenyOrgan(this.akceSchema, this.dotcenyOrgan)
          .then(() => this.stateService.go('doss.akce.data.organy'));
      }
      sub.unsubscribe();
    });
  }

  loadDotcenyOrgan(): Promise<DotcenyOrganModel> {
    return this.dotcenyOrganService.getById(this.akceSchema, this.id).then(dotcenyOrgan => {
      this.dotcenyOrgan = dotcenyOrgan;

      let noteTab;

      noteTab = this.tabs.find((tab) => tab.id === 'kontakty');
      noteTab.name = `Kontakty (${this.dotcenyOrgan.kontaktniOsoby.length})`;

      noteTab = this.tabs.find((tab) => tab.id === 'odbory');
      noteTab.name = `Odbory (${this.dotcenyOrgan.odbory.length})`;

      noteTab = this.tabs.find((tab) => tab.id === 'uradovny');
      noteTab.name = `Úřadovny (${this.dotcenyOrgan.pracoviste.length})`;

      return this.dotcenyOrgan;
    });
  }

  private updateDotcenyOrgan(kontaktniOsoba: KontaktniOsobaModel): void {
    this.dotcenyOrganService.update(this.akceSchema, this.mapToDotcenyOrganUpdateModel(kontaktniOsoba))
      .then(res => this.loadDotcenyOrgan());
  }

  private mapToDotcenyOrganUpdateModel(kontaktniOsoba: KontaktniOsobaModel): DotcenyOrganUpdateModel {
    return {
      id: this.dotcenyOrgan.id,
      kontaktniOsobyCreate: [kontaktniOsoba],
      odboryCreate: [],
      pracovisteCreate: []
    };
  }

  public onPracovisteUpdate(pracoviste): void {
    const dialog = this.dialogService.open(PracovisteComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.id,
        pracoviste: _.cloneDeep(pracoviste)
      }
    });

    const sub = dialog.afterClosed.subscribe((pracoviste: PracovisteModel) => {
      if (pracoviste) {
        this.dotcenyOrganService.updatePracoviste(this.akceSchema, pracoviste).then(() => this.loadDotcenyOrgan());
      }

      sub.unsubscribe();
    });
  }

  onAddNewPracoviste(): void {
    const dialog = this.dialogService.open(PracovisteComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.id
      }
    });

    const sub = dialog.afterClosed.subscribe((res: PracovisteModel) => {
      if (res) {
        this.dotcenyOrganService.update(this.akceSchema,
          {
             id: this.id,
             pracovisteCreate: [res],
           })
          .then(result => {
            if (result) {
              return this.loadDotcenyOrgan();
            }
          });
      }

      sub.unsubscribe();
    });
  }

  showCancelButton(pracoviste: PracovisteModel): boolean {
    return pracoviste.id && !ValidationUtils.isNonEmptyArray(pracoviste.reseneUkony);
  }

  onPracovisteCancel(pracoviste: PracovisteModel): void {
     const dialog = this.dialogService.open(ConfirmationComponent, {
       data: {
         msg: 'Opravdu chcete pracoviště smazat? Jeho obnovení nebude možné!',
       },
       className: ClassName.ADJUSTED_DIALOG,
     });

     const sub = dialog.afterClosed.subscribe((result: boolean) => {
       if (result) {
         this.dotcenyOrganService.cancelPracoviste(this.akceSchema, pracoviste)
           .then(() => this.loadDotcenyOrgan());
       }
       sub.unsubscribe();
     });
   }
}
