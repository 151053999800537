import {Component} from '@angular/core';
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  helpIds = HelpService.HELP_IDS;

  constructor() {}

}
