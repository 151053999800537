<div [loading]="loading">
  <div *ngIf="!loading">
    <div *ngIf="organizationalUnits.length > 0">
      <div class="column-100 checklist">
        <div *ngFor="let organizationalUnit of organizationalUnits" class="checklist-item" (click)="onPermissionsChange(organizationalUnit)">
          <div>
            <gmt-checkbox gmtType="multiselect" (click)="onPermissionsChange(organizationalUnit, true); $event.preventDefault(); $event.stopPropagation();" [gmtChecked]="checklistSelection.isChecked(organizationalUnit.code)" class="clickable"></gmt-checkbox> {{ organizationalUnit | organizationalUnitName }}
          </div>
        </div>
      </div>
    </div>
    <div class="empty-info bordered" *ngIf="organizationalUnits.length === 0">
      <span>
        Nenalezena žádná data
      </span>
    </div>
  </div>
</div>
