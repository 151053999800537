import {Component, EventEmitter, Input, Output} from '@angular/core';

import {TitleModel} from '@app/common/models/title.model';
import {ListModel} from '@app/common/models/list.model';
import {UkonModel} from "@app/doss/models/ukon.model";
import {TableServiceDoss} from "@app/doss/services/table-service-doss.service";
import {StavUkonuUtils} from "@app/doss/utils/stav-ukonu.utils";
import { UserNamePipe } from '@app/common/pipes/username.pipe';

@Component({
  selector: 'table-ukony',
  templateUrl: './table-ukony.component.html',
  styleUrls: ['./table-ukony.component.scss']
})
export class TableUkonyComponent {
  @Input() list: ListModel<UkonModel>;
  @Input() isChecked: Function;
  @Input() clickRow: boolean = true;
  @Input() inverseSelection: boolean;
  @Input() columnNames: any;
  @Input() customColumns: string[] = [
    'dotcenyOrgan', 'ukon', 'stav', 'kategorieDoss', 'datumPosledniZmeny', 'upozorneni', 'ukoly', 'oblibene', 'propojeni', 'platnostDo', 'verzePd'
  ];

  @Input() showNavIcons = false;
  @Output() checkAll: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<{}> = new EventEmitter();
  @Output() toggleCheckItem: EventEmitter<TitleModel> = new EventEmitter();
  public isArray = Array.isArray;
  protected readonly StavUkonuUtils = StavUkonuUtils;
  private readonly TABLE_ID = 'ukony';

  constructor(private tableService: TableServiceDoss,
              private usernamePipe: UserNamePipe
  ) {
  }

  onSort(column: string, ascOrDesc: string) {
    this.sort.emit({
      column,
      ascOrDesc,
    });
  }

  getColumnName(columnId: string) {
    if (this.columnNames && this.columnNames[columnId]) {
      return this.columnNames[columnId];
    }
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }

  isPast(datum: string) {
    const date = new Date(datum);
    date.setHours(0,0,0,0);

    const now = new Date();
    now.setHours(0,0,0,0);
    return date.getTime() - now.getTime() < 0;
  }

  hideColumn(columnId: string) {
    if (this.customColumns) {
      return !this.customColumns.includes(columnId);
    }
    return true;
  }
}
