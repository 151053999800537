import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {StateService, TargetState, Transition, TransitionService, UIRouterGlobals} from '@uirouter/angular';
import {AuthService} from '@app/common/services/auth.service';
import {MenuConfig} from '@app/models/menuConfig';
import {User} from '@app/models/user';
import {ApproveTermsService} from '@app/common/services/approve-terms.service';
import {ChangePasswordComponent} from '@app/common/components/change-password/change-password.component';
import {DialogService} from '@app/common/services/dialog.service';
import {GoogleAnalyticsService} from '@app/common/services/google-analytics.service';
import {APP_BRAND, APP_CONFIG, APPLICATIONS, EVENTS} from '@app/common/services/config.service';
import {LocalStorageService} from 'angular-2-local-storage';
import {updateBody} from '@app/common/utils/state.utils';
import {AkceModel} from "@app/doss/models/akce.model";
import {UserNamePipe} from "@app/common/pipes/username.pipe";
import { ProjectStatusService } from '@app/common/services/project-status.service';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  collapsed = false;
  user: User;
  servicedeskUrl: string = undefined;
  menuConfig: MenuConfig[] = [];
  ENV = this.config.ENV;
  akce: AkceModel;
  processes;

  constructor(
    @Inject(APPLICATIONS) private applications: any,
    private localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private config,
    @Inject(APP_BRAND) public APP_BRAND,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dialogService: DialogService,
    private approveTermsService: ApproveTermsService,
    private authService: AuthService,
    @Inject('$transition$') private transition: Transition,
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    private transitionService: TransitionService,
    private userNamePipe: UserNamePipe,
    public projectStatusService: ProjectStatusService,
  ) {
    this.checkPasswordChange = this.checkPasswordChange.bind(this);
    this.onAuthProjectUpdated = this.onAuthProjectUpdated.bind(this);
  }

  async ngOnInit() {
    this.googleAnalyticsService.onUserCome();
    updateBody('doss');

    this.collapsed = this.localStorageService.get('menuOpened');
    this.user = this.authService.getUser();
    this.servicedeskUrl = this.authService.getServiceDeskUrl();
    this.menuConfig = this.getMenuConfig();
    this.approveTermsService.checkApprovedTerms(this.user.id, this.applications.doss.name).then(this.checkPasswordChange); // @TODO DOSS

    this.authService.registerCallback(EVENTS.authProjectUpdated, this.onAuthProjectUpdated);

    this.transitionService.onStart({}, transition => {
      if (transition && transition.to()) {
        this.menuConfig = this.getMenuConfig(transition.to().name);
      }
    });

    this.projectStatusService.registerListener((data) => {
      this.processes = data;
    });
  }

  ngOnDestroy() {
    this.authService.unregisterCallback(EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
  }

  checkPasswordChange() {
    if (this.authService.isFirstLogin() && !this.authService.isSsoLoginEnabled()) {
      this.dialogService.open(ChangePasswordComponent, undefined);
    }
  }

  onAuthProjectUpdated(akce: AkceModel) {
    this.akce = akce;
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.localStorageService.set('menuOpened', this.collapsed);
  }

  logout() {
    this.authService.logout();
  }

  isActive(uiSref: string, strict = false): boolean {
    if (strict) {
      return this.uiRouterGlobals.$current.name === uiSref;
    }
    return !!this.uiRouterGlobals.$current.includes[uiSref];
  }

  private getMenuConfig(route?: string): MenuConfig[] {
    if (this.isRouteOrActive(route, 'doss.home')) {
      return [
        new MenuConfig('Úvodní stránka', 'doss.home', 'fa-home'),
        new MenuConfig('Seznam akcí', 'doss.akce.list', 'fa-list', { page: 1 })
      ];
    }

    if (this.isRouteOrActive(route, 'doss.akce.list', 'doss.akce.create')) {
      return [
        new MenuConfig('Seznam akcí', 'doss.akce.list', 'fa-list', { page: 1 })
      ];
    }

    return [
      new MenuConfig('Přehled', 'doss.akce.data.overview', 'fa-dashboard'),
      new MenuConfig('Karta akce', 'doss.akce.data.detail', 'fa-info-circle'),
      new MenuConfig('Úkony DOSS', 'doss.akce.data.ukony', 'fa-file', { page: 1 }),
      new MenuConfig('Verze PD', 'doss.akce.data.verzePd', 'fa-file', { page: 1 }),
      new MenuConfig('Exporty', 'doss.akce.data.exporty', 'fa-cloud-download'),
      new MenuConfig('Orgány', 'doss.akce.data.organy', 'fa-file', { page: 1 }),
      //new MenuConfig('Nastavení', 'doss.akce.data.setting', 'fa-wrench')
    ];
  }

  private isRouteOrActive(route: string, ...paths: string[]): boolean {
    for (const path of paths) {
      if (route === path || (route === undefined && this.isActive(path, true))) {
        return true;
      }
    }
    return false;
  }
}
