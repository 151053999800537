import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ListService } from './list.service';
import { PageableList } from '@app/models/pageable.list';
import { UICONSTANTS } from '@app/common/services/config.service';

@Injectable({ providedIn: 'root' })
export class PageableListService {

  constructor(
    private listService: ListService,
    private stateService: StateService,
  ) {}

  get(url: string, defaultFilter: any, localStorageKey?: string, restangularService?: any, attributes?: any): PageableList {
    const list = this.listService.createList(url, defaultFilter, restangularService, attributes);

    if (localStorageKey) {
      this.listService.attachFilterStorage(list, localStorageKey);
    }

    return new PageableList(list, this.listService, this.stateService, UICONSTANTS);
  }
}
