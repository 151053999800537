<div class="mt-30 mb-70 clearfix">
  <div class="column-33">
    <label>Evidenční číslo</label>
    <div class="info">{{ akce.evidencniCislo }}</div>

    <label>Číslo akce S</label>
    <div class="info">{{ akce.cisloAkceS }}</div>

    <label>Název stavby</label>
    <div class="info">{{ akce.nazevAkce }}</div>

    <label>Stupeň</label>
    <div class="info">{{ akce.stupen?.id }}</div>
  </div>

  <div class="column-33">
    <label>Organizace</label>
    <div class="info">{{ akce.organizace | projectSubjectName }}</div>

    <label>Organizační jednotka</label>
    <div class="info">{{ akce.organizacniJednotka | organizationalUnitName }}</div>

    <label>Manažer akce DOSS</label>
    <div class="info">{{ akce.manazerAkce | username }}</div>
  </div>

  <div class="column-33">
    <label>Odpovědný referent DOSS</label>
    <div class="info">{{ akce.referent | username: true }}</div>

    <label>Zpracovatel DOSS</label>
    <div class="info">{{ akce.zpracovatel | username: true }}</div>

    <label>Projektant</label>
    <div class="info">{{ akce.projektant | projectSubjectName: true }}</div>
  </div>
</div>

<h2>Lokalizace stavby</h2>

<div class="column-33">
  <div *ngFor="let category of detailyLokalizace; first as isFirst">
    <div class="padding-20 flexbox-container" [ngClass]="{'bordered-top' : !isFirst}">
        <label>{{ category.name }}</label>
        <div class="input flex-rest" *ngIf="akce?.lokalizaceStavby">
          <ng-container *ngFor="let detail of getEntitiesByCategory(category); let i = index">
            <div class="mb-5 mt-5 flexbox-container">
              <div class="info flex-rest">{{ akce.lokalizaceStavby[category.id][i].name }}</div>
              <a *ngIf="akce.lokalizaceStavby[category.id].length > 1"
                 (click)="remove(akce.lokalizaceStavby[category.id], i)" class="remove button red">
                <i class="fa fa-trash-o"></i>
              </a>
            </div>
          </ng-container>
          <div class="mb-5 mt-5">
            <gmt-select
              selectTitle="přidat"
              searchPlaceholder="Hledané spojení (minimálne 3 znaky)"
              [searchTextMinCharacters]="3"
              [restangularService]="restangularDoss"
              resource="geopas/entityByTerm"
              [filter]="{ filters: { entity: { values: [category] }, searchText: { values: [] }}}"
              (changed)="onUpdateLokalizaceStavby(category.id, $event)"
              [compareFunction]="compareLokalizace"
            ></gmt-select>
          </div>
        </div>
    </div>
  </div>
</div>
