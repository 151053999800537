import {Component} from '@angular/core';
import {DialogRef} from "@app/common/services/dialog-ref";
import {StateService} from "@uirouter/angular";
import {StavSouladuSPdModel} from "@app/doss/models/stav-souladu-s-pd.model";

@Component({
  templateUrl: './verze-pd-nelze-zalozit.component.html',
  styleUrls: ['./verze-pd-nelze-zalozit.component.scss']
})
export class VerzePdNelzeZalozitComponent {

  constructor(private stateService: StateService,
              private dialogComponent: DialogRef) {
    this.onZobrazitUkonyKPrezkumu = this.onZobrazitUkonyKPrezkumu.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onZobrazitUkonyKPrezkumu(): void {
    this.stateService.go('doss.akce.data.ukony', {souladSPd: [StavSouladuSPdModel.PREZKUM]});
    this.dialogComponent.close();
  }

  onCancel(): void {
    this.dialogComponent.close();
  }
}
