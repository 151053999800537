export enum RoleEnum {
  MANDATAR = 'MANDATAR',
  REFERENT = 'REFERENT',
  GEODET = 'GEODET',
  REFERENT_VEDOUCI = 'REFERENT_VEDOUCI',
  MANAZER = 'MANAZER',
  MANAZER_REPORT = 'MANAZER_REPORT',
  GENERALNI = 'GENERALNI',
  DASHBOARD = 'DASHBOARD',
  ADMIN = 'ADMIN',
  ADMIN_UV = 'ADMIN_UV',
  ADMIN_UP = 'ADMIN_UP',
  SUPER_ADMIN = 'SUPER_ADMIN',
  VZORY_ZADAVATEL = 'VZORY_ZADAVATEL',
  VZORY_SCHVALOVATEL = 'VZORY_SCHVALOVATEL',
  VZORY_ZPRACOVATEL = 'VZORY_ZPRACOVATEL',
  VZORY_NAHLED = 'VZORY_NAHLED',
  MANAZER_REPORT_UV = 'MANAZER_REPORT_UV',
  MANAZER_REPORT_UP = 'MANAZER_REPORT_UP',
  NAHLED = 'NAHLED',
  NAHLED_UV = 'NAHLED_UV',
  NAHLED_UP = 'NAHLED_UP',
  VFZE = 'VFZE',
  DOSS_NAHLED = 'DOSS_NAHLED',
  DOSS_MANAZER = 'DOSS_MANAZER',
  DOSS_REFERENT = 'DOSS_REFERENT',
  DOSS_ADMIN = 'DOSS_ADMIN',
}

export const roleOptions = [
  {
    id: RoleEnum.MANDATAR,
    name: 'Majetkář'
  },
  {
    id: RoleEnum.REFERENT,
    name: 'Referent'
  },
  {
    id: RoleEnum.GEODET,
    name: 'Geodet'
  },
  {
    id: RoleEnum.REFERENT_VEDOUCI,
    name: 'Referent vedoucí'
  },
  {
    id: RoleEnum.MANAZER,
    name: 'Reporter'
  },
  {
    id: RoleEnum.MANAZER_REPORT,
    name: 'Manažerský report'
  },
  {
    id: RoleEnum.GENERALNI,
    name: 'Generální ředitel'
  },
  {
    id: RoleEnum.DASHBOARD,
    name: 'Přehled MPV old'
  },
  {
    id: RoleEnum.ADMIN,
    name: 'Admin'
  },
  {
    id: RoleEnum.ADMIN_UV,
    name: 'Administrátor Úseku výstavby'
  },
  {
    id: RoleEnum.ADMIN_UP,
    name: 'Administrátor Úseku provozního'
  },
  {
    id: RoleEnum.SUPER_ADMIN,
    name: 'Superadministrátor'
  },
  {
    id: RoleEnum.VZORY_ZADAVATEL,
    name: 'Zadavatel vzoru'
  },
  {
    id: RoleEnum.VZORY_SCHVALOVATEL,
    name: 'Schvalovatel vzoru'
  },
  {
    id: RoleEnum.VZORY_ZPRACOVATEL,
    name: 'Zpracovatel vzoru'
  },
  {
    id: RoleEnum.VZORY_NAHLED,
    name: 'Náhled do modulu vzory'
  },
  {
    id: RoleEnum.MANAZER_REPORT_UV,
    name: 'Manažerský report úseku výstavby'
  },
  {
    id: RoleEnum.MANAZER_REPORT_UP,
    name: 'Manažerský report úseku provozního'
  },
  {
    id: RoleEnum.NAHLED,
    name: 'Náhled'
  },
  {
    id: RoleEnum.NAHLED_UV,
    name: 'Náhled úseku výstavby'
  },
  {
    id: RoleEnum.NAHLED_UP,
    name: 'Náhled úseku provozního'
  },
  {
    id: RoleEnum.NAHLED_UP,
    name: 'Náhled úseku provozního'
  },
  {
    id: RoleEnum.VFZE,
    name: 'VFZE validátor'
  },
  {
    id: RoleEnum.DOSS_NAHLED,
    name: 'DOSS náhled'
  },
  {
    id: RoleEnum.DOSS_MANAZER,
    name: 'DOSS manažer OJ'
  },
  {
    id: RoleEnum.DOSS_REFERENT,
    name: 'DOSS referent'
  },
  {
    id: RoleEnum.DOSS_ADMIN,
    name: 'DOSS admin'
  },
];
