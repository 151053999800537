<div class="list">
  <div class="actions">
    <a uiSref="doss.akce.create" class="button" *ngIf="canCreate()"><i class="fa fa-wrench"></i>Založení nové akce</a>
  </div>

  <h1>Seznam akcí</h1>
  <ng-container *ngIf="list">
    <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
      <gmt-select
        [restangularService]="configurationRestangularService"
        [multiselect]="true"
        [data]="list.filter.filters.organizacniJednotkaId"
        [globalFilterConfig]="globalFilter"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.organizacniJednotkaId.negation"
        resource="organizational-units"
        selectTitle="Organizační jednotka"
        logGa
      ></gmt-select>

      <gmt-select
        [optionItems]="manazerTypeOptions"
        [multiselect]="true"
        [data]="list.filter.filters.manazerAkce"
        [globalFilterConfig]="globalFilter"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.manazerAkce.negation"
        resource="users/list"
        [itemPipe]="usernamePipe"
        selectTitle="Manažer akce"
        logGa
      ></gmt-select>

      <gmt-select
        [multiselect]="true"
        [optionItems]="stavyAkce"
        [data]="list.filter.filters.stavAkce"
        [globalFilterConfig]="globalFilter"
        [(selectAll)]="list.filter.filters.stavAkce.negation"
        field="values"
        enableSelectAll="true"
        selectTitle="Stav akce"
        logGa
      ></gmt-select>

      <div class="column-100">
        <input class="fulltext" autocomplete="off" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
      </div>

    </filter>
    <div [loading]="list.loading || !settingsLoaded">
        <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
          <div class="flex1 basis0">
            <gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable" *ngIf="canExport()"><i class="fa fa-download"></i> Exportovat seznam</gmt-button>
          </div>
          <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
          <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
            <span>Záznamů na stránce</span>
            <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
          </div>
        </div>
        <table-akce [list]="list" (sort)="pageableList.onSort($event.column, $event.ascOrDesc)"></table-akce>
        <akce-legenda></akce-legenda>
        <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
        <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
      </div>
  </ng-container>
</div>
<help [helpId]="helpIds.DOSS_AKCE_LIST" class="cm-help-page"></help>
