import {Component, Input, OnInit} from '@angular/core';
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {StavUkonuUtils} from "@app/doss/utils/stav-ukonu.utils";
import {PropojeniUkonuModel} from "@app/doss/models/propojeni-ukonu.model";
import {UkonPropojeniModel} from "@app/doss/models/ukon-propojeni.model";

export interface TooltipPropojeniUkonuData {
  propojeniList: PropojeniUkonuModel[];
}

@Component({
  selector: 'tooltip-propojeni-ukonu',
  templateUrl: './tooltip-propojeni-ukonu.component.html',
  styleUrls: ['./tooltip-propojeni-ukonu.component.scss']
})
export class TooltipPropojeniUkonuComponent implements OnInit {
  @Input()
  data: TooltipPropojeniUkonuData;

  mappedData: Record<string, UkonPropojeniModel[]>;

  public readonly ValidationUtils = ValidationUtils;
  public readonly StavUkonuUtils = StavUkonuUtils;
  public readonly Object = Object;

  ngOnInit(): void {
    this.mappedData = this.groupByTypPropojeni(this.data.propojeniList);
  }

  private groupByTypPropojeni(propojeniList: PropojeniUkonuModel[]): Record<string, UkonPropojeniModel[]> {
    return propojeniList.reduce((grouped: Record<string, UkonPropojeniModel[]>, propojeni: PropojeniUkonuModel) => {
      const key = propojeni.typPropojeni;

      if (!grouped[key]) {
        grouped[key] = [];
      }

      grouped[key].push(propojeni.targetUkon);

      return grouped;
    }, {} as Record<string, UkonPropojeniModel[]>);
  }
}
