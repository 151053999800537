import { Inject, Injectable } from '@angular/core';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { RESTANGULAR_DIMAP } from '@app/common/services/restangular-dimap.service';
import { RESTANGULAR_VFZE } from '@app/common/services/restangular-vfze.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APPLICATIONS } from '@app/common/services/config.service';
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import { ListService } from '@app/common/services/list.service';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  symapPermissions = null;
  roles = {};
  dimapPermissions = null;
  vfzePermissions = null;
  promapPermissions = null;
  svPermissions = null;
  dossPermissions = null;
  symapProjects = null;
  dimapProjects = null;
  vfzeProjects = null;
  promapProjects = null;
  dossProjects = null;
  users = {};
  userPermissions = {};
  userApprovedTerms = {};
  termsConditions = {};

  constructor(
    private listService: ListService,
    @Inject(APPLICATIONS) private APPLICATIONS: any,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    @Inject(RESTANGULAR_DIMAP) private restangularDimap: any,
    @Inject(RESTANGULAR_VFZE) private restangularVfze: any,
    @Inject(RESTANGULAR_DOSS) private restangularDoss: any,
    @Inject(RESTANGULAR_SETTINGS) private restangularSettings: any
  ) { }

  async loadModuleRoles(module) {
    return this.restangularSettings.all(`${module}/roles/permissions`).getList().toPromise();
  }

  async getModuleRoles(module) {
    if (this.roles[module]) {
      return this.roles[module];
    } else {
      if (module === this.APPLICATIONS.sy.name) {
        this.roles[module] = await this.loadModuleRoles(module);
      } else if (module === this.APPLICATIONS.di.name) {
        const permissions = (await this.getPermissions(module)).map(p => p.name);
        this.roles[module] = [{
          role: this.APPLICATIONS.di.name,
          permissions,
        }];
      } else if (module === this.APPLICATIONS.vfze.name) {
        const permissions = (await this.getPermissions(module)).map(p => p.name);
        this.roles[module] = [{
          role: this.APPLICATIONS.vfze.name,
          permissions,
        }];
      } else if (module === this.APPLICATIONS.pk.name) {
        const permissions = (await this.getPermissions(module)).map(p => p.name);
        this.roles[module] = [{
          role: this.APPLICATIONS.pk.name,
          permissions,
        }];
      } else if (module === this.APPLICATIONS.sv.name) {
        this.roles[module] = await this.loadModuleRoles(module);
      } else if (module === this.APPLICATIONS.doss.name) {
        this.roles[module] = await this.loadModuleRoles(module);
      }

      return this.roles[module];
    }
  }

  loadModulePermissions(module) {
    return this.restangularSettings.one(`permissions/application/${module}/list`).get().toPromise().then((data) => this.setPermissions(module, data));
  }

  loadSyMAPProjectById(projectId) {
    const projectGetParams = {loadCollections: ['contract', 'customerWsdpAccount', 'customer', 'series', 'obligationTypes', 'occupationTypes', 'statisticsTypes', 'exportTypes', 'templateTypes', 'investor', 'investor.actingPersons', 'investor.cadastreAgent', 'investor.cadastreAgent.actingPersons', 'manager', 'designer', 'propertyActivityProcessor', 'landTakeDocumentationAuthor', 'geodet', 'propertyActivityHead', 'templatesOrganizationalUnit' ]};
    return this.restangularSymap.one('projects', projectId).get(projectGetParams).toPromise();
  }

  loadProjects(module) {
    const syMAPProjectGetParams = {loadCollections: ['series', 'obligationTypes', 'statisticsTypes', 'exportTypes', 'templateTypes', 'investor', 'investor.actingPersons', 'investor.cadastreAgent', 'investor.cadastreAgent.actingPersons', 'manager', 'designer', 'propertyActivityProcessor', 'landTakeDocumentationAuthor', 'geodet', 'propertyActivityHead', 'templatesOrganizationalUnit' ]};
    switch (module) {
      case this.APPLICATIONS.sy.name:
        return this.restangularSymap.one('projects').get(syMAPProjectGetParams).toPromise().then((data) => this.setProjects(module, data));
      case this.APPLICATIONS.di.name:
        return this.restangularDimap.one('projects').get().toPromise().then((data) => this.setProjects(module, data));
      case this.APPLICATIONS.vfze.name:
        return this.restangularVfze.one('projects').get().toPromise().then((data) => this.setProjects(module, data));
      case this.APPLICATIONS.doss.name:
        const list = this.listService.createList('akce/list', {limit: undefined}, this.restangularDoss);
        return this.listService.fetchResult(list).then((data) => {
          return this.setProjects(module, data.list.map(p => { return {id: p.id, key: p.schema, name: p.nazevAkce}; }));
        });
      case this.APPLICATIONS.pk.name:
        return new Promise((resolve, reject) => {
          resolve(this.setProjects(module, { items: [ { key: 'promap_default' }] }));
        });
      default:
    }
  }

  loadUser(userId) {
    return this.restangularSettings.one('users/user', userId).get().toPromise().then((data) => this.setUser(userId, data));
  }

  createUser(user) {
    return this.restangularSettings.all('users/user').post(user).toPromise().then((data) => {
      this.setUser(data.id, data);
      return data;
    });
  }

  saveUser(user) {
    return this.restangularSettings.one('users/user', user.id).customPUT(user).toPromise().then((data) => {
      this.setUser(data.id, data);
      return data;
    });
  }

  removeUser(userId) {
    return this.restangularSettings.one('users/user', userId).remove().toPromise().then(() => this.setUser(userId, undefined));
  }

  loadUserPermissions(module, userId) {
    return this.restangularSettings.one(`permissions/application/${module}/user/${userId}`).get().toPromise().then((data) => this.setUserPermissions(module, userId, data));
  }

  saveUserPermissions(module, projectKey, userId, permissions) {
    return this.restangularSettings.one(`permissions/application/${module}/project/${projectKey}`, 'user').post(userId, permissions).toPromise().then(() => {
      return this.updateUserPermissions(module, userId, projectKey, permissions);
    });
  }

  setPermissions(module, properties) {
    switch (module) {
      case this.APPLICATIONS.sy.name:
        this.symapPermissions = properties;
        return this.symapPermissions;
      case this.APPLICATIONS.di.name:
        this.dimapPermissions = properties;
        return this.dimapPermissions;
      case this.APPLICATIONS.vfze.name:
        this.vfzePermissions = properties;
        return this.vfzePermissions;
      case this.APPLICATIONS.pk.name:
        this.promapPermissions = properties;
        return this.promapPermissions;
      case this.APPLICATIONS.sv.name:
        this.svPermissions = properties;
        return this.svPermissions;
      case this.APPLICATIONS.doss.name:
        this.dossPermissions = properties;
        return this.dossPermissions;
      default:
    }
  }

  getPermissions(module): Promise<any> {
    const loadPermissions = (module) => {
      return this.loadModulePermissions(module).then(() => {
        return this.getPermissions(module);
      });
    };

    switch (module) {
      case this.APPLICATIONS.sy.name:
        if (!this.symapPermissions) {
          return loadPermissions(module);
        } else {
          return Promise.resolve(this.symapPermissions);
        }
      case this.APPLICATIONS.di.name:
        if (!this.dimapPermissions) {
          return loadPermissions(module);
        } else {
          return Promise.resolve(this.dimapPermissions);
        }
      case this.APPLICATIONS.vfze.name:
        if (!this.vfzePermissions) {
          return loadPermissions(module);
        } else {
          return Promise.resolve(this.vfzePermissions);
        }
      case this.APPLICATIONS.pk.name:
        if (!this.promapPermissions) {
          return loadPermissions(module);
        } else {
          return Promise.resolve(this.promapPermissions);
        }
      case this.APPLICATIONS.sv.name:
        if (!this.svPermissions) {
          return loadPermissions(module);
        } else {
          return Promise.resolve(this.svPermissions);
        }
      case this.APPLICATIONS.doss.name:
        if (!this.dossPermissions) {
          return loadPermissions(module);
        } else {
          return Promise.resolve(this.dossPermissions);
        }
      default:
    }
  }

  loadUserApprovedTerms(userId) {
    return this.restangularSettings.one(`users/user/${userId}/terms-conditions`).get().toPromise().then((data) => this.setUserApprovedTerms(userId, data));
  }

  loadTermsConditions(module) {
    return this.restangularSettings.one(`applications/${module}`).get().toPromise().then((data) => this.setTermsConditions(module, data));
  }

  setUserApprovedTerms(userId, approvedTerms) {
    this.userApprovedTerms[userId] = approvedTerms;
  }

  setTermsConditions(module, conditions) {
    this.termsConditions[module] = conditions;
  }

  saveConditions(module, conditions) {
    return this.getApproveTermsConditions(module).then((oldConditions) => {
      oldConditions = oldConditions.plain();
      oldConditions.termsAndConditions = conditions;

      return this.restangularSettings.one(`applications/${module}`).customPUT(oldConditions).toPromise();
    });
  }

  setProjects(module, data) {
    switch (module) {
      case this.APPLICATIONS.sy.name:
        this.symapProjects = data;
        return this.symapProjects;
      case this.APPLICATIONS.di.name:
        this.dimapProjects = data;
        return this.dimapProjects;
      case this.APPLICATIONS.vfze.name:
        this.vfzeProjects = data;
        return this.vfzeProjects;
      case this.APPLICATIONS.pk.name:
        this.promapProjects = data;
        return this.promapProjects;
      case this.APPLICATIONS.doss.name:
        this.dossProjects = data;
        return this.dossProjects;
      default:
    }
  }

  getProjectById(module, projectId) {
    return this.getProjects(module).then((projects) => {
      return projects.find(p => p.id === projectId);
    });
  }

  saveProject(module, project) {
    switch (module) {
      case this.APPLICATIONS.sy.name:
        return this.restangularSymap.one('projects', project.id).customPUT(project).toPromise().then(() => {
          return this.loadProjects(module);
        });
      case this.APPLICATIONS.di.name:
        return this.restangularDimap.one('projects', project.id).customPUT(project).toPromise().then(() => {
          return this.loadProjects(module);
        });
      default:
    }
  }

  getProjects(module) {
    let projects = null;
    switch (module) {
      case this.APPLICATIONS.sy.name:
        projects = this.symapProjects;
        break;
      case this.APPLICATIONS.di.name:
        projects = this.dimapProjects;
        break;
      case this.APPLICATIONS.vfze.name:
        projects = this.vfzeProjects;
        break;
      case this.APPLICATIONS.pk.name:
        projects = this.promapProjects;
        break;
      case this.APPLICATIONS.doss.name:
        projects = this.dossProjects;
        break;
      default:
    }

    return projects
      ? Promise.resolve(projects)
      : this.loadProjects(module)
          .then(() => {
            return this.getProjects(module);
          });
  }

  setUser(userId, data) {
    if (!data) {
      delete this.users[userId];
    } else {
      this.users[userId] = data;
    }
  }

  getUser(userId): Promise<any> {
    const user = this.users[userId];

    return user
      ? Promise.resolve(user)
      : this.loadUser(userId).then(() => {
          return this.getUser(userId);
        });
  }

  emptyUserPermissions(userId) {
    this.userPermissions[userId] = {};
  }

  setUserPermissions(module, userId, permissions) {
    if (!this.userPermissions[userId]) {
      this.emptyUserPermissions(userId);
    }

    this.userPermissions[userId][module] = permissions;
  }

  async updateUserPermissions(module, userId, projectKey, permissions) {
    const roles = await this.getModuleRoles(module);
    const role = permissions && permissions.projectRole && roles.find(role => role.role === permissions.projectRole);
    const rolePermissions = role && role.permissions || [];
    const modulePermissions = await this.getPermissions(module);

    const newPermissions = rolePermissions
      .concat(permissions.permissions)
      .map((perm) => {
        const permissionDetail = modulePermissions.find(p => p.name === perm);

        return {
          application: module,
          projectKey,
          permission: permissionDetail.name,
          permissionId: permissionDetail.id,
        };
      });

    if (!this.userPermissions[userId]) {
      this.userPermissions[userId] = {};
    }
    if (!this.userPermissions[userId][module]) {
      this.userPermissions[userId][module] = {};
    }

    this.userPermissions[userId][module][projectKey] = newPermissions;

    return this.userPermissions[userId][module][projectKey];
  }

  getUserPermissions(module, userId): Promise<any> {
    const permissions = this.userPermissions[userId];

    return permissions && permissions[module]
      ? Promise.resolve(permissions[module])
      : this.loadUserPermissions(module, userId)
          .then(() => {
            return this.getUserPermissions(module, userId);
          });
  }

  approveTerms(userId, approvedModule) {
    return this.getUserApprovedTerms(userId).then((terms) => {
      terms.push(approvedModule);
      return this.restangularSettings.all(`users/user/${userId}/terms-conditions`).customPUT({approvedTermsApplication: approvedModule}).toPromise();
    });
  }

  getApproveTermsConditions(module): Promise<any> {
    const termsConditions = this.termsConditions[module];
    return termsConditions
      ? Promise.resolve(termsConditions)
      : this.loadTermsConditions(module)
          .then(() => {
            return this.getApproveTermsConditions(module);
          });
  }

  getUserApprovedTerms(userId): Promise<any> {
    const approvedTerms = this.userApprovedTerms[userId];

    return approvedTerms
      ? Promise.resolve(approvedTerms)
      : this.loadUserApprovedTerms(userId).then(() => {
          return this.getUserApprovedTerms(userId);
        });
  }

  sendUserEmail(userId, email) {
    return this.restangularSettings
      .all(`users/user/${userId}/send-email`)
      .customPOST(email)
      .toPromise();
  }

  getNotificationConfigs(module, projectKey, userId) {
    const filter: any = { filter: { filters: {
      application: { values: [module] },
      projectKey: { values: [projectKey] },
    }}};

    if (userId) {
      filter.filter.filters.userId = { values: [ userId ]};
    }

    return this.restangularSettings
      .all('notification-configurations')
      .customPOST(filter)
      .toPromise()
      .then(r => r.plain());
  }

  uploadNotificationConfig(config) {
    return this.restangularSettings
      .one('notification-configurations')
      .customPUT(config)
      .toPromise()
      .then(r => r.plain());
  }

  uploadProjectNotificationConfigs(projectKey, configs) {
    return this.restangularSettings
      .one(`notification-configurations/${projectKey}`)
      .customPUT(configs)
      .toPromise();
  }
}
