<h2 class="mb-10" (click)="onSectionCollapse('tasksSectionCollapsed')">Moje úkoly</h2>
<div *ngIf="!tasksSectionCollapsed" class="mb-20">
  <div class="tabbable-line tabs-below">
      <ukol-list
        [ukolList]="ukolList"
        [defaultData]="{projectInfo: true, systemType: false}"
        [prehledAkce]="true"
      ></ukol-list>
  </div>
</div>
