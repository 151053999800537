<div class="cm-select">
    <div (click)="toggle(!visible || displayInputOnly);" class="cm-select-inputwrap" [ngClass]="{'count-visible': multiselect ? (selectAll || data[field]?.length > 0) : (!required && data[field])}">
        <a *ngIf="!displayInputOnly" href="#" (click)="$event.preventDefault();" class="select-value" [ngClass]="{'empty': !selectTitle && !multiselect && !data[field]}">
            {{getTitle()}}
        </a>
        <input *ngIf="displayInputOnly" type="text" class="search-text" placeholder="{{selectTitle}}" [value]="searchText" (input)="_onSearchTextChanged($event.target.value);"/>
        <span class="selected-count" (click)="$event.stopPropagation();" *ngIf="!selectAll || totalCount !== undefined">
            <span [hidden]="!multiselect" style="padding-right: 3px;">
              <ng-container *ngIf="!selectAll">{{ data[field]?.length }}</ng-container>
              <ng-container *ngIf="selectAll">{{ totalCount - data[field]?.length }}</ng-container>
            </span>
            <a class="fa fa-times" [attr.title]="getSelectedItemsNames()" [hidden]="required" (click)="$event.stopPropagation();selectAll ? toggleSelectAll() : unselectAll();"></a>
        </span>
        <span class="select-carret" *ngIf="!displayInputOnly">
          <span class="arrow" [ngClass]="{'active': visible}"></span>
        </span>
    </div>

    <div class="options-pane bordered" [ngClass]="{'multiselect': multiselect}" style="display:none">
        <span *ngIf="!displayInputOnly && enableSearch">
            <input type="text" class="search-text" [placeholder]="searchPlaceholder" [value]="searchText" (input)="_onSearchTextChanged($event.target.value)"/>
            <i class="fa fa-search" *ngIf="searchText.length === 0"></i>
            <a class="fa fa-times" *ngIf="searchText.length !== 0" (click)="$event.stopPropagation();resetSearchText()"></a>
        </span>
        <div [loading]="options && options.loading" class="cb">
            <div class="count bordered-bottom flexbox-container" *ngIf="options && options.list !== null && options.itemCount">
              <div class="flex-rest">Nalezeno záznamů: {{ options ? options.itemCount : '-' }}</div>
              <div class="flex-rest text-right select-all" *ngIf="enableSelectAll" (click)="toggleSelectAll()">
                <gmt-checkbox [gmtChecked]="selectAll"></gmt-checkbox> Vše
              </div>
            </div>
            <div class="count" [hidden]="!options || options.list === null || options.itemCount">Nenalezeny žádné záznamy</div>
            <div class="result" [hidden]="!options || options.list === null || !options.itemCount">
                <perfect-scrollbar *ngIf="options" (callbackRegister)="scrollbarCallbackRegister($event)" [hidden]="options.list === null || !options.itemCount">
                    <ul class="results">
                        <li *ngFor="let item of options.list" [ngClass]="{ selected: selectAll ? !isChecked(item) : isChecked(item) }">
                            <div class="item" (click)="toggleSelection(item)">
                                <gmt-checkbox [gmtType]="multiselect ? 'multiselect' : 'switchselect'" [gmtChecked]="selectAll ? !isChecked(item) : isChecked(item)">
                                    <span>{{formatItem(item)}}</span>
                                </gmt-checkbox>
                            </div>
                        </li>
                    </ul>
                    <div class="load-next" [hidden]="!options || options.list === null || options.itemCount <= options?.list?.length">
                        <input type="button" value="Načíst další" (click)="loadNext()" [disabled]="options && options.loading"/>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
