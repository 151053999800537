import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PracovisteModel} from "@app/doss/models/pracoviste.model";
import {
  PracovisteComponent
} from "@app/doss/components/pracoviste/pracoviste.component";
import {DialogService} from "@app/common/services/dialog.service";
import * as _ from "lodash";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";

@Component({
  selector: 'pracoviste-detail',
  templateUrl: './pracoviste-detail.component.html',
  styleUrls: ['./pracoviste-detail.component.scss']
})
export class PracovisteDetailComponent {
  @Input()
  akceSchema: string;

  @Input()
  dotcenyOrganId: number;

  @Input()
  pracoviste: PracovisteModel;

  @Input()
  showEdit: boolean = true;

  @Output()
  onChanged: EventEmitter<any> = new EventEmitter();

  constructor(private dotcenyOrganService: DotcenyOrganService,
              private dialogService: DialogService) {
    this.onPracovisteUpdate = this.onPracovisteUpdate.bind(this);
  }

  public onPracovisteUpdate(): void {
    const dialog = this.dialogService.open(PracovisteComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.dotcenyOrganId,
        pracoviste: _.cloneDeep(this.pracoviste)
      }
    });

    const sub = dialog.afterClosed.subscribe((pracoviste: PracovisteModel) => {
      if (pracoviste) {
        this.dotcenyOrganService.updatePracoviste(this.akceSchema, pracoviste).then(() => this.onChanged.emit());
      }

      sub.unsubscribe();
    });
  }
}
