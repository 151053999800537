import {Component, Input} from '@angular/core';
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

export interface TooltipKategorieDossData {
  kategorieDoss: KategorieDossModel[];
}

@Component({
  selector: 'tooltip-kategorie-doss',
  templateUrl: './tooltip-kategorie-doss.component.html',
  styleUrls: ['./tooltip-kategorie-doss.component.scss']
})
export class TooltipKategorieDossComponent {
  @Input() data: TooltipKategorieDossData;
  public readonly ValidationUtils = ValidationUtils;
}
