import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Ng2StateDeclaration, TargetState, UIRouterModule} from '@uirouter/angular';
import {registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {HttpClientModule} from '@angular/common/http';

import {CommonModule} from './../common/common.module';
import {ExportyModule} from "@app/doss/exporty/exporty.module";
import {PsCommonModule} from "@app/ps/ps-common.module";
import { ErrorModule } from '@app/error/error.module';

import {MainComponent} from "@app/doss/components/main/main.component";
import {HomeComponent} from "@app/doss/components/home/home.component";
import {HomeInformation} from '@app/doss/components/home-information/home-information.component';
import { HomeLastVisitedAkceComponent } from '@app/doss/components/home-last-visited-akce/home-last-visited-akce.component';
import { HomeOblibeneUkonyComponent } from '@app/doss/components/home-oblibene/home-oblibene.component';

import {AkceInfoComponent} from "@app/doss/components/akce-info/akce-info.component";
import {LokalizaceStavbyPipe} from "@app/doss/pipes/lokalizace-stavby.pipe";
import {AkceUpdateComponent} from "@app/doss/components/akce-update/akce-update.component";
import {FirstStepComponent} from "@app/doss/components/akce-create/first-step/first-step.component";
import {SecondStepComponent} from "@app/doss/components/akce-create/second-step/second-step.component";
import {ThirdStepComponent} from "@app/doss/components/akce-create/third-step/third-step.component";
import {AkceCreateComponent} from "@app/doss/components/akce-create/akce-create.component";
import {ProjektantComponent} from "@app/doss/components/projektant/projektant.component";
import {StupenAkcePipe} from "@app/doss/pipes/stupen-akce.pipe";
import {MapEditorComponent} from "@app/doss/components/map-editor/map-editor.component";
import {AkceListComponent} from "@app/doss/components/akce-list/akce-list.component";
import {TableAkceComponent} from "@app/doss/components/table-akce/table-akce.component";
import {AkceLegendaComponent} from "@app/doss/components/akce-legenda/akce-legenda.component";
import {AkceOverviewComponent} from "@app/doss/components/akce-overview/akce-overview.component";
import {AkceUkolyComponent} from "@app/doss/components/akce-ukoly/akce-ukoly.component";
import {AkceUkonStatisticsComponent} from '@app/doss/components/akce-ukon-statistics/akce-ukon-statistics.component';
import {AkceNotifikaceComponent} from '@app/doss/components/akce-notifikace/akce-notifikace.component';
import { ProjectStatusService } from '@app/common/services/project-status.service';
import {StavAkcePipe} from "@app/doss/pipes/stav-akce.pipe";

import {PracovisteComponent} from "@app/doss/components/pracoviste/pracoviste.component";
import {PracovistePipe} from "@app/doss/pipes/pracoviste.pipe";
import {OdborComponent} from "@app/doss/components/odbor/odbor.component";
import {OdborPipe} from "@app/doss/pipes/odbor.pipe";
import {DotcenyOrganPipe} from "@app/doss/pipes/dotceny-organ.pipe";
import {KontaktniOsobaPipe} from "@app/doss/pipes/kontaktni-osoba.pipe";
import { DotceneOrganyListComponent } from "@app/doss/components/dotcene-organy-list/dotcene-organy-list.component";
import { TableDotceneOrganyComponent} from "@app/doss/components/table-dotceny-organ/table-dotcene-organy.component";
import { DotcenyOrganFormComponent } from "@app/doss/components/dotceny-organ-form/dotceny-organ-form.component";
import { DotcenyOrganCreateComponent } from "@app/doss/components/dotceny-organ-create/dotceny-organ-create.component";
import { DotcenyOrganDetailComponent } from "@app/doss/components/dotceny-organ-detail/dotceny-organ-detail.component";
import {TableOdborComponent} from "@app/doss/components/table-odbor/table-odbor.component";
import {DotcenyOrganEntityPipe} from "@app/doss/pipes/dotceny-organ-entity.pipe";
import {EmailPipe} from "@app/doss/pipes/email.pipe";
import { PracovisteDetailComponent } from "@app/doss/components/pracoviste-detail/pracoviste-detail.component";
import {OpenHourPipe} from "@app/doss/pipes/open-hour.pipe";
import {OdborPracovisteCreateFormComponent} from "@app/doss/components/odbor-pracoviste-create-form/odbor-pracoviste-create-form.component";
import { TableKontaktniOsobyComponent } from "@app/doss/components/table-kontaktni-osoby/table-kontaktni-osoby.component";
import { OdborPracovisteCreateComponent } from "@app/doss/components/odbor-pracoviste-create/odbor-pracoviste-create.component";
import { ResenyUkonInfoComponent } from "@app/doss/components/reseny-ukon-info/reseny-ukon-info.component";

import {UkonDetailComponent} from "@app/doss/components/ukon-detail/ukon-detail.component";
import {UkonListComponent} from "@app/doss/components/ukon-list/ukon-list.component";
import {UkonCreateComponent} from "@app/doss/components/ukon-create/ukon-create.component";
import {UkonFormComponent} from "@app/doss/components/ukon-create/ukon-form/ukon-form.component";
import {KontaktniOsobaComponent} from "@app/doss/components/kontaktni-osoba/kontaktni-osoba.component";
import {PredchoziUkonPipe} from "@app/doss/pipes/predchozi-ukon.pipe";
import {UkonUpdateComponent} from "@app/doss/components/ukon-update/ukon-update.component";
import {TypUkonuPipe} from "@app/doss/pipes/typ-ukonu.pipe";
import { ChangeUkonStatusMenuComponent } from "@app/doss/components/change-ukon-status-menu/change-ukon-status-menu.component";
import { ChangeUkonStatusComponent } from "@app/doss/components/change-ukon-status/change-ukon-status.component";
import {FazeUkonuPipe} from "@app/doss/pipes/faze-ukonu.pipe";
import { UkonStatusDocumentsComponent } from "@app/doss/components/change-ukon-status-documents/ukon-status-documents.component";
import {DokumentTypePipe} from "@app/doss/pipes/file-to-upload-type.pipe";
import {TableUkonyComponent} from "@app/doss/components/table-ukony/table-ukony.component";
import {UkolCreateComponent} from "@app/doss/components/ukol-create/ukol-create.component";
import {OblibenyUkonComponent} from "@app/doss/components/oblibeny/oblibeny-ukon.component";
import {SouladSPdComponent} from "@app/doss/components/soulad-s-pd/soulad-s-pd.component";
import {VerzePdComponent} from "@app/doss/components/verze-pd/verze-pd.component";
import { UkonNelzeZalozitComponent } from "@app/doss/components/ukon-nelze-zalozit/ukon-nelze-zalozit.component";
import {VerzePdPipe} from "@app/doss/pipes/verze-pd.pipe";
import {NotesDossFormComponent} from "@app/doss/components/notes-doss-form/notes-doss-form.component";
import {NotesDossListComponent} from "@app/doss/components/notes-doss-list/notes-doss-list.component";
import {UkolListComponent} from "@app/doss/components/ukol-list/ukol-list.component";
import {DossUpozorneniComponent} from "@app/doss/components/upozorneni/doss-upozorneni.component";
import { PodrobneInformaceComponent } from "@app/doss/components/podrobne-informace/podrobne-informace.component";
import {StavFazeUkonuFilterPipe} from "@app/doss/pipes/stav-faze-ukonu-filter.pipe";
import { TooltipPropojeniUkonuComponent } from "@app/doss/components/tooltip-propojeni-ukonu/tooltip-propojeni-ukonu.component";
import { CellPropojeniUkonuComponent}  from "@app/doss/components/cell-propojeni-ukonu/cell-propojeni-ukonu.component";
import {VerzePdListComponent} from "@app/doss/components/verze-pd-list/verze-pd-list.component";
import {VerzePdFormComponent} from "@app/doss/components/verze-pd-form/verze-pd-form.component";
import {TableVerzePdComponent} from "@app/doss/components/table-verze-pd/table-verze-pd.component";
import {KategorieDossDetailPipe} from "@app/doss/pipes/kategproe-doss-list.pipe";
import { CellKategorieDossComponent} from "@app/doss/components/cell-kategorie-doss/cell-kategorie-doss.component";
import { TooltipKategorieDossComponent} from "@app/doss/components/tooltip-kategorie-doss/tooltip-kategorie-doss.component";
import {KategorieDossIdPipe} from "@app/doss/pipes/kategproe-doss-id.pipe";
import {KategorieDossTooltipPipe} from "@app/doss/pipes/kategproe-doss-tooltip.pipe";
import {  VerzePdNelzeZalozitComponent} from "@app/doss/components/verze-pd-nelze-zalozit/verze-pd-nelze-zalozit.component";
import {KategorieDossPipe} from "@app/doss/pipes/kategorie-doss.pipe";
import {KategorieDossListPipe} from "@app/doss/pipes/kategorie-doss-list.pipe";

import {WizardComponent} from "@app/doss/components/wizard/wizard.component";
import {WizardTabComponent} from "@app/doss/components/wizard-tab/wizard-tab.component";

import { TablePropojeniUkonuComponent } from "@app/doss/components/table-propojeni-ukonu/table-propojeni-ukonu.component";
import {UkonPropojeniPipe} from "@app/doss/pipes/propojeni-ukonu.pipe";
import { PropojeniUkonuFormComponent } from "@app/doss/components/propojeni-ukonu-create/propojeni-ukonu-form.component";
import {TypPropojeniPipe} from "@app/doss/pipes/typ-propojeni.pipe";

import {dossHomeState, dossState} from "@app/doss/doss.states";
import {
  akceCreateState,
  akceDataState,
  akceDetailState,
  akceListState,
  akceOverviewState,
  akceState,
  akceUpdateState
} from "@app/doss/akce.states";
import {organCreateState, organDetailState, organListState} from "@app/doss/dotceny-organ.states";
import {
  ukonCreateState,
  ukonDetailState,
  ukonListState,
  ukonUpdateState,
  ukonUpdateStatusState
} from "@app/doss/ukon.states";
import {verzePdListState} from "@app/doss/verze-pd.states";

registerLocaleData(localeCs, 'cs');

const STATES: Ng2StateDeclaration[] = [
  dossState,
  dossHomeState,

  akceState,
  akceListState,
  akceCreateState,
  akceDataState,
  akceOverviewState,
  akceDetailState,
  akceUpdateState,

  organListState,
  organDetailState,
  organCreateState,

  ukonListState,
  ukonCreateState,
  ukonDetailState,
  ukonUpdateState,
  ukonUpdateStatusState,

  verzePdListState
];

@NgModule({
  imports: [
    NgbModule,
    HttpClientModule,
    CommonModule,
    ErrorModule,
    UIRouterModule.forChild({
      states: [...STATES]
    }),
    PsCommonModule, // projectSubjectName
    ExportyModule,
  ],
  providers: [],
  declarations: [
    MainComponent,
    HomeComponent,
    HomeInformation,
    HomeLastVisitedAkceComponent,
    HomeOblibeneUkonyComponent,

    AkceListComponent,
    TableAkceComponent,
    AkceLegendaComponent,
    AkceOverviewComponent,
    AkceUkolyComponent,
    AkceNotifikaceComponent,
    AkceUkonStatisticsComponent,
    AkceInfoComponent,
    AkceCreateComponent,
    FirstStepComponent,
    SecondStepComponent,
    ThirdStepComponent,
    ProjektantComponent,
    MapEditorComponent,
    AkceUpdateComponent,
    LokalizaceStavbyPipe,
    StupenAkcePipe,
    StavAkcePipe,

    DotcenyOrganFormComponent,
    DotceneOrganyListComponent,
    TableDotceneOrganyComponent,
    DotcenyOrganCreateComponent,
    DotcenyOrganDetailComponent,
    TableOdborComponent,
    TableKontaktniOsobyComponent,
    PracovisteDetailComponent,
    ResenyUkonInfoComponent,
    OdborComponent,
    PracovisteComponent,
    OdborPracovisteCreateComponent,
    OdborPracovisteCreateFormComponent,
    DotcenyOrganPipe,
    DotcenyOrganEntityPipe,
    PracovistePipe,
    OdborPipe,
    KontaktniOsobaPipe,
    EmailPipe,
    OpenHourPipe,

    UkonListComponent,
    UkonCreateComponent,
    UkonFormComponent,
    UkonDetailComponent,
    KontaktniOsobaComponent,
    UkonUpdateComponent,
    ChangeUkonStatusMenuComponent,
    ChangeUkonStatusComponent,
    UkonStatusDocumentsComponent,
    UkonNelzeZalozitComponent,
    UkolCreateComponent,
    OblibenyUkonComponent,
    SouladSPdComponent,
    VerzePdComponent,
    PredchoziUkonPipe,
    TableUkonyComponent,
    CellPropojeniUkonuComponent,
    TooltipPropojeniUkonuComponent,
    PredchoziUkonPipe,
    TypUkonuPipe,
    PredchoziUkonPipe,
    FazeUkonuPipe,
    DokumentTypePipe,
    VerzePdPipe,
    StavFazeUkonuFilterPipe,
    NotesDossListComponent,
    NotesDossFormComponent,
    UkolListComponent,
    DossUpozorneniComponent,
    PodrobneInformaceComponent,

    VerzePdListComponent,
    TableVerzePdComponent,
    CellKategorieDossComponent,
    TooltipKategorieDossComponent,
    VerzePdFormComponent,
    VerzePdNelzeZalozitComponent,
    KategorieDossPipe,
    KategorieDossIdPipe,
    KategorieDossListPipe,
    KategorieDossDetailPipe,
    KategorieDossTooltipPipe,

    WizardComponent,
    WizardTabComponent,

    TablePropojeniUkonuComponent,
    PropojeniUkonuFormComponent,
    UkonPropojeniPipe,
    TypPropojeniPipe
  ],
  entryComponents: [
    ProjektantComponent,

    OdborComponent,
    PracovisteComponent,
    OdborPracovisteCreateComponent,
    KontaktniOsobaComponent,

    KontaktniOsobaComponent,
    UkonNelzeZalozitComponent,
    UkolCreateComponent,
    SouladSPdComponent,
    NotesDossFormComponent,
    DossUpozorneniComponent,
    TooltipPropojeniUkonuComponent,

    VerzePdFormComponent,
    TooltipKategorieDossComponent,
    VerzePdNelzeZalozitComponent,

    PropojeniUkonuFormComponent
  ],
  exports: [
    /*DotcenyOrganFormComponent,
    DotcenyOrganPipe,
    PracovistePipe,
    OdborPipe,
    KontaktniOsobaPipe,

    NotesDossListComponent,
    UkolListComponent,
    DossUpozorneniComponent,
    TableUkonyComponent,

    KategorieDossPipe,
    KategorieDossListPipe,

    WizardComponent,
    WizardTabComponent,

    TablePropojeniUkonuComponent,
    TypPropojeniPipe,*/
  ]
})

export class DossAppModule {
}
