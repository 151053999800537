import {Component, OnInit} from '@angular/core';

import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import {DialogRef} from '@app/common/services/dialog-ref';
import {ProjektantModel} from "@app/doss/models/projektant.model";

@Component({
  selector: 'projektant',
  templateUrl: './projektant.component.html',
  styleUrls: []
})
export class ProjektantComponent implements OnInit {
  projektant: ProjektantModel;

  constructor(
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
  ) {
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
    this.projektant = (<DialogConfigData>this.dialogConfig.data).projektant || {};
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close(this.projektant);
  }
}
