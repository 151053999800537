import {Injectable, Pipe, PipeTransform} from "@angular/core";

/**
 * Display name of occupation
 */
@Pipe({name: 'typPropojeni'})
@Injectable({
  providedIn: 'root'
})
export class TypPropojeniPipe implements PipeTransform {

  transform(typPropojeni: string): string {
    switch (typPropojeni) {
      case 'PREDCHUDCE':
        return 'Předchůdce';
      case 'SOUVISI':
        return 'Související';
      case 'NAVAZUJE':
        return 'Navazující';
      default:
        return null;
    }
  }
}
