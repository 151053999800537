import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {AkceStatusEnum, akceStatusOptions} from "@app/doss/enums/akce-status.enum";

/**
 * Display name of occupation
 */
@Pipe({name: 'stavAkce'})
@Injectable({
  providedIn: 'root'
})
export class StavAkcePipe implements PipeTransform {

  transform(status: AkceStatusEnum): string {
    return !status ? "" : akceStatusOptions.find(i => i.id == status).name;
  }
}
