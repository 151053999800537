import {Component, OnInit} from '@angular/core';
import {StateService} from "@uirouter/angular";
import {UkonModel} from "@app/doss/models/ukon.model";
import {UkonCreateModel} from "@app/doss/models/ukon-create.model";
import {UkonService} from "@app/doss/services/ukon.service";
import {AuthService} from "@app/common/services/auth.service";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './ukon-create.component.html',
  styleUrls: ['./ukon-create.component.scss']
})
export class UkonCreateComponent implements OnInit {
  helpIds = HelpService.HELP_IDS;
  akceSchema: string;
  ukon: UkonModel = <UkonModel>{};

  constructor(private authService: AuthService,
              private ukonService: UkonService,
              private stateService: StateService) {
    this.createUkon = this.createUkon.bind(this);
  }

  ngOnInit(): void {
    this.akceSchema = this.authService.getActualProject().schema;
    this.ukon.dotcenyOrgan = this.stateService.params.selectedDotcenyOrgan;
  }

  canCreate(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  async createUkon(): Promise<void> {
    await this.ukonService.create(this.akceSchema, this.mapToUkonCreateModel(this.ukon))
      .then((ukon: UkonModel) => this.stateService.go('doss.akce.data.ukony.detail', {
        akceKey: this.akceSchema,
        id: ukon.id
      }));
  }

  private mapToUkonCreateModel(ukon: UkonModel): UkonCreateModel {
    const {dotcenyOrgan, kategorieDoss, typUkonu, ...otherProperties} = ukon;

    return {
      dotcenyOrganId: dotcenyOrgan.id,
      kategorieDoss: kategorieDoss.map(kategorie => kategorie.id),
      typUkonu: typUkonu.id,
      ...otherProperties
    };
  }
}
