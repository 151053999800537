<div class="list property-status">
  <div class="actions">
  </div>

  <h1>Majetkoprávní přehled</h1>
  <filter [list]="list" (onChanged)="onFilterChanged($event)">
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.managementAreaId" [globalFilterConfig]="globalFilter" field="values" [attributes]="{geometry: {load: false}}" resource="management-areas/list" selectTitle="Majetková správa"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.areaId" [globalFilterConfig]="globalFilter" field="values" [attributes]="{geometry: {load: false}}" [filter]="filterArea" resource="areas/list" selectTitle="Katastrální území"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.titleId" [globalFilterConfig]="globalFilter" field="values" [attributes]="{area: {mapping: {attributes: {geometry: {load: false}}}}}" [filter]="filterTitle" resource="titles/list" selectTitle="List vlastnictví" [itemPipe]="titleNamePipe"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.subjectId" [globalFilterConfig]="globalFilter" field="values" [attributes]="{subjectSjm1: {}, subjectSjm2: {}}" [filter]="filterSubject" resource="subjects/list" selectTitle="Vlastník" [itemPipe]="subjectNamePipe"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.parcelId" [globalFilterConfig]="globalFilter" field="values" [attributes]="{geometry: {load: false}, title: {mapping: {attributes: {area: {mapping: {attributes: {geometry: {load: false}}}}}}}}" [filter]="filterParcel" resource="parcels/list" selectTitle="Parcela" [itemPipe]="parcelNamePipe"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.roadSectionId" [globalFilterConfig]="globalFilter" field="values" [attributes]="{geometry: {load: false}}" [filter]="filterRoadSection" resource="road-sections/list" selectTitle="Úsek silnice" [itemPipe]="roadSectionNamePipe"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.divisionTypeId" field="values" [filter]="filterDivisionType" resource="division-types/list" selectTitle="Druh plochy"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.ownerType" field="values" [optionItems]="ownerTypes" selectTitle="Druh vlastníka"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.opsubType" field="values" [optionItems]="opsubTypes" selectTitle="Druh opr. subjektu"></gmt-select>
    <gmt-select *ngIf="listLoaded" multiselect="true" [data]="list.filter.filters.opsubGroup" [globalFilterConfig]="globalFilter" field="values" [filter]="filterOpsubGroup" resource="opsub-group/list" selectTitle="Skupina opr. subjektů"></gmt-select>
  </filter>

  <div class="flexbox-container" *ngIf="groupBy">
    <div class="column-100">
      <fieldset>
        <legend>Zobrazit</legend>
        <gmt-switch [selectedId]="groupBy.id" [options]="groups" (select)="updateGroup($event.id)"></gmt-switch>
      </fieldset>
    </div>
  </div>

  <div [loading]="!list || list?.loading">
    <div class="bordered" *ngIf="list?.itemCount">
      <div class="table">
        <div class="head-group">
          <div class="row">
            <span class="cell"><sort-by [selected]="'areaName' === list.filter.sortOrder.sortBy" (sort)="onSort('areaName', $event)" [sortDir]="list.filter.sortOrder.direction" *ngIf="displaySections.area || displaySections.title || displaySections.parcel">K.Ú.</sort-by><span *ngIf="!displaySections.area && !displaySections.title && !displaySections.parcel">K.Ú.</span></span>
            <span class="cell"><sort-by [selected]="'titleLv' === list.filter.sortOrder.sortBy" (sort)="onSort('titleLv', $event)" [sortDir]="list.filter.sortOrder.direction" *ngIf="displaySections.title || displaySections.parcel">LV</sort-by><span *ngIf="!displaySections.title && !displaySections.parcel">LV</span></span>
            <span class="cell"><sort-by [selected]="'parcelNumber' === list.filter.sortOrder.sortBy" (sort)="onSort('parcelNumber', $event)" [sortDir]="list.filter.sortOrder.direction" *ngIf="displaySections.parcel">Parcela</sort-by><span *ngIf="!displaySections.parcel">Parcela</span></span>
            <span class="cell">Vlastník</span>
            <span class="cell"><sort-by [selected]="'roadName' === list.filter.sortOrder.sortBy" (sort)="onSort('roadName', $event)" [sortDir]="list.filter.sortOrder.direction" *ngIf="displaySections.road_section || displaySections.road">Číslo <span *ngIf="displaySections.road">silnice</span><span *ngIf="displaySections.road_section">úseku</span></sort-by><span *ngIf="!displaySections.road_section && !displaySections.road">Silnice</span></span>
            <span class="cell">Druh plochy</span>
            <span class="cell">Skupina opr. subjektů</span>
            <sort-by class="cell" [selected]="'propertyAreaKeep' === list.filter.sortOrder.sortBy" (sort)="onSort('propertyAreaKeep', $event)" [sortDir]="list.filter.sortOrder.direction">Výměra v pořádku [<span class="unit">m<sup>2</sup></span>]</sort-by>
            <sort-by class="cell" [selected]="'propertyAreaSell' === list.filter.sortOrder.sortBy" (sort)="onSort('propertyAreaSell', $event)" [sortDir]="list.filter.sortOrder.direction">Výměra nadbytečná [<span class="unit">m<sup>2</sup></span>]</sort-by>
            <sort-by class="cell" [selected]="'propertyAreaBuy' === list.filter.sortOrder.sortBy" (sort)="onSort('propertyAreaBuy', $event)" [sortDir]="list.filter.sortOrder.direction">Výměra pro výkup [<span class="unit">m<sup>2</sup></span>]</sort-by>
            <sort-by class="cell" [selected]="'geometricPlanLength' === list.filter.sortOrder.sortBy" (sort)="onSort('geometricPlanLength', $event)" [sortDir]="list.filter.sortOrder.direction">Délka nové hranice [<span class="unit">m</span>]</sort-by>
          </div>
        </div>
        <div class="row-group hover" *ngFor="let item of list.list" (click)="checklistSelection.toggleSelection(item)">
          <span class="list-check">
            <gmt-checkbox gmtType="multiselect" [gmtChecked]="checklistSelection.isChecked(item)">
            </gmt-checkbox>
          </span>
          <span class="row">
            <!-- k. ú. -->
            <span class="cell" *ngIf="item.areacount === undefined">
              {{ item.areaname }}
            </span>

            <span class="cell no-padding" *ngIf="item.areacount !== undefined">
              <div class="fill-content">
                  <div class="fill-content flexbox-container flex-centered padding-10">
                      <div class="icon-count flexbox-container">
                          <i class="fa fa-th"></i>
                      </div>
                      <div class="number ml-4">{{item.areacount}}</div>
                  </div>
              </div>
            </span>

            <!-- LV -->
            <span class="cell" *ngIf="item.titlecount === undefined">
              {{item.titlelv}}
            </span>
            <span class="cell no-padding" *ngIf="item.titlecount !== undefined">
              <div class="fill-content">
                  <div class="fill-content flexbox-container flex-centered padding-10">
                      <div class="icon-count flexbox-container">
                          <i class="fa fa-list-alt"></i>
                      </div>
                      <div class="number ml-4" >{{ item.titlecount }}</div>
                  </div>
              </div>
            </span>

            <span class="cell no-padding" *ngIf="item.parcelcount !== undefined">
              <cell-parcels
                [countParcels]="item.parcelcount"
                [tooltipType]="'parcels-dimap'"
                [tooltipData]="{
                  attributes: {title: {mapping: {attributes: {area: {}}}}},
                  filter: {
                    entityIds: currentFilter.parcelId,
                    filters: {titleId: {values: (item.titleid ? [item.titleid] : currentFilter.titleId)}, areaId: {values: (item.areaid ? [item.areaid] : currentFilter.areaId)}, roadId: {values: (item.roadid ? [item.roadid] : currentFilter.roadId)}, roadSectionId: {values: (item.roadsectionid ? [item.roadsectionid] : currentFilter.roadSectionId)}, ownerType: {values: (item.ownertype ? [item.ownertype] : currentFilter.ownerType)}, parcelId: {values: currentFilter.parcelId}, opsubGroup: {values: currentFilter.opsubGroup}}
                  }
                }"
              ></cell-parcels>
            </span>
            <span class="cell no-padding" *ngIf="item.parcelnumber !== undefined">
              <cell-parcel
                [parcel]="item"
                [tooltipType]="'dimap-parcel'"
                [tooltipData]="{ parcel: item }"
              ></cell-parcel>
            </span>

            <!-- vlastník -->
            <span class="cell no-padding">
                <div class="fill-content">
                    <div class="fill-content flexbox-container flex-centered padding-10">
                        <div class="icon-count flexbox-container">
                            <i class="fa fa-user"></i>
                        </div>
                        <div class="number ml-4">{{item.ownershipcount}}</div>
                    </div>
                </div>
            </span>

            <!-- silnice -->
            <span class="cell no-padding">
                <div class="fill-content">
                    <div class="fill-content flexbox-container flex-centered padding-10">
                        <div class="icon-count flexbox-container" *ngIf="item.roadcount !== undefined">
                            <i class="fa fa-road"></i>
                        </div>
                        <div class="number ml-4" *ngIf="item.roadcount !== undefined">{{item.roadcount}}</div>
                        <div *ngIf="item.roadname !== undefined">{{item.roadname}}</div>
                    </div>
                </div>
            </span>

            <span class="cell">{{item.divisiontypename}}</span>
            <span class="cell">{{item.opsubgroupname}}</span>
            <span class="cell number property-owned-inside">{{item.propertyareakeep|number:'1.2-2'}}</span>
            <span class="cell number property-owned-outside">{{item.propertyareasell|number:'1.2-2'}}</span>
            <span class="cell number property-others-inside">{{item.propertyareabuy|number:'1.2-2'}}</span>
            <span class="cell number">{{ceil(item.geometricplanlength)|number:'1.0-0'}}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="list && !list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <pagination
    *ngIf="list?.itemCount && list?.list !== null"
    [list]="list"
    (callbackRegister)="onListChangedSetterPagination($event)"
  ></pagination>

  <fieldset class="mt-20 mb-20 no-padding" [ngClass]="{bordered: (!selectionSummary.data && selectionSummary.loading) || (selectionSummary.data && !selectionSummary.data.duplicity)}">
    <legend>Výběr</legend>
    <div [loading]="selectionSummary.loading">
      <div *ngIf="selectionSummary.data">
        <div *ngIf="!selectionSummary.data.duplicity" class="p-20">
          <div class="column-100"><label>Počet KÚ</label><div class="info">{{selectionSummary.data.areaarraycount|number}}</div></div>
          <div class="column-100"><label>Počet LV</label><div class="info">{{selectionSummary.data.titlearraycount|number}}</div></div>
          <div class="column-100"><label>Počet parcel</label><div class="info">{{selectionSummary.data.parcelarraycount|number}}</div></div>
          <div class="column-100"><label>Počet vlastníků</label><div class="info">{{selectionSummary.data.ownershiparraycount|number}}</div></div>
          <div class="column-100"><label>Výměra v pořádku</label><div class="info">{{selectionSummary.data.propertyareakeep|number:'1.2-2'}} <span class="unit">m<sup>2</sup></span></div></div>
          <div class="column-100"><label>Výměra nadbytečná</label><div class="info">{{selectionSummary.data.propertyareasell|number:'1.2-2'}} <span class="unit">m<sup>2</sup></span></div></div>
          <div class="column-100"><label>Výměra pro výkup - cena</label><div class="info">{{selectionSummary.data.propertyareabuy|number:'1.2-2'}} <span class="unit">m<sup>2</sup></span></div></div>
          <div class="column-100"><label>Délka nové hranice - cena GP</label><div class="info">{{ceil(selectionSummary.data.geometricplanlength)|number:'1.0-0'}} <span class="unit">m</span></div></div>
          <div class="pt-10">
            <a class="button mr-10" (click)="resetSelection();">Zrušit výběr</a>
            <a class="button mr-10" (click)="export();" [loading]="exportLoading">Exportovat do CSV</a>
            <a class="button mr-10" (click)="exportXls();" [loading]="exportLoadingXls">Exportovat do XLS</a>
          </div>
        </div>
        <div *ngIf="selectionSummary.data.duplicity" class="empty-info">
          Duplicitní výběr parcel

          <div class="mt-10">
            <a class="button mr-10" (click)="resetSelection();">Zrušit výběr</a>
          </div>
        </div>
      </div>
      <div *ngIf="!selectionSummary.data && !selectionSummary.loading" class="empty-info">
        Nejsou vybrány žádné parcely
      </div>
    </div>
  </fieldset>

  <help [helpId]="helpIds.VM_PANEL_PROPERTY_STATUS" class="cm-help-page"></help>
</div>
