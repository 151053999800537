<div [loading]="podrobneInformaceList.loading">
  <div>
    <ul class="item-grid">
      <li *ngFor="let podrobneInformace of podrobneInformaceList.list" class="item">
        <div>
          <span class="podrobne-informace-span">{{podrobneInformace.stav}}</span>
          <span>{{podrobneInformace.datumZmeny | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.faze" class="podrobne-informace-span">({{podrobneInformace.faze}})</div>
        <div *ngIf="podrobneInformace.datumVydani">
          <span class="podrobne-informace-span">Datum vydání</span>
          <span>{{podrobneInformace.datumVydani | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.datumKoncePlatnosti">
          <span class="podrobne-informace-span">Datum konce platnosti</span>
          <span>{{podrobneInformace.datumKoncePlatnosti | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.lhutaProOdvolani">
          <span class="podrobne-informace-span">Lhůta pro odvolání</span>
          <span>{{podrobneInformace.lhutaProOdvolani | date}}</span>
        </div>
      </li>
    </ul>
  </div>

  <div class="bordered" *ngIf="podrobneInformaceList.list && podrobneInformaceList.itemCount === 0">
    <div class="empty-info">
      <span>Žádné podrobné informace</span>
    </div>
  </div>
</div>
