<div>
  <fileupload
    (change)="onFileSelect($event)"
    [disabled]="false"
    [clearFilesAfterUpload]="true"
    [draggable]="draggable"
    [message]="'Vložte nové dokumenty přetažením, nebo je vyberte z počítače.'"
    [ngClass]="required ? 'blue' : 'yellow'"
  ></fileupload>

  <ng-container *ngIf="ValidationUtils.isNonEmptyArray(dokumenty); else noDocumentsMessage">
    <div class="bordered">
      <table>
        <thead>
        <tr>
          <th>Název</th>
          <th>Poznámka</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let dokumentAdditionalData of dokumentyAdditionalData; let index = index;">
          <td class="attachment-item">
            <div>
              <div>
                <i [class]="getIconClass(dokumentAdditionalData)"></i>
              </div>
              <div>
                {{ dokumentAdditionalData.baseName + (dokumentAdditionalData.extension ? '.' + dokumentAdditionalData.extension : '') }}
                <p>{{ dokumentAdditionalData | DokumentType : 'title' }}</p>
              </div>
            </div>
          </td>
          <td>{{ dokumentAdditionalData.note }}</td>
          <td>
            <div class="fr" *ngIf="canUploadFiles()">
              <span>
                <a (click)="onEditDocument(dokumentAdditionalData)" title="Upravit">
                  <span class="fa fa-pencil fa-lg"></span>
                </a>
                <a (click)="onDeleteDocument(index)" class="remove-document" title="Odstranit">
                  <span class="fa fa-trash-o fa-lg"></span>
                </a>
              </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #noDocumentsMessage>
    <div class="bordered">
      <div class="empty-info">
        <span>Žádné dokumenty</span>
      </div>
    </div>
  </ng-template>
</div>
