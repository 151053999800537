export class StavSouladuSPdModel {
  public static readonly NENI_V_SOULAD_S_PD: StavSouladuSPdModel = new StavSouladuSPdModel(
    "NENI_V_SOULAD_S_PD",
    "Není v souladu s PD"
  );
  public static readonly PREZKUM: StavSouladuSPdModel = new StavSouladuSPdModel(
    "PREZKUM",
    "Přezkum"
  );
  public static readonly JE_V_SOULAD_S_PD: StavSouladuSPdModel = new StavSouladuSPdModel(
    "JE_V_SOULAD_S_PD",
    "Je v souladu s PD"
  );


  private constructor(private id: string, private name: string) {
  }
}
