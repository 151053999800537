import {Component, OnInit} from "@angular/core";
import {ListModel} from "@app/common/models/list.model";
import {AuthService} from "@app/common/services/auth.service";
import {ListService} from "@app/common/services/list.service";
import {User} from "@app/models/user";
import {UpozorneniDossModel} from "@app/doss/models/upozorneni-doss.model";

@Component({
  selector: 'akce-notifikace',
  templateUrl: './akce-notifikace.component.html',
  styleUrls: ['./akce-notifikace.component.scss']
})
export class AkceNotifikaceComponent implements OnInit {
  user: User;
  upozorneni: ListModel<UpozorneniDossModel>;


  constructor(private authService: AuthService,
              private listService: ListService) {
  }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.loadNotifications();
  }

  private loadNotifications() {
    this.upozorneni = this.listService.createList(
      'notifications',
      {
        filters: {
          user: this.user,
          archive: false
        },
        sortOrder: {sortBy: 'casOdeslani', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.upozorneni);
  }

}
