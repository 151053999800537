<h1>{{ note.id ? 'Editace poznámky' : 'Nová poznámka' }}</h1>

<div>
  <fieldset>
    <div id="editor"></div>
  </fieldset>

  <div class="actions" *ngIf="canEdit()">
    <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSave">Uložit</gmt-button>
    <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
  </div>
</div>
