import { Component } from '@angular/core';
import { AkceStatusEnum, LEGEND_ENTRIES } from "@app/doss/enums/akce-status.enum";

@Component({
  selector: 'akce-legenda',
  templateUrl: './akce-legenda.component.html',
  styleUrls: ['./akce-legenda.component.css']
})
export class AkceLegendaComponent {
  public readonly legendEntries = LEGEND_ENTRIES;
}
