import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";

@Pipe({name: 'kategorieDossTooltip'})
@Injectable({
  providedIn: 'root'
})
export class KategorieDossTooltipPipe implements PipeTransform {

  transform(kategorieDoss: KategorieDossModel): string {
    return kategorieDoss ? `(${kategorieDoss.idKategorie}) ${kategorieDoss.description}` : "nezvoleno";
  }
}
