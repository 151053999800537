import {Component, EventEmitter, Input, Output} from '@angular/core';

import {TitleModel} from '@app/common/models/title.model';
import {ListModel} from '@app/common/models/list.model';
import {SettingsService} from '@app/ps/services/settings.service';
import {AkceSeznamModel} from "@app/doss/models/akce-seznam.model";
import {TableServiceDoss} from "@app/doss/services/table-service-doss.service";
import { User } from '@app/models/user';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { LEGEND_ENTRIES } from "@app/doss/enums/akce-status.enum";

@Component({
  selector: 'table-akce',
  templateUrl: './table-akce.component.html',
  styleUrls: ['./table-akce.component.scss']
})
export class TableAkceComponent {
  @Input() list: ListModel<AkceSeznamModel>;
  @Input() isChecked: Function;
  @Input() inverseSelection: boolean;
  @Input() columnNames: any;
  @Input() customColumns: string[]; // avoid setting service
  @Input() customProjectKey: string;
  @Input() showNavIcons = false;
  @Output() checkAll: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<{}> = new EventEmitter();
  @Output() toggleCheckItem: EventEmitter<TitleModel> = new EventEmitter();
  @Output() select = new EventEmitter<string>();
  public isArray = Array.isArray;
  private readonly TABLE_ID = 'akce';

  constructor(
    private tableService: TableServiceDoss,
    private settingsService: SettingsService,
    public usernamePipe: UserNamePipe
  ) {
  }

  getRowColor(state: string): string {
    if (!state) {
      return 'white';
    }
    return '6px solid ' + LEGEND_ENTRIES.find(i => i.id == state).color;
  }

  onToggleCheckItem(title: TitleModel) {
    this.toggleCheckItem.emit(title);
  }

  onSort(column: string, ascOrDesc: string) {
    this.sort.emit({
      column,
      ascOrDesc,
    });
  }

  hideColumn(columnId: string) {
    if (this.customColumns) {
      return !this.customColumns.includes(columnId);
    }
    return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
  }

  getColumnName(columnId: string) {
    if (this.columnNames && this.columnNames[columnId]) {
      return this.columnNames[columnId];
    }
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }
}
