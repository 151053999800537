import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken('APP_CONFIG');

export const APP_BRAND = new InjectionToken('APP_BRAND');

export const APPLICATIONS = new InjectionToken('APPLICATIONS');

export const APP_WIKI_URL = 'https://clevermaps.atlassian.net/wiki/display/KBSY';

export const ROOT_CALLBACKS = new InjectionToken('ROOT_CALLBACKS');

export const UICONSTANTS = {
  itemsPerPage: [
    {id: 20, name: '20'},
    {id: 50, name: '50'},
    {id: 100, name: '100'},
    {id: 200, name: '200'},
  ],
  itemsPerPage10: [
    {id: 10, name: '10'},
    {id: 20, name: '20'},
    {id: 50, name: '50'},
    {id: 100, name: '100'},
    {id: 200, name: '200'},
  ],
};

export const EVENTS = {
  authUserUpdated: 'auth-user-updated',
  authProjectUpdated: 'auth-project-updated',
  authDossUkonUpdated: 'auth-doss-ukon-updated',
  authTimeout: 'auth-timeout',
  notificationWithWarningHidden: 'notification-with-warning-hidden',
  authApplicationUpdated: 'auth-application-updated',
};

export const APPLICATIONS_SRC = {
  sy: {
    name: 'SY',
    title: 'MAJA',
  },
  di: {
    name: 'DI',
    title: 'DIMAP',
  },
  pk: {
    name: 'PRO',
    title: 'PROMAP',
  },
  settings: {
    name: 'SETTINGS',
    title: 'Nastavení',
  },
  dashboard: {
    name: 'DASH',
    title: 'Dashboard',
  },
  vfze: {
    name: 'VFZE',
    title: 'VFZE',
  },
  landing: {
    name: 'LANDING',
    title: 'Úvodní stránka',
  },
  sv: {
    name: 'VZORY',
    title: 'Správa vzorů',
  },
  doss: {
    name: 'DOSS',
    title: 'DOSS',
  }
};
