import { Title } from '@angular/platform-browser';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { interval } from 'rxjs';

import { AuthService } from '@app/common/services/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {APP_BRAND, APP_CONFIG, APPLICATIONS} from '@app/common/services/config.service';

@Component({
  selector: 'gmt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user = {
    username: '',
    password: '',
  };
  loggedOut: boolean;
  redirectUrl: string;
  showLogin: boolean;
  maintanance: boolean;
  pfocus: boolean;
  ufocus: boolean;
  autofilled = false;
  sending = false;
  invalid = false;
  errMsg = '';
  servicedeskUrl = this.authService.getServiceDeskUrl();

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APP_CONFIG) public APP_CONFIG: any,
    @Inject(APPLICATIONS) private applications: any,
    private authService: AuthService,
    private stateService: StateService,
    private titleService: Title,
  ) {
    this.onSubmitCredentials = this.onSubmitCredentials.bind(this);
  }

  async ngOnInit() {
    this.redirectUrl = this.stateService.params.redirectUrl;
    this.loggedOut = this.stateService.params.loggedOut === 'true';
    this.showLogin = this.stateService.params.secret !== undefined || this.APP_BRAND.LOGIN.SSO === '';
    this.maintanance = this.APP_CONFIG.MAINTANANCE && !this.stateService.params.maintanance;
    this.titleService.setTitle(this.loggedOut ? 'Odhlášení' : 'Přihlášení');

    try {
      await this.loginBySsoToken();
    } catch (e) {
      let msg = 'Při ověřování přihlášení SSO došlo k chybě. Kontaktujte podporu.';

      if (this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'DTM') {
        msg = `Přihlášení uživatele do ${this.APP_BRAND.PS.TITLE} se nezdařilo. Nemáte přidělen přístup do systému ${this.APP_BRAND.PS.TITLE}`;
      }
      this.setErrorMessage(msg);
    }

    const isWebkit = 'WebkitAppearance' in document.documentElement.style;

    if (isWebkit) {
      const checkInterval = interval(100);

      const subscriber = checkInterval.subscribe(() => {
        try {
          [...document.querySelectorAll('input:-webkit-autofill')].forEach((elm) => {
            this.autofilled = true;
            const value = (elm as any).value;

            if (value) {
              this.user.password = (value);
              subscriber.unsubscribe();
            }
          });
        } catch (e) {}
      });
    }
  }

  setErrorMessage(msg) {
    this.sending = false;
    this.invalid = true;
    this.errMsg = msg || 'Chybné přihlašovací údaje, opakujte zadání.';
  }

  onSubmitCredentials(username, password) {
    if (typeof username !== 'string' || typeof password !== 'string') {
      return;
    }
    this.sending = true;
    this.invalid = false;
    this.errMsg = '';

    return this.authService.login(username, password, undefined)
      .then(() => {
        if (this.redirectUrl) {
          const states = this.stateService.get();
          const originUrl = this.redirectUrl.split('?')[0];
          const fakeUrl = `https://a.cz${this.redirectUrl}`;
          const search = {};
          for (const [key, value] of new URL(fakeUrl).searchParams.entries()) {
            search[key] = value;
          }
          for (const state of states) {
            const privatePortion = state.$$state();
            if (!privatePortion.url) {
              continue;
            }
            const match = privatePortion.url.exec(originUrl, search);
            if (match) {
              return this.stateService.go(state.name, match);
            }
          }
        }

        if (this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ') {
          if (this.authService.getActiveApplication() !== this.applications.doss.name) {
            this.authService.setActiveApplication(this.applications.landing.name);
          }
        }

        if (!this.authService.goToActiveAplication()) {
          const msg = 'Při přihlašování došlo k chybě. Kontaktujte podporu.';
          this.setErrorMessage(msg);
        }
      },
      (err) => {
        if (err.status === 429) {
          this.setErrorMessage('Příliš mnoho neúspěšných pokusů o přihlášení');
          return;
        }
        this.setErrorMessage(err.msg);
      }
    );
  }

  private async loginBySsoToken() {
    if (!this.APP_BRAND.LOGIN || !this.APP_BRAND.LOGIN.SSO) {
      return;
    }
    this.sending = true;

    if (this.APP_BRAND.LOGIN.SSO_OPTIONS && this.APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'IDENTITY_SERVER') {
      // ochrana proti únosu spojení
      if (sessionStorage.getItem('state') !== this.stateService.params.state ||
        !this.stateService.params.code ||
        !this.stateService.params.state) {
        return;
      }
      // interně získá token z identity serveru a vrátí gmt token
      await this.authService.login(undefined, undefined, undefined,
        this.stateService.params.code,
        sessionStorage.getItem('code_verifier'),
        this.APP_BRAND.LOGIN.SSO_OPTIONS.REDIRECT_URI
      );
    } else if (this.APP_BRAND.LOGIN.SSO_OPTIONS && this.APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'KEYCLOAK') {
      if (!this.stateService.params.code) {
        return;
      }

      const domainConfig = this.APP_BRAND.LOGIN.SSO_OPTIONS.HOSTS[window.location.hostname];
      if (!domainConfig) {
        return;
      }

      await this.authService.login(
        undefined,
        undefined,
        undefined,
        this.stateService.params.code,
        undefined,
        domainConfig.redirectUri,
        undefined
      );
    } else {
      if (!this.stateService.params.SAMLart) {
        return;
      }
      await this.authService.login(
        undefined,
        undefined,
        this.stateService.params.SAMLart,
        undefined,
        undefined,
        undefined,
        this.stateService.params.ssoLogoutToken
      );
    }

    if (this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ') {
      if (this.authService.getActiveApplication() !== this.applications.doss.name) {
        this.authService.setActiveApplication(this.applications.landing.name);
      }
    }

    if (!this.authService.goToActiveAplication()) {
      const msg = 'Při přihlašování došlo k chybě. Kontaktujte podporu.';
      this.setErrorMessage(msg);
    }
  }
}
