<div class="fr" style="margin-top: -5px">
  <a (click)="onOdborUpdate()" class="button"><i class="fa fa-pencil"></i></a>
  <gmt-button *ngIf="showCancelButton()" [clickAction]="onOdborCancel" class="button red">
    <i class="fa fa-trash-o"></i>
  </gmt-button>
</div>
<gmt-labeled-block [label]="odbor.nazev">
  <div class="bordered mb-10">
    <table *ngIf="ValidationUtils.isNonEmptyArray(odbor.reseneUkony); else emptyRow">
      <thead class="sticky">
      <th class="cell">{{ getColumnName('reseneUkony') }}</th>
      <th class="cell">{{ getColumnName('kontakt') }}</th>
      <th class="cell">{{ getColumnName('email') }}</th>
      <th class="cell">{{ getColumnName('telefon') }}</th>
      <th class="cell">{{ getColumnName('pracoviste') }}</th>
      </thead>

      <tbody class="row-group">
        <tr *ngFor="let resenyUkon of odbor.reseneUkony">
          <td class="cell">
            <reseny-ukon-info [resenyUkon]="resenyUkon"></reseny-ukon-info>
          </td>
          <td class="cell">
            <div class="user-card">
              <i class="fa fa-user mr-5"></i>
              <span class="cell" style="white-space:pre-wrap">{{ resenyUkon.kontaktniOsoba | kontaktniOsoba }}</span>
              <div *ngIf="resenyUkon.kontaktniOsoba?.resenaAgenda">{{resenyUkon.kontaktniOsoba.resenaAgenda}}</div>
            </div>
          </td>
          <td class="cell"> {{ resenyUkon.kontaktniOsoba?.email }}</td>
          <td class="cell"> {{ resenyUkon.kontaktniOsoba?.telefon }}</td>
          <td class="cell">
            <pracoviste-detail [akceSchema]="akceSchema"
                               [dotcenyOrganId]="dotcenyOrganId"
                               [pracoviste]="resenyUkon.pracoviste"
                               [showEdit]="false">
            </pracoviste-detail>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</gmt-labeled-block>
<ng-template #emptyRow>
  <div class="empty-info">Žádné řešené úkony</div>
</ng-template>
