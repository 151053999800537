import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ListModel } from "@app/common/models/list.model";
import {AuthService} from "@app/common/services/auth.service";
import { ListService } from "@app/common/services/list.service";
import {UkolModel} from "@app/doss/models/ukol.model";

@Component({
    selector: 'akce-ukoly',
    templateUrl: './akce-ukoly.component.html',
    styleUrls: ['./akce-ukoly.component.scss']
})
export class AkceUkolyComponent implements OnInit {
  @Input() defaultData: any = {};
  @Input() maxHeight: number;
  @Output() updated = new EventEmitter<boolean>();

  tasksSectionCollapsed: boolean;

  ukolList: ListModel<UkolModel>;

  constructor(private authService: AuthService,
              private listService: ListService
  ) {
  }

  async ngOnInit() {
    const collapsed = localStorage.getItem('tasksSectionCollapsed');
    this.tasksSectionCollapsed = collapsed ? JSON.parse(collapsed) : false;
    await this.loadUkoly();
  }

  onSectionCollapse(name: string) {
    const collapsed = this[name];
    this[name] = !collapsed;
    localStorage.setItem('tasksSectionCollapsed', JSON.stringify(this[name]));
  }

  private loadUkoly() {
    this.ukolList = this.listService.createList(
      'ukol',
      {
        filters: {
          projectInfo: true,
          systemType: false,
          cancelled: false,
          user: this.authService.getUser().username
        },
        sortOrder: {sortBy: 'termin', direction: 'desc'}
      },
    );
    this.listService.fetchResult(this.ukolList);
  }
}
