<div *ngIf="list.itemCount" class="bordered">
  <table>
    <thead class="sticky">
    <th class="cell">
      <sort-by (sort)="onSort('cisloVerze', $event)" [selected]="'cisloVerze' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('cisloVerze') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('platnostOd', $event)" [selected]="'platnostOd' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('platnostOd') }}
      </sort-by>
    </th>
    <th class="cell">
      <sort-by (sort)="onSort('platnostDo', $event)" [selected]="'platnostDo' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('platnostDo') }}
      </sort-by>
    </th>
    <th class="cell">{{ getColumnName('popisVerze') }}</th>
    <th class="cell">{{ getColumnName('autor') }}</th>
    <th class="cell">{{ getColumnName('kategorieDoss') }}</th>
    <th></th>
    </thead>

    <tbody *ngFor="let verzePd of list.list" class="row-group">
    <tr>
      <td class="cell">{{ verzePd?.cisloVerze }}</td>
      <td class="cell">{{ verzePd?.platnaOd | date }}</td>
      <td class="cell">{{ verzePd?.platnaDo | date }}</td>
      <td class="cell">{{ verzePd?.popisVerze }}</td>
      <td class="cell">{{ verzePd?.autor }}</td>
      <td class="cell no-padding">
        <cell-kategorie-doss
          [kategorieDoss]="verzePd?.kategorieDoss"
          [tooltipType]="'kategorie-doss'"
          [tooltipData]="{ kategorieDoss: verzePd?.kategorieDoss }"
        ></cell-kategorie-doss>
      </td>
      <td><div class="fr"><a (click)="onEditVerzePd(verzePd)" class="button"><i class="fa fa-pencil" *ngIf="canEdit()"></i></a></div></td>
    </tr>
    </tbody>
  </table>
</div>

<div *ngIf="!list.itemCount && list.list !== null" class="empty-info bordered">
  Nenalezena žádná data
</div>
<help [helpId]="helpIds.DOSS_VERZE_PD" class="cm-help-page"></help>
