<ng-container>
  <div id="top-bar" [ngClass]="{ test: ENV !== 'production' }" visibleByBrand="common.navbar.small">
    <div class="logo">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault();" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i [ngClass]="['fa', 'fa-bars']"></i>
      </a>
      <div class="image-container">
        <img src="./img/logo/doss_xxl.svg" alt="logo"/>
      </div>
    </div>
    <login [user]="user" (logoutClick)="logout()" [servicedeskUrl]="servicedeskUrl"></login>
  </div>

  <div id="top-bar" class="big-bar" [ngClass]="{ test: ENV !== 'production' }" visibleByBrand="common.navbar.big">
    <div class="top-bar-container left">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault()" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i class="fa fa-bars"></i>
      </a>
    </div>

    <div class="top-bar-container left logo">
      <a uiSref="landing.home"><img src="./img/logo/{{ APP_BRAND.PS.ICON_PATH }}_top.svg" alt="logo"/></a>
    </div>

    <div class="left top-bar-container top-bar-container-padding" *ngIf="ENV !== 'production'" style="color: red">
      TEST
    </div>

    <div class="top-bar-container top-bar-container-padding left">
      <div>
        <div *ngIf="isActive('doss.home')" class="header without-breadcrumbs">
          <div class="header-title">Úvodní stránka</div>
        </div>

        <div *ngIf="isActive('doss.akce.list', true) || isActive('doss.akce.create')" class="header">
            <div class="header-title">Seznam akcí</div>
            <a uiSref="doss.home">Úvodní stránka</a> > Seznam akcí
        </div>

        <div *ngIf="isActive('doss.akce.data')" class="header">
            <div class="header-title">Detail akce DOSS</div>
            <a uiSref="doss.home">Úvodní stránka</a>
            >
            <a uiSref="doss.akce.list">Seznam akcí</a>
            >
            Detail akce DOSS
        </div>
      </div>
    </div>

    <div class="top-bar-container top-bar-container-padding left top-bar-container__main" *ngIf="isActive('doss.akce.data') && akce">
      <div class="">
        <b class="pr-10">{{ akce.cisloAkceS ? ('(' + akce.cisloAkceS + ') ' + akce.nazevAkce) : akce.nazevAkce }}</b>
        <span *ngIf="akce.manazerAkce">Manažer akce {{ akce.manazerAkce | username }}</span>
      </div>
    </div>

    <div *ngIf="APP_BRAND.NAME === 'RSD'" class="top-bar-container top-bar-container-padding right pt-10">
      <img src="./img/logo/rsd_main.svg" alt="logo" height="60"/>
    </div>

    <div *ngIf="APP_BRAND.NAME === 'SZ'" class="top-bar-container top-bar-container-padding right pt-10">
      <img src="./img/logo/sz.png" alt="logo" height="60"/>
    </div>

    <div class="top-bar-container top-bar-container-padding right pt-20">
      <login [user]="user" [withCompany]="false" [servicedeskUrl]="servicedeskUrl" (logoutClick)="logout()"></login>
    </div>

    <div class="right small-spinner count-with-warning" [loading]="!processes || processes.procesCount === undefined" *ngIf="akce">
      <div *ngIf="processes !== undefined" [tooltip]="processTemplate" [activeContent]="true">
        <i class="fa fa-cog" [ngClass]="{ 'fa-spin': processes.running, 'blink-me': processes.running }"></i>
        <span class="number-plate blue">{{ processes.procesCount|number }}</span>
      </div>
    </div>
  </div>


  <div class="main">
    <app-menu [ngClass]="{collapsed: collapsed}" [collapsed]="collapsed" class="flexbox-container" id="menu" [menuConfig]="menuConfig"></app-menu>
    <div id="content-wrap">
      <div id="content" ui-view="content"></div>
    </div>
  </div>
</ng-container>


<ng-template #processTemplate>
  <div>
    <ul>
      <li *ngFor="let process of processes.processes" class="process">
        {{process.status === 'W' ? 'Čekající' : (process.status === 'R' ? 'Probíhající' : (process.status === 'F' ? 'Dokončeno' : 'Neúspěšně ukončeno'))}} "{{process.name}}"<br/>
        <span *ngIf="process.created">Zadáno {{process.created|date:'shortTime'}}<br/></span>
        <span *ngIf="process.started">Spuštěno: {{process.started|date:'shortTime'}}<br/></span>
        <span *ngIf="process.finished">Dokončeno: {{process.finished|date:'shortTime'}}<br/></span>
        <a class="fa fa-download p-5" *ngIf="process.status === 'F' && process.hasData" (click)="projectStatusService.download([process.id])"></a>
        <a class="fa fa-times p-5" *ngIf="process.status !== 'R'" (click)="projectStatusService.remove(process)"></a>
      </li>
    </ul>
    <div *ngIf="processes.processes.length === 0">Žádné aktivní nebo dokončené procesy</div>
  </div>
</ng-template>
