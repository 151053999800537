<div class="detail" [loading]="!loaded">
  <div style="display:flex">
    <h1>Karta akce</h1>
  </div>

  <div *ngIf="loaded">
    <div class="mt-30 mb-70 clearfix">
      <div class="edit" *ngIf="canEdit()">
        <a uiSref="doss.akce.data.update" class="button">Upravit údaje</a>
      </div>

      <div class="column-33">
        <label>Evidenční číslo</label>
        <div class="info">{{ akce.evidencniCislo }}</div>

        <label>Název stavby</label>
        <div class="info">{{ akce.nazevAkce }}</div>

        <label>Organizační jednotka</label>
        <div class="info">{{ akce.organizacniJednotka | organizationalUnitName }}</div>

        <label>Manažer akce</label>
        <div class="info">{{ akce.manazerAkce | username }}</div>

        <label>Odpovědný referent DOSS</label>
        <div class="info">{{ akce.referent | username: true }}</div>

        <label>Zpracovatel DOSS</label>
        <div class="info">{{ akce.zpracovatel | username: true }}</div>

        <label>Projektant</label>
        <div class="info">{{ akce.projektant | projectSubjectName: true }}</div>

        <label>Stupeň</label>
        <div class="info">{{ akce.stupen?.id }}</div>
      </div>

      <div class="column-33">
        <label>Datum spuštení projektu</label>
        <div class="info">{{ akce.datumSpusteni | date }}</div>

        <label>Stav akce</label>
        <div class="info">{{ akce.stav | stavAkce }}</div>

        <label>Verze PD</label>
        <div class="info">{{ akce.verzePd }}</div>

        <label>Předpoklad ukončení</label>
        <div class="info">{{ akce.predpokladaneDatumUkonceni | date }}</div>
      </div>

      <div class="column-33">
        <label>Kraj</label>
        <div class="info">{{ akce.lokalizaceStavby.region | lokalizaceStavby }}</div>

        <label>ORP</label>
        <div class="info">{{ akce.lokalizaceStavby.municipality_extended | lokalizaceStavby }}</div>

        <label>Obec</label>
        <div class="info">{{ akce.lokalizaceStavby.municipality | lokalizaceStavby }}</div>

        <label>Katastrální území</label>
        <div class="info">{{ akce.lokalizaceStavby.cadastral_area | lokalizaceStavby }}</div>
      </div>
    </div>

    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
        <div *ngIf="tab === 'poznamky'">
          <doss-notes-list
            [noteList]="noteList"
            [defaultData]="{projectInfo: true, systemType: false}"
            (updated)="onUpdateNoteList()"
          ></doss-notes-list>
        </div>
        <div *ngIf="tab === 'dokumenty'">
          <gmt-documents
            [documentList]="documentList"
            [defaultData]="{projectInfo: true}"
            [allowType]="true"
            [documentCategory]="'doss'"
            [defaultDocumentType]="defaultDocumentType"
            (updated)="onUpdateDocumentList();"
          ></gmt-documents>
        </div>
      </gmt-navbar>
    </div>
  </div>
</div>
<help [helpId]="helpIds.DOSS_AKCE_INFO" class="cm-help-page"></help>
