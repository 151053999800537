<div class="detail">
  <div class="row" style="height:250px">
    <div class="col-8" style="display: flex;height: 100%;">
      <home-last-visited-akce></home-last-visited-akce>
    </div>
    <div class="col-4" style="display: flex;height: 100%;">
      <home-oblibene></home-oblibene>
    </div>
  </div>
  <home-information></home-information>
</div>
<help [helpId]="helpIds.DOSS_HOME" class="cm-help-page"></help>
