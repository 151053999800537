<div *ngIf="tooltipComponent && tooltipData"
     [activeContent]="true"
     [data]="tooltipData"
     [tooltip]="tooltipComponent"
     class="tooltip-hover-area fill-content"
>
  <div class="fill-content flexbox-container flex-centered p-10">
    <div class="icon-count flexbox-container">
      <i class="fa fa-th"></i>
    </div>
    <div class="number ml-4">{{ kategorieDoss | kategorieDossId }}</div>
  </div>
</div>
