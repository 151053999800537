import {Component, Input} from '@angular/core';
import {
  CellAbstractComponent,
  CellTooltipMappingType
} from "@app/common/components/cell-abstract/cell-abstract.component";
import {PropojeniUkonuModel} from "@app/doss/models/propojeni-ukonu.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {
  TooltipPropojeniUkonuComponent
} from "@app/doss/components/tooltip-propojeni-ukonu/tooltip-propojeni-ukonu.component";

@Component({
  selector: 'cell-propojeni-ukonu',
  templateUrl: './cell-propojeni-ukonu.component.html',
  styleUrls: ['./cell-propojeni-ukonu.component.scss']
})
export class CellPropojeniUkonuComponent extends CellAbstractComponent {
  @Input() list = true;
  @Input() propojeniList: PropojeniUkonuModel[];

  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'propojeni-ukonu': TooltipPropojeniUkonuComponent,
  };

  public readonly ValidationUtils = ValidationUtils;
}
