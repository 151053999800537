import {Component, Inject, Input} from '@angular/core';
import {OdborModel} from "@app/doss/models/odbor.model";
import { Restangular } from 'ngx-restangular';
import {DialogService} from "@app/common/services/dialog.service";
import {OdborComponent} from "@app/doss/components/odbor/odbor.component";
import {
  PracovisteComponent
} from "@app/doss/components/pracoviste/pracoviste.component";
import {PracovisteModel} from "@app/doss/models/pracoviste.model";
import {ClassName} from "@app/common/enums/class-name.enum";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Component({
  selector: "odbor-pracoviste-create-form",
  templateUrl: './odbor-pracoviste-create-form.component.html',
  styleUrls: []
})
export class OdborPracovisteCreateFormComponent {
  @Input()
  akceSchema: string;

  @Input()
  dotcenyOrganId: number;

  @Input()
  data: any;

  constructor(public restangular: Restangular,
              private dialogService: DialogService) {
    this.onAddNewOdbor = this.onAddNewOdbor.bind(this);
    this.onAddNewPracoviste = this.onAddNewPracoviste.bind(this);
  }

  toggleCreateOdbor(): void {
    this.data.createOdbor = !this.data.createOdbor;
  }

  toggleCreatePracoviste(): void {
    if (!ValidationUtils.isNonEmptyArray(this.data.selectedDotcenyOrgan.pracoviste)) {
      this.data.createPracoviste = !this.data.createPracoviste;
    }
  }

  onAddNewOdbor(): void {
    const dialog = this.dialogService.open(OdborComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.dotcenyOrganId
      },
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe((res: OdborModel) => {
      if (res) {
        if (!this.data.selectedDotcenyOrgan.odbory) {
          this.data.selectedDotcenyOrgan.odbory = [];
        }
        this.data.selectedDotcenyOrgan.odbory.push(res);
      }

      sub.unsubscribe();
    });
  }

  removeOdbor(odbor: OdborModel): void {
    this.data.selectedDotcenyOrgan.odbory.splice(this.data.selectedDotcenyOrgan.odbory.indexOf(odbor), 1);
  }

  onAddNewPracoviste(): void {
    const dialog = this.dialogService.open(PracovisteComponent, {
      data: {
        akceSchema: this.akceSchema,
        dotcenyOrganId: this.dotcenyOrganId
      }
    });

    const sub = dialog.afterClosed.subscribe((res: PracovisteModel) => {
      if (res) {
        if (!this.data.selectedDotcenyOrgan.pracoviste) {
          this.data.selectedDotcenyOrgan.pracoviste = []
        }
        this.data.selectedDotcenyOrgan.pracoviste.push(res);
      }

      sub.unsubscribe();
    });
  }

  removePracoviste(pracoviste: PracovisteModel): void {
    this.data.selectedDotcenyOrgan.pracoviste.splice(this.data.selectedDotcenyOrgan.pracoviste.indexOf(pracoviste), 1);
  }
}
