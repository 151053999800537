import {Inject, Injectable} from '@angular/core';
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import {AkceModel} from "@app/doss/models/akce.model";
import {AkceCreateModel} from "@app/doss/models/akce-create.model";
import { AkceHomePageModel } from '../models/akce-home-page.model';
import { AkceAuthorizedModel } from '../models/akce-authorized.model';

@Injectable({
  providedIn: 'root'
})
export class AkceService {

  constructor(@Inject(RESTANGULAR_DOSS) private restangularDoss: any) {
  }

  create(akce: AkceCreateModel): Promise<AkceModel> {
    return this.restangularDoss.all('akce/create')
      .post(akce)
      .toPromise();
  }

  getById(akceKey: string, akceGetParams?: any): Promise<AkceModel> {
    return this.restangularDoss.one('akce', akceKey)
      .get(akceGetParams)
      .toPromise();
  }

  update(akce: AkceCreateModel): Promise<AkceModel> {
    return this.restangularDoss.one('akce/update/akce', akce.schema)
      .customPUT(akce)
      .toPromise();
  }

  getAkceForHomePage(schemas: string[]): Promise<AkceHomePageModel[]> {
    const requestBody = { schemas };
    return this.restangularDoss
      .all('home/last-visited-akce')
      .post(requestBody)
      .toPromise();
  }

  getAuthorizedAkceModels(): Promise<AkceAuthorizedModel[]> {
    return this.restangularDoss
    .one('akce/list/authorized')
    .get()
    .toPromise();
  }
}
