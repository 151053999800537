<div class="actions" *ngIf="!isInvestor">
  <a class="button fr" (click)="onSearchCompany(subject.companyName, subject.identificationNumber);">
    Vyhledat subjekt v systému Ares
  </a>
</div>

<div *ngIf="subject" class="cb">
  <fieldset *ngIf="!isInvestor">
    <legend>Typ osoby</legend>
    <div class="column-100">
      <gmt-input class="input">
        <gmt-switch [options]="subjectTypes" (select)="onSubjectTypeSelected($event)" [selected]="subjectType"></gmt-switch>
      </gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="subject.subjectType.indexOf('OPO') === 0 && !isInvestor">
    <legend>Základní údaje</legend>
    <div class="column-100">
      <gmt-input label="Název">
        <input type="text" [(ngModel)]="subject.companyName" class="button-padding"/>
      </gmt-input>
    </div>

    <div class="column-100">
      <gmt-input label="Závod / správa"><input type="text" [(ngModel)]="subject.branchName"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="IČO"><input type="text" [(ngModel)]="subject.identificationNumber"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="DIČ"><input type="text" [(ngModel)]="subject.vatId"/></gmt-input>
    </div>

  </fieldset>

  <fieldset *ngIf="subject.subjectType === 'OFO' && !isInvestor">
    <legend>Základní údaje</legend>
    <div class="column-50">
      <gmt-input label="Křestní jméno"><input type="text" [(ngModel)]="subject.firstName"/></gmt-input>
      <gmt-input label="Titul před jménem"><input type="text" [(ngModel)]="subject.titleBefore"/></gmt-input>
      <gmt-input label="Příjmení"><input type="text" [(ngModel)]="subject.surname"/></gmt-input>
      <gmt-input label="Titul za jménem"><input type="text" [(ngModel)]="subject.degreeAfter"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="R.Č."><input type="text" [(ngModel)]="subject.personalIdentificationNumber"/></gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="subject.subjectType.indexOf('OPO') === 0 && !isInvestor">
    <legend>Zápis obchodního rejstříku</legend>

    <div class="column-100">
      <gmt-input label="Rejstříkový soud"><input type="text" [(ngModel)]="subject.businessRegisterName"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Oddíl"><input type="text" [(ngModel)]="subject.businessRegisterSection"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Vložka"><input type="text" [(ngModel)]="subject.businessRegisterEntry"/></gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="subject.subjectType && !isInvestor">
    <legend>Adresa</legend>
    <div class="column-100">
      <gmt-input label="Ulice"><input type="text" [(ngModel)]="subject.street"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Číslo popisné"><input type="text" [(ngModel)]="subject.houseNumber"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Číslo orientační"><input type="text" [(ngModel)]="subject.streetNumber"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Obec"><input type="text" [(ngModel)]="subject.municipality"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="PSČ"><input type="text" [(ngModel)]="subject.postCode" gmtInputPostCode/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Část obce"><input type="text" [(ngModel)]="subject.neighbourhood"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Městská část"><input type="text" [(ngModel)]="subject.quarter"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Městský obvod"><input type="text" [(ngModel)]="subject.district"/></gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="subject.subjectType && !isInvestor">
    <legend>Kontakt</legend>
    <div class="column-50">
      <gmt-input label="E-mail"><input type="text" [(ngModel)]="subject.email"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Telefon"><input type="text" [(ngModel)]="subject.phoneNumber"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Číslo účtu"><input type="text" [(ngModel)]="subject.accountNumber"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Kód banky"><input type="text" [(ngModel)]="subject.bankCode"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Datová schránka"><input type="text" [(ngModel)]="subject.databoxId"/></gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="subject.subjectType.indexOf('OPO') === 0 && !isInvestor">
    <legend>Jednající osoby</legend>
    <div class="pl-40">
      <div class="table mb-10" *ngIf="subject.actingPersons.length">
        <div class="row-group" *ngFor="let acting of subject.actingPersons">
          <div class="row pb-10">
            <div class="action-cell">
              <a class="button red" (click)="onCancelActing(acting)" *ngIf="!acting.cancelled"><i class="fa fa-minus"></i></a>
              <a class="button green" (click)="onRestoreActing(acting)" *ngIf="acting.cancelled"><i class="fa fa-repeat"></i></a>
            </div>
            <div class="cell bordered-bottom" [ngClass]="{ cancelled : acting.cancelled }">
              <div class="column-100">
                <gmt-input label="Křestní jméno"><input type="text" [(ngModel)]="acting.firstName"/></gmt-input>
                <gmt-input label="Příjmení"><input type="text" [(ngModel)]="acting.surname"/></gmt-input>
                <gmt-input label="Titul před jménem"><input type="text" [(ngModel)]="acting.titleBefore"/></gmt-input>
                <gmt-input label="Titul za jménem"><input type="text" [(ngModel)]="acting.degreeAfter"/></gmt-input>
                <gmt-input label="Funkce"><input type="text" [(ngModel)]="acting.function"/></gmt-input>
                <gmt-input label="E-mail"><input type="text" [(ngModel)]="acting.email"/></gmt-input>
                <gmt-input label="Telefon"><input type="text" [(ngModel)]="acting.phoneNumber"/></gmt-input>
                <gmt-input label="Role">
                  <div (click)="acting.roleContract = !acting.roleContract">
                    <gmt-checkbox [gmtChecked]="acting.roleContract"></gmt-checkbox> Podpis smlouvy
                  </div>
                  <div (click)="acting.roleCadastre = !acting.roleCadastre; updateActingSelect()">
                    <gmt-checkbox [gmtChecked]="acting.roleCadastre"></gmt-checkbox> Jednání v návrhu na vklad
                  </div>
                </gmt-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column-100">
      <a class="button" (click)="onAddActing()"><i class="fa fa-plus"></i></a>
    </div>
  </fieldset>

  <fieldset *ngIf="subject.subjectType && subject.discriminator === 'I' && false">
    <legend>Data katastru nemovitostí</legend>

    <div class="column-100">
      <gmt-input label="ID oprávněného subjektu"><input type="text" [(ngModel)]="subject.opsubId"/></gmt-input>
    </div>
    <div class="column-50" *ngIf="subject.subjectType === 'OPO_PO'">
      <gmt-input label="ID nadřazeného subjektu"><input type="text" [(ngModel)]="subject.parentOpsubId"/></gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="subject.discriminator === 'I' || subject.discriminator === 'G'">
    <legend>Návrh na vklad do katastru nemovitostí</legend>

    <div class="column-100" *ngIf="subject.subjectType.indexOf('OPO') === 0">
      <gmt-input label="Výchozí jednající osoba">
        <gmt-select
          [optionItems]="actingPersonsNotCancelled"
          [data]="subject"
          field="cadastreActingPerson"
          (changed)="onUpdateCadastreActingPerson($event)"
          [reloadOptionsRegister]="reloadOptionsCadastreActingPersonRegister"
          [itemPipe]="actingPersonNamePipe"
        ></gmt-select>
      </gmt-input>
    </div>
    <div class="column-100" *ngIf="subject.discriminator === 'I'">
      <label>Osoba zastupující</label>
      <div class="info" *ngIf="subject.cadastreAgent">
        {{ subject.cadastreAgent | projectSubjectName }}&nbsp;
      </div>
      <div class="input">
        <a class="button" (click)="onAddAgent()">
          <i class="fa" [ngClass]="{ 'fa-pencil': subject.cadastreAgent, 'fa-plus' : !subject.cadastreAgent }"></i>
        </a>&nbsp;
        <a class="button red" (click)="subject.cadastreAgent = null" *ngIf="subject.cadastreAgent">
          <i class="fa fa-trash-o"></i>
        </a>
      </div>
    </div>
    <div class="column-100">
      <label (click)="subject.useCaseNumberAsReferenceNumber = !subject.useCaseNumberAsReferenceNumber">Použít
        číslo případu jako jednací číslo</label>
      <div class="info" (click)="subject.useCaseNumberAsReferenceNumber = !subject.useCaseNumberAsReferenceNumber">
        <gmt-checkbox [gmtChecked]="subject.useCaseNumberAsReferenceNumber"></gmt-checkbox>
      </div>
    </div>
    <div class="column-100">
      <label (click)="subject.useAccountNumberForProposal = !subject.useAccountNumberForProposal">
        Použít číslo účtu {{ subject.discriminator === 'I' ? 'organizace' : 'zástupce' }} pro úhradu návrhu na vklad
      </label>
      <div class="info" (click)="subject.useAccountNumberForProposal = !subject.useAccountNumberForProposal">
        <gmt-checkbox [gmtChecked]="subject.useAccountNumberForProposal"></gmt-checkbox>
      </div>
    </div>
    <div class="column-100 flexbox-container">
      <label (click)="subject.showPublicationNote = !subject.showPublicationNote">
        Poznámka o zveřejnění v registru smluv
      </label>
      <div class="info mr-5" (click)="subject.showPublicationNote = !subject.showPublicationNote">
        <gmt-checkbox [gmtChecked]="subject.showPublicationNote"></gmt-checkbox>
      </div>
      <div class="input flex-rest">
        <textarea [(ngModel)]="subject.publicationNoteTemplate"></textarea>
      </div>
    </div>
    <div class="column-100 flexbox-container">
      <label (click)="subject.showNoPublicationNote = !subject.showNoPublicationNote">
        Poznámka o nezveřejnění v registru smluv
      </label>
      <div class="info mr-5" (click)="subject.showNoPublicationNote = !subject.showNoPublicationNote">
        <gmt-checkbox [gmtChecked]="subject.showNoPublicationNote"></gmt-checkbox>
      </div>
      <div class="input flex-rest">
        <textarea [(ngModel)]="subject.noPublicationNoteTemplate"></textarea>
      </div>
    </div>
    <div class="column-100 flexbox-container">
      <label (click)="subject.useEasementDescription = !subject.useEasementDescription">
        Popis věcného břemene
      </label>
      <div class="info mr-5" (click)="subject.useEasementDescription = !subject.useEasementDescription">
        <gmt-checkbox [gmtChecked]="subject.useEasementDescription"></gmt-checkbox>
      </div>
      <div class="input flex-rest">
        <textarea [(ngModel)]="subject.easementDescription"></textarea>
      </div>
    </div>
    <div class="column-100 flexbox-container">
      <label>Poznámky</label>
      <div class="flex-rest">
        <div class="pl-40">
          <div class="table mb-10" *ngIf="subject.cadastreNotes?.length">
            <div class="row-group" *ngFor="let cadastreNote of subject.cadastreNotes">
              <div class="row pb-10">
                <div class="action-cell">
                  <a class="button red" (click)="onRemoveCadastreNote(cadastreNote)"><i
                    class="fa fa-minus"></i></a>
                </div>
                <div class="cell bordered-bottom">
                  <div class="column-100">
                    <label>Název</label>
                    <div class="cb"><input type="text" [(ngModel)]="cadastreNote.name"/></div>

                    <label>Text</label>
                    <div class="cb"><textarea [(ngModel)]="cadastreNote.content"></textarea></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input cb">
          <a class="button" (click)="onAddCadastreNote()"><i class="fa fa-plus"></i></a>
        </div>
      </div>
    </div>
  </fieldset>
</div>
