import { InjectionToken } from '@angular/core';

export const RESTANGULAR_DOSS = new InjectionToken<any>('RestangularSV');

export function RestangularDOSSFactory(restangular: any, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlDOSS + '/app');
  });

  return r;
}

