<h2 class="mb-10" (click)="onSectionCollapse('ukonSectionCollapsed')">Přehled řešených úkonů DOSS</h2>
<div class="mb-20" *ngIf="!ukonSectionCollapsed">
  <div [loading]="loading">
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="categoryTabs">
        <div class="bordered mt-10">
          <perfect-scrollbar [wheelPropagation]="true" [horizontal]="true" class="category-select">
            <div>
              <div class="flexbox-container" *ngIf="categoryTabs">
                <ng-container *ngFor="let category of categoryTabs; index as i">
                  <div class="flex1 basis0">
                    <div class="category">
                      <a class="filter-nav" [uiParams]="{ akceKey: akceSchema, stavyFilter: category.filter }" uiSref="doss.akce.data.ukony" *ngIf="category.value && i > 0"><i class="fa fa-filter"></i></a>
                      <span class="filter-nav" *ngIf="!category.value"><i class="fa fa-filter"></i></span>
                    <div class="category-nav">
                      <div class="count">
                        <span>{{ category.value }}</span>
                        <ng-container *ngIf="category.total !== undefined">
                          <span class="count-separator">/</span>
                          <span class="count-total">{{ category.total }}</span>
                        </ng-container>
                      </div>
                      <div class="label">{{ category.name }}</div>
                    </div>
                    <div
                      class="status tooltip-min"
                      [ngClass]="{ 'status--onhold': i === 1, 'status--progress': i === 2, 'status--done': i === 3 }"
                      title="{{ i === 1 ? 'nezahájeno' : (i === 2 ? 'v řešení' : (i === 3 ? 'ukončeno' : undefined)) }}"
                    ></div>
                  </div>
                  </div>
                  <div *ngIf="i === 3" class="category-separator"></div>
                </ng-container>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
