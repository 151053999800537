import {Component} from '@angular/core';
import {AkceModel} from '@app/doss/models/akce.model';
import {StateService} from "@uirouter/angular";
import {AkceCreateModel} from "@app/doss/models/akce-create.model";
import {AkceService} from "@app/doss/services/akce.service";
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './akce-create.component.html',
  styleUrls: ['./akce-create.component.scss']
})
export class AkceCreateComponent {
  akce: AkceModel = <AkceModel>{};
  helpIds = HelpService.HELP_IDS;

  constructor(private akceService: AkceService,
              private stateService: StateService,
            private authService: AuthService) {
    this.createAkce = this.createAkce.bind(this);
  }

  async createAkce(): Promise<void> {
    await this.akceService.create(this.mapToAkceCreateModel(this.akce))
      .then((akce: AkceModel) => this.stateService.go('doss.akce.data.overview', {akceKey: akce.schema}));
  }

  canCreate() {
    return this.authService.hasPermissionOnProject('admin', 'doss_default');
  }

  private mapToAkceCreateModel(akce: AkceModel): AkceCreateModel {
    const {organizace, organizacniJednotka, stupen, manazerAkce, referent, zpracovatel, ...otherProperties} = akce;

    return {
      organizaceId: organizace.customerIdText,
      organizacniJednotkaCode: organizacniJednotka.code,
      stupen: stupen && stupen.id,
      manazerAkce: manazerAkce && manazerAkce.idText,
      referent: referent && referent.idText,
      zpracovatel: zpracovatel && zpracovatel.idText,
      ...otherProperties
    };
  }
}
