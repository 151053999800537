<div [loading]="loading" style="flex:1; display: flex;flex-direction:column;">
  <h2 class="text-uppercase">Oblíbené</h2>
  <div class="flex-rest" style="overflow:hidden;">
    <perfect-scrollbar [wheelPropagation]="false" *ngIf="!loading" style="height:100%">
       <ng-container *ngIf="ValidationUtils.isNonEmptyArray(oblibeneUkonyList); else zadneOblibeneUkonu">
          <div *ngFor="let akceSchema of uniqueAkceSchemas" >
            <h3 class="mt-5 mb-5">{{ akceNames.get(akceSchema) }}</h3>
            <div *ngFor="let item of ukonyForAkce.get(akceSchema)" class="mb-10">
              <a class="not-underline" uiSref="doss.akce.data.ukony.detail"
                 [uiParams]="{ akceKey: item.akceSchema, id: item.ukonId }">
                {{ item.nazevUkonu }}
              </a>
            </div>
          </div>
        </ng-container>
    </perfect-scrollbar>
  </div>
</div>
<ng-template #zadneOblibeneUkonu>
  <p class="empty-info">Nemáte žádné oblíbené úkony v akcích</p>
</ng-template>

