import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {UkonPropojeniModel} from "@app/doss/models/ukon-propojeni.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'ukonPropojeni'})
@Injectable({
  providedIn: 'root'
})
export class UkonPropojeniPipe implements PipeTransform {

  transform(propojeniUkonu: UkonPropojeniModel): string {
    if (!propojeniUkonu) {
      return '';
    }

    return `${propojeniUkonu.typUkonu.description}${this.getDalsiPopis(propojeniUkonu)}\n${this.getDossPopis(propojeniUkonu)}`;
  }

  private getDalsiPopis(propojenyUkon: UkonPropojeniModel): string {
    return propojenyUkon.dalsiPopis ? ` - ${propojenyUkon.dalsiPopis}` : '';
  }

  private getDossPopis(propojenyUkon: UkonPropojeniModel): string {
    let dossPopis = propojenyUkon.dotcenyOrganNazev;

    if (propojenyUkon.pracovisteNazev) {
      dossPopis += `, pracoviště ${propojenyUkon.pracovisteNazev}`;
    }

    if (propojenyUkon.odborNazev) {
      dossPopis += ` (${propojenyUkon.odborNazev})`;
    }

    return dossPopis;
  }
}
