import {Component, Inject, OnInit} from '@angular/core';

import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import {DialogRef} from '@app/common/services/dialog-ref';
import {OdborModel} from "@app/doss/models/odbor.model";
import { Restangular } from 'ngx-restangular';
import {AlertComponent} from "@app/common/components/alert/alert.component";
import {ClassName} from "@app/common/enums/class-name.enum";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";
import {DialogService} from "@app/common/services/dialog.service";
import {ConfirmationComponent} from "@app/common/components/confirmation/confirmation.component";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Component({
  templateUrl: './odbor.component.html',
  styleUrls: []
})
export class OdborComponent implements OnInit {
  akceSchema: string;
  dotcenyOrganId: number;
  odbor: OdborModel;

  constructor(public restangular: Restangular,
              private dotcenyOrganService: DotcenyOrganService,
              private dialogService: DialogService,
              private dialogConfig: DialogConfig,
              private dialogRef: DialogRef) {
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async ngOnInit() {
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).akceSchema;
    this.dotcenyOrganId = (<DialogConfigData>this.dialogConfig.data).dotcenyOrganId;
    this.odbor = (<DialogConfigData>this.dialogConfig.data).odbor || <OdborModel>{};
  }

  isValid(): boolean {
    return !!this.odbor.nazev;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.dotcenyOrganId) {
      this.dotcenyOrganService.checkIfOdborAlreadyExists(this.akceSchema, this.dotcenyOrganId, this.odbor)
        .then(res => {
          if (!res.exists) {
            this.dialogRef.close(this.odbor);
          } else {
            this.dialogService.open(AlertComponent, {
              data: {msg: "Nelze založit duplicitní odbor."},
              className: ClassName.ADJUSTED_DIALOG
            });
          }
        });
    } else {
      this.dialogRef.close(this.odbor);
    }
  }
}
