import {StavUkonuInfoModel} from "@app/doss/models/stav-ukonu-info.model";

export class StavUkonuUtils {
  public static getStavAndFazeUkonuDesc(stavUkonu: StavUkonuInfoModel): string {
    if (!stavUkonu) {
      return null;
    }
    return stavUkonu.stav.menuName + (stavUkonu.faze ? ` (${stavUkonu.faze.menuName})` : '');
  }
}
