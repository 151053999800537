import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";

@Pipe({name: 'kategorieDoss'})
@Injectable({
  providedIn: 'root'
})
export class KategorieDossPipe implements PipeTransform {

  transform(ketogrieDoss: KategorieDossModel): string {
    return !ketogrieDoss ? "" : `(${ketogrieDoss.idKategorie}) ${ketogrieDoss.description}`;
  }
}
