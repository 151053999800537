import {Component, Input} from '@angular/core';
import {OdborUkonInfoModel} from "@app/doss/models/odbor-ukon-info.model";
import {KontaktniOsobaUkonInfoModel} from "@app/doss/models/kontaktni-osoba-ukon-info.model";
import {StavUkonuUtils} from "@app/doss/utils/stav-ukonu.utils";

@Component({
  selector: 'reseny-ukon-info',
  templateUrl: './reseny-ukon-info.component.html',
  styleUrls: ['./reseny-ukon-info.component.scss']
})
export class ResenyUkonInfoComponent {
  @Input()
  resenyUkon: OdborUkonInfoModel | KontaktniOsobaUkonInfoModel;

  protected readonly StavUkonuUtils = StavUkonuUtils;
}
