<div *ngIf="list.itemCount" class="bordered">
  <table>
    <thead class="sticky">
    <th class="cell" *ngIf="!hideColumn('dotcenyOrgan')">
      <sort-by (sort)="onSort('dotcenyOrgan', $event)" [selected]="'dotcenyOrgan' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('dotcenyOrgan') }}
      </sort-by>
    </th>
    <th class="cell" *ngIf="!hideColumn('ukon')">
      <sort-by (sort)="onSort('typUkonu', $event)" [selected]="'typUkonu' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('ukon') }}
      </sort-by>
    </th>
    <th class="cell" *ngIf="!hideColumn('stav')">
      {{ getColumnName('stav') }}
    </th>
    <th class="cell" *ngIf="!hideColumn('kategorieDoss')">
      <sort-by (sort)="onSort('kategorieDoss', $event)" [selected]="'kategorieDoss' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('kategorieDoss') }}
      </sort-by>
    </th>
    <th class="cell" *ngIf="!hideColumn('datumPosledniZmeny')">
      <sort-by (sort)="onSort('datumPosledniZmeny', $event)"
               [selected]="'datumPosledniZmeny' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('datumAutorPoslednyZmeny') }}
      </sort-by>
    </th>
    <th class="cell" *ngIf="!hideColumn('upozorneni')">
      <sort-by (sort)="onSort('upozorneni', $event)" [selected]="'upozorneni' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('upozorneni') }}
      </sort-by>
    </th>
    <th class="cell" *ngIf="!hideColumn('ukoly')">
      <sort-by (sort)="onSort('ukoly', $event)" [selected]="'ukoly' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('ukoly') }}
      </sort-by>
    </th>
    <th class="cell" *ngIf="!hideColumn('oblibene')">
      {{ getColumnName('oblibene') }}
    </th>
    <th class="cell" *ngIf="!hideColumn('propojeni')">
      {{ getColumnName('propojeni') }}
    </th>
    <th class="cell" *ngIf="!hideColumn('platnostDo')">
      <sort-by (sort)="onSort('platnostDo', $event)"
               [selected]="'platnostDo' === list.filter.sortOrder.sortBy"
               [sortDir]="list.filter.sortOrder.direction">
        {{ getColumnName('platnostDo') }}
      </sort-by>
    </th>
    <th class="cell" *ngIf="!hideColumn('verzePd')">
      {{ getColumnName('verzePd') }}
    </th>
    <th class="cell" *ngIf="!hideColumn('reseneUkony')">
      {{ getColumnName('reseneUkony') }}
    </th>
    <th class="cell" *ngIf="!hideColumn('odbor')">
      {{ getColumnName('odbor') }}
    </th>
    <th class="cell" *ngIf="!hideColumn('kontakt')">
      {{ getColumnName('kontakt') }}
    </th>
    <th class="cell" *ngIf="!hideColumn('uradovna')">
      {{ getColumnName('uradovna') }}
    </th>
    </thead>

    <tbody *ngFor="let ukon of list.list" class="row-group">
    <tr [uiParams]="{id: ukon.id}" [uiSref]="clickRow ? 'doss.akce.data.ukony.detail' : null" [ngClass]="{ clickable : clickRow }">
      <td class="cell" *ngIf="!hideColumn('dotcenyOrgan')">
        <span class="category-marker"></span>
        {{ ukon.dotcenyOrgan?.nazev }}
      </td>
      <td class="cell" *ngIf="!hideColumn('ukon')">
        <div><strong>{{ ukon.typUkonu?.description }}</strong></div>
        <div *ngIf="ukon.dalsiPopis"><pre>{{ukon.dalsiPopis}}</pre></div>
        <div>({{ukon.naZakladeUstanoveni}})</div>
      </td>
      <td class="cell" *ngIf="!hideColumn('stav')">
        {{ StavUkonuUtils.getStavAndFazeUkonuDesc(ukon.stavUkonu) }}
      </td>
      <td class="cell" *ngIf="!hideColumn('kategorieDoss')">
        {{ ukon.kategorieDoss | kategorieDossList }}
      </td>
      <td class="cell" *ngIf="!hideColumn('datumPosledniZmeny')">
        <div style="text-align: center">{{ ukon.datumPosledniZmeny | date }}</div>
        <div style="text-align: center">{{ ukon.autorPosledniZmeny | username }}</div>
      </td>
      <td class="cell" *ngIf="!hideColumn('upozorneni')">
        {{ ukon.upozorneni }}
      </td>
      <td class="cell" *ngIf="!hideColumn('ukoly')">
        {{ ukon.ukoly }}
      </td>
      <td (click)="$event.stopPropagation()" class="cell" style="text-align: center" *ngIf="!hideColumn('oblibene')">
        <oblibeny-ukon [ukon]="ukon" size="20px"></oblibeny-ukon>
      </td>
      <td class="cell no-padding" *ngIf="!hideColumn('propojeni')">
        <cell-propojeni-ukonu
          [propojeniList]="ukon.propojeni"
          [tooltipData]="{ propojeniList: ukon.propojeni }"
          [tooltipType]="'propojeni-ukonu'"
        ></cell-propojeni-ukonu>
      </td>
      <td class="cell" *ngIf="!hideColumn('platnostDo')">
        <span class="tag" [ngClass]="{'tag--danger': isPast(ukon.platnostDo), 'tag--active': !isPast(ukon.platnostDo)}" *ngIf="ukon.platnostDo">{{ ukon.platnostDo | date }}</span>
      </td>
      <td class="cell" *ngIf="!hideColumn('verzePd')">
        <div>
          <verze-pd [ukon]="ukon"></verze-pd>
        </div>
      </td>
      <td class="cell" *ngIf="!hideColumn('reseneUkony')">
        <reseny-ukon-info [resenyUkon]="ukon"></reseny-ukon-info>
      </td>
      <td class="cell" *ngIf="!hideColumn('odbor')">
        {{ ukon.odbor?.nazev }}
      </td>

      <td class="cell" *ngIf="!hideColumn('kontakt')">
        <div class="user-card" *ngIf="ukon.kontaktniOsoba">
          <i class="fa fa-user mr-5"></i>
          <span class="cell" style="white-space:pre-wrap">{{ ukon.kontaktniOsoba | kontaktniOsoba }}</span>
          <div *ngIf="ukon.kontaktniOsoba?.resenaAgenda">{{ukon.kontaktniOsoba.resenaAgenda}}</div>
        </div>
      </td>
      <td class="cell" *ngIf="!hideColumn('uradovna')">
        <ng-container *ngIf="ukon.pracoviste">
          <div>{{ ukon.pracoviste.nazev }}</div>
          {{ ukon.pracoviste.adresniMisto | address }}
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!list.itemCount && list.list !== null" class="empty-info bordered">
  Nenalezena žádná data
</div>
