import {Component, OnInit} from '@angular/core';

import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import {DialogRef} from '@app/common/services/dialog-ref';
import {PracovisteModel} from "@app/doss/models/pracoviste.model";
import {AdresniMistoModel} from "@app/doss/models/adresni-misto.model";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";
import {AlertComponent} from "@app/common/components/alert/alert.component";
import {ClassName} from "@app/common/enums/class-name.enum";
import {DialogService} from "@app/common/services/dialog.service";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {ConfirmationComponent} from "@app/common/components/confirmation/confirmation.component";

@Component({
  templateUrl: './pracoviste.component.html',
  styleUrls: []
})
export class PracovisteComponent implements OnInit {
  akceSchema: string;
  dotcenyOrganId: number;
  pracoviste: PracovisteModel;

  constructor(private dotcenyOrganService: DotcenyOrganService,
              private dialogService: DialogService,
              private dialogConfig: DialogConfig,
              private dialogRef: DialogRef) {
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).akceSchema;
    this.dotcenyOrganId = (<DialogConfigData>this.dialogConfig.data).dotcenyOrganId;
    this.pracoviste = (<DialogConfigData>this.dialogConfig.data).pracoviste || {fromGeopas: false};
    this.pracoviste.adresniMisto = this.pracoviste.adresniMisto || <AdresniMistoModel>{};
  }

  isValid(): boolean {
    return !!this.pracoviste && !!this.pracoviste.nazev && !!this.pracoviste.adresniMisto.cisloPopisne && !!this.pracoviste.adresniMisto.obec && !!this.pracoviste.adresniMisto.psc;
  }

  showCancelButton(): boolean {
    return this.pracoviste.id && !ValidationUtils.isNonEmptyArray(this.pracoviste.reseneUkony);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.dotcenyOrganId) {
      this.dotcenyOrganService.checkIfPracovisteAlreadyExists(this.akceSchema, this.dotcenyOrganId, this.pracoviste)
        .then(res => {
          if (!res.exists) {
            this.dialogRef.close(this.pracoviste);
          } else {
            this.dialogService.open(AlertComponent, {
              data: {msg: "Nelze založit duplicitní pracovište."},
              className: ClassName.ADJUSTED_DIALOG
            });
          }
        });
    } else {
      this.dialogRef.close(this.pracoviste);
    }
  }
}
