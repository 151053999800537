import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";

@Pipe({name: 'kategorieDossList'})
@Injectable({
  providedIn: 'root'
})
export class KategorieDossListPipe implements PipeTransform {

  transform(ketogrieDossList: KategorieDossModel[]): string {
    return (ketogrieDossList || []).sort((a, b) => a.idKategorie - b.idKategorie).map(ketogrieDoss => `(${ketogrieDoss.idKategorie}) ${ketogrieDoss.description}`).join(", ");
  }
}
