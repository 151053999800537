<h1 class="centered-text">{{ propojeniUkonu ? 'Editace propojení' : 'Nové propojení' }}</h1>

<fieldset>
  <div class="clearfix propojeni-ukonu" [loading]="!loaded">
    <div *ngIf="loaded" class="column-100">

      <ng-container *ngIf="!propojeniUkonu; else updatePropojeniUkonuId">
        <gmt-input label="Typ propojení">
          <gmt-switch disabled="true" (select)="handleSelectOption($event)" [options]="options" [selected]="currentOption"></gmt-switch>
        </gmt-input>

        <div>
          <label>Propojený úkon</label>
          <div class="mt-5">
            <gmt-input>
              <div class="flex-container">
                <gmt-select [data]="propojeniUkonuCreate"
                            [itemPipe]="ukonPropojeniPipe"
                            [multiselect]="true"
                            [optionItems]="propojitelneUkony"
                            class="width-100"
                            field="targetUkony"
                            label="Propojený úkon"
                            required="true"
                ></gmt-select>
              </div>
            </gmt-input>
          </div>
        </div>

        <div>
          <label>Důvod propojení</label>
          <div class="mt-5">
            <gmt-input>
              <input [(ngModel)]="propojeniUkonuCreate.duvodPropojeni" class="width-100" type="text"/>
            </gmt-input>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</fieldset>

<div class="actions">
  <gmt-button [clickAction]="savePropojeni" [gmtDisabled]="!isValid()">ULOŽIT ZMĚNY</gmt-button>
</div>

<ng-template #updatePropojeniUkonuId>
  <div class="column-100">
    <label>Typ propojení</label>
    <div class="info">{{ propojeniUkonu.typPropojeni | typPropojeni }}</div>
  </div>

  <div class="column-100">
    <label>Propojený úkon</label>
    <div class="info">{{ propojeniUkonu.targetUkon | ukonPropojeni }}</div>
  </div>

  <div class="column-100">
    <label>Důvod propojení</label>
    <gmt-input>
      <input class="width-100" [(ngModel)]="propojeniUkonu.duvodPropojeni" type="text"/>
    </gmt-input>
  </div>
</ng-template>
