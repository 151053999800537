import {UkonStatusFormFieldModel} from "@app/doss/models/ukon-status-form-field.model";

export interface UkonStatusConfigModel {
  stav: string;
  faze?: string;
  name: string;
  formFields: UkonStatusFormFieldModel[];
  hasNote?: boolean;
}

export class RozhodnutiStatuses {
  public static readonly ODESLANI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'odeslani-zadosti',
    name: 'Odeslání žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'zpusobOdeslani',
        name: 'Způsob odeslání',
        type: 'select',
        options: [
          {id: 1, name: "datová schránka"},
          {id: 2, name: "jiný způsob"}
        ],
        default: {id: 1, name: "datová schránka"},
        required: true
      }
    ]
  };

  public static readonly DORUCENI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'doruceni-zadosti',
    name: 'Doručení žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      }
    ]
  };

  public static readonly POSTOUPENI_ZADOSTI_JINEMU_DOSS: UkonStatusConfigModel = {
    stav: 'postoupeni-zadosti-jinemu-doss',
    name: 'Postoupení žádosti jinému DOSS',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'dotcenyOrgan',
        name: 'Dotčený DOSS',
        type: 'doss'
      }
    ]
  };

  public static readonly OZNAMENI_O_ZAHAJENI_RIZENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'oznameni-o-zahajeni-rizeni',
    name: 'Oznámení o zahájení řízení',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'termin',
        name: 'Termín',
        type: 'select',
        options: [
          {id: 1, name: "30 dní", value: 30},
          {id: 2, name: "60 dní", value: 60}
        ],
        required: true
      }
    ]
  };

  public static readonly ZPETVZETI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'zpetvzeti-zadosti',
    name: 'Zpětvzetí žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly PROJEDNANI_NAMITEK: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'projednani-namitek',
    name: 'Projednání námitek',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly USTNI_JEDNANI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'ustni-jednani',
    name: 'Ústní jednání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumJednani',
        name: 'Datum jednání',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'misto',
        name: 'Místo',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly MISTNI_SETRENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'mistni-setreni',
    name: 'Místní šetření',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumSetreni',
        name: 'Datum šetření',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'misto',
        name: 'Místo',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly SPOJENE_USTNI_JEDNANI_A_MISTNI_SETRENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'spojene-ustni-jednani-a-mistni-setreni',
    name: 'Spojené ústní jednání a místní šetření',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumSetreni',
        name: 'Datum šetření',
        type: 'date',
        required: true
      },
      {
        field: 'misto',
        name: 'Místo',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly POZADAVEK_NA_DOPLNENI_BEZ_PRERUSENI_RIZENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'pozadavek-na-doplneni-bez-preruseni-rizeni',
    name: 'Požadavek na doplnění bez přerušení řízení',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly PRERUSENI_VYZVA_K_DOPLNENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'preruseni-vyzva-k-doplneni',
    name: 'Přerušení / výzva k doplnění',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'lhuta',
        name: 'Lhůta',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly PRODLOUZENI_LHUTY_K_DOPLNENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'prodlouzeni-lhuty-k-doplneni',
    name: 'Prodloužení lhůty k doplnění',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'lhuta',
        name: 'Lhůta',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly URGENCE: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'urgence',
    name: 'Urgence',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'zpusobUrgence',
        name: 'Způsob urgence',
        type: 'select',
        options: [
          {id: 1, name: "Datovou schránkou"},
          {id: 2, name: "Email"},
          {id: 3, name: "Telefonicky"},
          {id: 4, name: "Písemně (dopis)"}
        ],
        required: true
      }
    ]
  };

  public static readonly PODNET_NA_NECINNOST: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'podnet-na-necinnost',
    name: 'Podnět na nečinnost',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'zpusobPodani',
        name: 'Způsob podání',
        type: 'select',
        options: [
          {id: 1, name: "Datovou schránkou"},
          {id: 2, name: "Email"},
          {id: 3, name: "Písemně (dopis)"}
        ],
        required: true
      }
    ]
  };

  public static readonly ZAMITNUTI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'zamitnuti-zadosti',
    name: 'Zamítnutí žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly ZASTAVENI_RIZENI: UkonStatusConfigModel = {
    stav: 'zastaveni-rizeni',
    name: 'Zastavení řízení',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly VYDANI_ROZHODNUTI: UkonStatusConfigModel = {
    stav: 'vydani-rozhodnuti',
    name: 'Vydání rozhodnutí',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumDoruceni',
        name: 'Datum doručení (vyvěšení)',
        type: 'date',
        required: true
      },
      {
        field: 'datumVydani',
        name: 'Datum vydání',
        type: 'date',
        required: true
      },
      {
        field: 'omezeniDelkyPlatnostiRozhodnuti',
        name: 'Omezení délky platnosti rozhodnutí',
        type: 'date',
        allowFutureDate: true
      },
      {
        field: 'lhutaProOdvolani',
        name: 'Lhůta pro odvolání',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'dokumentRozhodnuti',
        name: 'Dokument rozhodnutí',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ODVOLANI: UkonStatusConfigModel = {
    stav: 'odvolani',
    name: 'Odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumDoruceni',
        name: 'Datum doručení (vyvěšení)',
        type: 'date',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'odvolaniPodal',
        name: 'Odvolání podal',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly POSTOUPENI_SPISU_ODVOLACIMU_ORGANU: UkonStatusConfigModel = {
    stav: 'postoupeni-spisu-odvolacimu-organu',
    name: 'Postoupení spisu odvolacímu orgánu',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'odvolaciOrgan',
        name: 'Odvolací orgán',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly OZNAMENI_O_ZAHAJENI_RIZENI_O_ODVOLANI: UkonStatusConfigModel = {
    stav: 'oznameni-o-zahajeni-rizeni-o-odvolani',
    name: 'Oznámení o zahájení řízení o odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumDoruceni',
        name: 'Datum doručení (vyvěšení)',
        type: 'date',
        required: true
      }
    ]
  };

  public static readonly ZAMITNUTI_ODVOLANI: UkonStatusConfigModel = {
    stav: 'zamitnuti-odvolani',
    name: 'Zamítnutí odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZamitnuti',
        name: 'Dokument zamítnutí',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ZASTAVENI_ODVOLACIHO_RIZENI: UkonStatusConfigModel = {
    stav: 'zastaveni-odvolaciho-rizeni',
    name: 'Zastavení odvolacího řízení',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZastaveni',
        name: 'Dokument zastavení',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ROZHODNUTI_O_ODVOLANI: UkonStatusConfigModel = {
    stav: 'rozhodnuti-o-odvolani',
    name: 'Rozhodnutí o odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumDoruceni',
        name: 'Datum doručení (vyvěšení)',
        type: 'date',
        required: true
      },
      {
        field: 'datumVydani',
        name: 'Datum vydání',
        type: 'date',
        required: true
      },
      {
        field: 'dokumentRozhodnutiOodvolani',
        name: 'Dokument rozhodnutí o odvolání',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ROZHODNUTI_ZRUSENO_RIZENI_O_ODVOLANI_ZASTAVENO: UkonStatusConfigModel = {
    stav: 'rozhodnuti-zruseno-rizeni-o-odvolani-zastaveno',
    name: 'Rozhodnutí zrušeno, řízení o odvolání zastaveno',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZastaveni',
        name: 'Dokument zastavení',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly POTVRZENI_PUVODNIHO_ROZHODNUTI: UkonStatusConfigModel = {
    stav: 'potvrzeni-puvodniho-rozhodnuti',
    name: 'Potvrzení původního rozhodnutí',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentPotvrzeni',
        name: 'Dokument potvrzení',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ROZHODNUTI_ZMENENO_ODVOLACIM_ORGANEM: UkonStatusConfigModel = {
    stav: 'rozhodnuti-zmeneno-odvolacim-organem',
    name: 'Rozhodnutí změněno odvolacím orgánem',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentRozhodnuti',
        name: 'Dokument rozhodnutí',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly NABYTI_PRAVNI_MOCI: UkonStatusConfigModel = {
    stav: 'nabyti-pravni-moci',
    name: 'Nabytí právní moci',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      }
    ]
  };

  public static readonly MIMORADNE_OPRAVNE_PROSTREDKY: UkonStatusConfigModel = {
    stav: 'mimoradne-opravne-prostredky',
    name: 'Mimořádné opravné prostředky',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly STATUSES: UkonStatusConfigModel[] = [
    RozhodnutiStatuses.ODESLANI_ZADOSTI,
    RozhodnutiStatuses.DORUCENI_ZADOSTI,
    RozhodnutiStatuses.POSTOUPENI_ZADOSTI_JINEMU_DOSS,
    RozhodnutiStatuses.OZNAMENI_O_ZAHAJENI_RIZENI,
    RozhodnutiStatuses.ZPETVZETI_ZADOSTI,
    RozhodnutiStatuses.PROJEDNANI_NAMITEK,
    RozhodnutiStatuses.USTNI_JEDNANI,
    RozhodnutiStatuses.MISTNI_SETRENI,
    RozhodnutiStatuses.SPOJENE_USTNI_JEDNANI_A_MISTNI_SETRENI,
    RozhodnutiStatuses.POZADAVEK_NA_DOPLNENI_BEZ_PRERUSENI_RIZENI,
    RozhodnutiStatuses.PRERUSENI_VYZVA_K_DOPLNENI,
    RozhodnutiStatuses.PRODLOUZENI_LHUTY_K_DOPLNENI,
    RozhodnutiStatuses.URGENCE,
    RozhodnutiStatuses.PODNET_NA_NECINNOST,
    RozhodnutiStatuses.ZAMITNUTI_ZADOSTI,
    RozhodnutiStatuses.ZASTAVENI_RIZENI,
    RozhodnutiStatuses.VYDANI_ROZHODNUTI,
    RozhodnutiStatuses.ODVOLANI,
    RozhodnutiStatuses.POSTOUPENI_SPISU_ODVOLACIMU_ORGANU,
    RozhodnutiStatuses.OZNAMENI_O_ZAHAJENI_RIZENI_O_ODVOLANI,
    RozhodnutiStatuses.ZAMITNUTI_ODVOLANI,
    RozhodnutiStatuses.ZASTAVENI_ODVOLACIHO_RIZENI,
    RozhodnutiStatuses.ROZHODNUTI_O_ODVOLANI,
    RozhodnutiStatuses.ROZHODNUTI_ZRUSENO_RIZENI_O_ODVOLANI_ZASTAVENO,
    RozhodnutiStatuses.POTVRZENI_PUVODNIHO_ROZHODNUTI,
    RozhodnutiStatuses.ROZHODNUTI_ZMENENO_ODVOLACIM_ORGANEM,
    RozhodnutiStatuses.NABYTI_PRAVNI_MOCI,
    RozhodnutiStatuses.MIMORADNE_OPRAVNE_PROSTREDKY
  ];
}

export class DalsiUkonStatuses {
  public static readonly ODESLANI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'odeslani-zadosti',
    name: 'Odeslání žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'zpusobOdeslani',
        name: 'Způsob odeslání',
        type: 'select',
        options: [
          {id: 1, name: "datová schránka"},
          {id: 2, name: "jiný způsob"}
        ],
        default: {id: 1, name: "datová schránka"},
        required: true
      }
    ]
  };

  public static readonly DORUCENI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'doruceni-zadosti',
    name: 'Doručení žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      }
    ]
  };

  public static readonly POSTOUPENI_ZADOSTI_JINEMU_DOSS: UkonStatusConfigModel = {
    stav: 'postoupeni-zadosti-jinemu-doss',
    name: 'Postoupení žádosti jinému DOSS',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'dotcenyOrgan',
        name: 'Dotčený DOSS',
        type: 'doss'
      }
    ]
  };

  public static readonly RIZENI_UKONU: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'nezvoleno',
    name: 'Vyřízení úkonu',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'termin',
        name: 'Termín',
        type: 'select',
        options: [
          {id: 1, name: "30 dní", value: 30},
          {id: 2, name: "60 dní", value: 60},
          {id: 3, name: "120 dní", value: 120}
        ],
        required: true
      }
    ]
  };

  public static readonly PRERUSENI_VYZVA_K_DOPLNENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'preruseni-vyzva-k-doplneni',
    name: 'Přerušení / výzva k doplnění',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'lhuta',
        name: 'Lhůta',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly USTNI_JEDNANI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'ustni-jednani',
    name: 'Ústní jednání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumJednani',
        name: 'Datum jednání',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'misto',
        name: 'Místo',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly MISTNI_SETRENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'mistni-setreni',
    name: 'Místní šetření',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumSetreni',
        name: 'Datum šetření',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'misto',
        name: 'Místo',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly SPOJENE_USTNI_JEDNANI_A_MISTNI_SETRENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'spojene-ustni-jednani-a-mistni-setreni',
    name: 'Spojené ústní jednání a místní šetření',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumSetreni',
        name: 'Datum šetření',
        type: 'date',
        required: true
      },
      {
        field: 'misto',
        name: 'Místo',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly POZADAVEK_NA_DOPLNENI_BEZ_PRERUSENI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'pozadavek-na-doplneni-bez-preruseni',
    name: 'Požadavek na doplnění bez přerušení',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly ZPETVZETI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'zpetvzeti-zadosti',
    name: 'Zpětvzetí žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly PODNET_NA_NECINNOST: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'podnet-na-necinnost',
    name: 'Podnět na nečinnost',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'zpusobPodani',
        name: 'Způsob podání',
        type: 'select',
        options: [
          {id: 1, name: "Datovou schránkou"},
          {id: 2, name: "Email"},
          {id: 3, name: "Písemně (dopis)"}
        ],
        required: true
      }
    ]
  };

  public static readonly URGENCE: UkonStatusConfigModel = {
    stav: 'rizeni-ukonu',
    faze: 'urgence',
    name: 'Urgence',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'zpusobUrgence',
        name: 'Způsob urgence',
        type: 'select',
        options: [
          {id: 1, name: "Datovou schránkou"},
          {id: 2, name: "Email"},
          {id: 3, name: "Telefonicky"},
          {id: 4, name: "Písemně (dopis)"}
        ],
        required: true
      }
    ]
  };

  public static readonly ZASTAVENO: UkonStatusConfigModel = {
    stav: 'zastaveno',
    name: 'Zastaveno',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZastaveni',
        name: 'Dokument zastavení',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ZAMITNUTI_ZADOSTI: UkonStatusConfigModel = {
    stav: 'zamitnuti-zadosti',
    name: 'Zamítnutí žádosti',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZamitnuti',
        name: 'Dokument zamítnutí',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly VYDANO_ZAPORNE: UkonStatusConfigModel = {
    stav: 'vydano-zaporne',
    name: 'Vydáno záporně',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'lhutaProOdvolani',
        name: 'Lhůta pro odvolání',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'dokumentZamitnuti',
        name: 'Dokument zamítnutí',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly VYDANO_S_PODMINKOU: UkonStatusConfigModel = {
    stav: 'vydano-s-podminkou',
    name: 'Vydáno s podmínkou',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumVydani',
        name: 'Datum vydání',
        type: 'date',
        required: true
      },
      {
        field: 'datumKoncePlatnosti',
        name: 'Datum konce platnosti',
        type: 'date',
        allowFutureDate: true
      },
      {
        field: 'lhutaProOdvolani',
        name: 'Lhůta pro odvolání',
        type: 'date',
        required: true,
        allowFutureDate: true
      },
      {
        field: 'podminkyVydani',
        name: 'Podmínky vydání',
        type: 'input',
        required: true
      },
      {
        field: 'dokument',
        name: 'Dokument',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly VYDANO_BEZ_PODMINEK: UkonStatusConfigModel = {
    stav: 'vydano-bez-podminek',
    name: 'Vydáno bez podmínek',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumVydani',
        name: 'Datum vydání',
        type: 'date',
        required: true
      },
      {
        field: 'datumKoncePlatnosti',
        name: 'Datum konce platnosti',
        type: 'date',
        allowFutureDate: true,
      },
      {
        field: 'lhutaProOdvolani',
        name: 'Lhůta pro odvolání',
        type: 'date',
        required: true,
        allowFutureDate: true,
      },
      {
        field: 'dokument',
        name: 'Dokument',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly FIKCE_SOUHLASU: UkonStatusConfigModel = {
    stav: 'fikce-souhlasu',
    name: 'Fikce souhlasu',
    formFields: [
      {
        field: 'datumFikceSouhlasu',
        name: 'Datum fikce souhlasu',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'poznamka',
        name: 'Poznámka',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly ODVOLANI: UkonStatusConfigModel = {
    stav: 'odvolani',
    name: 'Odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumDoruceni',
        name: 'Datum doručení (vyvěšení)',
        type: 'date',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'odvolaniPodal',
        name: 'Odvolání podal',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly POSTOUPENI_SPISU_ODVOLACIMU_ORGANU: UkonStatusConfigModel = {
    stav: 'postoupeni-spisu-odvolacimu-organu',
    name: 'Postoupení spisu odvolacímu orgánu',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'odvolaciOrgan',
        name: 'Odvolací orgán',
        type: 'input',
        required: true
      }
    ]
  };

  public static readonly OZNAMENI_O_ZAHAJENI_RIZENI_O_ODVOLANI: UkonStatusConfigModel = {
    stav: 'oznameni-o-zahajeni-rizeni-o-odvolani',
    name: 'Oznámení o zahájení řízení o odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumDoruceni',
        name: 'Datum doručení (vyvěšení)',
        type: 'date',
        required: true
      }
    ]
  };

  public static readonly ZAMITNUTI_ODVOLANI: UkonStatusConfigModel = {
    stav: 'zamitnuti-odvolani',
    name: 'Zamítnutí odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZamitnuti',
        name: 'Dokument zamítnutí',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ZASTAVENI_ODVOLACIHO_RIZENI: UkonStatusConfigModel = {
    stav: 'zastaveni-odvolaciho-rizeni',
    name: 'Zastavení odvolacího řízení',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZastaveni',
        name: 'Dokument zastavení',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ROZHODNUTI_O_ODVOLANI: UkonStatusConfigModel = {
    stav: 'rozhodnuti-o-odvolani',
    name: 'Rozhodnutí o odvolání',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'datumDoruceni',
        name: 'Datum doručení (vyvěšení)',
        type: 'date',
        required: true
      },
      {
        field: 'datumVydani',
        name: 'Datum vydání',
        type: 'date',
        required: true
      },
      {
        field: 'dokumentRozhodnutiOodvolani',
        name: 'Dokument rozhodnutí o odvolání',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ROZHODNUTI_ZRUSENO_RIZENI_O_ODVOLANI_ZASTAVENO: UkonStatusConfigModel = {
    stav: 'rozhodnuti-zruseno-rizeni-o-odvolani-zastaveno',
    name: 'Rozhodnutí zrušeno, řízení o odvolání zastaveno',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentZastaveni',
        name: 'Dokument zastavení',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly POTVRZENI_PUVODNIHO_ROZHODNUTI: UkonStatusConfigModel = {
    stav: 'potvrzeni-puvodniho-rozhodnuti',
    name: 'Potvrzení původního rozhodnutí',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentPotvrzeni',
        name: 'Dokument potvrzení',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly ROZHODNUTI_ZMENENO_ODVOLACIM_ORGANEM: UkonStatusConfigModel = {
    stav: 'rozhodnuti-zmeneno-odvolacim-organem',
    name: 'Rozhodnutí změněno odvolacím orgánem',
    formFields: [
      {
        field: 'datum',
        name: 'Datum',
        type: 'date',
        default: 'currentDate',
        required: true
      },
      {
        field: 'duvod',
        name: 'Důvod',
        type: 'input',
        required: true
      },
      {
        field: 'dokumentRozhodnuti',
        name: 'Dokument rozhodnutí',
        type: 'document',
        required: true
      }
    ]
  };

  public static readonly STATUSES: UkonStatusConfigModel[] = [
    DalsiUkonStatuses.ODESLANI_ZADOSTI,
    DalsiUkonStatuses.DORUCENI_ZADOSTI,
    DalsiUkonStatuses.POSTOUPENI_ZADOSTI_JINEMU_DOSS,
    DalsiUkonStatuses.RIZENI_UKONU,
    DalsiUkonStatuses.PRERUSENI_VYZVA_K_DOPLNENI,
    DalsiUkonStatuses.USTNI_JEDNANI,
    DalsiUkonStatuses.MISTNI_SETRENI,
    DalsiUkonStatuses.SPOJENE_USTNI_JEDNANI_A_MISTNI_SETRENI,
    DalsiUkonStatuses.POZADAVEK_NA_DOPLNENI_BEZ_PRERUSENI,
    DalsiUkonStatuses.ZPETVZETI_ZADOSTI,
    DalsiUkonStatuses.PODNET_NA_NECINNOST,
    DalsiUkonStatuses.URGENCE,
    DalsiUkonStatuses.ZASTAVENO,
    DalsiUkonStatuses.ZAMITNUTI_ZADOSTI,
    DalsiUkonStatuses.VYDANO_ZAPORNE,
    DalsiUkonStatuses.VYDANO_S_PODMINKOU,
    DalsiUkonStatuses.VYDANO_BEZ_PODMINEK,
    DalsiUkonStatuses.FIKCE_SOUHLASU,
    DalsiUkonStatuses.ODVOLANI,
    DalsiUkonStatuses.POSTOUPENI_SPISU_ODVOLACIMU_ORGANU,
    DalsiUkonStatuses.OZNAMENI_O_ZAHAJENI_RIZENI_O_ODVOLANI,
    DalsiUkonStatuses.ZAMITNUTI_ODVOLANI,
    DalsiUkonStatuses.ZASTAVENI_ODVOLACIHO_RIZENI,
    DalsiUkonStatuses.ROZHODNUTI_O_ODVOLANI,
    DalsiUkonStatuses.ROZHODNUTI_ZRUSENO_RIZENI_O_ODVOLANI_ZASTAVENO,
    DalsiUkonStatuses.POTVRZENI_PUVODNIHO_ROZHODNUTI,
    DalsiUkonStatuses.ROZHODNUTI_ZMENENO_ODVOLACIM_ORGANEM
  ];
}
