<div class="detail">
  <div>
    <a uiSref="symap.project.projectSettings" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Nastavení</a>
  </div>

  <h1>{{ 'ps.projectSettings' | brandTranslate }}</h1>

  <div [loading]="loading">
    <div *ngIf="!loading" class="mt-20">
      <div class="flexbox-container">
        <div class="content-menu">
          <ng-container *ngFor="let tab of tabs">
            <div *ngIf="(tab.id !== 'KN' && tab.id !== 'T' && tab.id !== 'ZE' && tab.id !== 'WSDP') || (tab.id === 'KN' && user.superuser) || (tab.id === 'T' && !data.useTemplatesService) || (tab.id === 'ZE' && user.superuser) || (tab.id === 'WSDP' && (user.superuser || APP_BRAND.NAME === 'RSD'))" (click)="selectTab(tab)" [ngClass]="{ active : selectedTab === tab.id }">
              {{ tab.name }} <i *ngIf="tab.name === 'KN data' && !data.contract" class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div>
          </ng-container>
        </div>
        <div>
          <div *ngIf="selectedTab === 'G'">
            <fieldset class="bordered no-padding">
              <legend>{{ 'ps.projectInfo' | brandTranslate }}</legend>
              <project-info-settings [project]="data"></project-info-settings>
            </fieldset>

            <fieldset class="bordered">
              <legend>Další nastavení</legend>

              <div class="column-100">
                <gmt-input label="Realizace od">
                  <input type="text" [(ngModel)]="data.realizationFrom"/>
                </gmt-input>
              </div>
              <div class="column-100">
                <gmt-input label="Realizace do">
                  <input type="text" [(ngModel)]="data.realizationTo"/>
                </gmt-input>
              </div>
              <div visibleByBrand="maja" class="column-100">
                <gmt-input label="Zaokrouhlování cen">
                  <gmt-switch *ngIf="!currentState || currentState === 'CREATED'"
                    [(selectedId)]="data.priceRoundingPrecisionType"
                    [options]="priceRoundingPrecisionTypeOptions"
                    (changed)="onPriceRoundingPrecisionTypeChanged($event)"
                  ></gmt-switch>
                  <div class="info" *ngIf="currentState && currentState !== 'CREATED'">{{ project.priceRoundingPrecisionType | priceRoundingPrecisionTypeName}}</div>
                </gmt-input>
              </div>
              <div class="column-100" (click)="data.showExpertOpinionCoefficient = !data.showExpertOpinionCoefficient">
                <label>Povolit ve ZP zadání koeficientu podle zákona 416/2009 Sb</label>
                <div class="info">
                  <gmt-checkbox [gmtChecked]="data.showExpertOpinionCoefficient"></gmt-checkbox>
                </div>
              </div>
              <div class="column-100" *ngIf="user.superuser" (click)="data.multipleOpinionsPerParcel = !data.multipleOpinionsPerParcel">
                <label>Povolit zadání cen z více ZP na jednu parcelu</label>
                <div class="info">
                  <gmt-checkbox [gmtChecked]="data.multipleOpinionsPerParcel"></gmt-checkbox>
                </div>
              </div>
              <div visibleByBrand="maja" class="column-100" (click)="data.easementPriceByShare = !data.easementPriceByShare">
                <label>Zadání ceny VB za podíl na LV</label>
                <div class="info">
                  <gmt-checkbox [gmtChecked]="data.easementPriceByShare"></gmt-checkbox>
                </div>
              </div>
              <div visibleByBrand="maja" class="column-100" (click)="data.easementDistributionSystemParts = !data.easementDistributionSystemParts">
                <label>Záznam součásti distribuční soustavy na VB</label>
                <div class="info">
                  <gmt-checkbox [gmtChecked]="data.easementDistributionSystemParts"></gmt-checkbox>
                </div>
              </div>
              <div visibleByBrand="maja" class="column-100">
                <label>Upozornit notifikací před termínem realizace {{ "CONSTRUCTION_OBJECT_GENITIV" | wordtranslate }} (počet dní)</label>
                <div class="info">
                  <input type="text" [(ngModel)]="data.constructionObjectRealizationNotifyDaysBefore"/>
                </div>
              </div>
              <div class="column-100">
                <label>Vstupní data obsahují kresbu {{ "CONSTRUCTION_OBJECT_GENITIV" | wordtranslate }} (možnost zobrazení / vyhledávání v mapovém okně)</label>
                <div class="info" (click)="data.isConstructionObjectGeometry = !data.isConstructionObjectGeometry">
                  <gmt-checkbox [gmtChecked]="data.isConstructionObjectGeometry"></gmt-checkbox>
                </div>
              </div>
              <div class="column-100">
                <label>Používat modul správy šablon</label>
                <div class="info" (click)="data.useTemplatesService = !data.useTemplatesService">
                  <gmt-checkbox [gmtChecked]="data.useTemplatesService"></gmt-checkbox>
                </div>
              </div>
              <div visibleByBrand="maja" class="column-100">
                <label>Úprava ceny nájemních smluv<br/>ruční volba ceny za případ</label>
                <div class="info" (click)="data.manualRentPriceAllowed = !data.manualRentPriceAllowed">
                  <gmt-checkbox [gmtChecked]="data.manualRentPriceAllowed"></gmt-checkbox>
                </div>
              </div>
            </fieldset>

            <fieldset class="bordered">
              <legend>Panely</legend>
              <div class="column-100" (click)="data.projectInfoEnabled = !data.projectInfoEnabled;">
                <label>Panel '{{ 'ps.projectInfo' | brandTranslate }}' povolen</label>
                <div class="info">
                  <gmt-checkbox [gmtChecked]="data.projectInfoEnabled"></gmt-checkbox>
                </div>
              </div>
              <div class="column-100" (click)="data.geometricPlanModuleEnabled = !data.geometricPlanModuleEnabled">
                <label>Panel 'GP' povolen</label>
                <div class="info">
                  <gmt-checkbox [gmtChecked]="data.geometricPlanModuleEnabled"></gmt-checkbox>
                </div>
              </div>
              <div class="column-100" (click)="data.opinionModuleEnabled = !data.opinionModuleEnabled">
                <label>Panel 'ZP' povolen</label>
                <div class="input">
                  <gmt-checkbox [gmtChecked]="data.opinionModuleEnabled"></gmt-checkbox>
                </div>
              </div>

              <ng-container *ngIf="user.superuser && APP_BRAND.NAME === 'GMT'">
                <div class="column-100" (click)="data.vfzeModuleEnabled = !data.vfzeModuleEnabled">
                  <label>Panel 'Import' povolen</label>
                  <div class="info">
                    <gmt-checkbox [gmtChecked]="data.vfzeModuleEnabled"></gmt-checkbox>
                  </div>
                </div>
              </ng-container>
            </fieldset>
          </div>

          <div *ngIf="selectedTab === 'KN'">
            <div class="column-100">
              <gmt-input label="Objednatel měsíčních KN dat">
                <gmt-select
                  [restangularService]="configurationRestangularService"
                  resource="v2/customers"
                  [filter]="{ filters: { includeDepartments: { values: ['true'] }}, sortOrder: { sortBy: 'companyName' }}"
                  [data]="data"
                  field="customer"
                  [itemPipe]="projectSubjectNamePipe"
                ></gmt-select>
              </gmt-input>
            </div>
            <div class="column-100">
              <gmt-input label="Použitý WSDP přístup">
                <gmt-select
                  [restangularService]="configurationRestangularService"
                  resource="v2/customers"
                  [filter]="{ filters: { includeDepartments: { values: ['true'] }}, sortOrder: { sortBy: 'companyName' }}"
                  [itemPipe]="projectSubjectNamePipe"
                  [data]="data"
                  field="customerWsdpAccount"
                ></gmt-select>
              </gmt-input>
            </div>
            <div class="column-100">
              <label>Číslo smlouvy</label>
              <div class="input" *ngIf="data.contract">
                <a uiSref="settings.customers.detail.contract.detail" [uiParams]="{ id: data.contract.idText, customerId: data.contract.customer.idText }">
                  {{ data.contract.contractNumber }}
                </a>
              </div>
              <div class="input" *ngIf="!data.contract && data.customer">
                <a uiSref="settings.customers.detail.contract.new" [uiParams]="{ customerId: data.customer.idText }">
                  Založit smlouvu
                </a>
              </div>
            </div>
            <div class="column-100" (click)="data.updateCadastre = !data.updateCadastre">
              <label>Povolena aktualizace dat KN</label>
              <div class="info">
                <gmt-checkbox [gmtChecked]="data.updateCadastre"></gmt-checkbox>
              </div>
            </div>
            <div class="column-100" (click)="data.paidCadastreDataDelivery = !data.paidCadastreDataDelivery">
              <label>Úplatný režim výdeje dat KN</label>
              <div class="input">
                <gmt-checkbox [gmtChecked]="data.paidCadastreDataDelivery"></gmt-checkbox>
              </div>
            </div>
            <div class="column-100" (click)="data.includeLpis = !data.includeLpis">
              <label>Povolena aktualizace dat LPIS</label>
              <div class="info">
                <gmt-checkbox [gmtChecked]="data.includeLpis"></gmt-checkbox>
              </div>
            </div>
            <div class="column-100 mb-20">
              <gmt-input label="Aktualizovat rozsah území v PROMAPu">
                <month-picker (date)="onCadastreDeliveryDateChange($event)" [options]="cadastreDeliveryMonthPickerOptions"></month-picker>
                <div class="mt-5">
                  <gmt-button [clickAction]="runCadastreDelivery">Provést</gmt-button>
                </div>
              </gmt-input>
            </div>
            <div class="column-100">
              <gmt-input label="Nahrát aktuální data do projektu">
                <month-picker (date)="onDownloadDataDateChange($event)" [options]="downloadDataMonthPickerOptions"></month-picker>
                <div class="mt-5">
                  <gmt-button [clickAction]="runDownloadData">Provést</gmt-button>
                </div>
              </gmt-input>
            </div>
            <div class="column-100" *ngIf="user.superuser">
              <gmt-input label="Stáhnout aktuální data z Promapu">
                <month-picker (date)="onDownloadDataToInputTablesChange($event)" [options]="downloadDataMonthPickerOptions"></month-picker>
                <div class="mt-5">
                  <gmt-button [clickAction]="downloadDataToInputTables">Provést</gmt-button>
                </div>
              </gmt-input>
            </div>
            <div class="column-100" *ngIf="user.superuser">
              <gmt-input label="Provést interní aktualizaci KN dat">
                <gmt-button [clickAction]="updateCadastreData">Provést</gmt-button>
              </gmt-input>
            </div>
            <div class="column-100 mt-40">
              <activity-log></activity-log>
            </div>
          </div>

          <div *ngIf="selectedTab === 'WSDP'">
            <kn-wsdp-update></kn-wsdp-update>
          </div>

          <div *ngIf="selectedTab === 'ZE'">
            <data-ze [project]="data"></data-ze>
          </div>

          <div *ngIf="selectedTab === 'MAP'">
            <fieldset class="bordered">
              <legend>Výchozí nastavení</legend>

              <div class="column-100">
                <gmt-input label="Výchozí přiblížení mapového okna">
                  <input type="text" [(ngModel)]="data.mapZoom"/>
                </gmt-input>
              </div>
              <div class="column-100">
                <gmt-input label="Výchozí zeměpisná šířka">
                  <input type="text" [(ngModel)]="data.mapLat"/>
                </gmt-input>
              </div>
              <div class="column-100">
                <gmt-input label="Výchozí zeměpisná délka">
                  <input type="text" [(ngModel)]="data.mapLon"/>
                </gmt-input>
              </div>
            </fieldset>

            <fieldset class="bordered">
              <legend>Vrstvy mapy</legend>
              <map-settings [project]="project" [mapDefaults]="mapSettingsDefaults" (loaded)="getMapSettingsDefaults($event)"></map-settings>
            </fieldset>
          </div>

          <div *ngIf="selectedTab === 'I'">
            <gmt-project-subject *ngIf="data.investor" [subject]="data.investor"></gmt-project-subject>
          </div>

          <div *ngIf="selectedTab === 'N'">
            <series-list [seriesList]="seriesParsed"></series-list>
          </div>

          <div *ngIf="selectedTab === 'O'">
            <div>
              <div class="column-100 checklist">
                <div
                  *ngFor="let obligation of data.obligationTypes"
                  (click)="obligation.allowed = !obligation.allowed"
                  class="checklist-item with-action cb clearfix"
                >
                  <div class="input">
                    <gmt-button (click)="onUpdateObligation(obligation); $event.stopPropagation(); $event.preventDefault();" class="button"><span class="fa fa-pencil"></span></gmt-button>
                    <gmt-button (click)="onUpdateObligationFlow(obligation); $event.stopPropagation(); $event.preventDefault();" class="button"><span class="fa fa-exchange"></span></gmt-button>
                  </div>
                  <div class="info fn" *ngIf="obligation.defaultName === obligation.name">
                    <gmt-checkbox gmtType="multiselect" [gmtChecked]="obligation.allowed"></gmt-checkbox>
                    {{ obligation.defaultName }}
                  </div>
                  <div class="info fn" *ngIf="obligation.defaultName !== obligation.name">
                    <gmt-checkbox gmtType="multiselect" [gmtChecked]="obligation.allowed"></gmt-checkbox>
                    {{ obligation.name }} ({{ obligation.defaultName }})
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTab === 'T'">
            <div [loading]="dataTemplateTypes.loading">
              <div class="column-100 checklist" *ngIf="!dataTemplateTypes.loading">
                <div *ngFor="let templateType of dataTemplateTypes.list" (click)="checklistTemplates.toggleSelection(templateType)" class="checklist-item cb">
                  <gmt-checkbox gmtType="multiselect" [gmtChecked]="checklistTemplates.isChecked(templateType)"></gmt-checkbox>
                  {{ templateType.name }}
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTab === 'E'">
            <div [loading]="dataExportTypes.loading">
              <div class="column-100 checklist" *ngIf="!dataExportTypes.loading">
                <div *ngFor="let export of dataExportTypes.list" (click)="checklistExportTypes.toggleSelection(export)" class="checklist-item cb">
                  <gmt-checkbox gmtType="multiselect" [gmtChecked]="checklistExportTypes.isChecked(export)"></gmt-checkbox>
                  {{ export.name }}
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTab === 'S'">
            <div [loading]="!statistics">
              <statistics-settings [data]="statistics" *ngIf="statistics" (callbackRegister)="statisticsCallbackRegister($event)"></statistics-settings>
            </div>
          </div>

          <div *ngIf="selectedTab === 'CS'">
            <div [loading]="dataCaseStatusesLoading">
              <div class="column-100 checklist" *ngIf="!dataCaseStatusesLoading">
                <div *ngFor="let caseStatus of dataCaseStatuses; let i = index" (click)="displayEdit(i, $event, 'CaseStatuse')" class="checklist-item with-action cb clearfix">
                  <div class="info" *ngIf="i !== dataCaseStatuseEdited">
                    {{ caseStatus.name }} <span *ngIf="caseStatus.commonName && caseStatus.name !== caseStatus.commonName">({{caseStatus.commonName}})</span>
                  </div>
                  <div class="input" *ngIf="i === dataCaseStatuseEdited">
                    <input type="text" [(ngModel)]="caseStatus.name" (blur)="resetEdit(caseStatus, 'CaseStatuse');"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTab === 'CL'">
            <div [loading]="!columnsVisibility">
              <div *ngIf="columnsVisibility">
                <div *ngFor="let tableColumnsRestriction of columnsRestrictionsTable" class="mb-20">
                  <gmt-labeled-block label="{{ tableColumnsRestriction.name }}" [collapsed]="true">
                    <fieldset class="clearfix no-legend">
                      <div class="column-100 checklist">
                        <div *ngFor="let column of tableColumnsRestriction.columns" (click)="toggleHiddenColumn(tableColumnsRestriction.id, column.id)" class="checklist-item cb">
                          <gmt-checkbox gmtType="multiselect" [gmtChecked]="columnIsVisible(tableColumnsRestriction.id, column.id)"></gmt-checkbox>
                          {{ column.name }}
                        </div>
                      </div>
                    </fieldset>
                  </gmt-labeled-block>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTab === 'NL'">
            <div [loading]="!nomenclatureData">
              <table class="table mb-20">
                <thead>
                <tr>
                  <th><div class="text-left">Překlad</div></th>
                  <th><div class="text-left">Obecný název</div></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let nomenclatureItem of nomenclature; let i = index" (click)="displayEdit(i, $event, 'nomenclature')">
                  <td class="cell" style="width: 30%">
                    <div class="info" *ngIf="i !== nomenclatureEdited">{{ nomenclatureItem.name }}</div>
                    <div class="input" *ngIf="i === nomenclatureEdited">
                      <input type="text" [(ngModel)]="nomenclatureItem.name" (blur)="resetEdit(nomenclatureItem, 'nomenclature');"/>
                    </div>
                  </td>
                  <td class="cell">
                    <span *ngIf="nomenclatureItem.commonName">{{ nomenclatureItem.commonName }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="selectedTab === 'EM'">
            <notification-config [saveNotificationConfigRegister]="onSaveNotificationConfigRegister"></notification-config>
            <div visibleByBrand="maja">
              <div [loading]="!email">
                <div *ngIf="email">
                  <gmt-email-form [email]="email.welcomeEmail" label="Uvítací email" type="welcome"></gmt-email-form>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTab === 'DASHBOARD'">
            <dashboard-settings [project]="project" [dashboardDefaults]="dashboardSettingsDefaults" (loaded)="getDashboardSettingsDefaults($event)"></dashboard-settings>
          </div>
        </div>
      </div>
      <div class="cb text-center" *ngIf="!(selectedTab === 'WSDP')">
        <gmt-button [clickAction]="update" [gmtDisabled]="!isValid()">Uložit všechna nastavení</gmt-button>
      </div>
    </div>
  </div>
</div>
