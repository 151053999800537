import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {AuthService} from '@app/common/services/auth.service';
import {ClassName} from '@app/common/enums/class-name.enum';
import {ConfirmationComponent} from '@app/common/components/confirmation/confirmation.component';
import {DialogService} from '@app/common/services/dialog.service';
import {ListModel} from '@app/common/models/list.model';
import {ListService} from '@app/common/services/list.service';
import {PerfectScrollbarEvent} from '@app/common/components/perfectscrollbar/perfectscrollbar.component';
import {UkolCreateComponent} from "@app/doss/components/ukol-create/ukol-create.component";
import {UkolModel} from "@app/doss/models/ukol.model";
import {UkolService} from "@app/doss/services/ukol.service";


@Component({
  selector: 'ukol-list',
  templateUrl: './ukol-list.component.html',
  styleUrls: ['./ukol-list.component.scss']
})
export class UkolListComponent implements OnInit {

  @Input() defaultData: any = {};
  @Input() ukolList: ListModel<UkolModel>;
  @Input() maxHeight: number;
  @Input() prehledAkce: boolean;

  @Output() updated = new EventEmitter<boolean>();
  private akceSchema: string;

  constructor(
    private authService: AuthService,
    private listService: ListService,
    private dialogService: DialogService,
    private ukolService: UkolService
  ) {
    this.onEditUkol = this.onEditUkol.bind(this);
    this.onCanScrollTop = this.onCanScrollTop.bind(this);
    this.onDeleteukol = this.onDeleteukol.bind(this);
    this.onLoadNextUkoly = this.onLoadNextUkoly.bind(this);
    this.onCancelledCheckboxClicked = this.onCancelledCheckboxClicked.bind(this);
    this.checkCancelled = this.checkCancelled.bind(this);
  }

  ngOnInit() {
    this.akceSchema = this.authService.getActualProject().schema;
  }

  isForPrehledAkce(): boolean {
    return this.prehledAkce;
  }

  onEditUkol(ukol: UkolModel = null) {
    const dialog = this.dialogService.open(UkolCreateComponent, {
      data: {
        ukol: _.cloneDeep(ukol),
        schema: this.akceSchema,
        ukonId: ukol.ukon.id,
      }
    });

    const sub = dialog.afterClosed.subscribe((result: UkolModel | boolean) => {
      if (result) {
        this.cleanResult(result);

        const index = _.findIndex(this.ukolList.list, {id: ukol.id});
        this.ukolList.list.splice(index, 1, result as UkolModel);


        this.updated.emit(true);
      }
      sub.unsubscribe();
    });
  }

  onDeleteukol(ukol: UkolModel) {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete úkol smazat? Jeho obnovení nebude možné!',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.ukolService.delete(this.akceSchema, ukol.ukon.id, ukol.id).then(() => {
          this.ukolList.list = _.without(this.ukolList.list, ukol);
          this.ukolList.itemCount--;
          this.updated.emit(true);
        });
      }
      sub.unsubscribe();
    });
  }

  onLoadNextUkoly() {
    this.ukolList.filter.offset += this.ukolList.filter.limit;
    this.listService.fetchResult(this.ukolList, true);
  }

  onCanScrollTop(scrollbarEvent: PerfectScrollbarEvent) {
    this.scrollTop = scrollbarEvent.scrollTop;
  }

  getFilteredUkoly(isToday: boolean): ListModel<UkolModel> {
    const date = new Date();
    const options = {timeZone: 'Europe/Prague', hour12: false, year: 'numeric', month: '2-digit', day: '2-digit'};
    let today = date.toLocaleString('en-GB', options).replace(/\//g, '-').split(',')[0];
    today = today.split('-').reverse().join('-');

    const filteredList = this.ukolList.list.filter((ukol: UkolModel) => {
      return isToday ? ukol.termin === today : ukol.termin !== today;
    });
    return {
      ...this.ukolList,
      list: filteredList,
      itemCount: filteredList.length
    };
  }

  getName(ukol: UkolModel): string {
    const userIdText: string = this.authService.getUser().idText;
    if (ukol.urcenPro.idText === userIdText && ukol.autorUkolu.idText === userIdText) {
      return "můj úkol";
    }

    return "Úkol zadal(a) " + ukol.autorUkolu.name;
  }

  onFilterChanged() {
    return this.listService.fetchResult(this.ukolList);
  }

  checkCancelled() {
    return this.ukolList.filter.filters.cancelled !== false;
  }

  onCancelledCheckboxClicked() {
    if (this.checkCancelled()) {
      this.ukolList.filter.filters.cancelled = false;
    } else {
      delete this.ukolList.filter.filters.cancelled;
    }
    this.onFilterChanged();
  }

  private scrollTop: () => void = () => {
  };

  private cleanResult(result: any) {
    Object.keys(this.defaultData).forEach(key => delete result[key]);
  }

}
