<ng-template #layers>
  <ul *ngFor="let l of map?.getLayers().getArray()">
    <li (click)="l.setVisible(!l.getVisible())">
      <span>
        <div class="switch">
          <span class="selector">
            <span *ngIf="!l.getVisible()" class="fa fa-plus"></span>
            <span *ngIf="l.getVisible()" class="icon">
              <img *ngIf="l.get('icon')" src="{{ l.get('icon') }}"/>
              <span *ngIf="!l.get('icon')" class="col-rect" [ngStyle]="{ border: '1px solid ' + l.get('color'), background:  l.get('color') + '50' }"></span>
            </span>
          </span>
          <span class="title">{{ l.get('name') }}</span>
        </div>
      </span>
    </li>
  </ul>
</ng-template>

<div class="mt-20">
  <div class="flexbox-container">
    <div *ngIf="map" class="map-layer-selector">
      <div class="flexbox-container">
        <gmt-button *ngIf="!drawing && !modifying" [clickAction]="onDrawStart">Začít kreslit</gmt-button>
        <gmt-button *ngIf="!modifying && !drawing" [clickAction]="onModifyStart"><i class="fa fa-pencil"></i></gmt-button>
        <gmt-button *ngIf="!modifying && !drawing" [clickAction]="onClear" class="red"><i class="fa fa-trash-o"></i></gmt-button>
        <gmt-button *ngIf="drawing || modifying" [clickAction]="onActionStop" class="red"><i class="fa fa-times"></i></gmt-button>
      </div>
      <div class="mt-10">
        <div class="group">
          <ng-container *ngTemplateOutlet="layers, context:{group: 'base'}"></ng-container>
        </div>
      </div>
    </div>
    <div id="map"></div>
  </div>
</div>
