import {Inject, Injectable} from '@angular/core';
import {UkonCreateModel} from "@app/doss/models/ukon-create.model";
import {UkonModel} from "@app/doss/models/ukon.model";
import {UkonStavStatisticsModel} from '../models/ukon-stav-statistics.model';
import {PropojeniUkonuId, PropojeniUkonuModel} from "@app/doss/models/propojeni-ukonu.model";
import {PropojitelnyUkonModel} from "@app/doss/models/propojitelny-ukon.model";
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class UkonService {

  constructor(
    private restangular: Restangular,
  ) {
  }

  create(akceKey: string, ukon: UkonCreateModel): Promise<UkonModel> {
    return this.restangular.all(`ukon/create`)
      .post(ukon)
      .toPromise();
  }

  getById(akceKey: string, ukonId: number): Promise<UkonModel> {
    return this.restangular.one(`ukon`, ukonId)
      .get()
      .toPromise();
  }

  update(akceKey: string, ukon: UkonCreateModel): Promise<UkonModel> {
    return this.restangular.one(`ukon/update/ukon`, ukon.id)
      .customPUT(ukon)
      .toPromise();
  }

  getStavyUkonu(akceKey: string, ukonId: number): Promise<any> {
    return this.restangular.one(`ukon/${ukonId}/stavy`)
      .get()
      .toPromise();
  }

  updateStavUkonu(akceKey: string, ukonId: number, stavUkonuUpdate: FormData): Promise<UkonModel> {
    return this.restangular.one(`ukon/${ukonId}/stav/update`)
      .customPOST(stavUkonuUpdate)
      .toPromise();
  }

  stavUkonuGoBack(akceKey: string, ukonId: number): Promise<UkonModel> {
    return this.restangular.one(`ukon/${ukonId}/stav/goBack`)
      .customPUT()
      .toPromise();
  }

  cancel(akceKey: string, ukonId: number): Promise<UkonModel> {
    return this.restangular.one(`ukon/${ukonId}/stav/cancel`)
      .customPUT()
      .toPromise();
  }

  getPropojitelneUkony(akceKey: string, ukonId: number): Promise<PropojitelnyUkonModel[]> {
    return this.restangular.all(`ukon/${ukonId}/propojeni/propojitelne`)
      .getList()
      .toPromise();
  }

  getPropojeniUkonu(ukonId: number): Promise<PropojeniUkonuModel[]> {
    return this.restangular.all(`ukon/${ukonId}/propojeni/list`)
      .getList()
      .toPromise();
  }

  createPropojeniUkonu(akceKey: string, ukonId: number, propojeniUkonuCreate: any): Promise<PropojeniUkonuModel[]> {
    return this.restangular.all(`ukon/${ukonId}/propojeni/create`)
      .post(propojeniUkonuCreate)
      .toPromise();
  }

  updatePropojeniUkonu(akceKey: string, ukonId: number, propojeniUkonu: PropojeniUkonuModel): Promise<PropojeniUkonuModel> {
    return this.restangular.one(`ukon/${ukonId}/propojeni/${propojeniUkonu.id.sourceUkonId}`, propojeniUkonu.id.targetUkonId)
      .customPUT(propojeniUkonu)
      .toPromise();
  }

  removePropojeniUkonu(akceKey: string, ukonId: number, propojeniUkonuId: PropojeniUkonuId): Promise<PropojeniUkonuModel> {
    return this.restangular.one(`ukon/${ukonId}/propojeni/${propojeniUkonuId.sourceUkonId}`, propojeniUkonuId.targetUkonId)
      .remove()
      .toPromise();
  }

  createOblibenyUkon(akceKey: string, ukonId: number): Promise<UkonModel> {
    return this.restangular.all(`ukon/${ukonId}/oblibeny/create`)
      .post()
      .toPromise();
  }

  getListForProjectOverview(akceKey: string): Promise<UkonStavStatisticsModel> {
    return this.restangular.one(`ukon/list/overview`)
      .get()
      .toPromise();
  }
}
