import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {AkceModel} from '@app/doss/models/akce.model';
import {Feature} from "ol";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import {GeoJSON} from "ol/format";

@Component({
  selector: 'second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.scss']
})
export class SecondStepComponent implements OnInit {
  @Input()
  akce: AkceModel;

  constructor(protected cdr: ChangeDetectorRef,
              @Inject(RESTANGULAR_DOSS) public restangularDoss: any) {
    this.getEntityByGeometry = this.getEntityByGeometry.bind(this);
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  async getEntityByGeometry(): Promise<boolean> {
    this.akce.lokalizaceStavby = await this.restangularDoss.all('geopas/entityByGeometry')
      .post(this.akce.souradnice).toPromise();
    return !!this.akce.lokalizaceStavby;
  }

  isValid(): boolean {
    return !!this.akce && !!this.akce.souradnice;
  }

  polygonChanged(features: Array<Feature>): void {
    if (ValidationUtils.isNonEmptyArray(features)) {
      this.akce.souradnice = new GeoJSON().writeFeatures(features, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
    } else {
      this.akce.souradnice = null;
    }
  }
}
