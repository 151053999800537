import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "@app/common/services/auth.service";
import {UkonService} from "@app/doss/services/ukon.service";
import {UkonModel} from "@app/doss/models/ukon.model";
import {DialogService} from "@app/common/services/dialog.service";
import {UkolCreateComponent} from "@app/doss/components/ukol-create/ukol-create.component";
import {ClassName} from "@app/common/enums/class-name.enum";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {SouladSPdComponent} from "@app/doss/components/soulad-s-pd/soulad-s-pd.component";
import {StavUkonuUtils} from "@app/doss/utils/stav-ukonu.utils";
import {TabModel} from "@app/common/models/tab.model";
import {StateService} from "@uirouter/angular";
import {ListModel} from "@app/common/models/list.model";
import {ListService} from "@app/common/services/list.service";
import {DocumentModel} from "@app/common/models/document.model";
import {DocumentTypeModel} from "@app/common/models/document-type.model";
import {UkolModel} from "@app/doss/models/ukol.model";
import {NoteDossModel} from "@app/doss/models/note-doss-model";
import {UpozorneniDossModel} from "@app/doss/models/upozorneni-doss.model";
import {DotcenyOrganUtils} from '@app/doss/utils/dotceny-organ.utils';
import {PodrobneInformaceModel} from "@app/doss/models/podrobne-informace.model";
import {StavUkonuModel} from "@app/doss/models/stav-ukonu.model";
import {PropojeniUkonuModel} from "@app/doss/models/propojeni-ukonu.model";
import {PropojeniUkonuFormComponent} from "@app/doss/components/propojeni-ukonu-create/propojeni-ukonu-form.component";
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import * as _ from "lodash";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './ukon-detail.component.html',
  styleUrls: ['./ukon-detail.component.scss']
})
export class UkonDetailComponent implements OnInit {
  helpIds = HelpService.HELP_IDS;
  @Input()
  id: number;

  akceSchema: string;
  ukon: UkonModel;

  loaded: boolean = false;

  statusActions: any[];
  previousState: any;

  tabs: TabModel[];
  tab: string;

  noteList: ListModel<NoteDossModel>;
  historyList: ListModel<NoteDossModel>;
  documentList: ListModel<DocumentModel>;
  defaultDocumentType: DocumentTypeModel;
  ukolList: ListModel<UkolModel>;
  podrobneInformaceList: ListModel<PodrobneInformaceModel>;
  upozorneniList: ListModel<UpozorneniDossModel>;
  propojeniUkonuList: PropojeniUkonuModel[];

  upozorneniCounts = {archived: undefined, unarchived: undefined};
  protected readonly DotcenyOrganUtils = DotcenyOrganUtils;

  private readonly ZRUSENI_UKONU = 'ZRUSENI_UKONU';

  constructor(private authService: AuthService,
              private dialogService: DialogService,
              private ukonService: UkonService,
              private stateService: StateService,
              private listService: ListService) {
    this.uiOnParamsChanged(this.stateService.params);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
    this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
    this.onUpdateUkolList = this.onUpdateUkolList.bind(this);
    this.onUpdateUpozorneni = this.onUpdateUpozorneni.bind(this);
    this.onUpdateHistoryList = this.onUpdateHistoryList.bind(this);
  }

  async ngOnInit() {
    this.defaultDocumentType = DocumentTypeModel.getDefaultDOSSDocumentType();
    this.akceSchema = this.authService.getActualProject().schema;
    this.tabs = this.getTabs();
    await this.loadUkon();
    await this.loadStavyUkonu();
    this.loadNotes();
    this.loadDocuments();
    this.loadUkoly();
    this.loadUpozorneni();
    this.loadPodrobneInformace();
    this.loadHistory();
    this.loadPropojeniUkonu();

    this.loaded = true;
  }

  canManipulate() {
    return this.canEdit() || this.canCreate();
  }

  canEdit(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  canCreate(): boolean {
    return this.authService.hasPermissionForDossAction('manage_tasks');
  }

  onUkolCreate() {
    const dialog = this.dialogService.open(UkolCreateComponent, {
      data: {
        schema: this.akceSchema,
        ukonId: this.id,
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (result: boolean) => {
      if (result) {
        this.loaded = false;
        await this.loadUkon();
        await this.loadUkoly();
        await this.loadHistory();
        this.loaded = true;
      }
      sub.unsubscribe();
    });
  }

  onUpdateSouladSPd() {
    const dialog = this.dialogService.open(SouladSPdComponent, {
      data: {
        schema: this.akceSchema,
        ukon: this.ukon,
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (result: any) => {
      if (result) {
        this.loaded = false;
        await this.loadUkon();
        await this.loadStavyUkonu();
        await this.loadHistory();
        this.loaded = true;
      }
      sub.unsubscribe();
    });
  }

  isStavUkonuPriprava(): boolean {
    return this.ukon.stavUkonu.stav.id === "PRIPRAVA";
  }

  backStatus() {
    if (this.loaded) {
      return this.dialogService.confirmDialogPromise('Opravdu vrátit úkon do předcházejícího stavu?')
        .then((data) => {
          if (data === true) {
            this.loaded = false;
            this.ukonService.stavUkonuGoBack(this.akceSchema, this.id)
              .then(async () => await this.stateService.reload()
              );
          }

          this.loaded = true;
        });
    }
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab || 'poznamky';
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'poznamky');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
    this.loadHistory()
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'dokumenty');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
    this.loadHistory()
  }

  onUpdateUkolList() {
    const ukolTab = this.tabs.find((tab) => tab.id === 'ukoly');
    ukolTab.name = `Úkoly (${this.ukolList.list.length})`;
    this.loadHistory()
  }

  updateUpozorneniTab() {
    const upozorneniTab = this.tabs.find((tab) => tab.id === 'upozorneni');
    if (this.upozorneniCounts.unarchived !== undefined && this.upozorneniCounts.archived !== undefined) {
      upozorneniTab.name = `Upozornění (${this.upozorneniCounts.unarchived}/${this.upozorneniCounts.archived})`;
    } else {
      upozorneniTab.name = 'Upozornění';
    }
  }

  onUpdateUpozorneni() {
    const filter = {
      limit: 1,
      filters: {
        ukonId: this.ukon.id,
        archive: false
      }
    };

    const archivedFilter = {...filter, filters: {...filter.filters, archive: true}};
    const upozorneniList = this.listService.createList('notifications', filter);
    const upozorneniListArchived = this.listService.createList('notifications', archivedFilter);

    this.listService.fetchResult(upozorneniList).then(data => {
      this.upozorneniCounts.unarchived = data.itemCount;
      this.updateUpozorneniTab();
    });

    this.listService.fetchResult(upozorneniListArchived).then(data => {
      this.upozorneniCounts.archived = data.itemCount;
      this.updateUpozorneniTab();
    });
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'historie');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  isZruseniUkonu(stavUkonu: StavUkonuModel): boolean {
    return stavUkonu.id === this.ZRUSENI_UKONU;
  }

  cancel() {
    return this.dialogService.confirmDialogPromise('Opravdu chcete úkon zrušit?')
      .then(async (data) => {
        if (data === true) {
          await this.ukonService.cancel(this.akceSchema, this.id).then(() => this.stateService.reload());
        }
      });
  }

  loadHistory() {
    this.historyList = this.listService.createList(
      `ukon/${this.ukon.id}/historie`,
      {
        filters: {projectInfo: true, systemType: false, cancelled: false, ukonId: this.ukon.id},
        sortOrder: {sortBy: 'datumPridani', direction: 'desc'}
      },
    );
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private loadUkon(): Promise<UkonModel> {
    return this.ukonService.getById(this.akceSchema, this.id).then(ukon => this.ukon = ukon);
  }

  private loadStavyUkonu(): Promise<void> {
    return this.ukonService.getStavyUkonu(this.akceSchema, this.id).then(states => {
      this.previousState = StavUkonuUtils.getStavAndFazeUkonuDesc(states.previousState);
      this.statusActions = states.nextStates.items.map(nextStep => {
        return {
          key: nextStep.key,
          click: this.isZruseniUkonu(nextStep) ? () => this.cancel() : undefined,
          icon: this.getNextStepIcon(nextStep),
          menuName: nextStep.menuName ? nextStep.menuName : nextStep.id,
          title: nextStep.title ? nextStep.title : nextStep.menuName,
          fazeUkonu: nextStep.faze || []
        }
      });
    });
  }

  private getNextStepIcon(nextStep: any): string {
    if (this.isZruseniUkonu(nextStep)) {
      return 'fa-remove';
    }
    if (!ValidationUtils.isNonEmptyArray(nextStep.fazeUkonu)) {
      return 'fa-plus-square';
    }
    return '';
  }

  private getTabs(): TabModel[] {
    return [
      {
        name: 'Poznámky',
        id: 'poznamky',
        href: 'doss.akce.data.ukony.detail',
        urlParams: {tab: 'poznamky'},
      },
      {
        name: 'Úkoly',
        id: 'ukoly',
        href: 'doss.akce.data.ukony.detail',
        urlParams: {tab: 'ukoly'},
      },
      {
        name: 'Propojení',
        id: 'propojeni',
        href: 'doss.akce.data.ukony.detail',
        urlParams: {tab: 'propojeni'},
      },
      {
        name: 'Podrobné informace',
        id: 'podrobne-info',
        href: 'doss.akce.data.ukony.detail',
        urlParams: {tab: 'podrobne-info'},
      },
      {
        name: 'Historie',
        id: 'historie',
        href: 'doss.akce.data.ukony.detail',
        urlParams: {tab: 'historie'},
      },
      {
        name: 'Upozornění',
        id: 'upozorneni',
        href: 'doss.akce.data.ukony.detail',
        urlParams: {tab: 'upozorneni'},
      },
      {
        name: 'Dokumenty',
        id: 'dokumenty',
        href: 'doss.akce.data.ukony.detail',
        urlParams: {tab: 'dokumenty'},
      },
    ];
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      `ukon/${this.ukon.id}/notes`,
      {
        filters: {projectInfo: true, systemType: false, cancelled: false, ukonId: this.ukon.id},
        sortOrder: {sortBy: 'datumPridani', direction: 'desc'}
      },
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  private loadUkoly() {
    this.ukolList = this.listService.createList(
      `ukon/${this.ukon.id}/ukol`,
      {
        filters: {
          projectInfo: true,
          systemType: false,
          cancelled: false,
          ukonId: this.ukon.id,
          user: this.authService.getUser().username
        },
        sortOrder: {sortBy: 'termin', direction: 'desc'}
      },
    );
    this.listService.fetchResult(this.ukolList).then(() => {
      this.onUpdateUkolList();
    });
  }

  private loadPodrobneInformace() {
    this.podrobneInformaceList = this.listService.createList(
      `ukon/${this.ukon.id}/podrobne-informace`,
      {
        filters: {
          ukonId: this.ukon.id,
        },
        sortOrder: {sortBy: 'datumZmeny', direction: 'asc'}
      },
    );
    this.listService.fetchResult(this.podrobneInformaceList).then(() => {
    });
  }

  private loadDocuments() {
    this.documentList = this.listService.createList(
      `attachments`,
      {
        limit: null,
        filters: {ukonId: this.ukon.id, cancelled: false},
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}
      },
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }


  private loadUpozorneni() {
    this.upozorneniList = this.listService.createList(
      'notifications',
      {
        filters: {
          ukonId: this.ukon.id,
          archive: false
        },
        sortOrder: {sortBy: 'casOdeslani', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.upozorneniList).then(() => {
      this.onUpdateUpozorneni();
    });
  }

  isPast(datum: string) {
    const date = new Date(datum);
    date.setHours(0,0,0,0);

    const now = new Date();
    now.setHours(0,0,0,0);
    return date.getTime() - now.getTime() < 0;
  }

  handlePropojeniUkonuDialog(propojeniUkonu?: PropojeniUkonuModel) {
    const dialog = this.dialogService.open(PropojeniUkonuFormComponent, {
      data: {
        schema: this.akceSchema,
        ukonId: this.id,
        propojeniUkonu: _.cloneDeep(propojeniUkonu),
      },
      className: ClassName.HIGHER_DIALOG,
    });
    const sub = dialog.afterClosed.subscribe(async (result: boolean) => {
      if (result) {
        this.loaded = false;
        await this.loadPropojeniUkonu();
        this.loaded = true;
      }
      sub.unsubscribe();
    });
  }

  handleDeletePropojeniUkonu(propojeniUkonu: PropojeniUkonuModel) {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete propojení smazat?',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.ukonService.removePropojeniUkonu(this.akceSchema, this.id, propojeniUkonu.id)
          .then(async () => {
            this.loaded = false;
            await this.loadPropojeniUkonu();
            this.loaded = true;
          });
      }
      sub.unsubscribe();
    });
  }

  private async loadPropojeniUkonu() {
    return this.ukonService.getPropojeniUkonu(this.id)
      .then(propojeni => {
        this.propojeniUkonuList = propojeni;
        const tab = this.tabs.find((tab) => tab.id === 'propojeni');
        if (propojeni.length) {
          tab.name = `Propojení (${propojeni.length})`;
        } else {
          tab.name = 'Propojení';
        }
      });
  }

  canLink() {
    return this.authService.hasPermissionForDossAction('manage_actions')
  }
}
