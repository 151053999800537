import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {OpenHoursModel} from "@app/doss/models/open-hours.model";

@Pipe({name: 'openHour'})
@Injectable({
  providedIn: 'root'
})
export class OpenHourPipe implements PipeTransform {

  transform(openHour: OpenHoursModel): string {
    return `${this.getDayDesc(openHour.day)}: ${openHour.from} - ${openHour.to}`;
  }

  private getDayDesc(day: string) {
    switch (day) {
      case "1":
        return "PO";
      case "2":
        return "UT";
      case "3":
        return "ST";
      case "4":
        return "CT";
      case "5":
        return "PA";
      case "6":
        return "SO";
      case "7":
        return "NE";
    }
  }
}
