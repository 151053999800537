import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ListModel} from '@app/common/models/list.model';
import {TableServiceDoss} from "@app/doss/services/table-service-doss.service";
import {VerzePdModel} from "@app/doss/models/verze-pd.model";
import {VerzePdFormComponent} from "@app/doss/components/verze-pd-form/verze-pd-form.component";
import {ClassName} from "@app/common/enums/class-name.enum";
import {NoteModel} from "@app/common/models/note.model";
import {DialogService} from "@app/common/services/dialog.service";
import {AuthService} from "@app/common/services/auth.service";
import * as _ from "lodash";
import { HelpService } from '@app/common/services/help.service';

@Component({
  selector: 'table-verze-pd',
  templateUrl: './table-verze-pd.component.html',
  styleUrls: ['./table-verze-pd.component.scss']
})
export class TableVerzePdComponent {
  helpIds = HelpService.HELP_IDS;
  @Input() list: ListModel<VerzePdModel>;
  @Input() columnNames: any;
  @Input() showNavIcons = false;
  @Input() akceSchema: string;

  @Output() sort: EventEmitter<{}> = new EventEmitter();
  @Output() reload: EventEmitter<{}> = new EventEmitter();

  private readonly TABLE_ID = 'verzePd';

  constructor(private tableService: TableServiceDoss,
              private dialogService: DialogService,
              private authService: AuthService) {
  }

  onSort(column: string, ascOrDesc: string) {
    this.sort.emit({
      column,
      ascOrDesc,
    });
  }

  canEdit(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  getColumnName(columnId: string) {
    if (this.columnNames && this.columnNames[columnId]) {
      return this.columnNames[columnId];
    }
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }

  onEditVerzePd(verzePd: VerzePdModel) {
    const dialog = this.dialogService.open(VerzePdFormComponent, {
      data: {
        akceSchema: this.akceSchema,
        verzePd: _.cloneDeep(verzePd),
        isEdit: true
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (result: NoteModel | boolean) => {
      if (result) {
        this.reload.emit();
      }
      sub.unsubscribe();
    });
  }
}
