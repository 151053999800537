import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {DotcenyOrganModel} from "@app/doss/models/dotceny-organ.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'dotcenyOrgan'})
@Injectable({
  providedIn: 'root'
})
export class DotcenyOrganPipe implements PipeTransform {

  transform(dotcenyOrgan: DotcenyOrganModel): string {
    return !dotcenyOrgan ? "" : `${dotcenyOrgan.nazev}`;
  }
}
