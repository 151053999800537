<h1 class="centered-text">{{ ukol?.id ? 'Editace ukolu' : 'Nový úkol' }}</h1>

<fieldset>
  <div class="clearfix">
    <div class="column-100">
      <gmt-input *ngIf="!ukol?.id">
        <div style="display: flex; justify-content: center;">
          <gmt-switch (select)="handleSelectOption($event)" [options]="options"
                      [selected]="currentOption"></gmt-switch>
        </div>
      </gmt-input>

      <gmt-input label="Úkol" required="true">
        <input [(ngModel)]="ukol.nazev" type="text"/>
      </gmt-input>

      <gmt-input label="Termín" required="true">
        <input [(ngModel)]="ukol.termin" pick-a-date type="text"/>
      </gmt-input>

      <gmt-input label="Připomenout">
        <input [(ngModel)]="ukol.pripomenout" pick-a-date type="text"/>
      </gmt-input>

      <div *ngIf="currentOption.id === 'ukol-pro-kolegu'">
        <gmt-input label="Úkol pro kolegu" required="true">
          <gmt-select
            [data]="ukol"
            [restangularService]="restangularSettings"
            resource="users/list"
            [filter]="defaultFilter"
            field="urcenPro"
            [itemPipe]="usernamePipe"
          ></gmt-select>
        </gmt-input>
      </div>
    </div>
  </div>
</fieldset>

<div class="actions">
  <gmt-button [clickAction]="createUkol" [gmtDisabled]="!isValid()">ULOŽIT ZMĚNY</gmt-button>
</div>
