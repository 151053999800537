import {Component, OnInit} from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { UkonStavStatisticsModel } from '@app/doss/models/ukon-stav-statistics.model';
import { UkonService } from '@app/doss/services/ukon.service';

@Component({
    selector: 'akce-ukon-statistics',
  templateUrl: './akce-ukon-statistics.component.html',
  styleUrls: ['./akce-ukon-statistics.component.scss']
})
export class AkceUkonStatisticsComponent implements OnInit {
  akceSchema: string;
  ukonSectionCollapsed: boolean;
  ukonStavStatistics: UkonStavStatisticsModel;
  loading: boolean;

  categoryTabs: any[] = [
    {
      name: 'Celkový počet evidovaných úkonů',
      value: 0
    },
    {
      name: 'Nezahájeno',
      value: 0,
      total: '0%',
      filter: undefined
    },
    {
      name: 'V řešení',
      value: 0,
      total: '0%',
      filter: undefined
    },
    {
      name: 'Ukončeno',
      value: 0,
      total: '0%',
      filter: undefined
    },
    {
      name: 'Zastaveno, vydáno záporně',
      value: 0,
      total: '0%',
      filter: undefined
    },
  ];

  filterNezahajeno = ["PRIPRAVA"];

  filterVReseni = [
    "ODESLANI_ZADOSTI",
    "DORUCENI_ZADOSTI",
    "POSTOUPENI_ZADOSTI_JINEMU_DOSS",
    "RIZENI_UKONU_NEZVOLENO",
    "RIZENI_UKONU_OZNAMENI_O_ZAHAJENI_RIZENI",
    "RIZENI_UKONU_ZPETVZETI_ZADOSTI",
    "RIZENI_UKONU_PROJEDNANI_NAMITEK",
    "RIZENI_UKONU_USTNI_JEDNANI",
    "RIZENI_UKONU_MISTNI_SETRENI",
    "RIZENI_UKONU_SPOJENE_USTNI_JEDNANI_A_MISTNI_SETRENI",
    "RIZENI_UKONU_POZADAVEK_NA_DOPLNENI_BEZ_PRERUSENI_RIZENI",
    "RIZENI_UKONU_POZADAVEK_NA_DOPLNENI_BEZ_PRERUSENI",
    "RIZENI_UKONU_PRERUSENI_VYZVA_K_DOPLNENI",
    "RIZENI_UKONU_PRODLOUZENI_LHUTY_K_DOPLNENI",
    "RIZENI_UKONU_URGENCE",
    "RIZENI_UKONU_PODNET_NA_NECINNOST",
    "ODVOLANI",
    "POSTOUPENI_SPISU_ODVOLACIMU_ORGANU",
    "OZNAMENI_O_ZAHAJENI_RIZENI_O_ODVOLANI",
    "ROZHODNUTI_O_ODVOLANI",
    "MIMORADNE_OPRAVNE_PROSTREDKY"
  ];

  filterUkonceno = [
    "NABYTI_PRAVNI_MOCI",
    "ROZHODNUTI_ZMENENO_ODVOLACIM_ORGANEM",
    "POTVRZENI_PUVODNIHO_ROZHODNUTI",
    "VYDANI_ROZHODNUTI",
    "VYDANO_BEZ_PODMINEK",
    "VYDANO_S_PODMINKOU",
    "FIKCE_SOUHLASU",
    "ZAMITNUTI_ZADOSTI",
    "ZASTAVENI_RIZENI",
    "ZAMITNUTI_ODVOLANI",
    "ZASTAVENI_ODVOLACIHO_RIZENI",
    "ROZHODNUTI_ZRUSENO_RIZENI_O_ODVOLANI_ZASTAVENO",
    "VYDANO_ZAPORNE",
    "ZASTAVENO"
  ];

  filterZastaveno = [
    "ZAMITNUTI_ZADOSTI",
    "ZASTAVENI_RIZENI",
    "ZAMITNUTI_ODVOLANI",
    "ZASTAVENI_ODVOLACIHO_RIZENI",
    "ROZHODNUTI_ZRUSENO_RIZENI_O_ODVOLANI_ZASTAVENO",
    "VYDANO_ZAPORNE",
    "ZASTAVENO"
  ];

  constructor(private authService: AuthService,
            private ukonService: UkonService,
            private errorHandlerService: ErrorHandlerService) {
  }

  async ngOnInit() {
    const collapsed = localStorage.getItem('ukonSectionCollapsed');
    this.ukonSectionCollapsed = collapsed ? JSON.parse(collapsed) : false;
    this.akceSchema = this.authService.getActualProject().schema;
    await this.loadUkonList();
  }

  onSectionCollapse(name: string) {
    const collapsed = this[name];
    this[name] = !collapsed;
    localStorage.setItem('ukonSectionCollapsed', JSON.stringify(this[name]));
  }

  private async loadUkonList(): Promise<any> {
    try {
        this.loading = true;
        this.ukonStavStatistics = await this.ukonService.getListForProjectOverview(this.akceSchema);
        this.updateCategoryTabs();
      } catch (e) {
        this.errorHandlerService.get(e);
      } finally {
        this.loading = false;
      }
  }

  private updateCategoryTabs(): void {
    if (!this.ukonStavStatistics) {
      return;
    }
    const { itemCount, nezahajenoCount, vreseniCount, ukoncenoCount, zastavenoCount } = this.ukonStavStatistics;

    this.categoryTabs[0].value = itemCount;

    if (itemCount > 0) {
      this.categoryTabs[1].value = nezahajenoCount;
      this.categoryTabs[1].total = ((nezahajenoCount / itemCount) * 100).toFixed(0) + '%';
      this.categoryTabs[1].filter = this.filterNezahajeno;

      this.categoryTabs[2].value = vreseniCount;
      this.categoryTabs[2].total = ((vreseniCount / itemCount) * 100).toFixed(0) + '%';
      this.categoryTabs[2].filter = this.filterVReseni;

      this.categoryTabs[3].value = ukoncenoCount;
      this.categoryTabs[3].total = ((ukoncenoCount / itemCount) * 100).toFixed(0) + '%';
      this.categoryTabs[3].filter = this.filterUkonceno;

      this.categoryTabs[4].value = zastavenoCount;
      this.categoryTabs[4].total = ((zastavenoCount / itemCount) * 100).toFixed(0) + '%';
      this.categoryTabs[4].filter = this.filterZastaveno;
    } else {
      this.categoryTabs[1].total = '0%';
      this.categoryTabs[2].total = '0%';
      this.categoryTabs[3].total = '0%';
      this.categoryTabs[4].total = '0%';
    }
  }
}
