import {Component, OnInit} from '@angular/core';
import {AkceModel} from "@app/doss/models/akce.model";
import {AuthService} from "@app/common/services/auth.service";
import {TabModel} from "@app/common/models/tab.model";
import {ListModel} from "@app/common/models/list.model";
import {NoteModel} from "@app/common/models/note.model";
import {ListService} from "@app/common/services/list.service";
import {StateService} from "@uirouter/angular";
import {DocumentModel} from "@app/common/models/document.model";
import {DocumentTypeModel} from "@app/common/models/document-type.model";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './akce-info.component.html',
  styleUrls: ['./akce-info.component.scss']
})
export class AkceInfoComponent implements OnInit {
  loaded: boolean = false;
  akce: AkceModel = <AkceModel>{};
  noteList: ListModel<NoteModel>;
  documentList: ListModel<DocumentModel>;
  defaultDocumentType: DocumentTypeModel;
  helpIds = HelpService.HELP_IDS;

  tab: string;
  tabs: TabModel[];

  constructor(private listService: ListService,
              public authService: AuthService,
              private stateService: StateService) {
    this.uiOnParamsChanged(this.stateService.params);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
    this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
  }

  async ngOnInit() {
    this.defaultDocumentType = DocumentTypeModel.getDefaultDOSSDocumentType();
    this.tabs = this.getTabs();
    this.akce = this.authService.getActualProject();
    this.loadNotes();
    this.loadDocuments();
    this.loaded = true;
  }

  canEdit() {
    return this.authService.hasPermissionOnProject('admin', 'doss_default') || this.authService.hasPermissionOnProject('admin', this.akce.schema);
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    if (changedParams.tab !== undefined) {
      this.tab = changedParams.tab || 'poznamky';
    }
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'poznamky');
    noteTab.name = `Poznámky ke stavbě (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'dokumenty');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: {projectInfo: true, systemType: false, cancelled: false},
        sortOrder: {sortBy: 'datumPridani', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  private loadDocuments() {
    this.documentList = this.listService.createList(
      `attachments`,
      {
        limit: null,
        filters: {projectInfo: true, cancelled: false, ukonType: false},
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}
      },
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }


  private getTabs(): TabModel[] {
    return [
      {
        name: 'Poznámky ke stavbě',
        id: 'poznamky',
        href: 'doss.akce.data.detail',
        urlParams: {tab: 'poznamky'},
      },
      {
        name: 'Dokumenty',
        id: 'dokumenty',
        href: 'doss.akce.data.detail',
        urlParams: {tab: 'dokumenty'},
      },
    ];
  }
}
