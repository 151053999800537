import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'stavFazeUkonuFilter'})
@Injectable({
  providedIn: 'root'
})
export class StavFazeUkonuFilterPipe implements PipeTransform {

  transform(stavFazeUkonuFilter: any): string {
    return !stavFazeUkonuFilter ? "" : `${stavFazeUkonuFilter.description}`;
  }
}
