import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {FazeUkonuModel} from "@app/doss/models/faze-ukonu.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'fazeUkonu'})
@Injectable({
  providedIn: 'root'
})
export class FazeUkonuPipe implements PipeTransform {

  transform(fazeUkonu: FazeUkonuModel): string {
    return !fazeUkonu ? "" : `${fazeUkonu.menuName || fazeUkonu.id}`;
  }
}
