export class DotcenyOrganUtils {
  static getDotcenyOrganDescription(ukon: any): string {
    if (!ukon) {
      return "";
    }

    let ukonDescription = ukon.dotcenyOrgan.nazev;

    if (ukon.pracoviste) {
      ukonDescription += `, pracoviště ${ukon.pracoviste.nazev}`;
    }
    if (ukon.odbor) {
      ukonDescription += ` (${ukon.odbor.nazev})`;
    }

    return ukonDescription;
  }
}
