import {Component, Inject, Input, OnInit} from '@angular/core';
import {RESTANGULAR_DOSS} from '@app/common/services/restangular-doss.service';
import {UkonModel} from "@app/doss/models/ukon.model";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";
import {TypUkonuModel} from "@app/doss/models/typ-ukonu.model";
import {KategorieDossPipe} from "@app/doss/pipes/kategorie-doss.pipe";
import {TypUkonuPipe} from "@app/doss/pipes/typ-ukonu.pipe";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Component({
  selector: 'ukon-form',
  templateUrl: './ukon-form.component.html',
  styleUrls: ['./ukon-form.component.scss']
})
export class UkonFormComponent implements OnInit {
  @Input()
  redirectToPageAfterDossSave: string;
  @Input()
  redirectToPageParamsAfterDossSave: any;
  @Input()
  ukon: UkonModel;

  @Input()
  isUpdate: boolean = false;

  kategorieDoss: KategorieDossModel[] = [];
  typyUkonu: TypUkonuModel[] = [];

  constructor(@Inject(RESTANGULAR_DOSS) public restangularDoss: any,
              public kategorieDossPipe: KategorieDossPipe,
              public typUkonuPipe: TypUkonuPipe) {
  }

  async ngOnInit() {
    await this.loadTypUkonu();
    await this.loadKategorieDoss();
  }

  isValid(): boolean {
    return !!this.ukon && !!this.ukon.dotcenyOrgan && ValidationUtils.isNonEmptyArray(this.ukon.kategorieDoss) && !!this.ukon.typUkonu && !!this.ukon.naZakladeUstanoveni;
  }

  private loadKategorieDoss(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/kategorieDoss')
      .getList().toPromise()
      .then(data => this.kategorieDoss = data);
  }

  private loadTypUkonu(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/typUkonu')
      .getList().toPromise()
      .then(data => this.typyUkonu = data);
  }
}
