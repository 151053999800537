<gmt-input label="Dotčený orgán" required="true">
  <gmt-select
    [data]="ukon"
    [restangularService]="restangular"
    [itemPipe]="dotcenyOrganPipe"
    (changed)="onDotcenyOrganSelect($event.newValue)"
    field="dotcenyOrgan"
    required="true"
    resource="dotcenyOrgan/selected"
  ></gmt-select>
  <gmt-button [clickAction]="onDotcenyOrganCreate"><i class="fa fa-plus"></i></gmt-button>
</gmt-input>

<gmt-input label="Adresa" *ngIf="ukon.dotcenyOrgan">
  <div class="info">{{ (ukon?.dotcenyOrgan?.adresniMisto || {}) | address }}</div>
</gmt-input>

<gmt-input label="Odbor" *ngIf="ukon.dotcenyOrgan">
  <gmt-select
    [data]="ukon"
    [optionItems]="odbory"
    [itemPipe]="odborPipe"
    field="odbor"
  ></gmt-select>
  <gmt-button [clickAction]="onOdborCreate"><i class="fa fa-plus"></i></gmt-button>
</gmt-input>

<gmt-input label="Pracoviště" *ngIf="ukon.dotcenyOrgan">
  <gmt-select
    [data]="ukon"
    [optionItems]="pracoviste"
    [itemPipe]="pracovistePipe"
    field="pracoviste"
  ></gmt-select>
  <gmt-button [clickAction]="onPracovisteCreate"><i class="fa fa-plus"></i></gmt-button>
</gmt-input>

<gmt-input label="Kontakní osoba" *ngIf="ukon.dotcenyOrgan">
  <gmt-select
    [data]="ukon"
    [optionItems]="kontaktniOsoby"
    [itemPipe]="kontaktniOsobaPipe"
    field="kontaktniOsoba"
  ></gmt-select>
  <gmt-button [clickAction]="onKontaktniOsobaCreate"><i class="fa fa-plus"></i></gmt-button>
</gmt-input>
