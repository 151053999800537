<div class="info column-50 cell">
  <div>
    {{ pracoviste.nazev }}
    <a (click)="onPracovisteUpdate()" *ngIf="!pracoviste.fromGeopas && showEdit" class="mb-10">
      <i class="fa fa-pencil"></i>
    </a>
  </div>
  <div>{{ pracoviste.adresniMisto | address }}</div>
  <ng-container *ngIf="pracoviste.openHours">
    <div>Úřední hodiny:</div>
    <ng-container *ngFor="let openHour of pracoviste.openHours">
      <div>{{ openHour | openHour }}</div>
    </ng-container>
  </ng-container>
</div>
