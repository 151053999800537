import {Ng2StateDeclaration} from '@uirouter/angular';
import {VerzePdListComponent} from "@app/doss/components/verze-pd-list/verze-pd-list.component";

export const verzePdListState: Ng2StateDeclaration = {
  name: 'doss.akce.data.verzePd',
  url: '/verze-pd?page',
  data: {title: 'Verze projektové dokumentace'},
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
    openVerzePdCreateModal: null
  },
  views: {
    'content@doss': {
      component: VerzePdListComponent
    }
  }
};
