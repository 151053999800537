import { Component, DoCheck, Inject, KeyValueDiffers, OnChanges, OnInit } from '@angular/core';
import {StateService} from "@uirouter/angular";
import { saveAs } from 'file-saver';

import { ListModel } from '@app/common/models/list.model';

import { getOrder } from '../../pure/listLoadOrder';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ParcelNamePipe } from '@app/common/pipes/parcel-name.pipe';
import { RoadSectionNamePipe } from '@app/dimap/pipes/road-section-name.pipe';
import * as _ from 'lodash';
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpService } from "@app/common/services/http.service";

@Component({
  selector: 'property-status',
  templateUrl: './property-status.component.html',
  styleUrls: []
})
export class PropertyStatusComponent implements OnInit, DoCheck {

  requestID = this.stateService.params.requestid;
  listLoaded = false;
  groupBy;
  displaySections;
  checklistSelection;
  currentFilter: any = {};
  selectionSummary: any = {
    loading: true
  };
  exportLoading = false;
  exportLoadingXls = false;


  list: ListModel<any>;
  onListChangedPagination;
  differ;
  helpIds = HelpService.HELP_IDS;

  filterArea = {filters: {requestArea: {values: [true]}}, sortOrder: getOrder('areas/list')};
  filterSubject = {sortOrder: getOrder('subjects/list')};
  filterTitle = {sortOrder: getOrder('titles/list')};
  filterParcel = {sortOrder: getOrder('parcels/list')};
  filterRoadSection =  {sortOrder: getOrder('road-sections/list')};
  filterOpsubGroup = {sortOrder: getOrder('opsub-group/list')};
  filterDivisionType = {sortOrder: getOrder('division-types/list')};

  globalFilter = {
    areaId : {values: []},
    titleId : {values: []},
    managementAreaId: {values: []},
    subjectId : {values: []},
    parcelId : {values: []},
    roadId : {values: []},
    roadSectionId : {values: []},
    divisionTypeId : {values: []},
    ownerType : {values: []},
    opsubType : {values: []},
    opsubGroup : {values: []},
  };

  ownerTypes = [
    {name: 'Cizí', id: 'F'},
    {name: 'Vlastní', id: 'O'},
    {name: 'Ve spoluvlastnictví', id: 'S'}
  ];

  groups = [
    {value: 'Kraj', id: undefined},
    {value: 'Katastrální území', id: 'area'},
    {value: 'List vlastnictví', id: 'title'},
    {value: 'Parcely', id: 'parcel'},
    {value: 'Silnice', id: 'road'},
    {value: 'Úsek silnice', id: 'road_section'},
  ];

  opsubTypes = [
    {name: 'Fyzická osoba', id: 'OFO'},
    {name: 'Právnická osoba', id: 'OPO'},
    {name: 'Společné jmění manželů', id: 'BSM'}
  ];

  constructor(
    private restangular: Restangular,
    private localStorageService: LocalStorageService,
    public titleNamePipe: TitleNamePipe,
    public subjectNamePipe: SubjectNamePipe,
    public parcelNamePipe: ParcelNamePipe,
    public roadSectionNamePipe: RoadSectionNamePipe,
    private listLoadService: ListService,
    private stateService: StateService,
    private authService: AuthService,
    private differs: KeyValueDiffers,
    private httpService: HttpService,
  ) {

    this.listLoadServiceSetter = this.listLoadServiceSetter.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.needsToResetPage = this.needsToResetPage.bind(this);
    this.getActualPage = this.getActualPage.bind(this);
    this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
    this.onListChanged = this.onListChanged.bind(this);
    this.onListChangedSetterPagination = this.onListChangedSetterPagination.bind(this);
    this.fetchResults = this.fetchResults.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onUpdateFilterCriteria = this.onUpdateFilterCriteria.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
    this.updateSections = this.updateSections.bind(this);
    this.resetSelection = this.resetSelection.bind(this);
    this.exportXls = this.exportXls.bind(this);
    this.export = this.export.bind(this);
    this.showMap = this.showMap.bind(this);
  }

  ngOnInit() {
    if (!this.authService.hasPermission('property_status')) {
      return this.stateService.go('dimap.project.map', { projectKey: this.authService.getActualProject().key });
    }

    this.initializeList();
  }

  ngDoCheck() {
    if (!this.differ) {
      return;
    }
    const changes = this.differ.diff(this.list);
    if (changes) {
      this.currentFilter = {
        parcelId: [],
        titleId: [],
        managementAreaId: [],
        areaId: [],
        subjectId: [],
        roadId: [],
        roadSectionId: [],
        divisionTypeId: [],
        ownerType: [],
        opsubType: [],
        opsubGroup: []
      };
      for (const [key] of Object.entries(this.currentFilter)) {
        this.list.filter.filters[key].values.forEach((item) => {
          this.currentFilter[key].push(item.id);
        });
      }
    }
  }

  listLoadServiceSetter(setter) {
    if (typeof setter === 'function') {
      setter(this.listLoadService);
    }
  }

  onFilterChanged(changingPage): Promise<any> {
    this.listLoadService.fetchResultCount(this.list).then(this.onListChanged);
    // if changing page, fetchResult will be called from transition handler
    if (!changingPage) {
      return this.fetchResults();
    }
    return Promise.reject();
  }

  needsToResetPage() {
    return this.getActualPage(this.stateService.params) !== 1;
  }

  getActualPage(params) {
    return parseInt(params.page, 10) || 1;
  }

  uiOnParamsChanged(changedParams, transition) {
    if (transition.$from().name === transition.$to().name) {
      const page = this.getActualPage(changedParams);
      this.fetchResults(page);
    }
  }

  onListChanged() {
    if (this.onListChangedPagination) {
      this.onListChangedPagination();
    }
  }

  onListChangedSetterPagination(setter) {
    this.onListChangedPagination = setter.onListChanged;
  }

  fetchResults(page = this.getActualPage(this.stateService.params)) {
    const overfloatFallback = () => this.stateService.go('.', {page: 1});
    return this.listLoadService.fetchListPerPage(this.list, page, this.onListChanged, overfloatFallback);
  }

  onSort(sortValue, sortDir) {
    this.listLoadService.sort(this.list, sortValue, sortDir);
  }

  private initializeList() {
    this.getListService().then(list => {
      this.list = list;
      this.differ = this.differs.find(this.list).create();
      this.listLoaded = true;
      let tmpPARCELIDS;
      if (this.requestID) {
        tmpPARCELIDS = Object.assign({}, {values: this.list.filter.filters.parcelId.values.map((val) => ({id: val}))});
      }

      this.listLoadService.attachFilterStorage(
        this.list,
        'propertyStatusFilter',
      );

      _.assign(this.globalFilter, _.mapValues(this.globalFilter, (value, filterKey) => this.list.filter.filters[filterKey] || value));

      // todo watch

      if (tmpPARCELIDS) {
        this.list.filter.filters.parcelId = tmpPARCELIDS;
      }

      this.groupBy = this.list.filter.filters.groupAtributes.values[0];

      if (!this.groupBy || !_.find(this.groups, this.groupBy)) {
        this.groupBy = { id: undefined };
        this.list.filter.filters.groupAtributes.values = [];
      }

      this.updateSections();

      this.checklistSelection = new ChecklistModel(this.localStorageService.get(this.authService.getActualProject().key + '.parcelSelection') || undefined);

      this.checklistSelection.identity = (identityItem) => {
        return (listItem) => {
          return _.isEqual(identityItem.parcelid ? identityItem.parcelid : this.currentFilter.parcelId, listItem.parcelid) &&
            _.isEqual(identityItem.titleid ? identityItem.titleid : this.currentFilter.titleId, listItem.titleid) &&
            _.isEqual(identityItem.areaid ? identityItem.areaid : this.currentFilter.areaId, listItem.areaid) &&
            _.isEqual(identityItem.roadid ? identityItem.roadid : this.currentFilter.roadId, listItem.roadid) &&
            _.isEqual(identityItem.roadsectionid ? identityItem.roadsectionid : this.currentFilter.roadSectionId, listItem.roadsectionid) &&
            _.isEqual(identityItem.subjectId, listItem.subjectId) &&
            _.isEqual(identityItem.divisionTypeId, listItem.divisionTypeId) &&
            _.isEqual(identityItem.ownerType, listItem.ownerType) &&
            _.isEqual(identityItem.opsubType, listItem.opsubType) &&
            _.isEqual(identityItem.opsubGroup, listItem.opsubGroup);
        };
      };
      this.checklistSelection.transformation = (item) => {
        return {
          parcelid: item.parcelid ? [item.parcelid] : this.currentFilter.parcelId,
          titleid: item.titleid ? [item.titleid] : this.currentFilter.titleId,
          areaid: item.areaid ? [item.areaid] : this.currentFilter.areaId,
          roadid: item.roadid !== undefined ? [item.roadid] : this.currentFilter.roadId,
          roadsectionid: item.roadsectionid !== undefined ? [item.roadsectionid] : this.currentFilter.roadSectionId,
          managementAreaId: this.currentFilter.managementAreaId,
          subjectId: this.currentFilter.subjectId,
          divisionTypeId: this.currentFilter.divisionTypeId,
          ownerType: this.currentFilter.ownerType,
          opsubType: this.currentFilter.opsubType,
          opsubGroup: this.currentFilter.opsubGroup
        };
      };
      this.checklistSelection.afterCallback = () => {
        this.localStorageService.set(this.authService.getActualProject().key + '.parcelSelection', this.checklistSelection.checkedItems);
        this.reloadSelectionSummary();
      };

      this.onFilterChanged(false);

      this.reloadSelectionSummary();
    });
  }

  onUpdateFilterCriteria() {
    const changingPage = this.needsToResetPage();
    if (changingPage) {
      // we must reset page
      this.stateService.go('.', {
        page: 1
      });
      this.list.filter.offset = 0;
    }
    return this.onFilterChanged(changingPage);
  }

  updateGroup(groupBy) {
    this.list.filter.filters.groupAtributes.values = groupBy ? [groupBy] : [];
    this.onUpdateFilterCriteria().then(this.updateSections);
  }

  updateSections() {
    this.displaySections = {};
    this.displaySections[this.groupBy.id] = true;
  }

  resetSelection() {
    this.checklistSelection.checkedItems.splice(0, this.checklistSelection.checkedItems.length);
    this.checklistSelection.afterCallback();
  }

  exportXls() {
    this.exportLoadingXls = true;

    this.restangular.all('/property-status/export-xls')
      .withHttpConfig({responseType: 'blob'}) // important to set response as a blob
      .customPOST(this.checklistSelection.checkedItems)
      .toPromise()
      .then((data) => {
        const blob = new Blob(
          [data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        );
        saveAs(blob, 'export.xls', true);
        this.exportLoadingXls = false;
      }, () => {
        this.exportLoadingXls = false;
      })
    ;
  }

  export() {
    this.exportLoading = true;
    this.restangular.all('/property-status/export-list')
      .customPOST(this.checklistSelection.checkedItems)
      .toPromise()
      .then((data) => {
        const blob = new Blob([data], {type: 'text/csv;charset=utf-8'});
        saveAs(blob, 'export.csv');
        this.exportLoading = false;
      }, () => {
        this.exportLoading = false;
      });
  }

  showMap() {
    this.stateService.go('dimap.project.map', { displaySelection: true });
  }

  public ceil(val) {
    return Math.ceil(val);
  }

  private reloadSelectionSummary() {
    if (this.selectionSummary.abortFetch) {
      this.selectionSummary.abortFetch();
    }

    this.selectionSummary.abortFetchPromise = new Promise<any>((resolve, reject) => {
      this.selectionSummary.abortFetch = resolve;
    });

    this.selectionSummary.loading = true;
    this.httpService.call({
      path: 'property-status/selection-summary',
      method: 'POST',
      data: this.checklistSelection.checkedItems,
      cancelPromise: this.selectionSummary.abortFetchPromise
    }).then(data => {
      this.selectionSummary.loading = false;
      this.selectionSummary.data = data;
      this.selectionSummary.abortFetch = undefined;
      this.selectionSummary.abortFetchPromise = undefined;
    });
  }

  private getListService(): Promise<any> {
    const composeList = (parcelIDs = []) => {
      _.merge(this.globalFilter.parcelId.values, parcelIDs);
      return this.listLoadService.createList('property-status/list',
        {
          filters: _.assign({
            groupAtributes : {values: [{id: 'area'}]},
          }, this.globalFilter)
        },
        undefined,
        {}
      );
    };


    if (this.requestID) {
      return this.restangular.one(`parcel/intersection/${this.requestID}/ids`).get().toPromise().then((parcelIDs) => {
        return composeList(parcelIDs.plain());
      });
    } else {
      return Promise.resolve(composeList());
    }
  }
}
