import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {OdborModel} from "@app/doss/models/odbor.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'odbor'})
@Injectable({
  providedIn: 'root'
})
export class OdborPipe implements PipeTransform {

  transform(odbor: OdborModel): string {
    return !odbor ? "" : `${odbor.nazev}`;
  }
}
