import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';

import {AuthService} from '@app/common/services/auth.service';
import {ClassName} from '@app/common/enums/class-name.enum';
import {ConfirmationComponent} from '@app/common/components/confirmation/confirmation.component';
import {DialogService} from '@app/common/services/dialog.service';
import {ListModel} from '@app/common/models/list.model';
import {ListService} from '@app/common/services/list.service';
import {PerfectScrollbarEvent} from '@app/common/components/perfectscrollbar/perfectscrollbar.component';
import {User} from '@app/models/user';
import {Restangular} from 'ngx-restangular';
import {NotesDossFormComponent} from "@app/doss/components/notes-doss-form/notes-doss-form.component";
import {NoteDossModel} from "@app/doss/models/note-doss-model";


@Component({
  selector: 'doss-notes-list',
  templateUrl: './notes-doss-list.component.html',
  styleUrls: ['./notes-doss-list.component.scss']
})
export class NotesDossListComponent implements OnInit {

  @Input() defaultData: any = {};
  @Input() noteList: ListModel<NoteDossModel>;
  @Input() maxHeight: number;
  @Input() ukonId: number;
  @Input() editable: boolean = true;

  @Output() updated = new EventEmitter<boolean>();

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
    private listService: ListService,
    private dialogService: DialogService
  ) {
    this.onEditNote = this.onEditNote.bind(this);
    this.onCanScrollTop = this.onCanScrollTop.bind(this);
    this.onDeleteNote = this.onDeleteNote.bind(this);
    this.onLoadNextNotes = this.onLoadNextNotes.bind(this);
    this.onCancelledCheckboxClicked = this.onCancelledCheckboxClicked.bind(this);
    this.checkCancelled = this.checkCancelled.bind(this);
  }

  ngOnInit() {
  }

  canEdit() {
    return this.authService.hasPermissionForDossAction('manage_notes')
  }

  isAdmin() {
    return this.authService.hasPermissionForDossAction('admin')
  }

  onEditNote(note?: NoteDossModel) {
    const isEdit = !!note;

    note = note ? {...note, ukonId: this.ukonId} : {text: '', ukonId: this.ukonId};

    const dialog = this.dialogService.open(NotesDossFormComponent, {
      data: {note: {...note}}
    });

    const sub = dialog.afterClosed.subscribe((result: NoteDossModel | boolean) => {
      if (result) {
        this.cleanResult(result);

        if (isEdit) {
          const index = _.findIndex(this.noteList.list, {id: note.id});
          this.noteList.list.splice(index, 1, result as NoteDossModel);
        } else {
          this.noteList.list.unshift(result as NoteDossModel);
          this.noteList.itemCount++;
          this.scrollTop();
        }

        this.updated.emit(true);
      }
      sub.unsubscribe();
    });
  }

  onDeleteNote(note: NoteDossModel) {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete poznámku smazat? Její obnovení nebude možné!',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        const akceNoteDelete = this.restangular.one(`notes/${note.id}`);
        const ukonNoteDelete = this.restangular.one(`ukon/${this.ukonId}/notes/${note.id}`);

        (this.ukonId ? ukonNoteDelete : akceNoteDelete).remove().toPromise().then(() => {
          if (!this.checkCancelled()) {
            this.noteList.list = _.without(this.noteList.list, note);
          } else {
            note.cancelled = true;
          }
          this.noteList.itemCount--;
          this.updated.emit(true);
        });
      }
      sub.unsubscribe();
    });
  }

  onLoadNextNotes() {
    this.noteList.filter.offset += this.noteList.filter.limit;
    this.listService.fetchResult(this.noteList, true);
  }

  onCanScrollTop(scrollbarEvent: PerfectScrollbarEvent) {
    this.scrollTop = scrollbarEvent.scrollTop;
  }

  onFilterChanged() {
    return this.listService.fetchResult(this.noteList);
  }

  checkCancelled() {
    return this.noteList.filter.filters.cancelled !== false;
  }

  onCancelledCheckboxClicked() {
    if (this.checkCancelled()) {
      this.noteList.filter.filters.cancelled = false;
    } else {
      delete this.noteList.filter.filters.cancelled;
    }
    this.onFilterChanged();
  }

  private scrollTop: () => void = () => {
  };

  private cleanResult(result: any) {
    Object.keys(this.defaultData).forEach(key => delete result[key]);
  }

}
