<div>
  <span class="state-label pr-10">Stav:</span>
  <a (click)="opened = !opened" class="button active">{{ StavUkonuUtils.getStavAndFazeUkonuDesc(ukon.stavUkonu) }}&nbsp;&nbsp;<i
    class="fa fa-chevron-circle-down"></i></a>

  <div *ngIf="opened" class="content-container">
    <div class="bordered" *ngIf="canEdit()">
      <div *ngIf="!canChangeUkonStatus()" class="text-center p-10 info-message">
        {{ getStavUkonuNelzeZmenitMessage() }}
      </div>

      <a (click)="actionButton.click ? actionButton.click() : undefined" *ngFor="let actionButton of actionButtons"
         [uiParams]="{stav: actionButton.key}"
         class="bordered-bottom p-10"
         uiSref="{{ actionButton.click || ValidationUtils.isNonEmptyArray(actionButton.fazeUkonu) ? '' : 'doss.akce.data.ukony.detail.status' }}">
        <i class="fa {{ actionButton.icon }}"></i> {{ actionButton.menuName }}

        <gmt-select
          (changed)="stateService.go('doss.akce.data.ukony.detail.status', {stav: actionButton.key, faze: $event.newValue.key})"
          *ngIf="ValidationUtils.isNonEmptyArray(actionButton.fazeUkonu)"
          [enableSearch]="false"
          [itemPipe]="fazeUkonuPipe"
          [optionItems]="actionButton.fazeUkonu"
        >
        </gmt-select>
      </a>

      <div *ngIf="previousState">
        <a (click)="onBack()" class="p-10"><i class="fa fa-step-backward"></i> Vrátit do stavu "{{ previousState }}"</a>
      </div>
    </div>
  </div>
</div>
