import {Component, Inject, Input} from '@angular/core';
import {AkceModel} from '@app/doss/models/akce.model';
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Component({
  selector: 'third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.scss']
})
export class ThirdStepComponent {
  @Input()
  akce: AkceModel;

  readonly detailyLokalizace = [
    {id: 'region', name: 'Kraj'},
    {id: 'municipality_extended', name: 'ORP'},
    {id: 'municipality', name: 'Obec'},
    {id: 'cadastral_area', name: 'Katastrální území'}
  ];
  protected readonly Object = Object;

  constructor(@Inject(RESTANGULAR_DOSS) public restangularDoss: any) {
    this.onUpdateLokalizaceStavby = this.onUpdateLokalizaceStavby.bind(this);
  }

  isValid(): boolean {
    return this.akce && this.akce.lokalizaceStavby &&
      ValidationUtils.isNonEmptyArray(this.akce.lokalizaceStavby.region) &&
      ValidationUtils.isNonEmptyArray(this.akce.lokalizaceStavby.municipality_extended) &&
      ValidationUtils.isNonEmptyArray(this.akce.lokalizaceStavby.municipality) &&
      ValidationUtils.isNonEmptyArray(this.akce.lokalizaceStavby.cadastral_area);
  }

  remove(items: string[], index: number): void {
    items.splice(index, 1);
  }

  getEntitiesByCategory(category: any): string[] {
    if (this.akce.lokalizaceStavby) {
      return this.akce.lokalizaceStavby[category.id];
    }
    return [];
  }

  compareLokalizace(item1: any, item2: any): boolean {
    return item1.code === item2.code;
  }

  onUpdateLokalizaceStavby(category: string, changedData: any): void {
    if (!this.akce.lokalizaceStavby[category].some(item => item.code === changedData.newValue.code)) {
      this.akce.lokalizaceStavby[category].push(changedData.newValue);
    }
    changedData.data.selected = null;
  }
}
