<h1>Pracoviště dotčeného orgánu</h1>

<div class="cb">
  <fieldset>
    <div class="column-100">
      <gmt-input label="Název" required="true"><input [(ngModel)]="pracoviste.nazev" type="text"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Ulice"><input [(ngModel)]="pracoviste.adresniMisto.ulice" type="text"/></gmt-input>
      <gmt-input label="Číslo popisné" required="true"><input [(ngModel)]="pracoviste.adresniMisto.cisloPopisne"
                                                              type="text"/>
      </gmt-input>
      <gmt-input label="Část obce"><input [(ngModel)]="pracoviste.adresniMisto.castObce" type="text"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Číslo orientační"><input [(ngModel)]="pracoviste.adresniMisto.cisloOrientacni" type="text"/>
      </gmt-input>
      <gmt-input label="Městská část"><input [(ngModel)]="pracoviste.adresniMisto.mestskaCast" type="text"/></gmt-input>
      <gmt-input label="Obec" required="true"><input [(ngModel)]="pracoviste.adresniMisto.obec" type="text"/>
      </gmt-input>
    </div>

    <div class="column-100">
      <gmt-input label="Městský obvod"><input [(ngModel)]="pracoviste.adresniMisto.mestskyObvod" type="text"/>
      </gmt-input>
      <gmt-input label="Okres"><input [(ngModel)]="pracoviste.adresniMisto.okres" type="text"/></gmt-input>
      <gmt-input label="PSČ" required="true"><input [(ngModel)]="pracoviste.adresniMisto.psc" gmtInputPostCode
                                                    type="text"/></gmt-input>
    </div>
  </fieldset>
</div>

<div class="text-center pt-20">
  <gmt-button [clickAction]="onCancel" class="button black">
    Zrušit změny
  </gmt-button>
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()" class="button">
    Uložit změny
  </gmt-button>
</div>
