import {Component, Inject, OnInit} from '@angular/core';
import {SwitchOption} from "@app/common/components/switch/switch.component";
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";
import {RESTANGULAR_SETTINGS} from "@app/common/services/restangular-settings.service";
import {DialogRef} from "@app/common/services/dialog-ref";
import {SouladSPdService} from "@app/doss/services/soulad-s-pd.service";
import {UkonModel} from "@app/doss/models/ukon.model";
import * as _ from "lodash";

@Component({
  templateUrl: './soulad-s-pd.component.html',
  styleUrls: ['./soulad-s-pd.component.scss']
})
export class SouladSPdComponent implements OnInit {
  readonly options: SwitchOption[] = [
    {
      id: 'JE_V_SOULAD_S_PD',
      value: 'ÚKON JE V SOULADU S PD'
    },
    {
      id: 'NENI_V_SOULAD_S_PD',
      value: 'ÚKON NENÍ V SOULADU S PD'
    }
  ];

  currentOption: SwitchOption;

  loaded: boolean = false;

  private akceSchema: string;
  ukon: UkonModel;

  constructor(@Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
              private souladSPdService: SouladSPdService,
              private dialogConfig: DialogConfig,
              private dialogComponent: DialogRef) {
    this.saveSouladSPd = this.saveSouladSPd.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.handleSelectOption = this.handleSelectOption.bind(this);
  }

  async ngOnInit() {
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).schema;
    this.ukon = _.cloneDeep((<DialogConfigData>this.dialogConfig.data).ukon);

    this.currentOption = this.options[this.ukon.souladSPdInfo.stav === this.options[0].id ? 0 : 1];
    this.ukon.souladSPdInfo.stav = this.currentOption.id as string;

    this.loaded = true;
  }

  handleSelectOption(option: SwitchOption) {
    this.currentOption = option;
    this.ukon.souladSPdInfo.stav = option.id as string;
  }

  async saveSouladSPd(): Promise<void> {
    this.souladSPdService.update(this.akceSchema, this.ukon.id, this.ukon.souladSPdInfo)
      .then(res => this.closeDialog());
  }

  closeDialog(): void {
    this.dialogComponent.close({})
  }

  canBeUpdated(): boolean {
    return this.ukon.odeslanVeVerziPd.id !== this.ukon.souladSPdInfo.aktualniVerzePd.id;
  }
}
