﻿<div id="content" style="height:100%;justify-content: center;align-items: center;padding:10px 100px;">
  <perfect-scrollbar style="max-height:100%;min-width: 300px;max-width: 1000px;margin: 0 100px;flex:none">
    <div class="jumbotron text-center" style="margin:0">
      <div *ngIf="errorCode === 1" class="text-center">
        <p class="text-center">{{errors[errorCode]}}</p>

        <ul style="text-align:center">
          <li *ngFor="let project of getProjects()" class="ml-10 mt-5">
            <a (click)="goToProjectFunction(project.key);" class="underlined">{{project.name}}</a>
          </li>
        </ul>
      </div>
      <div *ngIf="errorCode === 2" class="text-center">
        <p *ngIf="getModules().length > 0" class="text-center">{{errors[errorCode]}}</p>

        <ul *ngIf="getModules().length > 0" style="text-align:center">
          <li *ngFor="let module of getModules()" class="pl-10 inline">
            <gmt-button [clickAction]="goToModuleFunction(module)">{{getModuleName(module)}}</gmt-button>
          </li>
        </ul>
        <div *ngIf="getModules().length === 0">
          <p>
            Nemáte přiřazen žádný modul. Kontaktujte podporu.
          </p>
          <gmt-button [clickAction]="goToLoginFunction(false)">Zpět na přihlášení</gmt-button>
        </div>
      </div>
      <div *ngIf="errorCode === 3" class="text-center">
        <p class="text-center">{{errors[errorCode]}}</p>

        <gmt-button [clickAction]="goToLoginFunction(true)">Zpět na přihlášení</gmt-button>
      </div>
      <div *ngIf="errorCode === 4" class="text-center">
        <p class="text-center">{{errors[errorCode]}}</p>

        <gmt-button [clickAction]="logout">Zpět na přihlášení</gmt-button>
      </div>
      <div *ngIf="errorCode === 5" class="text-center">
        <p class="text-center">{{errors[errorCode]}}</p>

        <gmt-button [clickAction]="logout">Zpět na přihlášení</gmt-button>
      </div>

      <div *ngIf="errorCode === 6" class="text-center">
        <p class="text-center">{{errors[errorCode]}}</p>
        <ul style="text-align:center">
          <li *ngFor="let akce of getProjects()" class="ml-10 mt-5">
            <a [uiParams]="{ akceKey: akce.schema }" uiSref="doss.akce.data.overview" class="underlined">{{akce.nazevAkce}}</a>
          </li>
        </ul>
      </div>

      <div *ngIf="!errors[errorCode]" class="text-center">
        <p class="text-center">Jejda něco se pokazilo.</p>

        <gmt-button [clickAction]="goToLoginFunction(true)">Zpět na přihlášení</gmt-button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
