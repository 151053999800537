<h1 class="centered-text">Úkon nelze založit.</h1>

<div class="padding-20">
  <div class="column-100" class="text-center">
    <span>Před založením prvního úkonu je nutné vložit platnou verzi projektové dokumentace.</span>
  </div>
</div>

<div class="actions">
  <gmt-button [clickAction]="onZalozitNovouVerziPd">VLOŽIT NOVOU VERZI PD</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">ZPĚT</gmt-button>
</div>
