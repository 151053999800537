import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DialogRef} from '@app/common/services/dialog-ref';
import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import {AuthService} from '@app/common/services/auth.service';

import * as GMTCK from 'gmt-ckeditor/dist';
import {NoteDossModel} from "@app/doss/models/note-doss-model";
import {Restangular} from "ngx-restangular";

@Component({
  selector: 'notes-edit-list',
  styleUrls: ['./notes-doss-form.component.scss'],
  templateUrl: './notes-doss-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NotesDossFormComponent implements OnInit {
  note: NoteDossModel;
  editor;
  test = this.authService.getActualProject().schema;

  constructor(
    public restangular: Restangular,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
    private authService: AuthService
  ) {
    this.note = (<DialogConfigData>dialogConfig.data).note;
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async ngOnInit() {
    await this.prepareEditor();
  }

  canEdit() {
    return this.authService.hasPermissionForDossAction('manage_notes')
  }

  isValid() {
    return this.editor && this.editor.getData();
  }

  onSave(): Promise<any> {
    this.note.text = this.editor.getData();

    const basePath = this.note.ukonId ? `ukon/${this.note.ukonId}/notes` : `notes`;
    const action = this.note.id ? 'customPUT' : 'customPOST';
    const url = this.note.id ? `${basePath}/${this.note.id}` : `${basePath}/create`;

    return this.restangular.one(url)[action](this.note)
      .toPromise()
      .then(note => this.dialogRef.close(note.plain()));
  }


  onCancel() {
    this.dialogRef.close(false);
  }

  private async prepareEditor() {
    this.editor = await GMTCK.ClassicEditor.create(document.querySelector('#editor'));
    this.editor.setData(this.note.text);
  }
}
