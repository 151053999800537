import {Component, Inject, OnInit} from '@angular/core';
import {UkolCreateModel} from "@app/doss/models/ukol-create.model";
import {SwitchOption} from "@app/common/components/switch/switch.component";
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";
import {RESTANGULAR_SETTINGS} from "@app/common/services/restangular-settings.service";
import {UkolModel} from "@app/doss/models/ukol.model";
import {DialogRef} from "@app/common/services/dialog-ref";
import {UkolService} from "@app/doss/services/ukol.service";
import {AuthService} from "@app/common/services/auth.service";
import { UserNamePipe } from '@app/common/pipes/username.pipe';

@Component({
  selector: 'ukol-create',
  templateUrl: './ukol-create.component.html',
  styleUrls: ['./ukol-create.component.scss']
})
export class UkolCreateComponent implements OnInit {
  ukol: UkolModel = <UkolModel>{};
  user: any;

  options: SwitchOption[] = [
    {
      id: 'ukol-pro-me',
      value: 'ÚKOL PRO MĚ',
    },
    {
      id: 'ukol-pro-kolegu',
      value: 'ÚKOL PRO KOLEGU',
    }];

  currentOption: SwitchOption;

  private akceSchema: string;
  private ukonId: number;

  private defaultFilter = {
    filters: {
      applications: {
        values: ['DOSS']
      },
      projects: {
        values: []
      }
    }
  };

  constructor(@Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
              private ukolService: UkolService,
              private dialogConfig: DialogConfig,
              private dialog: DialogRef,
              private authService: AuthService,
              public usernamePipe: UserNamePipe) {
    this.createUkol = this.createUkol.bind(this)
    this.handleSelectOption = this.handleSelectOption.bind(this);
  }

  ngOnInit() {
    this.currentOption = this.options[0];
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).schema;
    this.ukonId = (<DialogConfigData>this.dialogConfig.data).ukonId;
    this.user = this.authService.getUser();
    this.ukol = (<DialogConfigData>this.dialogConfig.data).ukol || <UkolModel>{};
    this.defaultFilter.filters.projects.values.push(this.akceSchema);
  }

  handleSelectOption(option: SwitchOption) {
    this.currentOption = option;
  }

  isValid(): boolean {
    return !!this.ukol.nazev && (this.isUkolProMe() || !!this.ukol.urcenPro) && !!this.ukol.termin;
  }

  async createUkol(): Promise<void> {
    if (this.ukol.id) {
      await this.ukolService.update(this.akceSchema, this.ukonId, this.ukol, this.ukol.id)
        .then((data) => this.dialog.close(data));

    } else {
      await this.ukolService.create(this.akceSchema, this.ukonId, this.mapToUkolCreateModel(this.ukol))
        .then((data) => this.dialog.close(data));
    }
  }
  
  private mapToUkolCreateModel(ukol: UkolModel): UkolCreateModel {
    const {urcenPro, ...otherProperties} = ukol;

    return {
      urcenPro: this.isUkolProMe() ? this.user.idText : (urcenPro && urcenPro.idText),
      ...otherProperties
    };
  }

  private isUkolProMe(): boolean {
    return this.currentOption.id === 'ukol-pro-me';
  }
}
