import {Component, OnInit} from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { StateService } from '@uirouter/angular';
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './akce-overview.component.html',
  styleUrls: ['./akce-overview.component.scss']
})
export class AkceOverviewComponent implements OnInit {
  authorizedAkceList: any;
  authorized: boolean;
  helpIds = HelpService.HELP_IDS;

  constructor(private authService: AuthService,
              private stateService: StateService,
  ) {
  }

  async ngOnInit() {
    if (!this.authService.hasPermissionForDossAction('web')) {
      this.stateService.go('error', {errorCode: 6});
      this.authorized = false;
    } else {
      this.authorized = true;
    }
  }
}
