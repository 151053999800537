
<h2 class="mb-10">Upozornění</h2>
<div>
  <div class="tabbable-line tabs-below">
      <div>
        <doss-upozorneni
          [user]="user"
          [readonly]="false"
          [upozorneniList]="upozorneni"
        ></doss-upozorneni>
      </div>
  </div>
</div>
