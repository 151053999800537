<gmt-tabbed-block [tabs]="upozorneniFilter" [selected]="upozorneniFilterSelectionVisible.id" (updated)="onUpozorneniFilterChanged($event)">
  <div class="notifications">
    <div [loading]="upozorneniList.loading">
      <div class="cb pt-10" *ngIf="!upozorneniList.itemCount && upozorneniList.list !== null">
        <div class="bordered">
          <div class="empty-info">
            <span>Žádná upozornění</span>
          </div>
        </div>
      </div>

      <div *ngIf="upozorneniList.itemCount">
        <a *ngIf="canModifyAllUpozorneni()" (click)="onCheckAllUpozorneni()" class="mr-5">
          <gmt-checkbox gmtType="multiselect" [gmtChecked]="checkedAllUpozorneni"></gmt-checkbox>
        </a>
        <a (click)="onChangeUpozorneniOrder()" class="button">
          Seřadit dle data <span class="fa {{upozorneniList.filter.sortOrder && upozorneniList.filter.sortOrder.direction === 'desc' ? 'fa-sort-amount-desc' : 'fa-sort-amount-asc'}}"></span>
        </a>
      </div>

      <perfect-scrollbar *ngIf="upozorneniList.itemCount" class="result" [wheelPropagation]="true" (callbackRegister)="onCanScrollTop($event)">
        <ul>
          <li *ngFor="let upozorneni of upozorneniList.list" [ngClass]="{'with-warning': upozorneni.withWarning, selected: isUpozorneniChecked(upozorneni)}" (click)="onToggleUpozorneniSelection(upozorneni, $event)">
            <div class="check">
              <gmt-checkbox gmtType="multiselect" [gmtChecked]="isUpozorneniChecked(upozorneni)" [gmtDisabled]="!canModify(upozorneni)"></gmt-checkbox>
            </div>
            <div class="date">
              <div>{{upozorneni.createdTimestamp | date}}</div>
            </div>
            <div class="type">
              <div>{{upozorneni.type}}</div>
            </div>
            <div class="message flex-rest">
              <div>
                <a uiSref="doss.akce.data.organy.detail" [uiParams]="{id: upozorneni.ukon.dotcenyOrgan.id}">{{upozorneni.ukon.typUkonu.description + ' ' + DotcenyOrganUtils.getDotcenyOrganDescription(upozorneni.ukon)}}</a>
                {{upozorneni.message}}
              </div>
            </div>
            <div class="notification-actions">
              <a class="fa fa-bookmark-o fa-lg button mr-5" *ngIf=" !upozorneni.archived && canModify(upozorneni)" (click)="onArchiveUpozorneni([upozorneni])"></a>
              <a class="fa fa-trash-o fa-lg button red" *ngIf="canModify(upozorneni)" (click)="onDeleteUpozorneni([upozorneni])"></a>
            </div>

          </li>
        </ul>
        <div class="notifications-actions" *ngIf="canEdit()">
          <div *ngIf="selectedUpozorneni.length > 0" class="actions-selected">
            <a class="fa fa-bookmark-o fa-lg button  mr-5" (click)="onArchiveUpozorneni(selectedUpozorneni)" *ngIf="upozorneniFilterSelectionVisible.id === 'U'"></a>
            <a class="fa fa-trash-o fa-lg button red" visibleByBrand="maja" (click)="onDeleteUpozorneni(selectedUpozorneni)"></a>
          </div>
          <div class="load-next" *ngIf="upozorneniList.itemCount > upozorneniList.list.length">
            <input type="button" value="Načíst další" (click)="onLoadNextUpozorneni()" [disabled]="upozorneniList.loading" />
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</gmt-tabbed-block>
