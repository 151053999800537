import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";

@Pipe({name: 'kategorieDossDetail'})
@Injectable({
  providedIn: 'root'
})
export class KategorieDossDetailPipe implements PipeTransform {

  transform(kategorieDossList: KategorieDossModel[]): string {
    return ValidationUtils.isNonEmptyArray(kategorieDossList) ? kategorieDossList.map(kategorieDoss => `(${kategorieDoss.idKategorie}) ${kategorieDoss.description}`).join(", ") : "nezvoleno (zahrnuto vše)";
  }
}
