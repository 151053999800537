import {Component, Input} from '@angular/core';
import {SouladSPdInfoModel} from "@app/doss/models/soulad-s-pd-info.model";
import {UkonModel} from "@app/doss/models/ukon.model";

@Component({
  selector: 'verze-pd',
  templateUrl: './verze-pd.component.html',
  styleUrls: ['./verze-pd.component.scss']
})
export class VerzePdComponent {
  @Input()
  ukon: UkonModel;

  getIconClass(stav: string): string {
    switch (stav) {
      case "NENI_V_SOULAD_S_PD":
        return "fa fa-exclamation-triangle";
      case "PREZKUM":
        return "fa fa-question";
      case "JE_V_SOULAD_S_PD":
        return "fa fa-check";
    }
  }
}
