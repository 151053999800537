import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@app/common/common.module';
import {Ng2StateDeclaration, TargetState, UIRouterModule} from '@uirouter/angular';
import {PsCommonModule} from "@app/ps/ps-common.module";
import {
  exportyState,
} from "@app/doss/exporty/exporty.states";
import {ExportyComponent} from "@app/doss/exporty/components/exporty/exporty.component";

const STATES: Ng2StateDeclaration[] = [
  exportyState,
];

@NgModule({
  imports: [
    NgbModule,
    HttpClientModule,
    CommonModule,
    UIRouterModule.forChild({
      states: [...STATES]
    }),
    PsCommonModule,
  ],
  providers: [],
  declarations: [
    ExportyComponent
  ],
  entryComponents: [
  ],
  exports: []
})

export class ExportyModule {
}
