
  <div class="bordered mt-10" *ngIf="kontaktniOsoby">
    <div *ngIf="!kontaktniOsoby.length" class="empty-info">Nejsou zadány kontatní osoby</div>
    <table *ngIf="kontaktniOsoby.length">
      <thead class="sticky">
      <th class="cell">{{ getColumnName('jmenoPrijmeni') }}</th>
      <th class="cell">{{ getColumnName('kontakt') }}</th>
      <th class="cell">{{ getColumnName('reseneUkony') }}</th>
      <th></th>
      </thead>

      <tbody class="row-group">
      <tr *ngFor="let kontaktniOsoba of kontaktniOsoby">
        <td class="cell">{{ kontaktniOsoba | kontaktniOsoba }}
          <div *ngIf="kontaktniOsoba.resenaAgenda">{{kontaktniOsoba.resenaAgenda}}</div>
        </td>
        <td class="cell">
          <div>{{ kontaktniOsoba.email }}</div>
          <div>{{ kontaktniOsoba.telefon }}</div>
        </td>
        <td class="cell">
          <reseny-ukon-info *ngFor="let resenyUkon of kontaktniOsoba.reseneUkony"
                            [resenyUkon]="resenyUkon"></reseny-ukon-info>
        </td>
        <td class="cell">
          <div class="fr">
            <a *ngIf="showCancelButton(kontaktniOsoba)" (click)="onKontaktniOsobaCancel(kontaktniOsoba)" class="red button mr-5">
              <i class="fa fa-trash-o"></i>
            </a>
            <a (click)="onKontaktniOsobaUpdate(kontaktniOsoba)" class="button"><i class="fa fa-pencil"></i></a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
