import {Component, Inject, Input, OnInit} from '@angular/core';
import {NoteModel} from "@app/common/models/note.model";
import {ClassName} from "@app/common/enums/class-name.enum";
import {DialogService} from "@app/common/services/dialog.service";
import {VerzePdFormComponent} from "@app/doss/components/verze-pd-form/verze-pd-form.component";
import {AuthService} from "@app/common/services/auth.service";
import {PageableList} from "@app/models/pageable.list";
import * as _ from "lodash";
import {SettingsService} from "@app/ps/services/settings.service";
import {PageableListService} from "@app/common/services/pageable.list.service";
import {StateService} from "@uirouter/angular";
import { Restangular } from 'ngx-restangular';
import {VerzePdService} from "@app/doss/services/verze-pd.service";
import {
  VerzePdNelzeZalozitComponent
} from "@app/doss/components/verze-pd-nelze-zalozit/verze-pd-nelze-zalozit.component";

@Component({
  templateUrl: './verze-pd-list.component.html',
  styleUrls: ['./verze-pd-list.component.scss']
})
export class VerzePdListComponent implements OnInit {
  akceSchema: string;

  list: any;
  pageableList: PageableList;
  deferredUpdateStatistics: any;
  settingsLoaded = false;

  globalFilter = {};

  constructor(public restangular: Restangular,
              private dialogService: DialogService,
              private authService: AuthService,
              private pageableListService: PageableListService,
              private verzePdService: VerzePdService,
              private settingsService: SettingsService,
              private stateService: StateService) {
  }

  async ngOnInit() {
    this.akceSchema = this.authService.getActualProject().schema;

    const promise = new Promise((resolve, reject) => {
      this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
      if (!this.settingsLoaded) {
        this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
      }

      this.deferredUpdateStatistics = {
        resolve: resolve,
        reject: reject,
      };
    });

    this.deferredUpdateStatistics.promise = promise;

    this.initializeList();

    if (this.stateService.params.openVerzePdCreateModal) {
      this.onCreateVerzePd();
    }
  }

  canCreate(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onCreateVerzePd() {
    this.verzePdService.verzePdCreateStatus(this.akceSchema).then(data => {
      if (data.valid) {
        this.openVerzePdCreateDialog();
      } else {
        this.openVerzePdCreateError();
      }
    })
  }

  async initStatisticsEntityFilter() {
    if (this.stateService.params.sef) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.getDefaultFilter().filters;
      await this.deferredUpdateStatistics.promise.then((updateEntityFilterKey) => {
        updateEntityFilterKey(this.stateService.params.sef);
      });
    }
  }

  private async initializeList() {
    await this.settingsService.getSettingsLoader();
    const filter = this.getDefaultFilter();
    this.pageableList = this.pageableListService.get(`verze-pd/list`, filter, 'verzePdFilter', this.restangular);

    this.list = this.pageableList.list;

    _.assign(this.globalFilter, _.mapValues(this.globalFilter, (value, filterKey) => this.list.filter.filters[filterKey] || value));

    await this.initStatisticsEntityFilter();
    await this.pageableList.load();
  }

  private getDefaultFilter() {
    return {filters: {searchText: {values: [{id: ''}]}}, sortOrder: {sortBy: 'id'}};
  }

  private openVerzePdCreateDialog() {
    const dialog = this.dialogService.open(VerzePdFormComponent, {
      data: {
        akceSchema: this.akceSchema,
        existingVerzePd: this.list.list
      },
      className: ClassName.HIGHER_DIALOG
    });

    const sub = dialog.afterClosed.subscribe(async (result: NoteModel | boolean) => {
      if (result) {
        await this.pageableList.load();
      }
      sub.unsubscribe();
    });
  }

  private openVerzePdCreateError() {
    const dialog = this.dialogService.open(VerzePdNelzeZalozitComponent, {
      data: {},
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (result: boolean) => sub.unsubscribe());
  }
}
