import { ChangeDetectorRef, Component, OnInit, Input, EventEmitter, Output, ElementRef, Inject, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { StateService } from '@uirouter/angular';
import { PerfectScrollbarEvent } from '../perfectscrollbar/perfectscrollbar.component';
import { StringUtils } from '@app/common/utils/string.utils';
import { WindowDimensionService } from '@app/common/services/window-dimension.service';
import { ListService } from '@app/common/services/list.service';
import { Restangular } from 'ngx-restangular';

export interface SelectItem {
  id: string | number;
  name: string;
}

@Component({
    selector: 'gmt-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() attributes: Object; // what to load in rest resource
  @Input() data: Object; // an object which is used to store object with field, to store currently selected value
  @Input() displayInput: string; // if present input field only is displayed
  @Input() field: string; // name of field of data object containg selected value
  @Input() filter: Object; // used to filter items in rest resource
  @Input() globalFilterConfig: Object; // global filter configuration, when is set, then reload items on every opening
  @Input() itemPipe: PipeTransform; // angular pipe function used to display each item
  @Input() logGa: string; // log user behaviour in cmSelect to google analytics
  @Input() multiselect: boolean; // allow to select multiple values [true/false]
  @Input() optionItems: any; // option items passed directly in situation when no rest resource is available
  @Input() reloadOptionsRegister: Function; // promise that forces options to be reloaded
  @Input() required: string; // disable cancelling of currently selected value
  @Input() resource: string; // url of rest resource used to load options
  @Input() restangularService: string;
  @Input() selectTitle: string; // text displayed when multiple values can be selected
  @Input() searchText = '';
  @Input() searchPlaceholder = 'Hledané spojení';
  @Input() searchTextMinCharacters = 0;
  @Input() selectAll = false;
  @Input() enableSelectAll = false;
  @Input() enableSearch = true;
  @Input() compareFunction: Function;
  @Output() changed = new EventEmitter(); // expression done on change
  @Output() searchTextChange = new EventEmitter<string>(); // expression done on change of searchText value
  @Output() selectAllChange = new EventEmitter<boolean>();

  initialized: boolean;
  visible: boolean;
  scrollTop: Function;
  _searchTimeout: any;
  logBehaviour: boolean;
  options: any;
  loadNext: Function;
  displayInputOnly: boolean;
  totalCount: number;

  constructor(
    private listLoadService: ListService,
    private windowDimensionService: WindowDimensionService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private restangular: Restangular,
    private stateService: StateService
  ) {
    this.initialized = false;
    this.visible = false;
    this.scrollTop = null;
    this._searchTimeout = null;

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
  }

  ngOnInit() {
    this.logBehaviour = this.logGa === '';
    this.multiselect = this.multiselect !== undefined && this.multiselect !== false ? true : false;

    // prevent blicking on first render after reposition
    if (!this.itemPipe) {
      this.itemPipe = new (class DefaultPipe implements PipeTransform {
        transform(item: any) {
          return item.name === null ? '-' : item.name;
        }
      })();
    }
    // if there is no direct need to set selected value to object directly, value is stored in current scope's data field named 'selected'.
    // it this case listener is an example for handling value change
    if (!this.data) {
      this.data = {};
    }
    if (!this.field) {
      this.field = 'selected';
    }
    if (this.multiselect && !this.data[this.field]) {
      this.data[this.field] = [];
    }
    this.displayInputOnly = ('displayInput' in this);
    // init static
    if (this.optionItems && !this.optionItems.path) {
      // static items + static filtering
      if (this.reloadOptionsRegister) {
        this.reloadOptionsRegister(() => {
          if (!this.initialized) {
            return;
          }
          const optionItems = this.reloadOptionsStatic();
          return optionItems;
        });
      }

      const selectedItems = this.data[this.field];
      if (selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0) {
        delete this.data[this.field];
        this.data[this.field] = this.optionItems.filter((v) => {
          return selectedItems.find((i) => {
            return i.id === v.id;
          });
        });
      }
    } else {
      // dynamic items + dynamic filtering
      this.initialized = true;
      // list preloaded in controller
      if (this.optionItems && this.optionItems.path) {
        this.options = this.optionItems;
        if (this.optionItems.promise) {
          this.optionItems.promise.then(() => {
            this.checkPosition(this.visible);
          });
        }
      } else {
        this.options = this.listLoadService.createList(this.resource, this.filter ? this.filter : {}, this.restangularService, this.attributes);
      }

      if (this.enableSelectAll) {
        const filter = this.filter ? {...this.filter, limit: 1 } : { limit: 1 };
        const totalList = this.listLoadService.createList(this.resource, filter, this.restangularService);
        this.listLoadService.fetchResult(totalList).then(data => {
          this.totalCount = data.itemCount;
        });
      }

      this.loadNext = () => {
        this.options.filter.offset += this.options.filter.limit;
        this.fetchResults(true);
      };

      if (this.reloadOptionsRegister) {
        // Reloads dynamic items
        this.reloadOptionsRegister(() => {
          if (this.getItemsType() === 'dynamic') {
            if (this._searchTimeout) {
              clearTimeout(this._searchTimeout);
            }
            this.options.list = null;
            this.listLoadService.cancelFetch(this.options);
            return this.reloadOptionsDynamic();
          }
        });
      }

      // if some objects are selected
      const selectedItems = this.data[this.field];
      if (selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0) {
        const selectedItemsIDs = selectedItems.map(i => i.id);
        const filter = {
          entityIds: selectedItemsIDs,
          limit: null,
        };
        const idsLoadList = this.listLoadService.createList(this.resource, filter, this.restangularService, this.attributes);
        this.listLoadService.fetchResult(idsLoadList).then(() => {
          this.data[this.field] = idsLoadList.list;
        });
      }
    }
  }

  getTitle() {
    return (this.multiselect || !this.data[this.field] ? this.selectTitle || (this.multiselect && this.itemPipe && this.selectTitle === undefined ? 'Výběr' : null) : null) || this.formatItem(this.data[this.field]);
  }

  scrollbarCallbackRegister(event: PerfectScrollbarEvent) {
    this.scrollTop = event.scrollTop;
  }

  onDocumentClick(event: Event) {
    // check if click inside cmSelect
    const isClickInside = this.elementRef.nativeElement.contains(event.target);
    if (!isClickInside) {
      // close pane
      setTimeout(() => {
        this.toggle(false);
        this.cdr.markForCheck();
      });
    }
  }

  getItemsType() {
    if (this.optionItems && !this.optionItems.path) {
      return 'static';
    } else {
      return 'dynamic';
    }
  }

  _onVisibilityChanged() {
    if (this.getItemsType() === 'static') {
      if (this.visible && !this.options) {
        this.reloadOptionsStatic();
        this.initialized = true;
      }
    } else if (this.getItemsType() === 'dynamic') {
      if (this.visible && this.options.list === null && !this.options.loading) {
        this.reloadOptionsDynamic();
      } else if (this.visible && this.globalFilterConfig) {
        // if globalFilterConfig is set, then reload items on opening only if any of global filter has changed
        const key = _.findKey(this.globalFilterConfig,
          (filter, key) => !_.isEqual(filter, this.options.filter.filters[key])
        );
        if (key) {
          this.reloadOptionsDynamic();
        }
      }
    }
  }

  _onSearchTextChanged(searchText = '') {
    if (this.searchText === searchText) {
      return;
    }
    this.searchText = searchText;

    if (this.searchText.length < this.searchTextMinCharacters) {
      return;
    }

    if (this.getItemsType() === 'static') {
      this.reloadOptionsStatic();
    } else if (this.getItemsType() === 'dynamic') {
      if (this._searchTimeout) {
        clearTimeout(this._searchTimeout);
      }

      if (this.globalFilterConfig) {
        _.assign(this.options.filter.filters,
          // clone all except current select
          _.mapValues(this.globalFilterConfig,
              (filter: any) => filter.values === this.data[this.field] ? _.assign(_.cloneDeep(filter), {values: filter.values}) : _.cloneDeep(filter)
          )
        );
      }

      if (this.searchText) {
        this.options.filter.filters.searchText = {values: [this.searchText]};
      } else {
        this.options.filter.filters.searchText = {values: ['']};
      }

      this._searchTimeout = setTimeout(this.reloadOptionsDynamic.bind(this), 250); // delay 250 ms
    }

    this.searchTextChange.emit(this.searchText);
  }

  setVisibility(visible: boolean) {
    this.visible = visible;
    this._onVisibilityChanged();
  }

  logEvent(value) {
    (<any>window).GoogleAnalytics('send', {
      hitType: 'event',
      eventCategory: 'cmSelect',
      eventAction: this.resource,
      eventLabel: this.stateService.current.name,
    });
  }

  toggle(visible: boolean) {
    if (visible) {
      if (this.logBehaviour) {
        this.logEvent('open');
      }

      if (this.scrollTop) {
        this.scrollTop();
      }
      // must setTimeout and then set listener, otherwise click even buble down
      // to document and dispatch again
      setTimeout(() => document.addEventListener('click', this.onDocumentClick));
      // if search text, then include it in filter
      // clear searchtext from previous search
      if (this.searchText && this.options && this.options.filter && this.options.filter.filters) {
        this.options.filter.filters.searchText = {values: [this.searchText]};
      } else if (this.options && this.options.filter && this.options.filter.filters) {
        this.options.filter.filters.searchText = {values: ['']};
      }
    } else {
      document.removeEventListener('click', this.onDocumentClick);
      // delete searchText from criteria when dropdown is closed in order this would not affect other filters.
      if (this.options && this.options.filter && this.options.filter.filters) {
        this.options.filter.filters.searchText = {values: ['']};
      }
    }

    // hide options pane
    this.setVisibility(visible);
    this.checkPosition(visible);
    setTimeout(() => this.checkPosition(visible));
  }

  checkPosition(visible: boolean) {
    if (!this.initialized) {
      return;
    }

    const element = this.elementRef.nativeElement;
    const optionsPane = element.querySelector('div.options-pane');

    optionsPane.style.marginLeft = 0;
    optionsPane.style.display = 'block';
    optionsPane.style.visibility = 'hidden';

    if (!visible) {
      optionsPane.style.visibility = 'visible';
      optionsPane.style.display = 'none';
      return;
    } else {
      const minPaneHeight = 150;
      const optionsPaneResult = optionsPane.querySelector('div.result');
      const selectValueElement = element.querySelector('div.cm-select-inputwrap');
      const searchTextElement = element.querySelector('.search-text');
      const dimensions = this.windowDimensionService.get(this.elementRef.nativeElement, 5);
      const searchHeight = optionsPane.clientHeight - optionsPaneResult.offsetHeight;
      let newHeight = Math.min(300 + searchHeight, dimensions.windowHeight - (selectValueElement.getBoundingClientRect().top - dimensions.windowOffsetTop + selectValueElement.getBoundingClientRect().height));
      let flip = false;

      const shouldFlipVerticaly = optionsPane.getBoundingClientRect().width && optionsPane.getBoundingClientRect().left - dimensions.windowOffsetLeft + optionsPane.getBoundingClientRect().width > dimensions.windowWidth && optionsPane.getBoundingClientRect().width > selectValueElement.getBoundingClientRect().width;
      if (shouldFlipVerticaly) {
        optionsPane.style.marginLeft = -(optionsPane.getBoundingClientRect().width - selectValueElement.getBoundingClientRect().width) + 'px';
      }

      if (newHeight < optionsPane.clientHeight && newHeight < minPaneHeight) {
        newHeight = Math.min(300 + searchHeight, selectValueElement.getBoundingClientRect().top - dimensions.windowOffsetTop);
        if (newHeight > optionsPane.clientHeight || newHeight > minPaneHeight) {
          flip = true;
        } else {
          newHeight = minPaneHeight;
        }
      } else {
          newHeight = Math.max(newHeight, minPaneHeight);
      }

      optionsPaneResult.style.maxHeight = newHeight ? (newHeight - searchHeight) + 'px' : '';
      // IE11 overflow workaround
      let hostPerfectScrollbar = optionsPaneResult.querySelector('perfect-scrollbar');
      if (hostPerfectScrollbar) {
        hostPerfectScrollbar.style.maxHeight = optionsPaneResult.style.maxHeight;
      }
      optionsPane.style.marginTop = (flip ? -(optionsPane.getBoundingClientRect().height + selectValueElement.getBoundingClientRect().height) : 0) + 'px';
      optionsPane.style.visibility = 'visible';
      optionsPane.style.display = 'block';

      if (this.enableSearch) {
        searchTextElement.focus();
      }
    }
  }

  fetchResults(additive: boolean = false) {
    this.checkPosition(this.visible);

    if (this.globalFilterConfig) {
      _.assign(this.options.filter.filters,
          _.mapValues(this.globalFilterConfig,
              (filter: any) => filter.values === this.data[this.field] ? _.assign(_.cloneDeep(filter), {values: filter.values}) : _.cloneDeep(filter)
          )
      );
    }

    if (this.searchText) {
      this.options.filter.filters.searchText = {values: [this.searchText]};
    } else {
      this.options.filter.filters.searchText = {values: ['']};
    }
    return this.listLoadService.fetchResult(this.options, additive).then(() => {
      setTimeout(() => {
        this.checkPosition(this.visible);
        // scrolltop is not avaliable on first open
        if (!additive && this.visible && this.scrollTop) {
          this.scrollTop();
        }
      });
      this.cdr.markForCheck();

      return this.options;
    });
  }

  reloadOptionsStatic() {
    let optionItems;
    if (this.filter) {
      optionItems = _.filter(this.optionItems, this.filter);
    } else {
      optionItems = this.optionItems;
    }
    this.options = {};
    this.options.list = this.filterStaticItems(this.searchText, optionItems, this.itemPipe);
    this.options.itemCount = this.options.list.length;
    this.totalCount = this.options.list.length;
    setTimeout(() => {
      this.checkPosition(this.visible);
    });
    return optionItems;
  }

  reloadOptionsDynamic() {
    this.options.filter.offset = 0;
    return this.fetchResults();
  }

  formatItem(item) {
    if (item && this.itemPipe) {
      const title = this.itemPipe.transform(item);
      return title === null ? '-' : title;
    } else {
      return item;
    }
  }

  compareItems(item1, item2) {
    if (this.compareFunction !== undefined) {
      return this.compareFunction(item1, item2);
    } else {
      return item1 === item2 || ('id' in item1 && item1.id === item2.id);
    }
  }

  toggleSelection(item) {
    let oldValue;
    let checked = this.isChecked(item);
    if (this.multiselect) {
      if (!this.data[this.field]) {
        this.data[this.field] = [];
      }

      // is currently selected
      if (checked) {
        _.remove(this.data[this.field], (selectedItem) => this.compareItems(selectedItem, item));
      }
      // is newly selected
      else {
        this.data[this.field].push(item);
      }
      oldValue = item;
    } else {
      oldValue = this.data[this.field];
      if (checked) {
        return;
      }

      this.data[this.field] = item;
      this.toggle(false);
    }

    this.changed.emit({
      newValue: this.data[this.field],
      oldValue: oldValue,
      data: this.data
    });
  }

  unselectAll() {
    const oldValue = this.data[this.field];
    this.data[this.field] = this.multiselect ? [] : null;

    this.changed.emit({
      newValue: this.data[this.field],
      oldValue: oldValue,
      data: this.data
    });
  }

  isChecked(item) {
    return this.data && this.data[this.field]
      ? (this.multiselect
        ? _.some(this.data[this.field], (selectedItem) => this.compareItems(selectedItem, item))
        : this.compareItems(this.data[this.field], item)
      )
      : false;
  }

  resetSearchText() {
    this._onSearchTextChanged('');
    // avoid blinking on resetting
    setTimeout(() => {
      this.elementRef.nativeElement.querySelector('.search-text').focus();
    });
  }

  getSelectedItemsNames() {
    const selectedItems = this.data[this.field];
    if (!this.selectAll && selectedItems && selectedItems instanceof Array) {
      const selectedItemsNames = selectedItems.map((i) => this.formatItem(i));
      return 'Zrušit výběr:\n' + selectedItemsNames.join('\n');
    } else {
      return 'Zrušit výběr';
    }
  }

  filterStaticItems(searchText: string, optionItems: any[], itemPipe: any) {
    if (searchText.length) {
      return optionItems.filter((value) => {
          const transformSearchText = itemPipe ? itemPipe.transform(value) : value.name;
          return !searchText || StringUtils.searchStringWithWildcard(searchText, transformSearchText);
        });
    } else {
      return optionItems;
    }
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.unselectAll();
    this.selectAllChange.emit(this.selectAll);
  }
}
