import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {PracovisteModel} from "@app/doss/models/pracoviste.model";
import {AddressPipe} from "@app/common/pipes/address.pipe";
/**
 * Display name of occupation
 */
@Pipe({name: 'pracoviste'})
@Injectable({
  providedIn: 'root'
})
export class PracovistePipe implements PipeTransform {
  constructor(public addressPipe: AddressPipe) {
  }
  transform(pracoviste: PracovisteModel): string {
    const address = !pracoviste || !pracoviste.adresniMisto ? "" : ` (${this.addressPipe.transform(pracoviste.adresniMisto)})`;
    return !pracoviste ? "" : `${pracoviste.nazev}${address}`;
  }
}