import {Component, OnInit} from '@angular/core';

import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import {DialogRef} from '@app/common/services/dialog-ref';
import {KontaktniOsobaModel} from "@app/doss/models/kontaktni-osoba.model";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";
import {DialogService} from "@app/common/services/dialog.service";
import {AlertComponent} from "@app/common/components/alert/alert.component";
import {ClassName} from "@app/common/enums/class-name.enum";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Component({
  selector: 'projektant',
  templateUrl: './kontaktni-osoba.component.html',
  styleUrls: []
})
export class KontaktniOsobaComponent implements OnInit {
  akceSchema: string;
  dotcenyOrganId: number;
  kontaktniOsoba: KontaktniOsobaModel;

  constructor(private dotcenyOrganService: DotcenyOrganService,
              private dialogService: DialogService,
              private dialogConfig: DialogConfig,
              private dialogRef: DialogRef) {
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).akceSchema;
    this.dotcenyOrganId = (<DialogConfigData>this.dialogConfig.data).dotcenyOrganId;
    this.kontaktniOsoba = (<DialogConfigData>this.dialogConfig.data).kontaktniOsoba || <KontaktniOsobaModel>{};
  }

  isValid(): boolean {
    return !!this.kontaktniOsoba && !!this.kontaktniOsoba.krestniJmeno && !!this.kontaktniOsoba.prijmeni;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.dotcenyOrganId) {
      this.dotcenyOrganService.checkIfKontaktniOsobaAlreadyExists(this.akceSchema, this.dotcenyOrganId, this.kontaktniOsoba)
        .then(res => {
          if (!res.exists) {
            this.dialogRef.close(this.kontaktniOsoba);
          } else {
            this.dialogService.open(AlertComponent, {
              data: {msg: "Nelze založit duplicitní kontaktní osobu."},
              className: ClassName.ADJUSTED_DIALOG
            });
          }
        });
    } else {
      this.dialogRef.close(this.kontaktniOsoba);
    }
  }
}
