import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableServiceDoss} from "@app/doss/services/table-service-doss.service";
import {PropojeniUkonuModel} from "@app/doss/models/propojeni-ukonu.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import { AuthService } from '@app/common/services/auth.service';

@Component({
  selector: 'table-propojeni-ukonu',
  templateUrl: './table-propojeni-ukonu.component.html',
  styleUrls: ['./table-propojeni-ukonu.component.scss']
})
export class TablePropojeniUkonuComponent {
  @Input() propojeniUkonuList: PropojeniUkonuModel[];
  @Input() isChecked: Function;
  @Input() inverseSelection: boolean;
  @Input() columnNames: any;
  @Input() customColumns: string[]; // avoid setting service
  @Input() customProjectKey: string;
  @Input() showNavIcons = false;
  @Output() onDelete: EventEmitter<PropojeniUkonuModel> = new EventEmitter();
  @Output() onUpdate: EventEmitter<PropojeniUkonuModel> = new EventEmitter();

  public isArray = Array.isArray;
  public readonly ValidationUtils = ValidationUtils;
  private readonly TABLE_ID = 'propojeneUkony';

  constructor(private tableService: TableServiceDoss,
              private authService: AuthService
  ) {
  }

  canEdit() {
    return this.authService.hasPermissionForDossAction('manage_actions')
  }

  getColumnName(columnId: string) {
    if (this.columnNames && this.columnNames[columnId]) {
      return this.columnNames[columnId];
    }
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }
}
