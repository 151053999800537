<div class="column-100 bordered">
  <div class="column-100 padding-20">
    <gmt-input label="Evidenční číslo">
      <input type="text" [(ngModel)]="akce.evidencniCislo"/>
    </gmt-input>

    <gmt-input label="Číslo akce S">
      <input type="text" [(ngModel)]="akce.cisloAkceS"/>
    </gmt-input>

    <gmt-input *ngIf="!isUpdate" required="true" label="Název akce">
      <input type="text" [(ngModel)]="akce.nazevAkce"/>
    </gmt-input>

    <gmt-input label="Stupeň">
      <gmt-select
        [data]="akce"
        field="stupen"
        [optionItems]="stupneAkce"
        [itemPipe]="stupenAkcePipe"
      ></gmt-select>
    </gmt-input>
  </div>

  <div class="column-100 bordered-top padding-20">
    <gmt-input label="Organizace" required="true">
      <gmt-select
        [restangularService]="configurationRestangularService"
        resource="v2/customers"
        [filter]="{ filters: { includeDepartments: { values: ['true'] }}, sortOrder: { sortBy: 'companyName' }}"
        [data]="akce"
        field="organizace"
        (changed)="onUpdateOrganizace($event.newValue)"
        [itemPipe]="projectSubjectNamePipe"
        required="true"
        [compareFunction]="compareCustomers"
      ></gmt-select>
    </gmt-input>

    <div *ngIf="akce.organizace && akce.organizace.customerIdText">
      <gmt-input label="Organizační jednotka" required="true">
        <gmt-select
          [data]="akce"
          field="organizacniJednotka"
          [optionItems]="organizationalUnitsNotCancelled"
          [itemPipe]="organizationalUnitNamePipe"
          (changed)="onUpdateOrganizacniJednotka($event)"
          [reloadOptionsRegister]="reloadOptionsOrganizationalUnitRegister"
          required="true"
        ></gmt-select>
      </gmt-input>
    </div>

    <div>
      <label>Manažer akce DOSS</label>
      <div class="input">
        <gmt-select
          [restangularService]="restangularSettings"
          resource="users/list"
          [data]="akce"
          [itemPipe]="usernamePipe"
          field="manazerAkce"
        ></gmt-select>
      </div>
    </div>
  </div>

  <div class="column-100 bordered-top padding-20">
    <gmt-input label="Odpovědný referent DOSS">
      <gmt-select
        [restangularService]="restangularSettings"
        resource="users/list"
        [data]="akce"
        [itemPipe]="usernamePipe"
        field="referent"
      ></gmt-select>
    </gmt-input>

    <gmt-input label="Zpracovatel DOSS">
      <gmt-select
        [restangularService]="restangularSettings"
        resource="users/list"
        [data]="akce"
        [itemPipe]="usernamePipe"
        field="zpracovatel"
      ></gmt-select>
    </gmt-input>

    <div>
      <label>Projektant</label>
      <div class="info">
        <span *ngIf="akce.projektant">{{ akce.projektant | projectSubjectName: true }} </span>
        <a (click)="onEditProjektant(akce.projektant)" class="button"><i class="fa fa-pencil"></i></a>
      </div>
    </div>
  </div>

  <div class="column-100 bordered-top padding-20">
    <gmt-input label="Datum spuštění akce">
      <input pick-a-date type="text" [(ngModel)]="akce.datumSpusteni"/>
    </gmt-input>

    <gmt-input label="Předpoklad ukončení">
      <input pick-a-date type="text" [(ngModel)]="akce.predpokladaneDatumUkonceni"/>
    </gmt-input>

    <gmt-input label="Stav akce">
      <gmt-select
        [data]="this"
        field="state"
        [optionItems]="STATE_LIST"
        (changed)="onStateChanged($event.newValue)"
      ></gmt-select>
    </gmt-input>

    <gmt-input *ngIf="akce.stav" label="Datum změny stavu">
      <input *ngIf="akce.stav == 'PRA'" type="text" pick-a-date [(ngModel)]="akce.datumPripravy" [maxToday]="true"/>
      <input *ngIf="akce.stav == 'AA'" type="text" pick-a-date [(ngModel)]="akce.datumAktivni" [maxToday]="true"/>
      <input *ngIf="akce.stav == 'POA'" type="text" pick-a-date [(ngModel)]="akce.datumPozastaveni" [maxToday]="true"/>
      <input *ngIf="akce.stav == 'UA'" type="text" pick-a-date [(ngModel)]="akce.datumUkonceni" [maxToday]="true"/>
    </gmt-input>
  </div>
</div>
