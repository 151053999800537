import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ProjectStatusService } from '@app/common/services/project-status.service';
import { ExportModel } from '@app/common/models/export.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'gmt-export-form',
  templateUrl: './export-form.component.html',
  styleUrls: []
})
export class ExportFormComponent implements OnInit {

  @Input() exportCfg: ExportModel;
  @Input() displayedPrevious: boolean;
  @Input() displayed: boolean;
  @Output() displayChanged = new EventEmitter();

  loading = false;
  showNotification = false;
  notification;

  constructor(
    private restangular: Restangular,
    private projectStatusService: ProjectStatusService,
  ) {
    this.onDisplayChange = this.onDisplayChange.bind(this);
    this.onCreateExport = this.onCreateExport.bind(this);
  }

  ngOnInit() {
  }

  onCreateExport() {
    const setting = <any>ExportFormComponent.getSelectedSettings(this.exportCfg) || {};
    this.loading = true;
    let promise;

    if (this.exportCfg.backendCall && typeof this.exportCfg.backendCall === 'function') {
      promise = this.exportCfg.backendCall();
    }
    if (!promise) {
      promise = this.restangular.one('exports', this.exportCfg.key).customPOST(setting)
        .toPromise()
        .then((data) => {
          this.projectStatusService.refresh(data);
        });
    }

    promise
      .then(() => {
        this.notification = 'Generuji... ' + (this.exportCfg.notification ? this.exportCfg.notification + ' ' : '') + 'Průběh sledujte v záhlaví aplikace.';
        this.showNotification = true;
        setTimeout(() => {
          this.showNotification = false;
        }, 5000);
        return setTimeout(() => {
          this.loading = false;
        }, 3000);
      });
  }

  onDisplayChange() {
    this.displayChanged.emit();
  }

  /**
   * Checks if required params for export type are set.
   * Only single selection combos are checked right now,
   * as no other fields are considered required.
   *
   * For future cases: requiredParams length should be equal
   * to the sum of distinct types of filled required params
   * to consider the export settings valid.
   *
   * @returns {boolean}
   */
  isValid() {
    // special validation for statistics export
    if (typeof this.exportCfg.isValid === 'function') {
      return this.exportCfg.isValid();
    }

    const requiredParams = this.exportCfg.userParams && this.exportCfg.userParams.filter(p => p.required);

    return !requiredParams || requiredParams
      .every(p =>
        (p.type === 'combo' && !p.comboOptions.allowMultipleSelection && p.selectedObject && p.selectedObject.selected) ||
        (p.checklist.checkedItems.length)
      );
  }

  public static getSelectedSettings(exportCfg: ExportModel) {
    // if export does not have any options, then return null
    if (!exportCfg.userParams || exportCfg.userParams.length === 0) {
      return undefined;
    }

    const setting = {};

    for (const param of exportCfg.userParams) {
      setting[param.key] = ExportFormComponent.createSettingValues(param);
    }

    return { exportSettings: setting };
  }

  private static createSettingValues(param: any) {
    if (param.type === 'combo') {
      if (!param.selectedObject || !param.selectedObject.selected) {
        return [];
      }
      // only one item allowed in combo
      if (!(param.selectedObject.selected instanceof Array)) {
        return [ { objectId: param.selectedObject.selected.id } ];
      // multiple selection in combo
      } else {
        return param.selectedObject.selected.map(item => ({ objectId: item.id }));
      }
    }
    return param.selected.map(item => ({ selection: item.id }));
  }

  defaultPipe(pipeName: string) {
    if (pipeName) {
      return {
        transform: (value: any) => {
          return (value[pipeName] ? value[pipeName] : '');
        }
      };
    }
  }
}
