﻿import {Component, OnInit, EventEmitter, Input, Output, ElementRef, HostListener, Inject} from '@angular/core';
import * as _ from 'lodash';
import {FileUtils} from '@app/common/utils/file.utils';
import {allowedExtensions} from '@app/common/models/document.model';
import { APP_BRAND } from '@app/common/services/config.service';

const BASETEXT = `Vyberte soubory nebo je přetáhněte sem.`;

export class UploadFileExtended {
  extension: string;
  title: string;
  status: string;
  valid: boolean;
  fileEntry: File;
  progress: number;
  uploading: any;
  error: boolean;
  errorMessage: string;
  validationMessage: string;
  constructor(fileEntry: File) {
    this.fileEntry = fileEntry;
  }
}

@Component({
  selector: 'fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() validation: Function;
  @Output('change') onchange = new EventEmitter();
  @Input() message: string = BASETEXT;
  @Input() disabled = false;
  @Input() clearFilesAfterUpload = false;
  @Input() inputId = 'hiddenFileSelect';
  @Input() files: UploadFileExtended[] = [];
  @Input() draggable = true;

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
    private elementRef: ElementRef,
  ) {
    this.inputFilesAdded = this.inputFilesAdded.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.fileIsValid = this.fileIsValid.bind(this);
  }

  ngOnInit() { }

  @HostListener('dragover', [ '$event' ])
  public dragOver(event: any): boolean {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  @HostListener('drop', [ '$event' ])
  public dropFiles(event: any): void {
    if (!this.draggable) {
      return undefined;
    }
    event.preventDefault();

    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === 'file') {
            const file = event.dataTransfer.items[i].getAsFile();
            this.files.push(FileUtils.fileToUpload(file, this.fileIsValid));
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          const file = event.dataTransfer.files[i];
          this.files.push(FileUtils.fileToUpload(file, this.fileIsValid));
        }
      }
      this.dispatchChange();
    }
  }

  @HostListener('click')
  openFileUpload() {
    const fileSelect: any = document.getElementById(this.inputId);
    fileSelect.click();
  }

  inputFilesAdded(event) {
    if (event.target && event.target.files.length > 0) {
      this.files.push(...Array.from(event.target.files).map((file: File) => FileUtils.fileToUpload(file, this.fileIsValid)).filter(item => item));
      this.dispatchChange();
    }
    const fileSelect: any = document.getElementById(this.inputId);
    fileSelect.value = '';
  }

  removeFile(file) {
    _.pull(this.files, file);
    this.dispatchChange();
  }

  fileIsValid(file) {
    if (this.APP_BRAND.NAME === 'DTM' && !allowedExtensions.includes(file.extension.toLowerCase())) {
      file.validationMessage = 'nepodporovaný typ souboru';
      return false;
    }
    return typeof this.validation === 'function' ? this.validation(file) : true;
  }

  dispatchChange() {
    this.onchange.emit(this.files.filter(f => f.valid));
    if (this.clearFilesAfterUpload) {
      this.files = [];
    }
  }
}
