import {Inject, Injectable} from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {VerzePdModel} from "@app/doss/models/verze-pd.model";

@Injectable({
  providedIn: 'root'
})
export class VerzePdService {

  constructor(private restangular: Restangular) {
  }

  create(akceKey: string, verzePd: VerzePdModel): Promise<VerzePdModel> {
    return this.restangular.all(`verze-pd/create`)
      .post(verzePd)
      .toPromise();
  }

  update(akceKey: string, verzePd: VerzePdModel): Promise<VerzePdModel> {
    return this.restangular.one(`verze-pd/update/verze-pd`, verzePd.id)
      .customPUT(verzePd)
      .toPromise();
  }

  verzePdCreateStatus(akceKey: string): Promise<any> {
    return this.restangular.one(`verze-pd/create/status`)
      .get()
      .toPromise();
  }
}
