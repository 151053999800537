import {Component} from '@angular/core';
import {DialogRef} from "@app/common/services/dialog-ref";
import {StateService} from "@uirouter/angular";

@Component({
  templateUrl: './ukon-nelze-zalozit.component.html',
  styleUrls: ['./ukon-nelze-zalozit.component.scss']
})
export class UkonNelzeZalozitComponent {

  constructor(private stateService: StateService,
              private dialogComponent: DialogRef) {
    this.onZalozitNovouVerziPd = this.onZalozitNovouVerziPd.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onZalozitNovouVerziPd(): void {
    this.stateService.go('doss.akce.data.verzePd', {openVerzePdCreateModal: true});
    this.dialogComponent.close();
  }

  onCancel(): void {
    this.dialogComponent.close();
  }
}
