import {Component, Inject, OnInit} from '@angular/core';
import {SwitchOption} from "@app/common/components/switch/switch.component";
import {ErrorHandlerService} from "@app/common/services/error-handler.service";
import { Restangular } from 'ngx-restangular';
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import {AlertComponent} from "@app/common/components/alert/alert.component";
import {ClassName} from "@app/common/enums/class-name.enum";
import {DialogService} from "@app/common/services/dialog.service";
import {DotcenyOrganModel} from "@app/doss/models/dotceny-organ.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {DotcenyOrganPipe} from "@app/doss/pipes/dotceny-organ.pipe";
import {KontaktniOsobaComponent} from "@app/doss/components/kontaktni-osoba/kontaktni-osoba.component";
import {KontaktniOsobaModel} from "@app/doss/models/kontaktni-osoba.model";
import {StateService} from "@uirouter/angular";
import {AuthService} from "@app/common/services/auth.service";
import {DotcenyOrganService} from "@app/doss/services/dotceny-organ.service";
import {User} from "@app/models/user";
import * as moment from 'moment';
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './dotceny-organ-create.component.html',
  styleUrls: ['./dotceny-organ-create.component.scss']
})
export class DotcenyOrganCreateComponent implements OnInit {
  akceSchema: string;
  helpIds = HelpService.HELP_IDS;

  loading: boolean = true;
  selectedTab: any;
  tabs: any[] = [
    {
      id: 'czech-point',
      name: 'Czech POINT',
      orderNum: 0
    },
    {
      id: 'vyber-doss',
      name: 'VÝBĚR DOSS',
      orderNum: 1
    },
    {
      id: 'doplneni-dalsich-udaju',
      name: 'DOPLNĚNÍ DALŠÍCH ÚDAJŮ',
      orderNum: 2
    },
  ];

  options: SwitchOption[] = [
    {
      id: 'search-by-ico',
      value: 'Podle IČO'
    },
    {
      id: 'search-by-name',
      value: 'Podle názvu'
    }
  ];

  currentOption: SwitchOption;
  icoToSearch: string;
  nameToSearch: string;

  data: any;
  dotceneOrgany: DotcenyOrganModel[];

  protected readonly ValidationUtils = ValidationUtils;

  private redirectToPageAfterDossSave: string;
  private redirectToPageParamsAfterDossSave: any;

  constructor(public restangular: Restangular,
              @Inject(RESTANGULAR_DOSS) public restangularDoss: any,
              private authService: AuthService,
              public dotcenyOrganPipe: DotcenyOrganPipe,
              private errorHandlerService: ErrorHandlerService,
              private dotcenyOrganService: DotcenyOrganService,
              private dialogService: DialogService,
              private stateService: StateService) {
    this.selectTab = this.selectTab.bind(this);
    this.goToLastStep = this.goToLastStep.bind(this);
    this.handleSelectOption = this.handleSelectOption.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onUpdateSelectedDotcenyOrgan = this.onUpdateSelectedDotcenyOrgan.bind(this);
    this.onAddNewKontaktniOsoba = this.onAddNewKontaktniOsoba.bind(this);
    this.saveDotcenyOrgan = this.saveDotcenyOrgan.bind(this);
  }

  ngOnInit() {
    this.akceSchema = this.authService.getActualProject().schema;
    this.currentOption = this.options[0];
    this.selectedTab = this.tabs[0];
    this.dotceneOrgany = [];
    this.initData();
    this.loading = false;

    this.redirectToPageAfterDossSave = this.stateService.params.redirectToPageAfterDossSave;
    this.redirectToPageParamsAfterDossSave = this.stateService.params.redirectToPageParamsAfterDossSave;
  }

  canCreate(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  getZpetButtonRedirect(): string {
    return this.redirectToPageAfterDossSave || "^";
  }

  getZpetButtonRedirectParams(): any {
    if (this.redirectToPageAfterDossSave) {
      return Object.assign({akceKey: this.akceSchema}, this.redirectToPageParamsAfterDossSave || {});
    }
    return {};
  }

  selectTab(tab: any): void {
    this.selectedTab = tab;
    if (tab.onClick) {
      tab.onClick();
    }
  }

  isStepDisabled(tab: any): boolean {
    return this.selectedTab.orderNum < tab.orderNum;
  }

  goToLastStep() {
    this.selectTab(this.tabs[2]);
  }

  handleSelectOption(option: SwitchOption) {
    this.currentOption = option;
  }

  onUpdateSelectedDotcenyOrgan(dotcenyOrgan: DotcenyOrganModel): void {
    this.data.createKontaktniOsoba = false;
    this.data.createOdbor = false;
    this.data.createPracoviste = ValidationUtils.isNonEmptyArray(dotcenyOrgan.pracoviste);
  }

  onAddNewKontaktniOsoba(): void {
    const dialog = this.dialogService.open(KontaktniOsobaComponent, {
      data: {akceSchema: this.akceSchema},
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe((kontaktniOsoba: KontaktniOsobaModel) => {
      if (kontaktniOsoba) {
        if (!this.data.selectedDotcenyOrgan.kontaktniOsoby) {
          this.data.selectedDotcenyOrgan.kontaktniOsoby = [];
        }
        this.data.selectedDotcenyOrgan.kontaktniOsoby.push(kontaktniOsoba);
      }

      sub.unsubscribe();
    });
  }

  removeKontaktniOsoba(kontaktniOsoba: KontaktniOsobaModel): void {
    this.data.selectedDotcenyOrgan.kontaktniOsoby.splice(this.data.selectedDotcenyOrgan.kontaktniOsoby.indexOf(kontaktniOsoba), 1);
  }

  async saveDotcenyOrgan(): Promise<void> {
    await this.dotcenyOrganService.create(this.akceSchema, this.data.selectedDotcenyOrgan)
      .then((dotcenyOrgan: DotcenyOrganModel) => {
        if (this.redirectToPageAfterDossSave) {
          const params = Object.assign({
            akceKey: this.akceSchema,
            selectedDotcenyOrgan: dotcenyOrgan
          }, this.redirectToPageParamsAfterDossSave || {});
          this.stateService.go(this.redirectToPageAfterDossSave, params)
        } else {
          this.stateService.go('doss.akce.data.organy.detail', {id: dotcenyOrgan.id})
        }
      });
  }

  slectedDotcenyOrgan(): boolean {
    return !!this.data.selectedDotcenyOrgan;
  }

  toggleCreateKontaktniOsoba(): void {
    this.data.createKontaktniOsoba = !this.data.createKontaktniOsoba;
  }

  async handleSearch() {
    if (!this.loading) {
      this.loading = true;
      this.dotceneOrgany = [];
      this.initData();

      try {
        const dotceneOrgany = await this.restangularDoss
          .all('geopas/ovmByTerm')
          .customGET(this.currentOption.id, {search: this.getSearchedValue()})
          .toPromise();

        if (ValidationUtils.isNonEmptyArray(dotceneOrgany.items)) {
          if (dotceneOrgany.itemCount === 1) {
            this.data.selectedDotcenyOrgan = dotceneOrgany.items[0];
            this.data.createPracoviste = ValidationUtils.isNonEmptyArray(this.data.selectedDotcenyOrgan.pracoviste);
            this.goToLastStep();
          } else {
            this.dotceneOrgany = dotceneOrgany.items;
            this.selectTab(this.tabs[1]);
          }
        } else {
          const message = dotceneOrgany.itemCount === 0 ? 'Orgán podle zadaných údajů nenalezen.' : 'Název orgánu zadejte přesněji, dotaz poskytuje velký počet výsledků.';
          this.showFailedNotification(message);
        }
      } catch (e) {
        this.errorHandlerService.get(e);
      } finally {
        this.loading = false;
      }
    }
  }

  private showFailedNotification(msg: string) {
    this.dialogService.open(AlertComponent, {
      className: ClassName.ADJUSTED_DIALOG,
      data: {msg: msg}
    });
  }

  private getSearchedValue(): string {
    return this.currentOption.id === 'search-by-ico' ? this.icoToSearch : this.nameToSearch;
  }

  private initData(): void {
    this.data = {
      selectedDotcenyOrgan: null,
      createKontaktniOsoba: false,
      createOdbor: false,
      createPracoviste: false
    };
  }
}
