import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { ListService } from '@app/common/services/list.service';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { RESTANGULAR_DOSS } from '@app/common/services/restangular-doss.service';
import { Restangular } from 'ngx-restangular';
import { APPLICATIONS } from '@app/common/services/config.service';
import { EVENTS } from '@app/common/services/config.service';

@Injectable({providedIn: 'root'})
export class HelpService {

  public static readonly HELP_IDS = {
    CONSTRUCTIONOBJECT_DETAIL: 1,
    CASES_DETAIL: 2,
    CONSTRUCTIONOBJECT_CREATECASE_PRICE: 3,
    CONSTRUCTIONOBJECT_CREATECASE_SELECT_AREA: 4,
    CONSTRUCTIONOBJECT_CREATECASE_SELECT_EASEMENT: 5,
    CONSTRUCTIONOBJECT_CREATECASE_SELECT_PARENT_OWNERSHIP: 6,
    CONSTRUCTIONOBJECT_CREATECASE_SUMMARY: 7,
    PANEL_HOME: 8,
    PANEL_CASES: 9,
    PANEL_TITLES: 10,
    PANEL_OCCUPATIONS: 11,
    PANEL_CONSTRUCTION_OBJECTS: 12,
    PANEL_PARCELS: 13,
    PANEL_MAP: 14,
    PANEL_STATISTICS: 15,
    PANEL_EXPORTS: 16,
    PANEL_SETTINGS: 17,
    TITLE_DETAIL: 18,
    SETTINGS_CHANGE_PASSWORD: 19,
    SETTINGS_EXPERTS: 20,
    SETTINGS_ADMINISTRATORS: 21,
    SETTINGS_TEMPLATES: 22,
    SETTINGS_USERS: 23,
    PANEL_TITLES_RIGHTS: 24,
    CONSTRUCTION_OBJECT_NEW_SELECT: 25,
    CONSTRUCTION_OBJECT_NEW_SUMMARY: 26,
    CONSTRUCTION_OBJECT_NEW_EASEMENT_ADD_FILES: 27,
    CONSTRUCTION_OBJECT_NEW_EASEMENT_INFO: 28,
    CONSTRUCTION_OBJECT_NEW_EASEMENT_SUMMARY: 29,
    TITLE_DETAIL_BLOCK_TITLE: 30,
    TITLE_DETAIL_OPINIONS: 31,
    TITLE_DETAIL_OPINIONS_CREATE_OTHERS: 32,
    TITLE_DETAIL_OPINIONS_CREATE_EXPERTS_OPINION: 33,
    TITLE_DETAIL_OPINIONS_UPDATE_PRELIMINARY_OPINION: 34,
    TITLE_DETAIL_CREATE_CASE_SELECT: 35,
    TITLE_DETAIL_CREATE_CASE_SELECT_TITLES: 36,
    TITLE_DETAIL_CREATE_CASE_SELECT_PARENT_OWNERSHIP: 37,
    TITLE_DETAIL_CREATE_CASE_CONFIRM: 38,
    TITLE_DETAIL_CREATE_CASE_SUMMARY: 39,
    CONSTRUCTIONOBJECT_UPDATE_GLOBAL_EASEMENT_PRICE: 40,
    CONSTRUCTIONOBJECT_UPDATE_EASEMENT_PRICE: 41,
    PK_PROJECTS: 42,
    CASES_DETAIL_GENERATE_DOCUMENTS: 45,
    CASES_DETAIL_UPDATE_SUBJECT: 46,
    CASES_DETAIL_UPDATE: 47,
    VM_PANEL_PROPERTY_STATUS: 48,
    VM_PANEL_MAP: 49,
    VM_PANEL_TITLES: 50,
    VM_PANEL_PARCELS: 51,
    VM_TITLE_DETAIL: 52,
    VM_PARCEL_DETAIL: 53,
    PK_DOWNLOAD_DATA: 54,
    VM_PANEL_SETTINGS: 55,
    PK_CREATE_FIRST_STEP: 56,
    PK_CREATE_SECOND_STEP: 57,
    PK_CREATE_THIRD_STEP: 58,
    PK_SETTINGS: 59,
    PK_SETTINGS_WSDP: 60,
    PS_SUBJECT_DETAIL: 61,
    PS_SUBJECT_LIST: 62,
    DASHBOARD_PROJECTS: 63,
    GEOMETRIC_PLAN_REQUEST_LIST: 64,
    GEOMETRIC_PLAN_REQUEST_DETAIL: 65,
    OPINION_REQUEST_LIST: 66,
    OPINION_REQUEST_DETAIL: 67,
    PS_PROJECT_INFO: 68,
    TICKET_LIST: 69,
    SAMPLE_LIST: 70,
    HOME: 71,
    SY_PROJECTS: 72,

    DOSS_EXPORTS: 100,
    DOSS_HOME: 101,
    DOSS_AKCE_CREATE: 102,
    DOSS_AKCE_INFO: 103,
    DOSS_AKCE_LIST: 104,
    DOSS_AKCE_OVERVIEW: 105,
    DOSS_AKCE_UPDATE: 106,
    DOSS_ORGANY_LIST: 107,
    DOSS_ORGANY_CREATE: 108,
    DOSS_ORGANY_DETAIL: 109,
    DOSS_VERZE_PD: 110,
    DOSS_UKON_CREATE: 111,
    DOSS_UKON_DETAIL: 112,
    DOSS_UKON_UPDATE: 113,
    DOSS_UKON_LIST: 114,
  };

  private helpResource = null;

  constructor(
    private authService: AuthService,
    private listService: ListService,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    @Inject(RESTANGULAR_DOSS) private restangularDoss: Restangular,
    @Inject(APPLICATIONS) private APPLICATIONS: any,
  ) {
    this.authService.registerCallback(EVENTS.authApplicationUpdated, (event) => {
      if (this.helpResource !== this.getRestangular()) {
        this.HELP = null;
        this.helpLoader = null;
      }
    });
  }

  HELP = null;
  helpLoader = null;

  getRestangular() : any {
    switch (this.authService.getActiveApplication()) {
      case this.APPLICATIONS.doss.name:
        this.helpResource = this.restangularDoss;
      break;
      default:
        this.helpResource = this.restangularSymap;
    }
    return this.helpResource;
  }

  loadHelp() {
    const resource = this.getRestangular();
    const defered = new Promise((resolve, reject) => {
      if (this.HELP) {
        resolve(this.HELP);
      } else if (this.helpLoader) {
        this.helpLoader.then(() => {
          resolve(this.HELP);
        });
      } else {
        const list = this.listService.createList('help', {limit: null}, this.getRestangular());
        this.helpLoader = this.listService.fetchResult(list).then((data) => {
            if (resource !== this.getRestangular()) {
              return reject();
            }
            this.HELP = data;
            this.HELP.list.forEach((h) => {
              h.strId = Object.keys(HelpService.HELP_IDS).find(key => HelpService.HELP_IDS[key] === h.id);
            });
            this.helpLoader = null;
            resolve(this.HELP);
          },
          () => {
            this.helpLoader = null;
            reject();
          });
      }
    });

    return defered;
  }

  getHelp() {
    return this.HELP;
  }

  saveChanges(help) {
    help.application = this.authService.getActiveApplication();
    return this.getRestangular().one('help').customPUT(help).toPromise().then((data) => {
      const helpId = help.id;
      const oldHelp = this.getHelpById(helpId);
      if (oldHelp) {
        const helpIndex = this.HELP.list.indexOf(oldHelp);
        this.HELP.list.splice(helpIndex, 1, data);
      } else {
        this.HELP.list.push(data);
      }
    });
  }

  getHelpById(helpId) {
    return this.loadHelp().then(() => {
      return this.HELP.list.find((help) => {
        return help.id === helpId;
      });
    });
  }
}
