<div class="detail" [loading]="!loaded">
  <div>
    <a uiSref="doss.akce.data.detail" [uiParams]="{ akceKey: akce.schema }" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Zpět na kartu akce</a>
  </div>
  <h1>Nastavení akce</h1>

  <div *ngIf="loaded">
    <wizard [submitButtonName]="'Uložit'" [submitAction]="updateAkce" *ngIf="canEdit()">
      <wizard-tab [isValid]="firstStepComponent.isValid()">
        <first-step #firstStepComponent [akce]="akce" [isUpdate]="true"></first-step>
      </wizard-tab>

      <wizard-tab>
        <third-step [akce]="akce"></third-step>
      </wizard-tab>
    </wizard>
  </div>
</div>
<help [helpId]="helpIds.DOSS_AKCE_UPDATE" class="cm-help-page"></help>
