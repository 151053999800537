import {Component, Inject, Input, OnInit} from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {UkonModel} from "@app/doss/models/ukon.model";
import {PracovisteModel} from "@app/doss/models/pracoviste.model";
import {DialogService} from "@app/common/services/dialog.service";
import {
  PracovisteComponent
} from "@app/doss/components/pracoviste/pracoviste.component";
import {PracovistePipe} from "@app/doss/pipes/pracoviste.pipe";
import {OdborModel} from "@app/doss/models/odbor.model";
import {OdborComponent} from "@app/doss/components/odbor/odbor.component";
import {KontaktniOsobaModel} from "@app/doss/models/kontaktni-osoba.model";
import {KontaktniOsobaComponent} from "@app/doss/components/kontaktni-osoba/kontaktni-osoba.component";
import {OdborPipe} from "@app/doss/pipes/odbor.pipe";
import {DotcenyOrganPipe} from "@app/doss/pipes/dotceny-organ.pipe";
import {DotcenyOrganModel} from "@app/doss/models/dotceny-organ.model";
import {KontaktniOsobaPipe} from "@app/doss/pipes/kontaktni-osoba.pipe";
import {ClassName} from "@app/common/enums/class-name.enum";
import {StateService} from "@uirouter/angular";

@Component({
  selector: 'dotceny-organ-form',
  templateUrl: './dotceny-organ-form.component.html',
  styleUrls: ['./dotceny-organ-form.component.scss']
})
export class DotcenyOrganFormComponent implements OnInit {

  @Input()
  redirectToPageAfterDossSave: string;

  @Input()
  redirectToPageParamsAfterDossSave: any;

  @Input()
  ukon: UkonModel;

  @Input()
  isUpdate: boolean = false;

  odbory: OdborModel[] = [];
  pracoviste: PracovisteModel[] = [];
  kontaktniOsoby: KontaktniOsobaModel[] = [];

  constructor(public restangular: Restangular,
              public dotcenyOrganPipe: DotcenyOrganPipe,
              public pracovistePipe: PracovistePipe,
              public odborPipe: OdborPipe,
              public kontaktniOsobaPipe: KontaktniOsobaPipe,
              private stateService: StateService,
              private dialogService: DialogService) {
    this.onDotcenyOrganCreate = this.onDotcenyOrganCreate.bind(this);
    this.onPracovisteCreate = this.onPracovisteCreate.bind(this);
    this.onOdborCreate = this.onOdborCreate.bind(this);
    this.onKontaktniOsobaCreate = this.onKontaktniOsobaCreate.bind(this);
  }

  ngOnInit() {
    if (this.ukon.dotcenyOrgan) {
      this.odbory = this.ukon.dotcenyOrgan.odbory;
      this.pracoviste = this.ukon.dotcenyOrgan.pracoviste;
      this.kontaktniOsoby = this.ukon.dotcenyOrgan.kontaktniOsoby;
    }
  }

  onPracovisteCreate(): void {
    const dialog = this.dialogService.open(PracovisteComponent, {
      data: {
        dotcenyOrganId: this.ukon.dotcenyOrgan.id
      },
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe((res: PracovisteModel) => {
      if (res) {
        this.ukon.pracoviste = res;
      }

      sub.unsubscribe();
    });
  }

  onDotcenyOrganCreate(): void {
    this.stateService.go('doss.akce.data.organy.create', {redirectToPageAfterDossSave: this.redirectToPageAfterDossSave, redirectToPageParamsAfterDossSave: this.redirectToPageParamsAfterDossSave});
  }

  onOdborCreate(): void {
    const dialog = this.dialogService.open(OdborComponent, {
      data: {
        dotcenyOrganId: this.ukon.dotcenyOrgan.id
      },
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe((odbor: OdborModel) => {
      if (odbor) {
        this.ukon.odbor = odbor;
      }

      sub.unsubscribe();
    });
  }

  onKontaktniOsobaCreate(): void {
    const dialog = this.dialogService.open(KontaktniOsobaComponent, {
      data: {
        dotcenyOrganId: this.ukon.dotcenyOrgan.id
      },
      className: ClassName.ADJUSTED_DIALOG
    });

    const sub = dialog.afterClosed.subscribe((kontaktniOsoba: KontaktniOsobaModel) => {
      if (kontaktniOsoba) {
        this.ukon.kontaktniOsoba = kontaktniOsoba;
      }

      sub.unsubscribe();
    });
  }

  onDotcenyOrganSelect(dotcenyOrgan: DotcenyOrganModel): void {
    this.kontaktniOsoby.splice(0);
    this.odbory.splice(0);
    this.pracoviste.splice(0);

    this.ukon.kontaktniOsoba = null;
    this.ukon.odbor = null;
    this.ukon.pracoviste = null;

    if (dotcenyOrgan) {
      this.odbory.push(...dotcenyOrgan.odbory);
      this.pracoviste.push(...dotcenyOrgan.pracoviste);
      this.kontaktniOsoby.push(...dotcenyOrgan.kontaktniOsoby);
    }
  }
}
