import { NgModule } from '@angular/core';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from '@app/common/services/auth.service';
import { Ng2StateDeclaration, Transition, UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@app/common/common.module';
import {APP_BRAND, APP_CONFIG, APPLICATIONS} from '@app/common/services/config.service';
import {LoginService} from "@app/common/services/login.service";

export const loginState: Ng2StateDeclaration = {
  name: 'login',
  url: '/login?redirectUrl&secret&SAMLart&loggedOut&code&state&ssoLogoutToken&session_state&maintanance',
  component: LoginComponent,
  onEnter: checkAuth,
};

export const otherwise: Ng2StateDeclaration = {
  name: 'otherwise',
  url: '/otherwise',
  onEnter: otherwiseFn
};

export function otherwiseFn(transition: Transition) {
  const loginService = transition.injector().get(LoginService);
  return loginService.redirectToLogin(true);
}

export function checkAuth(transition: Transition) {
  const authService = transition.injector().get(AuthService);
  const applications = transition.injector().get(APPLICATIONS);
  const brand = transition.injector().get(APP_BRAND);
  const config = transition.injector().get(APP_CONFIG);

  if (authService.isAuthenticated()) {
    if (brand.NAME === 'RSD' || brand.NAME === 'SZ') {
      if (authService.getActiveApplication() !== applications.doss.name) {
        authService.setActiveApplication(applications.landing.name);
      }
    }
    return authService.goToActiveAplication();
  }

  const params = transition.params();

  if (config.MAINTANANCE && !params.maintanance) {
    return true;
  }

  if (params.loggedOut !== 'true' && !params.SAMLart && !params.code && authService.isSsoLoginEnabled(params.secret)) {
    return authService.ssoLogin();
  }

  return true;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        loginState,
        otherwise,
      ]
    }),
    CommonModule,
  ],
  providers: [],
  declarations: [
    LoginComponent,
  ],
  entryComponents: [
    LoginComponent
  ]
})
export class LoginAppModule {
}
