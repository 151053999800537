import {Component, Input} from '@angular/core';

@Component({
  selector: "wizard-tab",
  template: `
    <div [hidden]="!isActive">
      <ng-content></ng-content>
    </div>`
})
export class WizardTabComponent {
  @Input()
  isActive: boolean = false;

  @Input()
  isValid: boolean = true;

  @Input()
  nextStepAction?: Function;
}
