import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ModulesService } from '@app/common/services/modules.service';
import { SelectItem } from '@app/common/components/select/select.component';
import { RolePipe } from '@app/common/pipes/role.pipe';
import { APPLICATIONS } from '@app/common/services/config.service';

export interface PermissionWithRoles {
  id: number;
  description: string;
  name: string;
  roles: string[];
}

export interface Permission {
  id: string;
  value: string;
}

export interface PermissionPayload {
  application: string;
  projectKey: string;
  projectRole?: string;
  permissions: string[];
}

@Component({
  selector: 'user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPermissionsComponent implements OnInit {
  roleEnabled: boolean;
  roles: SelectItem[];
  permissions: Permission[];
  currentPermissions: Permission[];
  currentRole: SelectItem;
  currentRolePermissions: Permission[];
  permissionsWithRoles: any[];
  private projectKey: string;
  private application: string;
  private userId: number;
  private initialPermissions: Permission[];

  constructor(
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
    private modulesService: ModulesService,
    @Inject(APPLICATIONS) private applications,
    public rolePipe: RolePipe,
  ) {
  }

  ngOnInit() {
    const data = <DialogConfigData>this.dialogConfig.data;
    this.application = data.application;
    this.roleEnabled = this.application === this.applications.sy.name || this.application === this.applications.doss.name;
    this.projectKey = data.projectKey;
    this.userId = data.userId;
    this.roles = data.roles;
    this.permissionsWithRoles = data.permissionsWithRoles;
    this.currentRole = data.currentRole && this.roles.find(role => role.id === data.currentRole.role);
    this.initialPermissions = this.getPermissions(this.permissionsWithRoles);
    this.currentPermissions = data.additionalPermissions || [];

    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.setRoleAndPermissions();
  }

  handleRoleSelect() {
    this.currentPermissions = [];
    this.setRoleAndPermissions();
  }

  handleItemSelect(items: Permission[]) {
    this.currentPermissions = items;
  }

  async handleSubmit() {
    const permissions = this.currentPermissions && this.currentPermissions.map(item => item.id);
    const currentRoleId: string = <string>(this.currentRole && this.currentRole.id);
    const payload: PermissionPayload = {
      application: this.application,
      projectKey: this.projectKey,
      permissions: permissions || [],
    };

    if (currentRoleId) {
      payload.projectRole = currentRoleId;
    }

    await this.modulesService.saveUserPermissions(this.application, this.projectKey, this.userId, payload);

    this.dialogRef.close(true);
  }

  private getPermissions(source: PermissionWithRoles[]): Permission[] {
    return source.map(item => ({id: item.name, value: item.description}));
  }

  private getCurrentRolePermissions(): Permission[] {
    const rolePermissions = this.permissionsWithRoles.filter(pwr => pwr.roles.includes(this.currentRole && this.currentRole.id)) || [];
    return rolePermissions.map(rp => ({id: rp.name, value: rp.description}));
  }

  private setRoleAndPermissions() {
    const currentPermissions = this.getCurrentRolePermissions().concat(this.currentPermissions);
    this.permissions = _.differenceBy(this.initialPermissions, currentPermissions, 'id');
    this.currentRolePermissions = _.sortBy(this.getCurrentRolePermissions(), 'value');
  }
}
