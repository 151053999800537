<fieldset>
  <legend>Odbor</legend>
  <gmt-checkbox (click)="toggleCreateOdbor()" [gmtChecked]="data.createOdbor"></gmt-checkbox>
  Odbor

  <ng-container *ngIf="data.createOdbor">
    <ng-container *ngFor="let odbor of data.selectedDotcenyOrgan?.odbory">
      <div class="padding-10">
        <div>
          {{ odbor.nazev }}
          <a (click)="removeOdbor(odbor)" *ngIf="!odbor.id"><i class="fa fa-trash-o"></i></a>
        </div>
      </div>
    </ng-container>

    <div class="padding-10">
      <gmt-button [clickAction]="onAddNewOdbor"><i class="fa fa-plus"></i></gmt-button>
    </div>
  </ng-container>
</fieldset>

<fieldset>
  <legend>Pracovište</legend>
  <gmt-checkbox (click)="toggleCreatePracoviste()" [gmtChecked]="data.createPracoviste"></gmt-checkbox>
  Pracovište

  <ng-container *ngIf="data.createPracoviste">
    <div *ngFor="let pracoviste of data.selectedDotcenyOrgan?.pracoviste" class="padding-10">
      <div>
        {{ pracoviste.nazev }}
        <a (click)="removePracoviste(pracoviste)" *ngIf="!pracoviste.fromGeopas && !pracoviste.id">
          <i class="fa fa-trash-o"></i>
        </a>
      </div>
      <div>{{ pracoviste.adresniMisto | address }}</div>
    </div>

    <div class="padding-10">
      <gmt-button [clickAction]="onAddNewPracoviste"><i class="fa fa-plus"></i></gmt-button>
    </div>
  </ng-container>
</fieldset>
