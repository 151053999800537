import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DialogService} from '@app/common/services/dialog.service';
import {ConfirmationComponent} from '@app/common/components/confirmation/confirmation.component';
import {ClassName} from '@app/common/enums/class-name.enum';
import {AuthService} from '@app/common/services/auth.service';
import {UploadFileExtended} from '@app/common/components/fileupload/fileupload.component';
import {
  DisabledDocumentType,
  DocumentChooseTypeFormComponent
} from '@app/common/components/document-choose-type-form/document-choose-type-form.component';
import {DocumentTypeModel} from '@app/common/models/document-type.model';
import {DocumentEditFormComponent} from "@app/common/components/document-edit-form/document-edit-form.component";
import {DocumentModel} from "@app/common/models/document.model";
import {ValidationUtils} from "@app/doss/utils/validation.utils";
import {DokumentTypePipe} from "@app/doss/pipes/file-to-upload-type.pipe";
import {DokumentAdditionalDataModel} from "@app/doss/models/dokument-additional-data.model";

@Component({
  selector: 'ukon-status-documents',
  templateUrl: './ukon-status-documents.component.html',
  styleUrls: ['./ukon-status-documents.component.scss']
})
export class UkonStatusDocumentsComponent implements OnInit {
  // bindings
  @Input() defaultData: any = {};
  @Input() defaultDocumentType: DocumentTypeModel;
  @Input() dokumenty: File[] = [];
  @Input() dokumentyAdditionalData: DokumentAdditionalDataModel[] = [];
  @Input() allowType: boolean;
  @Input() maxHeight: number;
  @Input() authorizedUser: boolean;
  @Input() draggable = true;
  @Input() required = true;
  @Input() documentCategory = 'doss';
  @Input() disabledDocumentTypes: DisabledDocumentType[];
  @Output() updated = new EventEmitter<boolean>();

  // model
  restBaseUrl: string;
  authToken: string;

  userId: number;
  referentId: number;

  public readonly ValidationUtils = ValidationUtils;

  constructor(private authService: AuthService,
              private dialogService: DialogService,
              private fileToUploadTypePipe: DokumentTypePipe) {
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onEditDocument = this.onEditDocument.bind(this);
    this.onDeleteDocument = this.onDeleteDocument.bind(this);
  }

  ngOnInit() {
    this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
    this.authToken = this.authService.getToken();
    this.referentId = this.authService.getActualProject().referent ? this.authService.getActualProject().referent.id : null;
    this.userId = this.authService.getUser().id;
  }

  canUploadFiles() {
    return this.authService.hasPermissionOnProject('manipulate_files') && this.userId === this.referentId;
  }

  onFileSelect(files: UploadFileExtended[]) {
    if (files.length === 1) {
      const dialog = this.dialogService.open(DocumentChooseTypeFormComponent, {
        data: {
          allowType: this.allowType,
          documentCategory: this.documentCategory,
          file: files[0],
          defaultDocumentType: this.defaultDocumentType,
          disabledDocumentTypes: this.disabledDocumentTypes
        },
        className: ClassName.HIGHER_DIALOG,
      });

      const sub = dialog.afterClosed.subscribe((result: { type: string, note: string, baseName: string } | boolean) => {
        if (typeof result === 'object') {
          files.forEach(file => this.insertFileToUpload(file, result.type, result.note, result.baseName));
        } else {
          this.dokumenty.splice(0, this.dokumenty.length);
          files.splice(0, files.length);
        }
        sub.unsubscribe();
      });
    } else {
      for (const file of files.filter(file => !file.uploading)) {
        this.insertFileToUpload(file);
      }
    }
  }

  onEditDocument(dokumentAdditionalData: DokumentAdditionalDataModel) {
    const dialog = this.dialogService.open(DocumentEditFormComponent, {
      data: {
        allowType: this.allowType,
        omitFileUpload: true,
        document: {...dokumentAdditionalData},
        documentCategory: this.documentCategory,
        disabledDocumentTypes: this.disabledDocumentTypes
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: DocumentModel) => {
      if (result) {
        dokumentAdditionalData.baseName = result.baseName;
        dokumentAdditionalData.attachmentType = result.type;
        dokumentAdditionalData.note = result.note;

        this.updated.emit(true);
      }
      sub.unsubscribe();
    });
  }

  onDeleteDocument(index: number) {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete soubor smazat?',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.dokumenty.splice(index, 1)
        this.dokumentyAdditionalData.splice(index, 1)
        this.updated.emit(true);
      }
      sub.unsubscribe();
    });
  }

  getIconClass(dokumentAdditionalData: DokumentAdditionalDataModel): string {
    return `mr-10 fa fa-lg flex-icon ${this.fileToUploadTypePipe.transform(dokumentAdditionalData, 'icon')}`;
  }

  private insertFileToUpload(file: UploadFileExtended, attachmentType = '', note = '', baseName?) {
    this.dokumenty.push(file.fileEntry);

    this.dokumentyAdditionalData.push({
      filename: file.fileEntry.name,
      baseName: baseName,
      extension: file.extension,
      note: note,
      attachmentType: attachmentType
    });
  }
}
