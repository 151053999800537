<div *ngIf="ValidationUtils.isNonEmptyArray(propojeniUkonuList); else noData" class="bordered">
  <table>
    <thead class="sticky">
    <th class="cell">
      {{ getColumnName('vazba') }}
    </th>
    <th class="cell">
      {{ getColumnName('ukon') }}
    </th>
    <th class="cell">
      {{ getColumnName('duvodPropojeni') }}
    </th>
    <th></th>
    </thead>

    <tbody *ngFor="let propojeniUkonu of propojeniUkonuList" class="row-group">
    <tr [uiParams]="{id: propojeniUkonu.targetUkon.id}" [uiSref]="'doss.akce.data.ukony.detail'" class="clickable">
      <td class="cell">
        {{ propojeniUkonu.typPropojeni | typPropojeni }}
      </td>
      <td class="cell" style="white-space: pre-wrap">{{ propojeniUkonu.targetUkon | ukonPropojeni }}
      </td>
      <td class="cell">
        {{ propojeniUkonu.duvodPropojeni }}
      </td>
      <td>
        <div class="fr" *ngIf="canEdit()">
          <a (click)="$event.stopPropagation();onUpdate.emit(propojeniUkonu)" class="button" title="Upravit">
            <span class="fa fa-pencil fa-lg"></span>
          </a>
          <a (click)="$event.stopPropagation();onDelete.emit(propojeniUkonu)" class="remove-propojeni button red" title="Odstranit">
            <span class="fa fa-trash-o fa-lg"></span>
          </a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #noData>
  <div class="empty-info bordered">
    Nenalezena žádná data
  </div>
</ng-template>
