<div class="detail">
  <div>
    <a uiSref="doss.akce.list" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Zpět na seznam akcí</a>
  </div>
  <h1>Založení nové akce</h1>

  <wizard [submitButtonName]="'Založit akci'" [submitAction]="createAkce" *ngIf="canCreate()">
    <wizard-tab [isValid]="firstStepComponent.isValid()">
      <first-step #firstStepComponent [akce]="akce"></first-step>
    </wizard-tab>

    <wizard-tab [nextStepAction]="secondStepComponent.getEntityByGeometry"
                [isValid]="secondStepComponent.isValid()">
      <second-step #secondStepComponent [akce]="akce"></second-step>
    </wizard-tab>

    <wizard-tab [isValid]="thirdStepComponent.isValid()">
      <third-step #thirdStepComponent [akce]="akce"></third-step>
    </wizard-tab>
  </wizard>
</div>
<help [helpId]="helpIds.DOSS_AKCE_CREATE" class="cm-help-page"></help>
