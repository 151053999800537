import {Component, Inject, OnInit} from '@angular/core';
import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import {DialogRef} from '@app/common/services/dialog-ref';
import {VerzePdModel} from "@app/doss/models/verze-pd.model";
import {KategorieDossPipe} from "@app/doss/pipes/kategorie-doss.pipe";
import {KategorieDossModel} from "@app/doss/models/kategorie-doss.model";
import {RESTANGULAR_DOSS} from "@app/common/services/restangular-doss.service";
import {VerzePdService} from "@app/doss/services/verze-pd.service";
import {ValidationUtils} from "@app/doss/utils/validation.utils";

@Component({
  selector: 'note-form',
  templateUrl: './verze-pd-form.component.html',
  styleUrls: ['./verze-pd-form.component.scss']
})
export class VerzePdFormComponent implements OnInit {
  akceSchema: string;
  isEdit: boolean;
  existingVerzePd: VerzePdModel[];

  verzePd: VerzePdModel;
  kategorieDoss: KategorieDossModel[] = [];
  selectedKategorieDoss = <any>{};
  minFromDate: Date;

  constructor(@Inject(RESTANGULAR_DOSS) public restangularDoss: any,
              public dialogComponent: DialogRef,
              public kategorieDossPipe: KategorieDossPipe,
              private dialogConfig: DialogConfig,
              private verzePdService: VerzePdService) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async ngOnInit() {
    this.akceSchema = (<DialogConfigData>this.dialogConfig.data).akceSchema;
    this.verzePd = (<DialogConfigData>this.dialogConfig.data).verzePd || <VerzePdModel>{};
    this.existingVerzePd = [...(<DialogConfigData>this.dialogConfig.data).existingVerzePd || []];
    this.isEdit = (<DialogConfigData>this.dialogConfig.data).isEdit || false;

    if (!this.isFirstVerzePd()) {
      this.minFromDate = new Date(this.existingVerzePd.sort((a, b) => b.id - a.id)[0].platnaOd);
      this.minFromDate.setDate(this.minFromDate.getDate() + 1);
    }

    await this.loadKategorieDoss();
  }


  onSave() {
    if (this.isEdit) {
      return this.verzePdService.update(this.akceSchema, this.verzePd).then(data => {
        this.dialogComponent.close(data);
      });
    }

    if (!this.isFirstVerzePd()) {
      this.verzePd.kategorieDoss = this.selectedKategorieDoss.values.map(value => value.id);
    }

    return this.verzePdService.create(this.akceSchema, this.verzePd).then(data => {
      this.dialogComponent.close(data);
    });
  }

  onCancel() {
    this.dialogComponent.close(false);
  }

  public shouldDisableSave() {
    return !(this.verzePd && this.verzePd.cisloVerze && this.verzePd.cisloVerze.trim() &&
      this.verzePd.popisVerze && this.verzePd.popisVerze.trim() &&
      this.verzePd.platnaOd)
  }

  public isFirstVerzePd(): boolean {
    return !ValidationUtils.isNonEmptyArray(this.existingVerzePd);
  }

  private loadKategorieDoss(): Promise<void> {
    return this.restangularDoss
      .all('seznamy/kategorieDoss')
      .getList().toPromise()
      .then(data => this.kategorieDoss = data);
  }
}
