import {AfterContentChecked, Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {WizardTabComponent} from "@app/doss/components/wizard-tab/wizard-tab.component";

@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements AfterContentChecked {
  @Input()
  backButtonName: string = "Zpět";
  @Input()
  nextButtonName: string = "Další";
  @Input()
  submitButtonName: string;

  @Input()
  submitAction?: Function;

  @ContentChildren(WizardTabComponent)
  tabs: QueryList<WizardTabComponent>;

  private selectedTab: WizardTabComponent;

  constructor() {
    this.onBackStep = this.onBackStep.bind(this);
    this.onNextStep = this.onNextStep.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  ngAfterContentChecked(): void {
    if (this.tabs && this.tabs.length > 0 && !this.selectedTab) {
      this.selectTab(0);
    }
  }

  async onBackStep(): Promise<void> {
    this.selectTab(this.tabs.toArray().indexOf(this.selectedTab) - 1);
  }

  async onNextStep(): Promise<void> {
    if (!this.selectedTab.nextStepAction || await this.selectedTab.nextStepAction()) {
      this.selectTab(this.tabs.toArray().indexOf(this.selectedTab) + 1);
    }
  }

  async onSubmit(): Promise<void> {
    this.submitAction && await this.submitAction();
  }

  isValid(): boolean {
    return this.selectedTab && this.selectedTab.isValid;
  }

  isFirstStep(): boolean {
    return this.selectedTab === this.tabs.first;
  }

  isFinalStep(): boolean {
    return this.selectedTab === this.tabs.last;
  }

  private selectTab(selectTabIndex: number): void {
    this.tabs.forEach((tab, index) => tab.isActive = index === selectTabIndex);
    this.selectedTab = this.tabs.toArray()[selectTabIndex];
  }
}
