<div class="wrapper">
  <div *ngFor="let typPropojeni of Object.keys(mappedData); last as isLast" [ngClass]="!isLast ? 'mb-10' : ''">
    <label class="one-line">{{ typPropojeni | typPropojeni }}</label>

    <div *ngFor="let ukon of mappedData[typPropojeni]" class="one-line">
      <a [uiParams]="{id: ukon.id}" [uiSref]="'doss.akce.data.ukony.detail'">
        {{ ukon.typUkonu.description }}
      </a>
      ({{ StavUkonuUtils.getStavAndFazeUkonuDesc(ukon.stavUkonu) }} {{ ukon.stavUkonu.datumZmeny | date }})
    </div>
  </div>
</div>
