import {Ng2StateDeclaration} from '@uirouter/angular';
import {
  DotceneOrganyListComponent
} from "@app/doss/components/dotcene-organy-list/dotcene-organy-list.component";
import {
  DotcenyOrganCreateComponent
} from "@app/doss/components/dotceny-organ-create/dotceny-organ-create.component";
import {resolveId} from "@app/common/utils/state.utils";
import {
  DotcenyOrganDetailComponent
} from "@app/doss/components/dotceny-organ-detail/dotceny-organ-detail.component";

export const organListState: Ng2StateDeclaration = {
  name: 'doss.akce.data.organy',
  url: '/organy?page',
  data: {title: 'Dotčené orgány'},
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
  views: {
    'content@doss': {
      component: DotceneOrganyListComponent
    }
  }
};

export const organDetailState: Ng2StateDeclaration = {
  name: 'doss.akce.data.organy.detail',
  url: '/:id/detail/{tab:ukony|kontakty|uradovny|odbory}',
  params: {
    id: {
      type: 'int',
      value: undefined,
    },
    tab: {
     value: null,
     dynamic: true,
     inherit: false,
     squash: true
    }
  },
  data: {title: 'Dotčený orgán'},
  views: {
    'content@doss': {
      component: DotcenyOrganDetailComponent
    }
  },
  resolve: {
    id: ['$stateParams', resolveId]
  }
};

export const organCreateState: Ng2StateDeclaration = {
  name: 'doss.akce.data.organy.create',
  url: '/create',
  data: {title: 'Založení nový DOSS'},
  params: {
    redirectToPageAfterDossSave: null,
    redirectToPageParamsAfterDossSave: null
  },
  views: {
    'content@doss': {
      component: DotcenyOrganCreateComponent
    }
  }
};
