import {Injectable} from '@angular/core';
import {DalsiUkonStatuses, RozhodnutiStatuses, UkonStatusConfigModel} from "@app/doss/models/ukon-status-config.model";
import {TypUkonuModel} from "@app/doss/models/typ-ukonu.model";

@Injectable({
  providedIn: 'root'
})
export class UkonStatusService {

  getUkonStatusConfig(typUkonu: TypUkonuModel, stav: string, faze?: string): UkonStatusConfigModel {
    if (typUkonu.id === "ROZHODNUTI") {
      return RozhodnutiStatuses.STATUSES.find(status => status.stav === stav && status.faze === faze);
    }
    return DalsiUkonStatuses.STATUSES.find(status => status.stav === stav && status.faze === faze);
  }
}
