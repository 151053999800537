import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {TypUkonuModel} from "@app/doss/models/typ-ukonu.model";

@Pipe({name: 'typUkonu'})
@Injectable({
  providedIn: 'root'
})
export class TypUkonuPipe implements PipeTransform {

  transform(typUkonu: TypUkonuModel): string {
    return !typUkonu ? "" : `${typUkonu.description}`;
  }
}
