import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "@app/common/services/auth.service";
import {UkonService} from "@app/doss/services/ukon.service";
import {UkonModel} from "@app/doss/models/ukon.model";

@Component({
  selector: 'oblibeny-ukon',
  templateUrl: './oblibeny-ukon.component.html',
  styleUrls: ['./oblibeny-ukon.component.scss']
})
export class OblibenyUkonComponent implements OnInit {
  @Input() ukon: UkonModel;
  @Input() size: string = '24px';

  akceSchema: string;

  constructor(private authService: AuthService,
              private ukonService: UkonService) {
  }

  async ngOnInit() {
    this.akceSchema = this.authService.getActualProject().schema;
  }

  canEdit(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  public createOblibenyUkon() {
    this.ukonService.createOblibenyUkon(this.akceSchema, this.ukon.id).then(ukon => this.ukon = ukon);
  }
}
